import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";


export const setRisks = (risks, initiativeId) => ({
    type: "SET_RISKS",
    payload: {
        risks: risks,
        initiativeId: initiativeId,
    },
    request_time: Date.now()
});

export const setRisk = (risk) => ({
    type: "SET_RISK",
    payload: {
        id: risk.id,
        description: risk.description,
        severity: risk.severity,
        mitigator: risk.mitigator,
        risk_type: risk.risk_type,
        probability: risk.probability || "1",
        initiative_id: risk.initiative_id,
        
        request_time: Date.now()
    },
});

export const setUpdateRisk = (risk) => ({
    type: "UPDATE_RISK",
    payload: {
        id: risk.id,
        description: risk.description,
        severity: risk.severity,
        mitigator: risk.mitigator,
        risk_type: risk.risk_type,
        probability: risk.probability || "1",
        initiative_id: risk.initiative_id,

        request_time: Date.now()
    },
})

export const setRiskError = (error) => ({
    type: "RISK_ERROR",
    payload: {
        error: error
    }
});

export const deleteRisk = (risk_id) => ({
    type: "DELETE_RISK",
    payload: {
        id: risk_id,
    },
    request_time: Date.now()
});

export const getInitiativeRisks = (initiativeId) => {

    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "risks", "list")) {
                throw new Error("No permissions");
            }
            if (state.risks.request_time + 1000 * 2 < Date.now()
                || state.risks.initiativeId === null
                || state.risks.request_time === null
                || state.risks.initiativeId !== initiativeId) {
                fetch(`${BACKEND_URL}/initiatives/${initiativeId}/risks`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setRisks(data, initiativeId));
                    })
                    .catch((error) => {
                        errorHandler("risks", error.message);
                        dispatch(setRiskError(error));
                    });
            }
        } catch (error) {
            errorHandler("risks", error.message);
            dispatch(setRiskError(error));
        }
    };
}

export const postRisk = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            // if (!getUserPermission(state, "risks", "create", true)) {
            //     throw new Error("No permissions");
            // }
            if (state.initiatives.initiative.leader_user === state.sessions.user.id
                || state.initiatives.initiative.sponsor_user === state.sessions.user.id
                || state.sessions.user.role === 'admin'
                || getUserPermission(state, "risks", "create", true)) {
                fetch(`${BACKEND_URL}/risks`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                    body: JSON.stringify(data)
                })
                    .then((response) => {
                        if (response.status === 201) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        if (data != null && data !== undefined && data.id !== undefined) {
                            dispatch(setRisk(data));
                        }
                    })
                    .catch((error) => {
                        errorHandler("risks", error.message);
                        dispatch(setRiskError(error));
                    });
            }
        } catch (error) {
            errorHandler("risks", error.message);
            dispatch(setRiskError(error));
        }
    };
}

export const updateRisk = (risk_id, data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            // if (!getUserPermission(state, "risks", "update", true)) {
            //     throw new Error("No permissions");
            // }
            if (state.initiatives.initiative.leader_user === state.sessions.user.id
                || state.initiatives.initiative.sponsor_user === state.sessions.user.id
                || state.sessions.user.role === 'admin'
                || getUserPermission(state, "risks", "patch", true)) {
                fetch(`${BACKEND_URL}/risks/${risk_id}`, {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                    body: JSON.stringify(data)
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        if (data != null && data !== undefined && data.id !== undefined) {
                            dispatch(setUpdateRisk(data));
                        }
                    })
                    .catch((error) => {
                        errorHandler("risks", error.message);
                        dispatch(setRiskError(error));
                    });
            }
        } catch (error) {
            errorHandler("risks", error.message);
            dispatch(setRiskError(error));
        }
    };
};

export const deleteRiskById = (risk_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "risks", "delete", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/risks/${risk_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
                .then((response) => {
                    if (response.status === 204) {
                        return true;
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((vl) => {
                    if (vl) {
                        dispatch(deleteRisk(risk_id));
                    }
                })
                .catch((error) => {
                    errorHandler("risks", error.message);
                    dispatch(setRiskError(error));
                });
        } catch (error) {
            errorHandler("risks", error.message);
            dispatch(setRiskError(error));
        }
    };
}