import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { patchTask } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, Modal, Input, Form } from "reactstrap";
const AcceptTask = ({ dispatch, task, editableInInitiative }) => {
  const areas = useSelector((state) => state.areas.areas);
  const users = useSelector((state) => state.users.users);
  const stages = useSelector((state) => state.stages.stages);
  const phases = useSelector((state) => state.phases.phases);

  let [modalTasks, setModalTasks] = useState(false);

  let [loadedTask, setLoadedTask] = useState(true);

  let [description, setDescription] = useState(task.description);
  let [area_id, setAreaId] = useState(task.area_id);
  let [stageId, setStageId] = useState(task.stage);
  let [forecast_date, setForecastDate] = useState(task.forecast_date);
  let [responsable_user, setResponsableUser] = useState(task.responsable_user);
  let [checked, setChecked] = useState(false);
  let [estimated, setEstimated] = useState("");

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalTasks(false);
  });

  useEffect(() => {
    if (loadedTask) {
      setEstimated(task.estimated_date.split("T")[0]);
      setLoadedTask(false);
    }
  }, [task, loadedTask]);

  const addTask = () => {
    if (modalTasks) {
      return <></>;
    }
    return (
      <Button
        color='primary'
        className='btn-sm'
        onClick={() => setModalTasks(true)}
      >
        {language["tasks"]["form"]["edit"]}
      </Button>
    );
  };

  const handleSubmitTasks = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    dispatch(patchTask(task.id, object));
    setModalTasks(false);
    setChecked(false);
  };

  return (
    <div>
      <Button
        color='primary'
        className='btn-sm'
        onClick={() => setModalTasks(true)}
      >
        {language["tasks"]["form"]["edit"]}
      </Button>
      <Modal
        className='modal-dialog-centered'
        isOpen={modalTasks}
        toggle={() => setModalTasks(false)}
      >
        <div className='modal-body p-2'>
          <Card className='bg-secondary shadow border-0'>
            <CardBody className='px-lg-5 py-lg-2'>
              <br />
              <Form onSubmit={handleSubmitTasks}>
                {editableInInitiative ? (
                  <div>
                    <label htmlFor='description'>
                      {language["tasks"]["table"]["description"]}
                    </label>
                    <Input
                      className='form-control-sm'
                      maxLength={254}
                      type='text'
                      name='description'
                      required
                      multiple={true}
                      autoComplete='off'
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    <br />
                    <label>{language["tasks"]["table"]["area"]}</label>
                    <Input
                      className='form-control-sm'
                      type='select'
                      name='area_id'
                      required
                      value={area_id}
                      onChange={(e) => setAreaId(e.target.value)}
                    >
                      {areas.map((area) => (
                        <option key={area.id} value={area.id}>
                          {area.name}
                        </option>
                      ))}
                    </Input>
                    <br />
                    <label>{language["tasks"]["table"]["user"]}</label>
                    <Input
                      className='form-control-sm'
                      type='select'
                      name='responsable_user'
                      required
                      value={responsable_user}
                      onChange={(e) => setResponsableUser(e.target.value)}
                    >
                      {Object.values(users).map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.full_name}
                        </option>
                      ))}
                    </Input>
                    <br />

                    <label>{language["tasks"]["table"]["forecast_date"]}</label>
                    <Input
                      className='form-control-sm'
                      type='date'
                      name='forecast_date'
                      required
                      value={forecast_date.split("T")[0]}
                      onChange={(e) => setForecastDate(e.target.value)}
                    />
                    <br />
                    <label htmlFor='estimated_date'>
                      {language["activities"]["table"]["estimated_date"]}
                    </label>
                    <Input
                      className='form-control-sm'
                      type='date'
                      name='estimated_date'
                      value={estimated}
                      onChange={(e) => setEstimated(e.target.value)}
                    />
                  </div>
                ) : (
                  <div>
                    <label htmlFor='estimated_date'>
                      {language["tasks"]["table"]["estimated_date"]}
                    </label>
                    <Input
                      className='form-control-sm'
                      type='date'
                      name='estimated_date'
                      value={estimated}
                      onChange={(e) => setEstimated(e.target.value)}
                    />
                  </div>
                )}
                <div>
                  <label htmlFor='completed'>
                    {language["tasks"]["table"]["completed"]}
                  </label>
                  <Input
                    className='form-control-sm'
                    type='select'
                    name='completed'
                    value={checked}
                    onChange={(e) => setChecked(e.target.value)}
                  >
                    <option value={false}>
                      {language["tasks"]["completed"]["no"]}
                    </option>
                    <option value={true}>
                      {language["tasks"]["completed"]["yes"]}
                    </option>
                    <option value={null}>
                      {language["tasks"]["completed"]["annulment"]}
                    </option>
                  </Input>
                </div>
                <br />
                <div>
                  <Button color='success' className='btn-sm' type='submit'>
                    {language["tasks"]["form"]["save"]}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default connect()(AcceptTask);
