import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { language } from "../../assets/language";
import { getInitiativeTshifts,  getTreeTshifts } from "../../redux/actions";
import {  Row } from "reactstrap";
import Loading from "../../components/Loading/Loading";
import BarGraph from "../../components/Graphs/BarGraph";
import { fixNumber } from "../../utils/fix_number";
import moment from "moment";

const TreeGraph = ({  initiative_id, start, end, tree=null, is_initiative = true, height= "50vh" }) => {
  const initiativeTshift = useSelector((state) => state.tshifts.tshifts);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [stats, setStats] = useState([]);

  
  const unit =  localStorage.getItem("unit") !== "undefined" ? JSON.parse(localStorage.getItem("unit")) : "";

  let [tshifts, setTshifts] = useState([]);
  useEffect(() => {
    if (initiativeTshift.length > 0)  {
      setTshifts(initiativeTshift);
      setLoading(false);
    }

  }, [initiativeTshift, is_initiative]);

 

  useEffect(() => {
    if(start && end) {
      let startDate = moment(start, "DD-MM-YYYY");
      let endDate = moment(end, "DD-MM-YYYY");
      if(startDate.isValid() && endDate.isValid()) {
        if (is_initiative) {
          dispatch(getInitiativeTshifts(initiative_id,startDate.format("DD-MM-YYYY"), endDate.format("DD-MM-YYYY")))
        } else {
          dispatch(getTreeTshifts(tree.id,startDate.format("DD-MM-YYYY"), endDate.format("DD-MM-YYYY")))
        }
        
      }
     
  }
   
  }, [start,end]);

  useEffect(() => {
    let _stats = {};
    // each tshift has month, year, base, forecast, initiative_id
    // iterate over each tshift and create stat with month, year, base_sum, forecast_sum
    // base_sum is the sum of all tshifts base that are in the same month and year of the stat
    // forecast_sum is the sum of all tshifts forecast that are in the same month and year of the stat
    tshifts.forEach((th) => {
      let inf = `${th.month}-${th.year}`;
      if (_stats.hasOwnProperty(inf)) {
        _stats[inf]["base_sum"] += fixNumber(th.base);
        _stats[inf]["forecast_sum"] += fixNumber(th.forecast);
        _stats[inf]["real_sum"] += fixNumber(th.real);
        
      } else {
        _stats[inf] = {
          base_sum: fixNumber(th.base),
          forecast_sum: fixNumber(th.forecast),
          real_sum: fixNumber(th.real),
        };
      }
    });
    setStats(_stats);
    setLoading(false);
  }, [tshifts]);




 
    return loading ?  <Loading /> :   (
      <Row style={{margin:"10px"}} >
      
        <h3 className='text-center'>
          {language.initiatives.stats.initiative_value_month} {unit.value}
        </h3>
        <BarGraph stats={stats} height={height} promiseLine={tree?.promise_value} baseLine={tree?.initial_value} />
      </Row>
    );
};

export default connect()(TreeGraph);
