import React, { useEffect, useMemo , useState} from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getAllAreas,
  getAllPhases,
  getAllInfoStates,
  getAllUsers,
} from "../../redux/actions";
import ActivateInitiative from "./ActivateInitiative";
import { language } from "../../assets/language";
import SimpleTable from "../../components/tables/SimpleTable";
import DescriptionModal from "../../components/Modal/DescriptionModal";


import { Badge,   } from "reactstrap";
const ListInitiativeSummary = ({
  dispatch,
  initiatives,
  handle,
  fullscreen,
  initiativesId = null,
}) => {
  const areas = useSelector((state) => state.areas.areas);
  const info_states = useSelector((state) => state.info_states.info_states);
  const currentUser = useSelector(state => state.sessions.user);
  const users = useSelector((state) => state.users.users);
  const [loading, setLoading] = useState(true);

  const info_states_dict = useMemo(() => {
    return Object.values(info_states).reduce((inf, info) => {
      inf[info.id] = info;
      return inf;
    }, {});
  }, [info_states]);
  const phases = useSelector((state) => state.phases.phases);
  const areas_dict = useMemo(() => {
    return areas.reduce((acc, area) => {
      acc[area.id] = area;
      return acc;
    }, {});
  }, [areas]);

  useEffect(() => {
    dispatch(getAllPhases());
    dispatch(getAllAreas()); 
    dispatch(getAllInfoStates());
    dispatch(getAllUsers());
  }, []);

  useEffect(() => {
    if (areas && phases && info_states && users) {
      setLoading(false);
    }
  }, [areas, phases, info_states, users]);

  const columns = useMemo(() => {
    let col = [
      {
        Header: language.initiatives.table.title,
        accessor: "title",
       
        sortType: (rowA, rowB) => {
          // Asumiendo que title es un objeto JSX y el texto está en props.children
          let a = rowA.original.title.props.children.toString().toLowerCase();
          let b = rowB.original.title.props.children.toString().toLowerCase();
          return a.localeCompare(b);
        },
      },
      {
        Header: language.initiatives.table.responsability,
        accessor: "responsability",
      },
      {
        Header: language.initiatives.table.stage,
        accessor: "stage",
      },
      {
        Header: language.initiatives.table.year_value,
        accessor: "year_value",
      },
      {
        Header: language.initiatives.table.accumulated_total,
        accessor: "accumulated_total",
      },
      {
        Header: language.initiatives.table.accumulated_current,
        accessor: "accumulated_current",
      },
      {
        Header: language.initiatives.table.current,
        accessor: "current",
      },
      {
        Header: language.initiatives.table.last_task,
        accessor: "last_task",
      },
      {
        Header: language.initiatives.table.last_task_date,
        accessor: "last_task_date",
      },
      {
        Header: language.initiatives.table.last_task_responsible,
        accessor: "last_task_responsible",
      },
    ];
    return col;
  }, []);

  // render areas in list with name, description, parent_id
  const renderInitiatives = () => {
    if (!initiatives || initiatives.length === 0) {
      return <p>{language["initiatives"]["not_found"]}</p>;
    }
    let initiativesList = []
    if (!loading) {


    initiativesList = initiatives.map((initiative) => {
      return {
        title: !initiativesId ? (
          <Link to={`/initiative/${initiative.id}`}>{initiative.title}</Link>
        )
        :  initiativesId.includes(initiative.id) ? (
          <Link to={`/initiative/${initiative.id}`}>{initiative.title}</Link>
        ) : initiative.title,
        responsability: initiative.leader_user === currentUser.id ?
          currentUser.firstname + " " + currentUser.lastname : users[initiative.leader_user] ? 
          users[initiative.leader_user].firstname + " " + users[initiative.leader_user].lastname : "",
        area: areas_dict && initiative["area_id"] && areas_dict.hasOwnProperty(initiative["area_id"]) ? 
          areas_dict[initiative["area_id"]].name : "",
        stage: initiative.stage === "deleted" ? (
            <ActivateInitiative initiativeId={initiative.id} />
          ) : phases.hasOwnProperty(initiative.stage) ? (
            <div 
              style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"row", flexWrap:"nowrap", width:"100%"}}
            >
              <span 
                className="dot" 
                style={{
                  backgroundColor: initiative.status === "in_progress"  ? "rgb(91, 166, 145)": initiative.status === "complete" ? "blue" : "red", 
                  height:"7px", 
                  width:"7px", 
                  display: "inline-block", 
                  borderRadius:"50%", 
                  margin:"1px", 
                  marginRight:"5px"
                }}
              />
              {phases[initiative.stage].name }
            </div>    
          ) : (
            <>
              {initiative.stage}
            </>
          ),
        status: info_states_dict.hasOwnProperty(initiative.actual_state) ? (
          initiative.status === "complete" ||
          initiative.status === "in_progress" ? (
            <Badge color='success'>
              {language["initiatives"]["status"][initiative.status]}
            </Badge>
          ) : (
            <Badge color='danger'>
              {language["initiatives"]["status"][initiative.status]}
            </Badge>
          )
        ) : (
          <Badge color='secondary'>{language["initiatives"]["no_state"]}</Badge>
        ),
        current: initiative.current ? (
          fullscreen ? (
            <div style={{ overflowWrap: "break-word" }}>
              {initiative.current}
            </div>
          ) : (
            <DescriptionModal
              handle={handle}
              dashboard={true}
              description={initiative.current}
              title={initiative.title}
            />
          )
        ) : (
          language.initiatives.no_info
        ),
        year_value: (initiative.last_month_value * 12 || 0).toFixed(0),
        accumulated_total: initiative.accumulated_total,
        accumulated_current: initiative.accumulated_current,
        last_task: initiative.last_task
          ? initiative.last_task.description
          : language["initiatives"]["no_task"],
        last_task_date: initiative.last_task
          ? initiative.last_task.estimated_date.split("T")[0]
          : language["initiatives"]["no_task"],
        last_task_responsible: initiative.last_task ? (
          users[initiative.last_task.responsable_user] ? (
            users[initiative.last_task.responsable_user].firstname +
            " " +
            users[initiative.last_task.responsable_user].lastname
          ) : (
            language["initiatives"]["no_responsible"]
          )
        ) : (
          language["initiatives"]["no_task"]
        ),
      };
    
    });
    }
  
    if (!initiativesList || initiativesList.length === 0) {
      return <p>{language["initiatives"]["not_found"]}</p>;
    }

    return <SimpleTable data={initiativesList} columns={columns} />;
  };


  return <div>{renderInitiatives()}</div>;
};

export default connect()(ListInitiativeSummary);
