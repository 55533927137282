import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import {
  getAllGroups,
  addRoleGroup,
  deleteRoleGroup,
  getRoleGroups,
} from "../../redux/actions";
import { language } from "../../assets/language";
import SimpleTable from "../../components/tables/SimpleTable";
import { Input } from "reactstrap";
import Loading  from "../../components/Loading/Loading"

const ListRoleGroups = ({ dispatch, role }) => {
  const groups = useSelector((state) => state.groups.groups);
  const role_groups = useSelector(
    (state) => state.role_groups.role_groups
  );
  const loadingGroups = useSelector((state) => state.role_groups.loading);

  const [role_groups_change, setRoleGroupsChange] = useState(false);
  const [loading , setLoading] = useState(true);

  useEffect(() => {
    dispatch(getAllGroups());
    dispatch(getRoleGroups(role.id));
  }, [dispatch, role, groups, loadingGroups]);

  useEffect(() => {
    if ( role_groups.length > 0) {
      setRoleGroupsChange(false);
      setLoading(false);
    }
  }, [role_groups, role_groups_change]);

  useEffect(() => {
    if(groups && role_groups){
      setLoading(false);
    }
    }, [groups, role_groups]);

  const changeRoleGroup = (e) => {
    setLoading(true);
    e.preventDefault();
    const group_id = e.target.value;
    const role_group = role_groups.find((rp) => rp.permission_group_id === group_id);
    if (role_group) {
      dispatch(deleteRoleGroup(role_group.role_id, role_group.id));
    } else {
      dispatch(
        addRoleGroup({ role_id: role.id, group_id: group_id }, role.id)
      );
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: language["groups"]["table"]["name"],
        accessor: "name",
      },
      {
        Header: language.groups.table.list,
        accessor: "list",
      },
      {
        Header: language.groups.table.create,
        accessor: "create",
      },
      {
        Header: language.groups.table.patch,
        accessor: "patch",
      },
      {
        Header: language.groups.table.change,
        accessor: "change",
      },
      {
        Header: language.groups.table.delete,
        accessor: "delete",
      },
    ],
    []
  );

  const renderRoleGroups = () => {
    if (loading) {
      return <Loading />
    }
    if ( !groups || !role_groups) {
      return <p>{language["groups"]["not_found"]}</p>;
    }

    
    //filter groups by inititative true
    let groupsFiltered = groups.filter((group) => group.initiative !== true);


    const groupTransposed = Object.entries(groupsFiltered.reduce((acc, { id, name, action, initiative }) => {
      if (!acc[name]) {
        acc[name] = [];
      }
      acc[name].push({id: id, action: action , type: initiative});
      
      return acc;
    }, {}))
    let _groups = groupTransposed.map((group) => {

      return {
        id: group[1][0].id,
        name: group[0],
        list:
          <Input
            className='form-control-md mb-5 mt-0'
            id={group[1][0].id}
            type='checkbox'
            checked={role_groups.find((p) => p.permission_group_id === group[1][0].id) ? true : false}
            onChange={(e) =>changeRoleGroup(e)}
            value={group[1][0].id}
            />,
        create:
          <Input
            className='form-control-md mb-5 mt-0'
            id={group[1][1].id}
            type='checkbox'
            checked={role_groups.find((p) => p.permission_group_id === group[1][1].id) ? true : false}
            onChange={(e) =>changeRoleGroup(e)}
            value={group[1][1].id}
            />,
        patch:
          <Input
            className='form-control-md mb-5 mt-0'
            id={group[1][2].id}
            type='checkbox'
            checked={role_groups.find((p) => p.permission_group_id === group[1][2].id) ? true : false}
            onChange={(e) =>changeRoleGroup(e)}
            value={group[1][2].id}
            />,
        change:
          <Input
            className='form-control-md mb-5 mt-0'
            id={group[1][3].id}
            type='checkbox'
            checked={role_groups.find((p) => p.permission_group_id === group[1][3].id) ? true : false}
            onChange={(e) =>changeRoleGroup(e)}
            value={group[1][3].id}
            />,
        delete:
          <Input
            className='form-control-md mb-5 mt-0'
            id={group[1][4].id}
            type='checkbox'
            checked={role_groups.find((p) => p.permission_group_id === group[1][4].id) ? true : false}
            onChange={(e) =>changeRoleGroup(e)}
            value={group[1][4].id}
            />,


       
            
       
      };
    });

    return <SimpleTable data={_groups} columns={columns} />;
  };

  return (
    <div>
      {!loading ? (
        renderRoleGroups()
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default connect()(ListRoleGroups);
