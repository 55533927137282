import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { deleteUserById } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { Button, Card } from "reactstrap";
import DeleteModal from "../../components/Modal/DeleteModal";

const DeleteUser = ({ dispatch, user }) => {
  let [modalUsers, setModalUsers] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalUsers(false);
  });

  const deleteUserButton = () => {
    if (modalUsers) {
      return <></>;
    }
    return (
      <Button
        color='danger'
        className='btn-sm'
        onClick={() => setModalUsers(true)}
      >
        {language["users"]["form"]["delete"]}
      </Button>
    );
  };

  const handleSubmitUsers = () => {
    dispatch(deleteUserById(user.id));
    setModalUsers(false);
  };

  const renderModalFormUsers = () => {
    if (modalUsers) {
      return (
        <div  ref={wrapperRef}>
          <Card className="p-3">
          
          <div>
            <Button
              color='danger'
              block
              className='btn-sm'
              onClick={() => handleSubmitUsers()}
            >
              {language["users"]["form"]["deleteConfirm"]}
            </Button>
          </div>
          <div>
            <Button
              color='secondary'
              block
              className='btn-sm'
              onClick={() => setModalUsers(false)}
            >
              {language["general"]["cancel"]}
            </Button>
          </div>
          </Card>
        </div>
      );
    }
  };

  return (
    <DeleteModal
      buttonText={language["users"]["form"]["delete"]}
      confirmText={language["users"]["form"]["deleteConfirm"]}
      confirm={handleSubmitUsers}
    />
  );
};

export default connect()(DeleteUser);
