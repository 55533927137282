import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { getAllTrees, postTree } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { Button, Card, CardHeader, CardBody } from "reactstrap";
import {
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Container,
  Table,
  Modal,
} from "reactstrap";
const CreateTree = ({ dispatch }) => {
  const trees = useSelector((state) => state.trees.trees);

  let [modalTrees, setModalTrees] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalTrees(false);
  });

  let [name, setName] = useState("");
  let [abv, setAbv] = useState("");
  let [parent_id, setParentId] = useState(
    trees && trees.length > 0 ? trees[0].id : ""
  );

  const handleSubmitTrees = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    if (parent_id === "") {
      object.parent_id = null;
    }
    if (trees && trees.length > 0) {
      if (parent_id !== "") {
        dispatch(postTree(object));
      }
    } else {
      dispatch(postTree(object));
    }
    setModalTrees(false);
    setName("");
    setAbv("");
  };

  useEffect(() => {
    dispatch(getAllTrees());
  }, [dispatch]);

  const renderFormTree = () => {
    return (
      <div>
        <Button
          className='btn-sm'
          color='success'
          onClick={() => setModalTrees(true)}
        >
          {language["trees"]["form"]["create"]}
        </Button>
        <Modal
          className='modal-dialog-centered'
          isOpen={modalTrees}
          toggle={() => setModalTrees(false)}
        >
          <div className='modal-body p-0'>
            <Card className='bg-secondary shadow border-0' ref={wrapperRef}>
              <CardBody className='px-lg-5 py-lg-2'>
                <h2>{language["trees"]["form"]["create"]}</h2>
                <Form onSubmit={handleSubmitTrees}>
                  <label htmlFor='name'>
                    {language["trees"]["table"]["name"]}
                  </label>
                  <Input
                    className='form-control-sm'
                    maxLength={254}
                    type='text'
                    name='name'
                    required
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                  <br />
                  <label htmlFor='abv'>
                    {language["trees"]["table"]["abv"]}
                  </label>
                  <Input
                    className='form-control-sm'
                    maxLength={254}
                    type='text'
                    name='abv'
                    required
                    multiple={true}
                    onChange={(e) => setAbv(e.target.value.toLowerCase())}
                    value={abv}
                  />
                  <br />
                  {trees && trees.length > 0 ? (
                    <label>{language["trees"]["table"]["parent"]}</label>
                  ) : null}
                  {trees && trees.length > 0 ? (
                    <Input
                      className='form-control-sm'
                      type='select'
                      name='parent_id'
                      required
                      onChange={(e) => {
                        setParentId(e.target.value);
                      }}
                      value={parent_id}
                    >
                      {trees.map((tree) => (
                        <option key={tree.id} value={tree.id}>
                          {language.trees.names[tree.name] || tree.name}
                        </option>
                      ))}
                    </Input>
                  ) : (
                    <></>
                  )}
                  <br />
                  <br />
                  <Button className='btn-sm' color='success' type='submit'>
                    {language["trees"]["form"]["create"]}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </div>
    );
  };

  return <>{renderFormTree()}</>;
};

export default connect()(CreateTree);
