import { combineReducers } from "redux";
import sessions from "./sessions";
import areas from "./areas";
import activities from "./activities";
import users from "./users";
import cards from "./cards";
import initiatives from "./initiatives";
import kpi_seconds from "./kpi_seconds";
import risks from "./risks";
import stages from "./stages";
import phases from "./phases";
import approvals from "./approvals";
import checklists from "./checklists";
import initiative_checklists from "./initiative_checklists";
import levers from "./levers";
import initypes from "./initypes";
import initiative_users from "./initiative_users";
import roles from "./roles";
import permits from "./permits";
import groups from "./groups";
import advances from "./advances";
import kshifts from "./kshifts";
import configs from "./configs";
import trees from "./trees";
import tshifts from "./tshifts";
import tasks from "./tasks";
import info_states from "./info_states";
import counter_stats from "./counter_stats";
import tags from "./tags";
import decisions from "./decisions";
import initiatives_stage from "./inititatives_stage";
import group_permits from "./group_permits";
import role_groups from "./role_groups";
import exports from "./exports";
import documents from "./documents";

export default combineReducers({
  sessions,
  areas,
  activities,
  users,
  cards,
  initiatives,
  kpi_seconds,
  risks,
  stages,
  phases,
  approvals,
  checklists,
  initiative_checklists,
  levers,
  initypes,
  initiative_users,
  roles,
  permits,
  advances,
  kshifts,
  configs,
  tshifts,
  trees,
  tasks,
  tags,
  info_states,
  counter_stats,
  decisions,
  initiatives_stage,
  groups,
  group_permits,
  role_groups,
  exports,
  documents
});
