import {
  setInitiative,
  setInitiativeApprovals,
  setInitiativeChecklists,
} from ".";
import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";
import { toast } from "react-toastify";
import { language } from "../../assets/language";
import { getResponsabilityPermission } from "../../utils/getResponsabilityPermission";

export const setStagesError = (error) => ({
  type: "STAGES_ERROR",
  payload: {
    error: error,
  },
});

export const setStages = (stages, initiativeId) => ({
  type: "SET_STAGES",
  payload: {
    stages: stages,
    initiativeId: initiativeId,
  },
  request_time: Date.now(),
});

export const setAllStages = (stages) => ({
  type: "SET_ALL_STAGES",
  payload: {
    stages: stages,
  },
  request_time: Date.now(),
});

export const setStage = (data) => ({
  type: "SET_STAGE",
  payload: {
    id: data.id,
    name: data.name,
    forecast_date: data.forecast_date,
    estimated_date: data.estimated_date,
    real_date: data.real_date,
    start_date: data.start_date,
    completed: data.completed,
    request_time: Date.now(),
  },
});

export const deleteStage = (stage_id) => ({
  type: "DELETE_STAGE",
  payload: {
    id: stage_id,
  },
  request_time: Date.now(),
});

export const updateStage = (stage) => ({
  type: "UPDATE_STAGE",
  payload: {
    id: stage.id,
    forecast_date: stage.forecast_date,
    estimated_date: stage.estimated_date,
    real_date: stage.real_date,
    start_date: stage.start_date,
    completed: stage.completed,
    initiative_id: stage.initiative_id,
    name: stage.name,
  },
  request_time: Date.now(),
});

export const getStages = (initiativeId, force = false) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (!getUserPermission(state, "stages", "list", true)) {
        throw new Error("No permissions");
      }
      if (
        force ||
        state.stages.request_time + 1000 * 2 < Date.now() ||
        state.stages.request_time === null ||
        state.stages.initiativeId !== initiativeId
      ) {
        fetch(`${BACKEND_URL}/initiatives/${initiativeId}/stages`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.sessions.token}`,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              throw new Error(response.status);
            }
          })
          .then((data) => {
            dispatch(setStages(data, initiativeId));
          })
          .catch((error) => {
            errorHandler("stages", error.message);
            dispatch(setStagesError(error.message));
          });
      }
    } catch (error) {
      errorHandler("stages", error.message);
      dispatch(setStagesError(error.message));
    }
  };
};

export const postStage = (data) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (!getUserPermission(state, "stages", "create", true)) {
        throw new Error("No permissions");
      }
      fetch(`${BACKEND_URL}/stages`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.sessions.token}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 201) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          if (data != null && data !== undefined && data.id !== undefined) {
            dispatch(setStage(data));
          }
        })
        .catch((error) => {
          errorHandler("stages", error.message);
          dispatch(setStagesError(error.message));
        });
    } catch (error) {
      errorHandler("stages", error.message);
      dispatch(setStagesError(error.message));
    }
  };
};

export const postStageChange = (initiativeId, data) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      /*if (state.initiatives.initiative.leader_user === state.sessions.user.id
                || state.initiatives.initiative.sponsor_user === state.sessions.user.id
                || state.sessions.user.role === 'admin'
                || !getUserPermission(state, "initiatives", "change-stage", true)) {
                throw new Error("No permissions");
            }*/
      fetch(`${BACKEND_URL}/initiatives/${initiativeId}/stages/next`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.sessions.token}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          if (
            data != null &&
            data !== undefined &&
            data.initiative &&
            data.initiative.id !== undefined
          ) {
            dispatch(setInitiative(data.initiative));
            dispatch(getStages(initiativeId, true));
            if (data.hasOwnProperty("checklists")) {
              dispatch(setInitiativeChecklists(data.checklists, initiativeId));
            }
            if (data.hasOwnProperty("approvals")) {
              dispatch(setInitiativeApprovals(data.approvals, initiativeId));
            }
            try {
              let text = language.stages.change[data.message];
              toast.info(text);
            } catch (error) {}
          }
        })
        .catch((error) => {
          errorHandler("stages", error.message);
          dispatch(setStagesError(error.message));
        });
    } catch (error) {
      errorHandler("stages", error.message);
      dispatch(setStagesError(error.message));
    }
  };
};

export const deleteStageById = (stage_id) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (!getUserPermission(state, "stages", "delete", true)) {
        throw new Error("No permissions");
      }
      fetch(`${BACKEND_URL}/stages/${stage_id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.sessions.token}`,
        },
      })
        .then((response) => {
          if (response.status === 204) {
            return true;
          } else {
            throw new Error(response.status);
          }
        })
        .then((vl) => {
          if (vl) {
            dispatch(deleteStage(stage_id));
          }
        })
        .catch((error) => {
          errorHandler("stages", error.message);
          dispatch(setStagesError(error));
        });
    } catch (error) {
      errorHandler("stages", error.message);
      dispatch(setStagesError(error));
    }
  };
};

export const patchStage = (stageId, data) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (
       getResponsabilityPermission(state,"stage","change","","Lider", true) ||
        state.sessions.user.role === "admin" ||
        getUserPermission(state, "stages", "patch", true)
      ) {
        fetch(`${BACKEND_URL}/stages/${stageId}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.sessions.token}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              throw new Error(response.status);
            }
          })
          .then((data) => {
            if (data != null && data !== undefined && data.id !== undefined) {
              dispatch(updateStage(data));
              toast.success(language.stages.success)

    

            }
          })
          .catch((error) => {
            errorHandler("stages", error.message);
            dispatch(setStagesError(error));
          });
      }
    } catch (error) {
      errorHandler("stages", error.message);
      dispatch(setStagesError(error));
    }
  };
};

export const getAllStagesStats = () => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (!getUserPermission(state, "stages", "list", true)) {
        throw new Error("No permissions");
      }
      if (
        state.stages.stats_request_time + 1000 * 2 < Date.now() ||
        state.stages.stats_request_time === null
      ) {
        fetch(`${BACKEND_URL}/stages/stats`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.sessions.token}`,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              throw new Error(response.status);
            }
          })
          .then((data) => {
            dispatch(setAllStages(data));
          })
          .catch((error) => {
            errorHandler("stages", error.message);
            dispatch(setStagesError(error.message));
          });
      }
    } catch (error) {
      errorHandler("stages", error.message);
      dispatch(setStagesError(error.message));
    }
  };
};
