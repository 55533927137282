import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getAllAreas,
  getAllPhases,
  getCurrentUserInitiatives,
} from "../../redux/actions";
import { language } from "../../assets/language";
import SimpleTable from "../../components/tables/SimpleTable";
import { getUserPermission } from "../../utils/getUserPermission";
import { DeleteInitiative } from ".";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Modal,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  Input,
  ListGroupItem,
  Form,
  ListGroup,
  Row,
  Col,
  FormGroup,
} from "reactstrap";

const ListMyInitiatives = ({ dispatch }) => {
  const state = useSelector((state) => state);
  const initiatives = useSelector((state) => state.initiatives.initiatives);
  const areas = useSelector((state) => state.areas.areas);
  const phases = useSelector((state) => state.phases.phases);
  const areas_dict = useMemo(() => {
    return areas.reduce((acc, area) => {
      acc[area.id] = area;
      return acc;
    }, {});
  }, [areas]);

  useEffect(() => {
    dispatch(getAllPhases());
    dispatch(getCurrentUserInitiatives());
    dispatch(getAllAreas());
  }, [dispatch]);

  const columns = useMemo(() => {
    let col = [
      {
        Header: language.initiatives.table.title,
        accessor: "title",
      },
      {
        Header: language.initiatives.table.code,
        accessor: "code",
      },
      {
        Header: language.initiatives.table.area,
        accessor: "area",
      },
      {
        Header: language.initiatives.table.forecast_date,
        accessor: "forecast_date",
      },
      {
        Header: language.initiatives.table.stage,
        accessor: "stage",
      },
      {
        Header: language.initiatives.table.status,
        accessor: "status",
      },
    ];
    if (getUserPermission(state, "initiatives", "delete")) {
      col.push({
        Header: language.initiatives.form.actions,
        accessor: "actions",
      });
    }
    return col;
  }, [state]);

  // render areas in list with name, description, parent_id
  const renderInitiatives = () => {
    if (!initiatives || initiatives.length === 0) {
      return (
        <small className='text-uppercase text-muted font-weight-bold'>
          {language["initiatives"]["not_found"]}
        </small>
      );
    }
    let initiativesList = initiatives.map((initiative) => {
      return {
        title: (
          <Link to={`/initiative/${initiative.id}`}>{initiative.title}</Link>
        ),
        code: initiative.code,
        area:
          areas_dict &&
          initiative["area_id"] &&
          areas_dict.hasOwnProperty(initiative["area_id"])
            ? areas_dict[initiative["area_id"]].name
            : "",
        forecast_date:
          initiative.forecast_date.split("T")[0] ||
          language["initiatives"]["no_date"],
        stage: phases.hasOwnProperty(initiative.stage)
          ? phases[initiative.stage].name
          : initiative.stage,
        status: language["initiatives"]["status"][initiative.status],
        actions:
          !initiative.is_deleted &&
          getUserPermission(state, "initiatives", "delete") ? (
            <DeleteInitiative initiative={initiative} />
          ) : null,
      };
    });

    return <SimpleTable data={initiativesList} columns={columns} />;
  };

  return (
    <Card>
      <CardBody>
        <h1>{language["initiatives"]["title"]}</h1>
        {renderInitiatives()}
      </CardBody>
    </Card>
  );
};

export default connect()(ListMyInitiatives);
