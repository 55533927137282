import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { getAllAreas, getAllRoles, postUser } from "../../redux/actions";
import { useClickAway } from "react-use";
import { language } from "../../assets/language";
import {
  Button,
  Card,
  FormGroup,
  Form,
  Input,
  Modal,
  CardBody,
} from "reactstrap";

const CreateUser = ({ dispatch }) => {
  const areas = useSelector((state) => state.areas.areas);
  const roles = useSelector((state) => state.roles.roles);

  let [modalUsers, setModalUsers] = useState(false);

  let [firstname, setFirstname] = useState("");
  let [lastname, setLastname] = useState("");
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [area, setArea] = useState("");
  let [role, setRole] = useState("");
  let [roleId, setRoleId] = useState("");
  let [roleInitial, setRoleInitial] = useState(true);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalUsers(false);
  });

  useEffect(() => {
    dispatch(getAllAreas());
    dispatch(getAllRoles());
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    if (!regex.test(password)) {
      alert(
        "Error: La contraseña no cumple con los minimos requerimientos"
      );
      return;
    }


    dispatch(postUser(object));
    setModalUsers(false);
    setFirstname("");
    setLastname("");
    setEmail("");
    setPassword("");
  };

  useEffect(() => {
    if (
      roleInitial &&
      roles &&
      Object.keys(roles).length > 0 &&
      roleId === ""
    ) {
      setRoleId(roles[Object.keys(roles)[0]].id);
      setRole(Object.keys(roles)[0].name);
      setRoleInitial(false);
    }
  }, [roles, roleId, roleInitial]);

  useEffect(() => {
    Object.values(roles).find((_role) => {
      if (_role.name === role) {
        setRoleId(_role.id);
        return _role;
      }
      return null;
    });
  }, [roles, role]);

  const renderFormCard = () => {
    return (
      <>
        <Button
          color='success'
          className='btn-sm'
          onClick={() => setModalUsers(true)}
        >
          {language["users"]["form"]["create"]}
        </Button>
        <Modal
          className='modal-dialog-centered'
          isOpen={modalUsers}
          toggle={() => setModalUsers(false)}
        >
          <div className='modal-body p-0'>
            <Card className='bg-secondary shadow border-0' ref={wrapperRef}>
              <CardBody className='px-lg-5 py-lg-2'>
                <h2>{language["users"]["form"]["create"]}</h2>
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <label htmlFor='firstname'>
                      {language["users"]["table"]["firstname"]}
                    </label>
                    <Input
                      className='form-control-sm'
                      type='text'
                      name='firstname'
                      required
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor='lastname'>
                      {language["users"]["table"]["lastname"]}
                    </label>
                    <Input
                      className='form-control-sm'
                      type='text'
                      name='lastname'
                      required
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor='email'>
                      {language["users"]["table"]["email"]}
                    </label>
                    <Input
                      className='form-control-sm'
                      type='text'
                      name='email'
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor='role'>
                      {language["users"]["table"]["role"]}
                    </label>
                    <Input
                      className='form-control-sm'
                      type='select'
                      name='role'
                      required
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                    >
                      {Object.values(roles).map((role) => (
                        <option key={role.id} value={role.name}>
                          {language["roles"]["kinds"].hasOwnProperty(role.name)
                            ? language["roles"]["kinds"][role.name]
                            : role.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Input type='hidden' name='role_id' value={roleId} />
                  </FormGroup>
                  <FormGroup>
                    <label>{language["users"]["table"]["area"]}</label>
                    <Input
                      className='form-control-sm'
                      type='select'
                      name='area_id'
                      required
                      value={area}
                      onChange={(e) => setArea(e.target.value)}
                    >
                      {areas.map((area) => (
                        <option key={area.id} value={area.id}>
                          {area.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor='password'>
                      {language["users"]["table"]["password"]}
                    </label>
                    <Input
                      className='form-control-sm'
                      type='password'
                      name='password'
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <p>{language["users"]["form"]["password_info"]}</p>
                  </FormGroup>
                  <FormGroup>
                    <Button color='success' className='btn-sm' type='submit'>
                      {language["users"]["form"]["create"]}
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </>
    );
  };

  return <div>{renderFormCard()}</div>;
};

export default connect()(CreateUser);
