const initialState_Risk = {
    request_time: null,
    error: null,
    initiativeId: null,
    risks: [],
};

const risks = (state = initialState_Risk, action) => {
    switch (action.type) {
        case 'SET_RISKS':
            return {
                risks: action.payload.risks,
                request_time: action.request_time,
                initiativeId: action.payload.initiativeId,
            };
        case 'SET_RISK':
            return {
                risks: [...state.risks, action.payload],
                request_time: action.payload.request_time,
                initiativeId: state.initiativeId,
            };
        
        case 'UPDATE_RISK':
            return {
                ...state,
                risks: state.risks.map(risk => risk.id === action.payload.id ? action.payload : risk),
                request_time: action.request_time,
            }
        
        case 'RISK_ERROR':
            return {
                ...state,
                error: action.payload.error,
                request_time: null,
                // risks: [],
            };
        case "DELETE_RISK":
            return {
                ...state,
                risks: state.risks.filter(risk => risk.id !== action.payload.id),
                request_time: action.request_time,
            };
        default:
            return state;
    }
}

export default risks;