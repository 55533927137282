const initialState_Risk = {
    request_time: null,
    error: null,
    kpisecondId: null,
    kshifts: [],
};

const sortByYearMonth = (a,b) => {
    if (a.year !== b.year) {
        return a.year - b.year;
      }
      return a.month - b.month;
}


const kshifts = (state = initialState_Risk, action) => {
    switch (action.type) {
        case 'SET_KSHIFTS':
            return {
                kshifts: action.payload.kshifts,
                request_time: action.request_time,
                kpisecondId: action.payload.kpisecondId,
            };
        case "ADD_KSHIFT":

            return {
                kshifts: [...state.kshifts, action.payload].sort(sortByYearMonth),
                request_time: action.payload.request_time,
                kpisecondId: state.kpisecondId,

            }
        
        case 'SET_KSHIFT':
            return {
                kshifts: state.kshifts.map(kshift => {
                    if (kshift.id === action.payload.id) {
                        return action.payload;
                    }
                    return kshift;
                }),
                request_time: action.payload.request_time,
                kpisecondId: state.kpisecondId,
            };
        case 'KSHIFT_ERROR':
            return {
                ...state,
                error: action.payload.error,
                request_time: null,
                // kshifts: [],
            };
        case "DELETE_KSHIFT":
            return {
                ...state,
                kshifts: state.kshifts.filter(kshift => kshift.id !== action.payload.id),
                request_time: action.request_time,
            };
        default:
            return state;
    }
}

export default kshifts;