import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { language } from "../../assets/language";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import Loading from "../Loading/Loading";


Chart.register(ChartDataLabels);
Chart.defaults.set("plugins.datalabels", {
  display: function (context) {
    return context.dataset.data[context.dataIndex] > 0;
  },
  color: "black",
  align: "end",
  anchor: "end",
  font: { size: "12" },
});

const BarGraph = ({ stats, height, promiseLine = null, baseLine = null, allTrees= false, kpisecond_id = null }) => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [config,setConfig] = useState({});
  const [promise,setPromise] = useState();
  const [real, setReal] = useState();
  const [forecast, setForecast] =useState();
  const [labels, setLabels] = useState();


  let green = "rgb(91, 166, 145)";
  let blue = "rgb(60, 85, 115 )";
  let orange = "rgb(217, 145, 82)";
  let gray = "rgb(200, 200, 200)";



  const promiseColor = blue;
  const promiseBorderColor = blue;

  const forecastColor = gray;
  const forecastBorderColor = gray;

  const realColor = green;
  const realBorderColor = green;


  useEffect(() => {
      if(stats) {
      renderTshifts()
      }
  }, [stats]);





 useEffect(() => {
  if (promise && forecast && real && labels) {
    const dataSets = [
      {
          type: "bar",
          label: "Real",
          data: real,
          backgroundColor: realColor,
          borderColor: realBorderColor,
          borderWidth: 1,
          categoryPercentage: 0.7,
          barPercentage: 0.9,
          order: 2,
          stack: "1",
      },
      {
        type: "bar",
        label: "Forecast",
        data: forecast,
        backgroundColor: forecastColor,
        borderColor: forecastBorderColor,
        borderWidth: 1,
        categoryPercentage: 0.7,
        barPercentage: 0.9,
        order: 3,
        stack: "1",
      },
      {
        type: "line",
        order:1,
        label: "Promesas",
        data: promise,
        backgroundColor: Object.keys(stats).map((key, index) => {
          return promiseColor;
        }),
        borderColor: Object.keys(stats).map((key, index) => {
          return promiseBorderColor;
        }),
        datalabels: {
          labels: {
            index: {
              align: "end",
              anchor: "end",
              color: "black",
              font: { size: 14 },
              formatter: function (value, context) {
                return context.dataset.data[context.dataIndex];
              },
            },
            
          },
        }
       
       
      },
     
    ];

    if (baseLine) {
      dataSets.push({
        type: "line",
        order: 1,
        label: "Base",
        data: Array(real.length).fill(baseLine),
        borderColor: orange,
        borderWidth: 1,
        pointRadius:0,
        datalabels: {
          display: false  // This will hide data labels for the "Base" line
        }
      });
    }
    if (promiseLine) {
      dataSets.push({
        type: "line",
        order: 1,
        label: "Promesa",
        data: Array(real.length).fill(promiseLine),
        borderColor: green,
        borderWidth: 1,
        pointRadius: 0 ,
        datalabels: {
          display: false  // This will hide data labels for the "Base" line
        }
      });
    }

    setData({
      labels: labels,
      datasets: dataSets
    });
    setConfig({

      options: {
        responsive: true,
       
        scales: {
          xAxes: [{
              stacked: true
          }],
          yAxes: [{
              stacked: true,
              grid: {
                display: false
            }
          }],
          y: {
            stacked: true,
            grid: {
              display: false
            }
          }
      },
      },
      plugins: {
        datalabels: {
          display: function (context) {
            return (
              Math.abs(
                  context.dataset.data[context.dataIndex]
              ) > 0
            );
          },
          formatter: function (value, context) {
            return Math.abs(
                context.dataset.data[context.dataIndex]
            );
          },
          color: "white",
          align: "center",
          anchor: "center",
          font: { size: "14" },
        },
      },
    });
    setLoading(false);
  }
  },[promise,forecast,real,labels]);


  const legendMargin = {
    id: "legendMargin",
    beforeInit(chart, legend, options) {
      const fitValue = chart.legend.fit;
      chart.legend.fit = function fit() {
        fitValue.bind(chart.legend)();
        return (this.height += 20);
      };
    },
  };

  const renderTshifts = () => {
   
    let dataLabels = Object.keys(stats).map((key, index) => {
      return language.general.abv_months[key.split("-")[0] || 0] + " " + key.split("-")[1];
    });
    setLabels(dataLabels);

    let dataPromise = Object.values(stats).map((tshift, index) => {
      // return Math.random() * (100 - 0) + 0;
      return tshift.base_sum;
    });
    setPromise(dataPromise);

    let dataReal = Object.values(stats).map((tshift, index) => {
          return tshift.real_sum;
      }
    );
    setReal(dataReal);

    let dataForecast = Object.values(stats).map((tshift, index) => {

        if (Math.abs(tshift.real_sum) >= 0 && !allTrees) {
            return null;
        }
      return tshift.forecast_sum;
    });
    setForecast(dataForecast);
    //datareal is the forecast data invers
    

  };

  return loading ?  <Loading /> :   ( 
      <Bar options={config} data={data} height={height ? height : "auto"}
        plugins={[legendMargin]} />
  );
};

export default connect()(BarGraph);
