import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { deletePhaseById } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { Button } from "reactstrap";

const DeletePhase = ({ dispatch, phase }) => {
  let [modalPhases, setModalPhases] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalPhases(false);
  });

  const deletePhaseButton = () => {
    if (modalPhases) {
      return <></>;
    }
    return (
      <Button
        color='danger'
        className='btn-sm'
        onClick={() => setModalPhases(true)}
      >
        {language["phases"]["form"]["delete"]}
      </Button>
    );
  };

  const handleSubmitPhases = () => {
    dispatch(deletePhaseById(phase.id));
    setModalPhases(false);
  };

  const renderModalFormPhases = () => {
    if (modalPhases) {
      return (
        <div className='form' ref={wrapperRef}>
          <div>
            <Button
              color='warning'
              className='btn-sm'
              onClick={() => setModalPhases(false)}
            >
              {language["general"]["cancel"]}
            </Button>
          </div>
          <div>
            <Button
              color='danger'
              className='btn-sm'
              onClick={() => handleSubmitPhases()}
            >
              {language["phases"]["form"]["deleteConfirm"]}
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      {deletePhaseButton()}
      {renderModalFormPhases()}
    </div>
  );
};

export default connect()(DeletePhase);
