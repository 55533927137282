import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { getInitiative, getStages, patchStage } from "../../redux/actions";
import { language } from "../../assets/language";
import  "./index.css"

import {
  Button,
  Input,
  Form,
  FormGroup,
} from "reactstrap";



const ListInitiativeStages = ({
  dispatch,
  initiative,
  editForecast = false,
  editEstimated = false,
  setLoadIni,
}) => {

  const phases = useSelector((state) => state.phases.phases);
  const stages = useSelector((state) => state.stages.stages);
  const [loading, setLoading] = useState(true);
  const [completed,setCompleted] = useState(0);
  const [color, setColor] = useState("success");
  const [currentStage, setCurrentStage] = useState();
  const [width,setWidth] = useState("90%");
  const today = new Date();
  const currentUser = useSelector((state) => state.sessions.user);

  let stageRef = React.createRef() 



  // // esto actualiza el de la iniciativa y deberia ser el del stage
  // let [forecast_date, setForecastDate] = useState(initiative.forecast_date);
  // let [estimated_date, setEstimatedDate] = useState(initiative.estimated_date || initiative.forecast_date);
  let [stagesEditables, setStagesEditables] = useState([]);

  useEffect(() => {
    dispatch(getStages(initiative.id));
  }, []);

  const saveChanges = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    let lista = stagesEditables.filter((stage) => stage !== object.id);
    setStagesEditables(lista);
    dispatch(patchStage(object.id, object));
    setLoading(true);
   
  };

  useEffect(() => {
    if (stages && phases) {
      let complete = stages.filter(s => s.completed ===true).length;
      setCompleted(complete);
      let stage = stages[complete];
      setCurrentStage(stage);
      let date = new Date(stage ? stage.forecast_date : "today");
      if(stage){
        if (date < today) {
          setColor("danger");
        } else if (date > today){
          setColor("warning");
        }
      }
      setLoading(false);
      dispatch(getInitiative(initiative.id));
    }
  }, [stages, phases]);

  useEffect (()=>{
    if(stageRef.current){
      setWidth(stageRef.current.getBoundingClientRect().width);
    }
  },[])




  const renderStages = () => {
    if (!loading) {
      // each stage has name, forecast_date, order. Render sorted by order asc
      return (
        <div className="container-f">
        <div className='wrapper' >
        <div className="arrow-steps clearfix" >
         
          {stages
            .filter(
              (stage) => initiative.is_deleted || (stage.name !== "deleted" && stage.name !== "finalized")
            )
            .map((stage) => {
              return (
                <div
                className={currentStage === stage ? "step current" : "step"}
                  key={stage.id}
                >
                  <div key={"d-" + stage.id} >
                    <Form onSubmit={saveChanges} key={stage.id}>
                      <input type='hidden' name='id' value={stage.id} />
                      <h2>
                        {phases.hasOwnProperty(stage.name)
                          ? phases[stage.name].name
                          : stage.name}
                      </h2>
                      {stage.name !== "adoption" ?
                      <FormGroup>
                        <label htmlFor='forecast_date'>
                          {language.stages.table.end_date}:{" "}
                        </label>
                        <Input
                          className='form-control-sm'
                          name='forecast_date'
                          type='date'
                          disabled={(!editForecast || stage.completed ) && "user" === currentUser.role }
                          value={stage.forecast_date.split("T")[0]}
                          onChange={(e) => {
                            stage.forecast_date = e.target.value;
                            setStagesEditables(
                              stagesEditables.concat(stage.id)
                            );
                          }}
                        />
                      </FormGroup>
                      : ""}
                      {initiative.stage !== "evaluation" &&
                      initiative.stage !== "idea" &&
                      (editEstimated ||
                        stage.estimated_date.split("T")[0] !==
                          stage.forecast_date.split("T")[0]) ? (
                        <FormGroup>
                          <label htmlFor='estimated_date'>
                            {language.stages.table.estimated_date}:{" "}
                          </label>
                          <Input
                            className='form-control-sm'
                            name='estimated_date'
                            type='date'
                            disabled={(!editEstimated || stage.completed)  && "user" === currentUser.role}
                            value={stage.estimated_date.split("T")[0]}
                            onChange={(e) => {
                              stage.estimated_date = e.target.value;
                              setStagesEditables(
                                stagesEditables.concat(stage.id)
                              );
                            }}
                          />
                        </FormGroup>
                      ) : (
                        <></>
                      )}
                      <FormGroup>
                        <label htmlFor='completed'>
                          {language.stages.table.completed}:{" "}
                        </label>
                        <Input
                          className='form-control-sm'
                          type='text'
                          disabled={true}
                          value={
                            stage.completed
                              ? language["stages"]["completed"]["yes"]
                              : language["stages"]["completed"]["no"]
                          }
                        />
                      </FormGroup>
                      {stage.completed && stage.real_date ? (
                        <FormGroup>
                          <label htmlFor='real_date'>
                            {language.stages.table.real_date}:{" "}
                          </label>
                          <Input
                            className='form-control-sm'
                            type='date'
                            disabled={true}
                            value={stage.real_date.split("T")[0]}
                          />
                        </FormGroup>
                      ) : (
                        <></>
                      )}
                      {/* {!stage.completed &&
                      !initiative.is_deleted &&
                      getUserPermission(state, "stages", "delete") ? (
                        <DeleteStage stage={stage} />
                      ) : (
                        <></>
                      )} */}
                      {stagesEditables.includes(stage.id) ? (
                        <Button
                          className='btn-sm'
                          color="success"
                          block
                        >{language.stages.form.save}</Button>
                      ) : (
                        <></>
                      )}
                    </Form>
                  </div>
                </div>
              );
            })}
        </div>
        </div>
        </div>
      );
    }
  };

  return <div>{renderStages()}</div>;
};

export default connect()(ListInitiativeStages);
