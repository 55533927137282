const initialState_Tags = {
  request_time: null,
  error: null,
  revive: null,
  delete: null,
};

const initiatives_stage = (state = initialState_Tags, action) => {
  switch (action.type) {
    case "SET_REVIVE_INITIATIVE":
      return {
        revive: action.payload,
        request_time: action.request_time,
      };
    case "SET_PERMANENT_DELETE_INITIATIVE":
      try {
        return {
          ...state,
          delete: action.payload,
          request_time: action.request_time,
        };
      } catch (e) {
        console.log(e);
        return state;
      }
    default:
      return state;
  }
};

export default initiatives_stage;
