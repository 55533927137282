const initialState_Area = {
    areas: [],
    error: null,
    request_time: null,
    loading: false,
    loading_time: null
};

const addChildrenToAreas = (_areas) => {
    let areas = _areas.map(area => {
        area.children = [];
        return area;
    });
    return areas.map((area) => {
        if (area.parent_id !== null) {
            let parent = areas.find(a => a.id === area.parent_id);
            if (parent) {
                if (!parent.children) {
                    parent.children = [];
                } else if (parent.children.find(a => a.id === area.id)) {
                    return area;
                }
                parent.children.push(area);
            }
        }
        return area;
    });
}

const areas = (state = initialState_Area, action) => {
    let _areas;
    switch (action.type) {
        case 'SET_AREAS':
            _areas = addChildrenToAreas(action.payload.areas);
            return {
                areas: _areas,
                request_time: action.request_time,
            };
        case "NEW_AREA":
            _areas = addChildrenToAreas([...state.areas, action.payload]);
            return {
                areas: _areas,
                request_time: action.request_time,
            };
        case "UPDATE_AREA":
            _areas = addChildrenToAreas(state.areas.map(area => {
                if (area.id === action.payload.id) {
                    return {
                        ...area, 
                        ...action.payload
                    };
                }
                return area;
            }));
            return {
                areas: _areas,
                request_time: action.request_time,
            };
        case "DELETE_AREA":
            _areas = addChildrenToAreas(state.areas.filter(area => area.id !== action.payload.id));
            return {
                areas: _areas,
                request_time: action.request_time,
            };
        case "AREA_ERROR":
            return {
                ...state,
                error: action.payload.error,
                // areas: [],
                request_time: null,
                loading: false,
                loading_time: null
            };
        case "SET_AREAS_LOADING":
            return {
                ...state,
                loading: action.payload.loading,
                loading_time: action.request_time
            }
        default:
            return state;
    }
}

export default areas;