const initialState_group = {
    permits: [],
    group_permits: [],
    group_id: null,
    request_time: null,
    group_request_time: null,
    error: null,
    loading: false,
    group_loading: false,
    group_permits_edition: [],
    error_edition: null,
    loading_time: null,
    group_loading_time: null
};

const group_permits = (state = initialState_group, action) => {
    switch (action.type) {
        case 'SET_PERMITS':
            return {
                ...state,
                permits: action.payload.permits,
                request_time: action.request_time,
            };
        case 'SET_GROUP_PERMITS':
            return {
                ...state,
                group_permits: action.payload.group_permits,
                group_id: action.payload.group_id,
                group_request_time: action.request_time,
            };
        case "SET_GROUP_PERMIT":
            return {
                ...state,
                permits: [...state.permits, action.payload],
                group_permits: [...state.group_permits, action.payload],
                request_time: action.request_time,
                group_request_time: action.request_time,
            };
        case "DELETE_GROUP_PERMIT":
            return {
                ...state,
                group_permits: state.group_permits.filter(per => per.id !== action.payload.id),
                request_time: action.request_time,
                group_request_time: action.request_time,
            };
        case "SET_PERMITS_ERROR":
            return {
                ...state,
                // permits: [],
                // group_permits: [],
                request_time: null,
                error: action.payload.error,
                loading_time: null,
                loading: false
            };
        case "SET_PERMITS_LOADING":
            return {
                ...state,
                loading: action.payload.loading,
                loading_time: action.request_time
            }
        case "SET_GROUP_PERMITS_LOADING":
            return {
                ...state,
                group_loading: action.payload.loading,
                group_loading_time: action.request_time
            }
        case "SET_GROUP_PERMITS_EDITION":
            return {
                ...state,
                group_permits_edition: action.payload.group_permits,
                group_id: action.payload.group_id,
                group_request_time: action.request_time,
            };
        case "SET_PERMITS_EDITION_RROR":
            return {
                ...state,
                group_permits_edition: [],
                request_time: null,
                error_edition: action.payload.error,
                group_loading: false,
                group_loading_time: null
            };
        default:
            return state;
    }
}

export default group_permits;