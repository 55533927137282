import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { getAllChecklists, getAllPhases } from '../../redux/actions';
import { language } from '../../assets/language';
import { useHistory, useLocation } from "react-router-dom";
import SimpleTable from '../../components/tables/SimpleTable';
import { CreateChecklist, DeleteChecklist } from '.';
import { getUserPermission } from '../../utils/getUserPermission';
import { Button , Card, FormGroup, Form ,  Input} from "reactstrap";

const ShowChecklist = ({ dispatch }) => {

    const state = useSelector(state => state);
    const phases = useSelector(state => state.phases.phases);
    const checklists = useSelector(state => state.checklists.checklists);
    const location = useLocation();

    let history = useHistory();

    let [phaseId, setPhase] = useState(null);

    useEffect(() => {
        dispatch(getAllPhases());
        dispatch(getAllChecklists());
    }, []);


    useEffect(() => {
        let query = new URLSearchParams(location.search);
        if (location.search !== "" && query.get("phase") !== undefined) {
            let el = Object.keys(phases).find(p => p === query.get("phase"));
            if (el) {
                setPhase(el.phaseId);
            }
        } else {
            try {
                setPhase(Object.values(phases).filter(ph => ph.required_checklist === true).map(ph => ph)[0].id);
            } catch (error) {
            }
        }
    }, [dispatch, location, phases]);

    const renderPhaseSelector = () => {
        // return select with phases name and id
        return (
            <div>
                <label>{language.checklists.phase}</label>
                <Input className="form-control-sm" type="select"
                onChange={(e) => {
                    setPhase(e.target.value);
                    history.push(`/checklists?phase=${e.target.value}`);
                }}
                    defaultValue={phaseId}
                >
                    {(Object.keys(phases).length === 0) ? <option value="">{language.phases.select}</option> : <></>}
                    {Object.values(phases).filter(ph => ph.required_checklist === true
                    ).map(p => <option key={p.id} value={p.id}>{p.name}</option>)}
                </Input>
            </div>
        )
    };

    const columns = useMemo(
        () => {
            let col = [
                {
                    Header: language.checklists.table.question,
                    accessor: 'question',
                },
            ];
            if (getUserPermission(state, 'checklists', 'delete')) {
                col.push({
                    Header: language.general.actions,
                    accessor: 'actions',
                });
            }
            return col;
        },
        [state]
    );

    const renderChecklists = () => {

        let checkLists = checklists.filter(c => c.phase_id === phaseId).map(c => {
            return {
                id: c.id,
                question: c.question,
                actions: <DeleteChecklist checklist={c} />
            }
        });

        if (!checkLists || checkLists.length === 0) {
            return <p>{language["checklists"]["not_found"]}</p>;
          }

        return <SimpleTable data={checkLists} columns={columns} />

    };

    return (
        <Card className="p-3 m-3">
             <h1>{language.checklists.title}</h1> 
            {renderPhaseSelector()}
            <br />
            {renderChecklists()}
            <CreateChecklist phase={phaseId} />
        </Card>
    );
}

export default connect()(ShowChecklist);