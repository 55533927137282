import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import {
  patchTShift,
  getOrganizationTshifts,
  getInitiative,
  postTshift,
  getTreeTshifts,
} from "../../redux/actions";
import { language } from "../../assets/language";
import { toast } from "react-toastify";
import { Button, Input, Table } from "reactstrap";
import Loading from "../../components/Loading/Loading";
import { getResponsabilityPermission } from "../../utils/getResponsabilityPermission";
import { validateInputNumber } from "../../utils/validateInputNumber";
import { getUserPermission } from "../../utils/getUserPermission";
import { notNull } from "../../utils/notNull";
import { getMonthYearDate } from "../../utils/getMonthYearDate";




const ListTreeTshifts = ({ dispatch, initiativeId = null, tree }) => {
  const state = useSelector((state) => state)
  const trees = useSelector((state) => state.trees.trees);
  const all_tshifts = useSelector((state) => state.tshifts.tshifts);
  const initiative = useSelector((state) => state.initiatives.initiative);
  const [update, setUpdate] = useState(false);
  const [tshifts, setTshifts] = useState(null);
  const [tshiftsNewValues, setTshiftsNewValues] = useState([]);
  const [loading, setLoading] = useState(true);
  const initialDate = getMonthYearDate(tree.initial_date);
  const promiseDate = getMonthYearDate(tree.promise_date);
  const forecastDate = getMonthYearDate(tree.forecast_date);


  useEffect(() => {
    if (all_tshifts && tree) {
      const tshift = all_tshifts
      .filter((tshift) => tshift.tree_id === tree.id)
      setTshifts(tshift);
      setLoading(false);
    }
  }, [all_tshifts,tree]);

  useEffect(() => {
    if (all_tshifts.length === 0) {
      if (initiativeId === null) {
        dispatch(getOrganizationTshifts("organization"));
      } else {
        dispatch(getTreeTshifts(tree.id, undefined, undefined));
      }
    }
  
  }, [initiativeId, all_tshifts]);

const addTShift = (tshift, value, type) => {
  let error = validateInputNumber(value);
  if (error) {
    return;
  }
  if (value ==="") {
    value = null
  }
  let newTshifts = [...tshiftsNewValues];
  let finder = newTshifts.find((t) => t.id === tshift.id);

  if (finder) {
    newTshifts = newTshifts.filter((t) => t.id !== tshift.id);
    if (type === "base") {
      finder.base = value;
    } else if (type === "forecast") {
      finder.forecast = value;
    } else {
      finder.real = value;
    }
    newTshifts.push(finder);
  } else {
    if (type === "base") {
      tshift.base = value;
    } else if (type === "forecast") {
      tshift.forecast = value;
    } else {
      tshift.real = value;
    }
    newTshifts.push(tshift);
  }

  setTshiftsNewValues(newTshifts);
}


  useEffect(() => {
    if (tshiftsNewValues.length > 0) {
      setUpdate(true);
    }
  }, [tshiftsNewValues]);

   

  const sendChanges = () => {
    setLoading(true);
    tshiftsNewValues.forEach((t) => {
      dispatch(patchTShift(t));
      call_propagation(t);
    });
      setUpdate(false);
      setTshiftsNewValues([]);
      toast.success(language.tshifts.success)
      if (initiativeId) {
        dispatch(getInitiative(initiativeId));
      }
  };

  const getlastTshiftsInitiative = () => {
    let lastTshift = tshifts[tshifts.length - 1];
    let lastTshifts = all_tshifts.filter(
      (tshift) =>
        tshift.month === lastTshift.month && tshift.year === lastTshift.year
    );
    return lastTshifts
  }

  //get first tshifsts initiative
  const getfirstTshiftsInitiative = () => {
    let firstTshift = tshifts[0];
    let firstTshifts = all_tshifts.filter(
      (tshift) =>
        tshift.month === firstTshift.month && tshift.year === firstTshift.year
    );
    return firstTshifts
  }

  const permissionTshift = () => {
    return (initiative &&
    initiative.stage === "evaluation" &&
     getResponsabilityPermission(state,"tshifts", "change",initiativeId,"Lider") &&
    (tree.calculation === "" ||
      tree.calculation === null ||
      tree.calculation === undefined)) ||
  (initiative &&
    getUserPermission(state, "tshifts", "patch") &&
    (tree.calculation === "" ||
      tree.calculation === null ||
      tree.calculation === undefined)) ||
  (initiative === undefined &&
    (tree.calculation === "" ||
      tree.calculation === null ||
      tree.calculation === undefined))
    ? false
    : true
  }


  const addMonthTShift = () => {
    setLoading(true);
    let latestTshifts =  getlastTshiftsInitiative()
    let lastTshift = latestTshifts[latestTshifts.length - 1];
    let nextYear = lastTshift.year;
    let nextMonth = lastTshift.month + 1;
    if (nextMonth >= 11) {
      nextMonth = 0;
      nextYear = nextYear + 1;
    }
    latestTshifts.forEach((ts)=> {
      let newTshift = {
        initiative_id: initiativeId,
        tree_id: ts.tree_id,
        month: nextMonth,
        year: nextYear,
        base: ts.base,
        forecast: ts.forecast,
      };
      dispatch(postTshift(newTshift))
    })
    toast.success(language.tshifts.success)
  }

  //add month tshift before
  //compare witth addmonth tsifts
  const addMonthTShiftBefore = () => {
    setLoading(true);
    let firstTshift = getfirstTshiftsInitiative();
    let previousYear = firstTshift[0].year;
    let previousMonth = firstTshift[0].month - 1;
    if (previousMonth < 0) {
      previousMonth = 11;
      previousYear = previousYear - 1;
    }
    firstTshift.forEach((ts)=> {
      let newTshift = {
        initiative_id: initiativeId,
        tree_id: ts.tree_id,
        month: previousMonth,
        year: previousYear,
        base: ts.base,
        forecast: ts.forecast,
      };
      dispatch(postTshift(newTshift))
    })
    

  }

  useEffect(() => {
      if (tshifts !== null && tshifts !== undefined) {
        setLoading(false);
      }
    },[tshifts])
  

  const call_propagation = (data) => {
    if (tree.calc_parents && tree.calc_parents.length > 0) {
      // for each calc_parent, calculate forecast based on calculation attribute
      // example: trees = [{name: 'value', calc_parents: ['ing', 'cost'], calculation: 'ing-cost'}, {name: 'ing', calc_parents: []}, {name: 'cost', calc_parents: []}]
      // if ing tree change base attribute, then value tree will be updated as base = ing.base - cost.base
      let calc = "";
      let parent;
      let _child;
      let parent_tshift;
      tree.calc_parents.forEach((_parent) => {
        parent = trees.find((tree) => tree.abv === _parent);
        parent_tshift = all_tshifts.find(
          (tshift) =>
            tshift.tree_id === parent.id &&
            tshift.initiative_id === initiativeId &&
            tshift.month === data.month &&
            tshift.year === data.year
        );
        if (data.base !== undefined) {
          parent.base = 0;
          calc = parent.calculation;
          parent.children.forEach((child) => {
            _child = all_tshifts.find(
              (tshift) =>
                tshift.tree_id === child.id &&
                tshift.initiative_id === initiativeId &&
                tshift.month === data.month &&
                tshift.year === data.year
            );
            calc = calc.replace(child.abv, _child.base);
            calc = calc.replace("null", "0");
          });
          try {
            parent_tshift.base = eval(calc);
            dispatch(
              patchTShift({
                id: parent_tshift.id,
                base: parent_tshift.base,
              })
            );
          } catch (error) {
            toast.error(language.trees.errors.calculation);
          }
        } 
        if (data.forecast !== undefined) {
          parent.forecast = 0;
          calc = parent.calculation;
          parent.children.forEach((child) => {
            _child = all_tshifts.find(
              (tshift) =>
                tshift.tree_id === child.id &&
                tshift.initiative_id === initiativeId &&
                tshift.month === data.month &&
                tshift.year === data.year
            );
            calc = calc.replace(child.abv, _child.forecast);
            calc = calc.replace("null", "0");
          });
          try {
            parent_tshift.forecast = eval(calc);
            dispatch(
              patchTShift({
                id: parent_tshift.id,
                forecast: parent_tshift.forecast,
              })
            );
          } catch (error) {
            toast.error(language.trees.errors.calculation);
          }
        }
        if (data.real !== undefined) {
          parent.real = 0;
          calc = parent.calculation;
          parent.children.forEach((child) => {
            _child = all_tshifts.find(
              (tshift) =>
                tshift.tree_id === child.id &&
                tshift.initiative_id === initiativeId &&
                tshift.month === data.month &&
                tshift.year === data.year
            );
            calc = calc.replace(child.abv, _child.real);
            calc = calc.replace("null", "0");
          });
          try {
            parent_tshift.real = eval(calc);
            dispatch(
              patchTShift({
                id: parent_tshift.id,
                real: parent_tshift.real,
              })
            );
          } catch (error) {
            toast.error(language.trees.errors.calculation);
          }
        }
      });
    }
  };

  const renderTshifts = () => {
    if (!tshifts || tshifts.length === 0) {
      return <p>{language["tshifts"]["not_found"]}</p>;
    }

    return (
      <div>
       
        <h3 className="text-center">{language.trees.names.table} {language.trees.names[tree.name] || tree.name}</h3>
        
       
        <Table hover responsive className='align-items-center'>
          <thead className='thead-light'>
            <tr key='r-0'>
              <th style={{ textAlign: "center", maxWidth: "100px" }} key='c-0'>
              <Button
                        variant="contained"
                        color="success"
                        className='btn-sm'
                        block
                        onClick={() => addMonthTShiftBefore()}
                      > {language.kshifts.add_month} 
                      
                      </Button>
              </th>
              {tshifts.map((tshift, index) => {
                return (
                  <th  style={{ textAlign: "center", maxWidth: "100px" }} className='text-center' key={"Header-" + index}>
                    {language.general.abv_months[tshift.month || 0]} - {tshift.year}
                  </th>
                );
              })}
              {
               <th>
              <Button
              variant="contained"
              color="success"
              className='btn-sm'
              block
              onClick={() => addMonthTShift()}
            > {language.kshifts.add_month}
            
            </Button>
            </th>

              }
              
            </tr>
           
          </thead>
          <tbody>
            <tr key='r-1'>
              <td  key='c-1'>
                {initiativeId != null
                  ? language.tshifts.table.base.ini
                  : language.tshifts.table.base.org}
              </td>
              {tshifts.map((tshift, index) => {
                 let dateTshift = new Date(tshift.year, tshift.month,1);
                return (
                  <td  style={{ textAlign: "center", maxWidth: "100px" }} key={index+"base"}>
                    <Input
                     
                      maxLength={254}
                      className={tshiftsNewValues.find(t => t.id === tshift.id ) ?  'form-control-sm text-center border-primary' : 'form-control-sm text-center' }
                      disabled={ permissionTshift()|| 
                        //date not between initialDate and forecast date
                        (dateTshift.getTime() <= initialDate.getTime()
                        ||
                        dateTshift.getTime() >= promiseDate.getTime())}
                      key={tshift.id+"base"}
                      
                      value={tshiftsNewValues.find(t => t.id === tshift.id ) 
                        ? tshiftsNewValues.find(t => t.id === tshift.id ).base 
                        :  notNull(tshift.base) ? tshift.base : ""}
                      
                      onChange={(e) => {addTShift(tshift, e.target.value, "base");}}
                      
                    />
                  </td>
                );
              })}
              <td>
              </td> 
            </tr>
            <tr key='r-2'>
              <td key='c-2'>{language.tshifts.table.forecast}</td>
              {tshifts.map((tshift, index) => {
                let dateTshift = new Date(tshift.year, tshift.month,1);
                return (
                  <td key={"forecast-" +index}>
                    <Input
                      className={tshiftsNewValues.find(t => t.id === tshift.id ) ?  'form-control-sm text-center border-primary' : 'form-control-sm text-center' }

                      disabled={
                        permissionTshift() ||  (dateTshift.getTime() <= initialDate.getTime()
                        || dateTshift.getTime() >= forecastDate.getTime())
                      }
                      value={tshiftsNewValues.find(t => t.id === tshift.id ) 
                        ? tshiftsNewValues.find(t => t.id === tshift.id ).forecast 
                        :  notNull(tshift.forecast) ? tshift.forecast : ""}
                      onChange={(e) => {addTShift(tshift, e.target.value, "forecast");}}
                  
                    />
                  </td>
                );
              })}
              <td>
              </td>
                    
           </tr>
            <tr key='r-3'>
              <td key='c-3'>{language.tshifts.table.real}</td>
              {tshifts.map((tshift, index) => {
                return tshift.real != null ? (
                  <td key={"real-" +index}>
                    <Input
                      className={tshiftsNewValues.find(t => t.id === tshift.id ) ?  'form-control-sm text-center border-primary' : 'form-control-sm text-center' }

                      disabled={
                        permissionTshift()
                      }
                      value={tshiftsNewValues.find(t => t.id === tshift.id ) 
                        ? tshiftsNewValues.find(t => t.id === tshift.id ).real 
                        :   notNull(tshift.real) ? tshift.real : ""}
                      onChange={(e) => {addTShift(tshift, e.target.value, "real");}}
                  
                    />
                  </td>
                ) : // compare today vs new date of tshift month and year
                (new Date(tshift.year, tshift.month, 1).getTime() <= new Date().getTime()) ?
                
                (<td
                  style={{ maxWidth: "100px", textAlign: "center" }}
                >
                  <Button
                    variant="contained"
                    color="success"
                    className='btn-sm'
                    disabled={ permissionTshift() }
                    onClick={() => addTShift(tshift, "0.0", "real")}
                  >
                    {"+"}
                  </Button>
                </td>)
                : <td></td>
              })}
              <td>
              </td>
            </tr>
            {initiativeId === null ? (
              <tr key='r-4'>
                <td key='c-4' >{language.tshifts.table.initiative_value}</td>
                {tshifts.map((tshift, index) => {
                  return (
                    <td className='text-center' key={"initiative_value-" +index}>
                      {(tshift.initiative_value)}
                    </td>
                  );
                })}
              </tr>
            ) : null}
          </tbody>
        </Table>
      
      </div>
    );
  };

  return loading ? <Loading /> :  (
    <div>
      {/* {renderTshifts()} */}
     
      {renderTshifts()}
      {
          update ? ( <Button
            variant=""
            color="success"
            className='btn-sm z-10 m-3 p-1 float-right'
            onClick={()=>{ sendChanges();} }
          > {language.trees.update_tshift}</Button>
          ) : ""
        }
     
    </div>
  );
};

export default connect()(ListTreeTshifts);
