import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import {  getInitiativeKpis } from "../../redux/actions";
import { language } from "../../assets/language";
import SimpleTable from "../../components/tables/SimpleTable";
import { DeleteKpiSecond, EditKpiSecond } from ".";
import { ListKpisecondKshifts } from "../kshifts";
import { Button, Col, Row } from "reactstrap";
import Loading from "../../components/Loading/Loading";
import moment from "moment";
import { getUserPermission } from "../../utils/getUserPermission";
import { getResponsabilityPermission } from "../../utils/getResponsabilityPermission";
import { FaEye, FaRegEye, FaRegEyeSlash, FaTable, FaTabletAlt } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom";
// import { getUserPermission } from '../../utils/getUserPermission';

const ListInitiativeKpis = ({ dispatch, initiative }) => {
  const state = useSelector(state => state);
  
  const [loading, setloading] = useState(true);
  const kpiseconds = useSelector((state) => state.kpi_seconds.kpi_seconds);


  

  useEffect(() => {
    dispatch(getInitiativeKpis(initiative.id));
  }, [dispatch, initiative]);




  useEffect(() => {
    if (kpiseconds !== null && kpiseconds !== undefined) {
      setloading(false);
    }
  }, [kpiseconds]);


  const columns = useMemo(() => {
    let col = [
      {
        Header: language.kpiseconds.table.description,
        accessor: "description",
      },
      // {
      //   Header: language.kpiseconds.table.tree,
      //   accessor: "tree",
      // },
      {
        Header: language.kpiseconds.table.units,
        accessor: "units",
      },
      {
        Header: language.kpiseconds.table.initial_value,
        accessor: "initial_value",
      },
      {
        Header: language.kpiseconds.table.initial_date,
        accessor: "initial_date",
      },
      {
        Header: language.kpiseconds.table.objetive_value,
        accessor: "objetive_value",
      },
      {
        Header: language.kpiseconds.table.objetive_date,
        accessor: "objetive_date",
      },
      {
        Header: language.general.actions,
        accessor: "actions",
      },

    ];
    // if (getUserPermission(state, 'kpiseconds', 'delete')) {
    //     col.push({
    //         Header: language.general.actions,
    //         accessor: 'actions',
    //     });
    // }
    return col;
  }, []);

  const renderKpiseconds = () => {
    if (loading) return <Loading />;
    if (!kpiseconds || kpiseconds.length === 0) {
      return <p>{language["kpiseconds"]["not_found"]}</p>;
    }

    let kpisecondsList = kpiseconds.map((kpisecond) => {
      return {
        id: kpisecond.id,
        description: kpisecond.description,
        //tree: kpisecond.tree_id ? treesValues.find(tree => tree.id === kpisecond.tree_id).name: "No pertenece a un Valor",
        units: kpisecond.units,
        initial_value: kpisecond.initial_value,
        initial_date: kpisecond.initial_date
        ?  moment(kpisecond.initial_date.split("T")[0]).format("MM-YYYY")
        : language["kpiseconds"]["no_date"],
        objetive_value: kpisecond.objetive_value,
        objetive_date:
           moment(kpisecond.objetive_date.split("T")[0]).format("MM-YYYY") ||
          language["kpiseconds"]["no_date"],
        actions: (
          <Row >
            {getUserPermission(state,"kpiseconds","patch") ||
            (initiative.stage ==="evaluation" && getResponsabilityPermission(state,"kpiseconds","change",initiative.id, "Lider") ) ? (
              <Col xs={4}>
                <Link to={`/kpi/${kpisecond.id}`}>
                <Button size="sm"  >
                  <FaEye />
                </Button>
                </Link>
              </Col>
            ) : (
              ""
            )}
         
            {getUserPermission(state,"kpiseconds","patch") ||(initiative.stage ==="evaluation" && getResponsabilityPermission(state,"kpiseconds","change",initiative.id, "Lider") )? 
            <Col xs={4}>
            <DeleteKpiSecond kpisecond={kpisecond} />
            </Col>
            : null}
         
           </Row>
        ),
      };
    });

    return <SimpleTable columns={columns} data={kpisecondsList} />;
  };

  return (
    <div>
     
      {renderKpiseconds()}
    </div>
  );
};

export default connect()(ListInitiativeKpis);
