import { toast } from "react-toastify";
import { language } from "../../assets/language";
import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";


export const newUser = (data) => ({
    type: "NEW_USER",
    payload: {
        id: data.id,
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        role: data.role,
        role_id: data.role_id,
        area_id: data.area_id,
        full_name: data.full_name || data.firstname + " " + data.lastname,
    },
    request_time: Date.now()
});

export const setUserError = (error) => ({
    type: "USER_ERROR",
    payload: {
        error: error
    }
});

export const deleteUser = (user_id) => ({
    type: "DELETE_USER",
    payload: {
        id: user_id,
    },
    request_time: Date.now()
});

export const updateUser = (user) => ({
    type: "UPDATE_USER",
    payload: {
        id: user.id,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        role: user.role,
        role_id: user.role_id,
        area_id: user.area_id,
        full_name: user.full_name || user.firstname + " " + user.lastname,
    },
    request_time: Date.now()
});

export const setUsers = (users) => ({
    type: "SET_USERS",
    payload: {
        users: users,
    },
    request_time: Date.now()
});

export const getAllUsers = () => {

    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "users", "list", true)) {
                throw new Error("No permissions");
            }
            if (state.users.request_time === null || state.users.request_time + 1000 * 2 < Date.now()) {
                fetch(`${BACKEND_URL}/users`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setUsers(data));
                    })
                    .catch((error) => {
                        errorHandler("users", error.message);
                        dispatch(setUserError(error));
                    });
            }
        } catch (error) {
            errorHandler("users", error.message);
            dispatch(setUserError(error));
        }
    };
}

export const postUser = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "users", "create", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/users`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
                body: JSON.stringify(data)
            })
                .then((response) => {
                    if (response.status === 201) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    if (data != null && data !== undefined && data.id !== undefined) {
                        dispatch(newUser(data));
                    }
                })
                .catch((error) => {
                    errorHandler("users", error.message);
                    dispatch(setUserError(error));
                });
        } catch (error) {
            errorHandler("users", error.message);
            dispatch(setUserError(error));
        }
    };
}

export const deleteUserById = (user_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "users", "delete", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/users/${user_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
                .then((response) => {
                    if (response.status === 204) {
                        return true;
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((vl) => {
                    if (vl) {
                        dispatch(deleteUser(user_id));
                    }
                })
                .catch((error) => {
                    errorHandler("users", error.message);
                    dispatch(setUserError(error));
                });
        } catch (error) {
            errorHandler("users", error.message);
            dispatch(setUserError(error));
        }
    };
}

export const patchUser = (userId, data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (getUserPermission(state, "users", "patch", true)) {
                fetch(`${BACKEND_URL}/users/${userId}`, {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                    body: JSON.stringify(data)
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        if (data != null && data !== undefined && data.id !== undefined) {
                            dispatch(updateUser(data));
                            toast.success(language.users.form.update_success);
                        }
                    })
                    .catch((error) => {
                        errorHandler("users", error.message);
                        dispatch(setUserError(error));
                    });
            }
        } catch (error) {
            errorHandler("users", error.message);
            dispatch(setUserError(error));
        }
    };
}