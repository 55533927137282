import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { getKpiSecondById, getKpisecondKshifts, updateKPIKshift, updateKPISecond, copyKshiftPromise } from '../../redux/actions';
import { language } from '../../assets/language';
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Row } from "reactstrap";
import { validateInputNumber } from '../../utils/validateInputNumber.js';
import Loading from '../../components/Loading/Loading';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import ListKpisecondKshifts from './ListKpisecondKshifts';
import KPIGraph from '../progress/KPIGraph';
import ReactDatePicker from 'react-datepicker';
import { FaAngleLeft,  FaEdit } from 'react-icons/fa';
import InitiativeHeader from '../initiatives/InitiativeHeader';
import ConfirmModal from '../../components/Modal/ConfirmModal';




const KshiftDetail = ({ dispatch,  match }) => {
    const history = useHistory();
    const initiative = useSelector(state => state.initiatives.initiative);
    const phases = useSelector(state => state.phases.phases);
    const kpiId = match.params.kpiId;
    const kpisecond = useSelector(state => state.kpi_seconds.kpi_second);
    const [loading, setLoading] = useState(true);
    const [description, setdescription] = useState("");
    const [initial_value, setinitial_value] = useState("");
    const [forecast_value, setforecast_value] = useState("");
    const [objetive_value, setobjetive_value] = useState("");
    const [units, setunits] = useState("");
    const [memory, setmemory] = useState("");
    const [source, setsource] = useState("");
    const [initial_date, setinitial_date] = useState(
      new Date()
    );
    const [objetive_date, setobjetive_date] = useState(
      new Date()
    )
    const [forecast_date, setforecast_date] = useState(
      new Date()
    );
    const [editAble, seteditAble] = useState(false);

    const goBack = () => {
      history.goBack();
    }
  



    useEffect(() => {
        if (kpiId) {
        dispatch(getKpiSecondById(kpiId));
        dispatch(getKpisecondKshifts(kpiId));  
      }
    }, []);


    useEffect(() => {
        if ( kpisecond !== null && kpisecond !== undefined && kpisecond.id === kpiId) {
          setdescription(kpisecond.description);
          setinitial_value(kpisecond.initial_value);
          setobjetive_value(kpisecond.objetive_value);
          setforecast_value(kpisecond.forecast_value);
          setmemory(kpisecond.memory);
          setsource(kpisecond.source);
          setunits(kpisecond.units);
          setinitial_date(new Date(kpisecond.initial_date));
          setforecast_date(new Date(kpisecond.forecast_date));
          setobjetive_date(new Date(kpisecond.objetive_date));
          setLoading(false);
        }
      },[kpisecond]);

      const handleSubmitKPis = () => {

        var object = { 
          id: kpiId,
          description: description,
          units: units,
          initial_value: initial_value,
          initial_date: initial_date,
          objetive_value: objetive_value,
          objetive_date: objetive_date,
          forecast_value: forecast_value,
          forecast_date: forecast_date,
          memory: memory,
          source: source,
        };
       
        dispatch(updateKPISecond(object));
      
      };

      const handleupdateKPIKShift = (object) => {
        object.id = kpiId;
        dispatch(updateKPIKshift(object));
      
      };

      const copyBase = () => {
        dispatch(copyKshiftPromise(kpiId))
      }
    

    return loading ? <Loading /> : (
        <Card className='mt-4'>
           <InitiativeHeader initiative={initiative}  phases={phases}/>
          <CardHeader className='text-center'>
           
          </CardHeader>
          <CardBody>
            <Button className='btn-sm m-2 float-left' onClick={goBack}>
            <FaAngleLeft className="sidebar-icon" />
              </Button>
          <Row
              className='justify-content-center align-items-center'
            >
            <Col md={6}>
          
            <h1 className='text-center'>{language["kpiseconds"]["kpi"]}</h1>
            
          </Col>
          <Col md={6}>
          
            <h1 className='text-center'>{description}</h1>
          </Col>
            </Row>
          <Form onSubmit={(e)=>handleSubmitKPis(e)} role='form'>

            <Row className=' mb-3 justify-content-center align-items-center '>
              <Col md={12}>

                <Row className='justify-content-center align-items-center' >
                 
                  <Col md={4}  >
                  {editAble ? 
                   <Button
                   color='success'
                   className='btn-sm m-2 float-right'
                   onClick={() => {
                    seteditAble(!editAble);
                    handleSubmitKPis();
                  }}
                 >
                 Guardar
               </Button>
               :
               <Button
                 color='warning'
                 className='btn-sm m-2 float-right'
                 onClick={() => seteditAble(!editAble)}
               >
                 <FaEdit />
               </Button>

                }
                 
                  <FormGroup>
                   
                    <label>{language["kpiseconds"]["table"]["units"]}</label>
                    <Input
                      className='form-control-sm'
                      maxLength={254}
                      type='text'
                      name='units'
                      value={units}
                      disabled={!editAble}
                      onChange={(e) => setunits(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>{language["kpiseconds"]["table"]["memory"]}</label>
                    <Input
                      className='form-control-sm'
                      type='text'
                      maxLength={254}
                      name='memory'
                      value={memory}
                      disabled={!editAble}
                      onChange={(e) => setmemory(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>{language["kpiseconds"]["table"]["source"]}</label>
                    <Input
                      className='form-control-sm'
                      maxLength={254}
                      type='text'
                      name='source'
                      value={source}
                      disabled={!editAble}
                      onChange={(e) => setsource(e.target.value)}
                    />
                  
                  </FormGroup>
                  </Col>
                  <Col md={8} className='p-0' >
                  <KPIGraph  kpisecond={kpisecond} />
                  </Col>
                </Row>
              </Col>


              <Col md={12}>
                <Row>
                <Col md={3} >
                <FormGroup>
                  <label>
                    {language["kpiseconds"]["table"]["initial_value"]}
                  </label>
                  <Input
                    className='form-control-sm'
                    maxLength={254}
                    type='text'
                    name='initial_value'
                    value={initial_value}
                    onChange={(e) => !validateInputNumber(e.target.value ) ?  setinitial_value(e.target.value): initial_value}
                  />
                </FormGroup>
                </Col>

                <Col md={3} >
                <FormGroup>
                  <label>
                    {language["kpiseconds"]["table"]["initial_date"]}
                  </label>
                  <ReactDatePicker 
                        type="date" 
                        id="initial_date" 
                        dateFormat={"MM-yyyy"}
                        showMonthYearPicker
                        selected={initial_date}
                        o onChange={(e) => {
                          if (e > initial_date) {
                            setforecast_date(e);
                          }
                          setinitial_date(e);
                        }}
                        className='form-control form-control-sm'
                       />
                </FormGroup>
                </Col>
                <Col md={3}  >
                  
                  <ConfirmModal
                    color='warning'
                    className='btn-sm py-2 mt-4'
                    outline={true}
                    confirm={() => handleupdateKPIKShift({
                      initial_date: initial_date,
                      initial_value: initial_value,
                    })}
                    buttonText={language["kpiseconds"]["form"]["updateWith"]}
                    confirmText={language["kpiseconds"]["form"]["editConfirm"]}
                  />
                </Col>
                <Col md={3} >

                  <ConfirmModal
                    color='warning'
                    className='btn-sm py-2 mt-4'
                    confirm={() => handleSubmitKPis()}
                    buttonText={language["kpiseconds"]["form"]["updateWithout"]}
                    confirmText={language["kpiseconds"]["form"]["editConfirm"]}
                  />

                </Col>

                </Row>
              
                <Row>
                <Col md={3} >
                <FormGroup>
                  <label>
                    {language["kpiseconds"]["table"]["objetive_value"]}
                  </label>
                  <Input
                    className='form-control-sm'
                    type='text'
                    name='objetive_value'
                    value={objetive_value}
                    onChange={(e) => !validateInputNumber(e.target.value ) ?  setobjetive_value(e.target.value): objetive_value}
                  />
                </FormGroup>
                </Col>
                <Col md={3} >
                <FormGroup>
                  <label>
                    {language["kpiseconds"]["table"]["objetive_date"]}
                  </label>
                  <ReactDatePicker 
                        type="date" 
                        id="objetive_date" 
                        dateFormat={"MM-yyyy"}
                        showMonthYearPicker
                        selected={objetive_date}
                        onChange={(e) => {
                          if (e < initial_date) {
                            setinitial_date(e);
                          }
                          setobjetive_date(e);
                        }}
                        className='form-control form-control-sm'
                       />
                 
                </FormGroup>
                </Col>
                <Col md={3}  >
                 

                  <ConfirmModal
                    color='warning'
                    className='btn-sm py-2 mt-4'
                    outline={true}
                    confirm={() => handleupdateKPIKShift(
                      {
                        objetive_date: objetive_date,
                        objetive_value: objetive_value,
                      })
                    }
                    buttonText={language["kpiseconds"]["form"]["updateWith"]}
                    confirmText={language["kpiseconds"]["form"]["editConfirm"]}
                  />


                </Col>
                <Col md={3} >
                 <ConfirmModal
                    color='warning'
                    className='btn-sm py-2 mt-4'
                    confirm={() => handleSubmitKPis()}
                    buttonText={language["kpiseconds"]["form"]["updateWithout"]}
                    confirmText={language["kpiseconds"]["form"]["editConfirm"]}
                  />
                </Col>
                </Row>

                <Row>
                <Col md={3} >
                <FormGroup>
                  <label>
                    {language["kpiseconds"]["table"]["forecast_value"]}
                  </label>
                  <Input
                    className='form-control-sm'
                    name='forecast_value'
                    value={forecast_value}
                    onChange={(e) => !validateInputNumber(e.target.value ) ?  setforecast_value(e.target.value): forecast_value}
                  />
                </FormGroup>
                </Col>
                <Col md={3} >
                <FormGroup>
                  <label>
                    {language["kpiseconds"]["table"]["forecast_date"]}
                  </label>
                  <ReactDatePicker 
                        type="date" 
                        id="forecast_date" 
                        dateFormat={"MM-yyyy"}
                        showMonthYearPicker
                        selected={forecast_date}
                        onChange={(e) => {
                          if (e < initial_date) {
                            setinitial_date(e);
                          }
                          setforecast_date(e);
                        }}
                        className='form-control form-control-sm'
                       />
                 
                </FormGroup>
                </Col>
                <Col md={3}  >
                  <ConfirmModal
                    color='warning'
                    className='btn-sm py-2 mt-4'
                    outline={true}
                    confirm={() => handleupdateKPIKShift({
                      forecast_date: forecast_date,
                      forecast_value: forecast_value,
                    })}
                    buttonText={language["kpiseconds"]["form"]["updateWith"]}
                    confirmText={language["kpiseconds"]["form"]["editConfirm"]}
                  />


                </Col>
               
                <Col md={3} >


                  <ConfirmModal
                    color='primary'
                    className='btn-sm py-2 mt-4 '
                    confirm={() => copyBase()}
                    buttonText={language["kpiseconds"]["form"]["copy"]}
                    confirmText={language["kpiseconds"]["form"]["editConfirm"]}

                  />
                  


                </Col>
                </Row>
              </Col>
            </Row>
            </Form>

            <Row className='align-items-center'>
              <Col>
               <ListKpisecondKshifts kpiId={kpiId} kpisecond={kpisecond} />  
              </Col>
            </Row>



          </CardBody>           
        </Card>
    );
}

export default connect()(KshiftDetail);