import React from "react";
import { connect } from "react-redux";
import { Spinner } from "reactstrap"; // Assuming you're using Reactstrap
import { language } from "../../../assets/language";

// Component to show while PDF is being prepared
const PreparingPdf = () => {
  return (
    <div style={{
      position: 'absolute',
      top: '5%', // Adjust top as needed
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center', // Center horizontally
      alignItems: 'start', // Align to the top
      backgroundColor: 'rgba(255, 255, 255, 1)', // Opaque white background
      zIndex: 1000 // Ensure it's above other content
    }}>
      {/* Content of the loading screen */}
      <div style={{ textAlign: 'center' }}>
        <Spinner color='primary' size={"xl"} style={{ display: 'block' }} />
        <p style={{ marginTop: '1rem' }}>{language["initiatives"]["exporting"]}</p> {/* The message */}
      </div>
    </div>
  );
};

export default connect()(PreparingPdf);
