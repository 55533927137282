import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";

export const newTask = (data) => ({
  type: "NEW_TASK",
  payload: {
    id: data.id,
    description: data.description,
    area_id: data.area_id,
    created_at: data.created_at,
    forecast_date: data.forecast_date,
    estimated_date: data.estimated_date,
    real_date: data.real_date,
    stage: data.stage,
    responsable_user: data.responsable_user,
    completed: data.completed,
    status: data.status,
    initiative_id: data.initiative_id ? data.initiative_id : null,
    initiative: data.initiative ? data.initiative : null,
  },
  request_time: Date.now(),
});

export const setTaskError = (error) => ({
  type: "TASK_ERROR",
  payload: {
    error: error,
  },
});

export const deleteTask = (task_id) => ({
  type: "DELETE_TASK",
  payload: {
    id: task_id,
  },
  request_time: Date.now(),
});

export const updateTask = (task) => ({
  type: "UPDATE_TASK",
  payload: {
    id: task.id,
    description: task.description,
    area_id: task.area_id,
    created_at: task.created_at,
    forecast_date: task.forecast_date,
    estimated_date: task.estimated_date,
    real_date: task.real_date,
    stage: task.stage,
    status: task.status,
    responsable_user: task.responsable_user,
    completed: task.completed,
    initiative: task.initiative ? task.initiative : null,
    initiative_id: task.initiative_id ? task.initiative_id : null,
  },
  request_time: Date.now(),
});

export const setTasks = (tasks) => ({
  type: "SET_TASKS",
  payload: {
    tasks: tasks,
  },
  request_time: Date.now(),
});

export const setInitiativeTasks = (tasks, initiative_id) => ({
  type: "SET_INITIATIVE_TASKS",
  payload: {
    tasks: tasks,
    initiative_id: initiative_id,
  },
  request_time: Date.now(),
});

export const setTasksLoading = (value) => ({
  type: "SET_TASKS_LOADING",
  payload: {
    loading: value,
  },
  request_time: Date.now(),
});

export const getCurrentUserTasks = () => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(setTasksLoading(false));
      if (!getUserPermission(state, "tasks", "list", true)) {
        throw new Error("No permissions");
      }
      if (
        (!state.tasks.loading &&
          (state.tasks.request_time === null ||
            state.tasks.request_time + 1000 * 2 < Date.now())) ||
        (state.tasks.loading &&
          (state.tasks.loading_time === null ||
            state.tasks.loading_time + 1000 * 5 < Date.now()))
      ) {
        dispatch(setTasksLoading(true));
        fetch(`${BACKEND_URL}/users/${state.sessions.user.id}/tasks`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.sessions.token}`,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              throw new Error(response.status);
            }
          })
          .then((data) => {
            dispatch(setTasks(data));
            dispatch(setTasksLoading(false));
          })
          .catch((error) => {
            errorHandler("tasks", error.message);
            dispatch(setTaskError(error));
            dispatch(setTasksLoading(false));
          });
      }
    } catch (error) {
      errorHandler("tasks", error.message);
      dispatch(setTaskError(error));
      dispatch(setTasksLoading(false));
    }
  };
};

export const postTask = (data, initiative) => {
  return (dispatch, getState) => {
    const state = getState();

    try {
      // if (!getUserPermission(state, "tasks", "create", true)) {
      // throw new Error("No permissions");
      // }

      if (
        initiative ||
        state.sessions.user.role === "admin" ||
        getUserPermission(state, "tasks", "create", true)
      ) {
        fetch(`${BACKEND_URL}/tasks`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.sessions.token}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            if (response.status === 201) {
              return response.json();
            } else {
              throw new Error(response.status);
            }
          })
          .then((data) => {
            if (data != null && data !== undefined && data.id !== undefined) {
              dispatch(newTask(data));
            }
          })
          .catch((error) => {
            errorHandler("tasks", error.message);
            dispatch(setTaskError(error));
          });
      }
    } catch (error) {
      errorHandler("tasks", error.message);
      dispatch(setTaskError(error));
    }
  };
};

export const getInitiativeTasks = (initiative_id) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (!getUserPermission(state, "tasks", "list", true)) {
        throw new Error("No permissions");
      }
      if (
        (!state.tasks.loading &&
          (state.tasks.initiative_request_time === null ||
            state.tasks.initiative_request_time + 1000 * 2 < Date.now() ||
            state.tasks.initiative_id !== initiative_id)) ||
        (state.tasks.loading &&
          (state.tasks.loading_time === null ||
            state.tasks.loading_time + 1000 * 5 < Date.now()))
      ) {
        dispatch(setTasksLoading(true));
        fetch(`${BACKEND_URL}/initiatives/${initiative_id}/tasks`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.sessions.token}`,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              throw new Error(response.status);
            }
          })
          .then((data) => {
            dispatch(setInitiativeTasks(data, initiative_id));
            dispatch(setTasksLoading(false));
          })
          .catch((error) => {
            errorHandler("tasks", error.message);
            dispatch(setTaskError(error));
            dispatch(setTasksLoading(false));
          });
      }
    } catch (error) {
      errorHandler("tasks", error.message);
      dispatch(setTaskError(error));
      dispatch(setTasksLoading(false));
    }
  };
};

export const patchTask = (taskId, data) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (!getUserPermission(state, "tasks", "patch", true)) {
        throw new Error("No permissions");
      }
      fetch(`${BACKEND_URL}/tasks/${taskId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.sessions.token}`,
        },
        method: "PATCH",
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          dispatch(updateTask(data));
        })
        .catch((error) => {
          errorHandler("tasks", error.message);
          dispatch(setTaskError(error.message));
        });
    } catch (error) {
      errorHandler("tasks", error.message);
      dispatch(setTaskError(error));
    }
  };
};

export const deleteTaskById = (task) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      // if (!getUserPermission(state, "tasks", "delete", true)) {
      // throw new Error("No permissions");
      // }
      if (
        (state.initiatives.initiative !== undefined &&
          state.initiatives.initiative !== null &&
          (state.initiatives.initiative.leader_user ===
            state.sessions.user.id ||
            state.initiatives.initiative.sponsor_user ===
              state.sessions.user.id)) ||
        task.creator === state.sessions.user.id ||
        state.sessions.user.role === "admin" ||
        getUserPermission(state, "tasks", "delete", true)
      ) {
        fetch(`${BACKEND_URL}/tasks/${task.id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.sessions.token}`,
          },
        })
          .then((response) => {
            if (response.status === 204) {
              return true;
            } else {
              throw new Error(response.status);
            }
          })
          .then((vl) => {
            if (vl) {
              dispatch(deleteTask(task.id));
            }
          })
          .catch((error) => {
            errorHandler("tasks", error.message);
            dispatch(setTaskError(error));
          });
      }
    } catch (error) {
      console.log(error);
      errorHandler("tasks", error.message);
      dispatch(setTaskError(error));
    }
  };
};
