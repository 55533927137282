import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getCurrentUserCards,
  acceptCardToInitiative,
  getTags,
  getAllUsers,
} from "../../redux/actions";
import { language } from "../../assets/language";
import { CreateCard, DeleteCard, RejectCard, EditCard } from ".";
import DescriptionModal from "../../components/Modal/DescriptionModal";
import { getUserPermission } from "../../utils/getUserPermission";
import SimpleTable from "../../components/tables/SimpleTable";
import { Button, Card, FormGroup, Input, Row, Col } from "reactstrap";
import Loading from "../../components/Loading/Loading";

const changeStateType = (type_) => {
  switch (type_) {
    case true:
      return "true";
    case false:
      return "false";
    case null:
      return "null";
    default:
      return "";
  }
};

const ListMyCards = ({ dispatch }) => {
  const [cards, setcards] = useState([]);
  const cardsData = useSelector((state) => state.cards.cards);
  const state = useSelector((state) => state);
  const areas = useSelector((state) => state.areas.areas);
  const tags = useSelector((state) => state.tags.tags);
  const users = useSelector((state) => state.users.users);
  const initiatives = useSelector((state) => state.initiatives.initiatives)
  const [loading, setloading] = useState(true);
  const unit =  localStorage.getItem("unit") !== "undefined" ? JSON.parse(localStorage.getItem("unit")) : "";

  
  const currentUser = useSelector((state) => state.sessions.user);

  let [blocked_card, setBlockedCard] = React.useState(false);
  let [cardState, setCardState] = React.useState("all");
  let [cardValued, setCardValued] = React.useState("all");
  let [cardEase, setCardEase] = React.useState("all");
  let [cardAreas, setCardAreas] = React.useState("all");
  let [cardStatus, setCardStatus] = React.useState("all");
  let [initiativesId, setInitiativesId] = React.useState([]);

  let states_cards = useMemo(() => [0, 1, 2, 3, 4], []);
  let valued_cards = useMemo(() => [0, 1, 2], []);
  let ease_cards = useMemo(() => [0, 1, 2, 3, 4], []);

  useEffect(() => {
    setcards(cardsData);
  }, [cardsData]);

  useEffect(() => {
    if (initiatives) {
      setInitiativesId(initiatives.map((item) => item.id));
    }
  }, [initiatives]);

  
  const areas_dict = useMemo(() => {
    return areas.reduce((acc, area) => {
      acc[area.id] = area;
      return acc;
    }, {});
  }, [areas]);

  useEffect(() => {
    dispatch(getCurrentUserCards());
    dispatch(getAllUsers())
    dispatch(getTags());
  }, []);

  useEffect(() => {
    if (tags && cardsData && areas && users &&  areas_dict) {
      setloading(false);
    }
  }, [tags, cardsData, cardAreas, areas, areas_dict]);
  const acceptCard = (id) => {
    dispatch(acceptCardToInitiative(id));
  };

  const columns = useMemo(
    () => [
      {
        Header: language["cards"]["table"]["title"],
        accessor: "title",
      },
      {
        Header: language["cards"]["table"]["area"],
        accessor: "area",
      },
      {
        Header: language["cards"]["table"]["user"],
        accessor: "user",
      },
      {
        Header: language["cards"]["table"]["description"],
        accessor: "description",
      },
      {
        Header: language["cards"]["table"]["estimated_value"] + unit.value,
        accessor: "estimated_value",
      },
      {
        Header: language["cards"]["table"]["is_accepted"],
        accessor: "is_accepted",
      },
      {
        Header: language["cards"]["table"]["state"],
        accessor: "state",
      },
      {
        Header: language["cards"]["table"]["valued"],
        accessor: "valued",
      },
      {
        Header: language["cards"]["table"]["fase"],
        accessor: "fase",
      },
      {
        Header: language["cards"]["table"]["ease"],
        accessor: "ease",
      },
      {
        Header: language["cards"]["table"]["action"],
        accessor: "action",
      },
    ],
    []
  );
  const renderTableIdea = () => {
    let _data =
      cards && tags && areas &&
      cards
        .sort((a, b) => {
          a = a.created_at;
          b = b.created_at;
          return a > b ? -1 : a < b ? 1 : 0;
        })
        .filter((card) => {
          if (
            cardState !== "all" &&
            card.state !== null &&
            card.state !== undefined
          ) {
            return card.state.toString() === cardState.toString();
          }
          return true;
        })
        .filter((card) => {
          if (
            cardValued !== "all" &&
            card.valued !== null &&
            card.valued !== undefined
          ) {
            return card.valued.toString() === cardValued.toString();
          }
          return true;
        })
        .filter((card) => {
          if (
            cardAreas !== "all" &&
            areas_dict[card.area_id].name !== null &&
            areas_dict[card.area_id].name !== undefined
          ) {
            return cardAreas === areas_dict[card.area_id].name;
          }
          return true;
        })
        .filter((card) => {
          if (cardStatus !== "all" && card.is_accepted !== undefined) {
            return cardStatus === changeStateType(card.is_accepted);
          }
          return true;
        })
        .filter((card) => {
          if (
            cardEase !== "all" &&
            card.ease !== null &&
            card.ease !== undefined
          ) {
            return card.ease.toString() === cardEase.toString();
          }
          return true;
        })
        .map((card, i) => {
          return {
            id: card.id,
            title: card.initiative_id  && initiativesId.includes(card.initiative_id)  ?  (
              <Link to={`/initiative/${card.initiative_id}`}>{card.title}</Link>
            ): card.title,
            area:
              areas_dict &&
              card["area_id"] &&
              areas_dict.hasOwnProperty(card["area_id"])
                ? areas_dict[card["area_id"]].name
                : "",
            description: (
              <DescriptionModal
                description={card.description}
                title={card.title}
              />
            ),
            user: users[card.original_user] ? users[card.original_user].full_name: "",
            estimated_value: card.estimated_value,
            is_accepted: card.is_accepted
              ? language["cards"]["is_accepted"]["accept"]
              : card.is_accepted === false 
              ? language["cards"]["is_accepted"]["reject"]
              : language["cards"]["is_accepted"]["pending"],
            state: tags[0] ?
             tags.find((t) => t.id === card.state) ?  tags.find((t) => t.id === card.state).name : "Nueva" : "Nueva",
            valued: language.cards.valued[card.valued || 0],
            ease: language.cards.ease[card.ease || 0],
            fase: card.fase,
            action: (
              <div>
                {![true, false].includes(card.is_accepted) &&
                (getUserPermission(state, "initiatives", "create", false) ||
                  getUserPermission(state, "Ideas", "delete", false) ||
                  getUserPermission(state, "Ideas", "patch", false)) ? (
                  <div>
                    {getUserPermission(
                      state,
                      "Iniciativas",
                      "create",
                      false
                    ) ? (
                      <Button
                        className='btn-sm mb-1'
                        color='success'
                        block
                        disabled={blocked_card}
                        onClick={() => {
                          setBlockedCard(true);
                          acceptCard(card.id);
                        }}
                      >
                        {language["cards"]["form"]["accept"]}
                      </Button>
                    ) : (
                      <></>
                    )}
                    {getUserPermission(state, "Ideas", "delete", false) &&
                    card.original_user !== currentUser.id ? (
                      <RejectCard card={card} />
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                {getUserPermission(state, "Ideas", "patch", false) &&
                !card.is_accepted ? (
                  <EditCard card={card} />
                ) : (
                  <></>
                )}
                {getUserPermission(state, "Ideas", "delete", false)  &&
                !card.is_accepted ? (
                  <DeleteCard card={card} />
                ) : (
                  <></>
                )}
              </div>
            ),
          };
        });

    if (_data.length === 0) {
      return <h3>{language.cards.not_found}</h3>;
    }

    return <SimpleTable data={_data} columns={columns} />;
  };

  const renderSelectCardState = () => {
    return (
      <FormGroup>
        <h4>{language.cards.form.select_state}: </h4>
        <Input
          className='form-control-sm'
          type='select'
          onChange={(e) => setCardState(e.target.value)}
          value={cardState}
        >
          <option key={"all.state"} value={"all"}>
            {language.cards.form.all}
          </option>
          {Array.isArray(tags)
            ? tags.map((tag) => (
                <option key={tag.id} value={tag.id}>
                  {tag.name}
                </option>
              ))
            : ""}
        </Input>
      </FormGroup>
    );
  };

  const renderSelectValued = () => {
    return (
      <FormGroup>
        <h4>{language.cards.form.select_valued}: </h4>
        <Input
          className='form-control-sm'
          type='select'
          onChange={(e) => setCardValued(e.target.value)}
          value={cardValued}
        >
          <option key={"all.valued"} value={"all"}>
            {language.cards.form.all}
          </option>
          {valued_cards.map((num, index) => {
            return (
              <option key={index} value={num}>
                {language.cards.valued[num || 0]}
              </option>
            );
          })}
        </Input>
      </FormGroup>
    );
  };

  const renderSelectEase = () => {
    return (
      <FormGroup>
        <h4>{language.cards.form.select_ease}: </h4>
        <Input
          className='form-control-sm'
          type='select'
          onChange={(e) => setCardEase(e.target.value)}
          value={cardEase}
        >
          <option key={"all.ease"} value={"all"}>
            {language.cards.form.all}
          </option>
          {ease_cards.map((num, index) => {
            return (
              <option key={index} value={num}>
                {language.cards.ease[num || 0]}
              </option>
            );
          })}
        </Input>
      </FormGroup>
    );
  };
const renderSelectCardArea = () => {
  const cardsAreaFilter = cards
    .map((card) => areas_dict[card.area_id] ? areas_dict[card.area_id].name : "")
    .filter((name, index, self) => self.indexOf(name) === index);

  return (
    <FormGroup>
      <h4>{language.cards.form.select_areas}: </h4>
      <Input
        className='form-control-sm'
        type='select'
        onChange={(e) => {
          setCardAreas(e.target.value);
        }}
        value={cardAreas}
      >
        <option key={"all.ease"} value={"all"}>
          {language.cards.form.all}
        </option>
        {cardsAreaFilter.map((num, index) => (
          <option key={index} value={num}>
            {num}
          </option>
        ))}
      </Input>
    </FormGroup>
  );
};

  const renderSelectCardStatus = () => {
    let cardsAreaFilter = [];
    cards.map((card) => {
      if (!cardsAreaFilter.includes(card.is_accepted)) {
        cardsAreaFilter.push(card.is_accepted);
      }
    });
    return (
      <FormGroup>
        <h4>{language.cards.form.select_status}: </h4>
        <Input
          className='form-control-sm'
          type='select'
          onChange={(e) => {
            setCardStatus(e.target.value);
          }}
          value={cardStatus}
        >
          <option key={"all.status"} value={"all"}>
            {language.cards.form.all}
          </option>
          <option value={true}>
            {language["cards"]["is_accepted"]["accept"]}
          </option>
          <option value={false}>
            {language["cards"]["is_accepted"]["reject"]}
          </option>
          <option value={"null"}>
            {language["cards"]["is_accepted"]["pending"]}
          </option>
        </Input>
      </FormGroup>
    );
  };
  return loading ? (
    <Loading />
  ) : (
    <Card className='p-3 mt-3'>
      <div className='pb-3'>
        <h1 className='float-left w-25'>{language["cards"]["title"]}</h1>
        <CreateCard getCurrentUserCards={getCurrentUserCards} />
      </div>
      <Row>
        <Col>{renderSelectCardState()}</Col>
        <Col>{renderSelectCardArea()}</Col>
        <Col>{renderSelectCardStatus()}</Col>
        <Col>{renderSelectValued()}</Col>
        <Col>{renderSelectEase()}</Col>
      </Row>
      {renderTableIdea()}
    </Card>
  );
};

export default connect()(ListMyCards);
