import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { language } from "../../assets/language";
import { ListInitiativeSummary } from "../initiatives";
import { FormGroup, Input } from "reactstrap";
import { CreateTask, ListInitiativeTasksSummary } from "../tasks";
import { getUserPermission } from "../../utils/getUserPermission";

const ControlDashboard = ({ dispatch, _initiatives, handle, fullscreen }) => {

  const state = useSelector((state) => state);
  let [initiative, setInitiative] = useState(null);
  const user_initiatives = useSelector((state) => state.initiatives.initiatives);
  let [initiativesId, setInitiativesId] = useState([]);
  let [initiatives, setInitiatives] = useState([]);
  
  
  useEffect(() => {
    if (user_initiatives) {
      setInitiativesId(user_initiatives.map((item) => item.id));
    }
  }, [user_initiatives]);

  useEffect(() => {
    if (_initiatives && initiativesId) {
      setInitiatives(
        _initiatives.filter((item) => initiativesId.includes(item.id))
      );
    }
  }, [_initiatives, initiativesId]);


  useEffect(() => {
    if (initiative) {
      let ini = initiatives.find((ini) =>
        initiative !== null ? ini.id === initiative.id : null
      );
      if (!ini) {
        if (initiatives.length > 0) {
          setInitiative(initiatives[0]);
        } else {
          setInitiative(null);
        }
      }
    }
  }, [initiatives, initiative]);

  const selectInitiative = (initiativeId) => {
    // if exists initiative with id = initiativeId
    if (initiatives.find((initiative) => initiative.id === initiativeId)) {
      setInitiative(
        initiatives.find((initiative) => initiative.id === initiativeId)
      );
    } else {
      setInitiative(null);
    }
  };

  const renderSelectInitiative = () => {
    if (initiatives.length > 0) {
      return (
        <FormGroup>
          <label>{language.initiatives.form.select}: </label>
          <Input
            className='form-control-sm'
            type='select'
            onChange={(e) => selectInitiative(e.target.value)}
            value={initiative ? initiative.id : ""}
          >
            <option value=''>{language.general.select}</option>
            {initiatives.map((initiative, index) => {
              return (
                <option key={index} value={initiative.id}>
                  {initiative.title}
                </option>
              );
            })}
          </Input>
        </FormGroup>
      );
    }
  };

  return (
    <>
      <br />
      <h1>{language["control"]["titles"]["summary"]}</h1>

      <ListInitiativeSummary
        initiatives={_initiatives}
        handle={handle}
        fullscreen={fullscreen}
        initiativesId={initiativesId}
      />
    </>
  );
};

export default connect()(ControlDashboard);
