import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { getAllUsers, postInitiativeUser } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
} from "reactstrap";

const CreateInitiativeUser = ({ dispatch, initiative }) => {
  const users = useSelector((state) => state.users.users);
  const initiative_users = useSelector((state) => state.initiative_users.users);
  const _groups = useSelector((state)=> state.groups.groups);
  const [groups, setGroups] = useState([]);
 

  useEffect(()=> {
    if (_groups) {
       let groupFilter =  _groups.filter(group => !group.is_deleted && group.initiative)
       let groupMap = groupFilter.map(group=>group.name)
      groupMap = groupMap.filter((item, index) => groupMap.indexOf(item) === index)

      setGroups(groupMap)

    }
  },[_groups])

  let [modalInitiativeUsers, toggleModal] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    toggleModal(false);
    setUserId("");
    setResponsability("");
  });

  let [userId, setUserId] = useState("");
  let [loadedUsers, setLoadedUsers] = useState(false);
  let [responsability, setResponsability] = useState("");

  const handleSubmitInitiativeUsers = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    dispatch(postInitiativeUser(initiative.id, object));
    toggleModal(false);
    setUserId("");
    setResponsability("");
  };

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    if (loadedUsers && Object.keys(users) > 0) {
      setUserId(Object.values(users)[0].id);
      setLoadedUsers(false);
    }
  }, [users, loadedUsers]);

  return (
    <div>
      <Button
        color='success'
        className='btn-sm float-right mt-2'
        onClick={() => toggleModal(true)}
      >
        {language["users"]["form"]["create"]}
      </Button>
      <Modal
        className='modal-dialog-centered'
        isOpen={modalInitiativeUsers}
        toggle={() => toggleModal(false)}
      >
        <div className='modal-body p-0'>
          <Card className='bg-secondary shadow border-0'>
            <CardBody className='px-lg-5 py-lg-2'>
              <h2 className='text-muted text-center mt-2 mb-3'>
                {language["users"]["form"]["create"]}
              </h2>
              <Form onSubmit={handleSubmitInitiativeUsers} role='form'>
                <FormGroup>
                  <label>{language["users"]["table"]["full_name"]}</label>
                  <Input
                    className='form-control-sm'
                    type='select'
                    name='user_id'
                    required
                    onChange={(e) => {
                      setUserId(e.target.value);
                    }}
                    value={userId}
                  >
                    <option value=''>{language["users"]["table"]["full_name"]}</option>
                    {Object.values(users)
                      .filter(
                        (_us) =>
                          !initiative_users.find((_u) => _u.user_id === _us.id)
                      )
                      .map((us) => (
                        <option id={us.id} key={us.id} value={us.id}>
                          {us.full_name}
                        </option>
                      ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label>{language["initiative_users"]["table"]["responsability"]}</label>
                  <Input
                    className='form-control-sm'
                    type='select'
                    name='responsability'
                    required
                    value={responsability}
                    onChange={(e) => {
                      setResponsability(e.target.value);
                    }}
                  >
                    <option value=''>{language["initiative_users"]["table"]["responsability"]}</option>
                    
                      <option key={"Lider"} value={"Lider"}>
                       Dueño
                      </option>
                   
                  </Input>



                  


                  </FormGroup>

                <Input
                  type='hidden'
                  name='initiative_id'
                  defaultValue={initiative.id}
                />
                <Button className='btn-sm' 
                  color='success' 
                  type='submit'
                  disabled={userId === "" || responsability === ""}
                >
                  {language["initiative_users"]["form"]["create"]}
                </Button>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default connect()(CreateInitiativeUser);
