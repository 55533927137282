import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import {
  getAllPermits,
  addRolePermit,
  deleteRolePermit,
  getRolePermitsEdition,
} from "../../redux/actions";
import { language } from "../../assets/language";
import SimpleTable from "../../components/tables/SimpleTable";
import { Input } from "reactstrap";
import Loading  from "../../components/Loading/Loading"

const ListRolePermits = ({ dispatch, role }) => {
  const permits = useSelector((state) => state.permits.permits);
  const role_permits = useSelector(
    (state) => state.permits.role_permits_edition
  );
  const loadingPermits = useSelector((state) => state.permits.loading);
  const loadingRolePermits = useSelector((state) => state.permits.role_loading);

  const [role_permits_change, setRolePermitsChange] = useState(false);
  const [loading , setLoading] = useState(false);

  useEffect(() => {
    dispatch(getAllPermits());
    dispatch(getRolePermitsEdition(role.id));
  }, [dispatch, role, permits, loadingPermits]);

  useEffect(() => {
    if (role_permits_change && role_permits.length > 0) {
      setRolePermitsChange(false);
      setLoading(false);
    }
  }, [role_permits, role_permits_change]);

  useEffect(() => {
    if(permits){
      setLoading(false);

    }
    }, [permits]);

  const changeRolePermit = (e) => {
    setLoading(true);
    e.preventDefault();
    const permit_id = e.target.value;
    const role_permit = role_permits.find((rp) => rp.permit_id === permit_id);
    if (role_permit) {
      dispatch(deleteRolePermit(role_permit.role_id, role_permit.id));
    } else {
      dispatch(
        addRolePermit({ role_id: role.id, permit_id: permit_id }, role.id)
      );
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: language["permits"]["table"]["resource"],
        accessor: "resource",
      },
      {
        Header: language["permits"]["table"]["action"],
        accessor: "action",
      },
      {
        Header: language["role_permits"]["table"]["exists"],
        accessor: "exists",
      },
    ],
    []
  );

  const renderRolePermits = () => {
    if (loadingPermits || loadingRolePermits) {
      return <p>{language.general.loading}</p>;
    }
    if (!permits || permits.length === 0 || !role_permits) {
      return <p>{language["permits"]["not_found"]}</p>;
    }

    let _permits = permits.map((permit) => {
      const resource = role_permits.find((p) => p.permit_id === permit.id);
      return {
        id: permit.id,
        resource: language.permits.resources[permit.resource || "default"],
        action: language.permits.actions[permit.action || "default"],
        exists: 
          <Input
            className='form-control-md mb-5'
            id={permit.id}
            type='checkbox'
            checked={resource ? true : false}
            onChange={(e) =>changeRolePermit(e)}
            value={permit.id}
          />
       
      };
    });

    return <SimpleTable data={_permits} columns={columns} />;
  };

  return (
    <div>
      {!role_permits_change ? (
        renderRolePermits()
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default connect()(ListRolePermits);
