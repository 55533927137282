import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";


export const setChecklists = (checklists) => ({
    type: "SET_CHECKLISTS",
    payload: {
        checklists: checklists,
    },
    request_time: Date.now()
});

export const setChecklist = (data) => ({
    type: "SET_CHECKLIST",
    payload: {
        id: data.id,
        question: data.question,
        phase_id: data.phase_id,
        request_time: Date.now()
    },
});

export const setChecklistError = (error) => ({
    type: "CHECKLIST_ERROR",
    payload: {
        error: error,
    }
});

export const deleteChecklist = (checklist_id) => ({
    type: "DELETE_CHECKLIST",
    payload: {
        id: checklist_id,
    },
    request_time: Date.now()
});

export const getAllChecklists = () => {

    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "checklists", "list", true)) {
                throw new Error("No permissions");
            }
            
                fetch(`${BACKEND_URL}/checklists`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setChecklists(data));
                    })
                    .catch((error) => {
                        errorHandler("checklists", error.message);
                        dispatch(setChecklistError(error));
                    });
            
        } catch (error) {
            errorHandler("checklists", error.message);
            dispatch(setChecklistError(error));
        }
    };
}

export const postChecklist = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "checklists", "create", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/checklists`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
                body: JSON.stringify(data)
            })
                .then((response) => {
                    if (response.status === 201) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    if (data != null && data !== undefined && data.id !== undefined) {
                        dispatch(setChecklist(data));
                    }
                })
                .catch((error) => {
                    errorHandler("checklists", error.message);
                    dispatch(setChecklistError(error));
                });
        } catch (error) {
            errorHandler("checklists", error.message);
            dispatch(setChecklistError(error));
        }
    };
}

export const deleteChecklistById = (checklist_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "checklists", "delete", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/checklists/${checklist_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
                .then((response) => {
                    if (response.status === 204) {
                        return true;
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((vl) => {
                    if (vl) {
                        dispatch(deleteChecklist(checklist_id));
                    }
                })
                .catch((error) => {
                    console.log(error);
                    errorHandler("checklists", error.message);
                    dispatch(setChecklistError(error))
                });
        } catch (error) {
            errorHandler("checklists", error.message);
            dispatch(setChecklistError(error));
        }
    };
}