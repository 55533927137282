import {
    Row,
    Col,
    Badge,
  } from "reactstrap";
  import { language } from "../../assets/language";


const InitiativeHeader = ({initiative,  phases }) => {
    return (
      <>
        <Row>
          <Col md='6' className="text-center">
            <h1 className='p-3'>
              {language["initiatives"]["table"]["title"]}: {initiative.title}
            </h1>
            {initiative.is_blocked ? (
              <h3>{language["initiatives"]["table"]["is_blocked"]}</h3>
              ) : (
              <></>
              )
            }
          </Col>
          <Col md='6' className="text-center">
            <h1 className='p-3'>
              {language["initiatives"]["table"]["code"]}: {initiative.code}
            </h1>
          </Col>
        </Row>

        <Row>
          <div className='col-md-3 text-center'>
            <h3 >
                {language["initiatives"]["table"]["ola"]}: {initiative["ola"]}
            </h3>
          </div>
          <div className='col-md-3 text-center'>
            <h3>
              {language["initiatives"]["table"]["status"]}:{" "}
              {initiative.status === "complete"  || initiative.status === "in_progress" ? (
                  <Badge color='success'>
                  {language["initiatives"]["status"][initiative.status]}
                  </Badge>
              ) : (
                  <Badge color='danger'>
                  {language["initiatives"]["status"][initiative.status]}
                  </Badge>
              )}
            </h3>
          </div>
          <div className='col-md-3 text-center'>
            <h3>
              {language["initiatives"]["table"]["stage"]}:{" "}
              {phases.hasOwnProperty(initiative.stage)
                ? phases[initiative.stage].name
                : initiative.stage}
            </h3>
          </div>
          <div className='col-md-3 text-center'>
            <h3>
              {language["initiatives"]["table"]["year_value"]}:{" "}
              {(initiative.last_month_value * 12 || 0).toFixed(2)}
            </h3>
          </div>
        </Row>
      </>
    )
};
export default InitiativeHeader;
