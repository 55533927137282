 
 

 export const validateInputNumber = (value) => {
        const pattern = /^-?(?:\d+|\d+\.\d{1,2}|[0-9]+\.|)$/;
        const isValid = pattern.test(value);
        if (isValid) {
          return false;
        } else {
          return true;
        }
      };