import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";


export const newArea = (data) => ({
    type: "NEW_AREA",
    payload: {
        id: data.id,
        name: data.name,
        description: data.description,
        parent_id: data.parent_id
    },
    request_time: Date.now()
});

export const setAreaError = (error) => ({
    type: "AREA_ERROR",
    payload: {
        error: error
    }
});

export const deleteArea = (area_id) => ({
    type: "DELETE_AREA",
    payload: {
        id: area_id
    },
    request_time: Date.now()
});

export const updateArea = (area) => ({
    type: "UPDATE_AREA",
    payload: {
        id: area.id,
        name: area.name,
        description: area.description,
        parent_id: area.parent_id
    },
    request_time: Date.now()
});

export const setAreas = (areas) => ({
    type: "SET_AREAS",
    payload: {
        areas: areas
    },
    request_time: Date.now()
});

export const setAreasLoading = (value) => ({
    type: "SET_AREAS_LOADING",
    payload: {
        loading: value,
    },
    request_time: Date.now()
});

export const getAllAreas = () => {

    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "areas", "list", true)) {
                throw new Error("No permissions");
            }
            if ((!state.areas.loading && (state.areas.request_time === null || state.areas.request_time + 1000 * 2 < Date.now()))
                || (state.areas.loading && (state.areas.loading_time === null || state.areas.loading_time + 1000 * 5 < Date.now()))
            ) {
                dispatch(setAreasLoading(true));
                fetch(`${BACKEND_URL}/areas`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setAreas(data));
                        dispatch(setAreasLoading(false));
                    })
                    .catch((error) => {
                        errorHandler("areas", error.message);
                        dispatch(setAreaError(error));
                        dispatch(setAreasLoading(false));
                    });
            }
        } catch (error) {
            errorHandler("areas", error.message);
            dispatch(setAreaError(error));
            dispatch(setAreasLoading(false));
        }
    };
}

export const postArea = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "areas", "create", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/areas`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
                body: JSON.stringify(data)
            })
                .then((response) => {
                    if (response.status === 201) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    if (data != null && data !== undefined && data.id !== undefined) {
                        dispatch(newArea(data));
                    }
                })
                .catch((error) => {
                    errorHandler("areas", error.message);
                    dispatch(setAreaError(error));
                });
        } catch (error) {
            errorHandler("areas", error.message);
            dispatch(setAreaError(error));
        }
    };
}

// update area
export const putArea = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            fetch(`${BACKEND_URL}/areas/${data.id}`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
                body: JSON.stringify(data)
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    if (data != null && data !== undefined && data.id !== undefined) {
                        dispatch(updateArea(data));
                    }
                })
                .catch((error) => {
                    errorHandler("areas", error.message);
                    dispatch(setAreaError(error));
                });
        } catch (error) {
            errorHandler("areas", error.message);
            dispatch(setAreaError(error));
        }
    };
}



export const deleteAreaById = (area_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "areas", "delete", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/areas/${area_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
                .then((response) => {
                    if (response.status === 204) {
                        return true;
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((vl) => {
                    if (vl) {
                        dispatch(deleteArea(area_id));
                    }
                })
                .catch((error) => {
                    errorHandler("areas", error.message);
                    dispatch(setAreaError(error));
                });
        } catch (error) {
            errorHandler("areas", error.message);
            dispatch(setAreaError(error));
        }
    };
}