const initialState_InitiativeUser = {
    request_time: null,
    error: null,
    users: [],
    initiative_id: null,
    deleted: false,
};

const initiative_users = (state = initialState_InitiativeUser, action) => {
    switch (action.type) {
        case 'SET_INITIATIVE_USERS':
            return {
                users: action.payload.users.filter(user => !user.is_deleted),
                request_time: action.request_time,
                initiative_id: action.payload.initiative_id
            };
        case 'SET_INITIATIVE_USER':
            try {
            return {
                ...state,
                users: [...state.users, action.payload],
                request_time: action.request_time,
                initiative_id: action.payload.initiative_id
            };
                
            } catch (e) {
                console.log(e);
                return state;
            }
        case 'SET_INITIATIVE_USER_ERROR':
            return {
                ...state,
                error: action.payload.error,
                request_time: null,
                initiative_id : null,
                // users: [],
            };
        case 'DELETE_INITIATIVE_USER':
            try {
                return {
                    ...state,
                    users: state.users.filter(us => us.id !== action.payload.id),
                    request_time: action.request_time,
                    initiative_id: action.payload.initiative_id,
                };
            } catch (e) {
                console.log(e);
                return state;
            }
        default:
            return state;
    }
}

export default initiative_users;