import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { deleteAdvanceById } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { Button } from "reactstrap";

const DeleteAdvance = ({ dispatch, advance }) => {
  let [modalAdvances, setModalAdvances] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalAdvances(false);
  });

  const deleteAdvanceButton = () => {
    if (modalAdvances) {
      return <></>;
    }
    return (
      <Button
        color='danger'
        className='btn-sm'
        onClick={() => setModalAdvances(true)}
      >
        {language["advances"]["form"]["delete"]}
      </Button>
    );
  };

  const handleSubmitAdvances = () => {
    dispatch(deleteAdvanceById(advance.id));
    setModalAdvances(false);
  };

  const renderModalFormAdvances = () => {
    if (modalAdvances) {
      return (
        <div className='form' ref={wrapperRef}>
          <div>
            <Button
              color='warning'
              className='btn-sm'
              onClick={() => setModalAdvances(false)}
            >
              {language["general"]["cancel"]}
            </Button>
          </div>
          <br />
          <div>
            <Button
              color='danger'
              className='btn-sm'
              onClick={() => handleSubmitAdvances()}
            >
              {language["advances"]["form"]["deleteConfirm"]}
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      {deleteAdvanceButton()}
      {renderModalFormAdvances()}
    </div>
  );
};

export default connect()(DeleteAdvance);
