import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { postKPISecond } from "../../redux/actions";
import { language } from "../../assets/language";
import { getAllTrees } from "../../redux/actions";
import {
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  Form,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import Loading from "../../components/Loading/Loading";
import ReactDatePicker from "react-datepicker";
import { validateInputNumber } from "../../utils/validateInputNumber";
const CreateKpiSecond = ({ initiative }) => {
  
  let dispatch = useDispatch();
  let [modalKPIs, toggleModal] = useState(false);
  let [loading, setLoading] = useState(true);
  const treesRequest = useSelector(
    (state) => state.trees.trees
  )
  //use state initial_Date and forecaste_date
  const [initial_date, setinitial_date] = useState(new Date());
  const [objetive_date, setobjetive_date] = useState(new Date());
  const [forecast_date, setforecast_date] = useState(new Date());

  const [description, setdescription] = useState("");
  const [units, setunits] = useState("");
  const [initial_value, setinitial_value] = useState("")
  const [objetive_value, setobjetive_value] = useState("");
  const [memory, setmemory] = useState("");
  const [source, setsource] = useState("");
  const [forecast_value, setforecast_value] = useState("");




  useEffect(() => {
    dispatch(getAllTrees());
  }, []);


  useEffect(()=> {
    if(treesRequest[0]?.id !== null){
      setLoading(false);
    }
  }, [treesRequest])

  // function clean state
  const cleanState = () => {
    setdescription("");
    setunits("");
    setinitial_value("");
    setobjetive_value("");
    setmemory("");
    setsource("");
    setinitial_date(new Date());
    setforecast_date(new Date());
    setobjetive_date(new Date());
    setforecast_value("");
  };






  const handleSubmitKPis = (e) => {
    e.preventDefault();
   
    var object = {
      description: description,
      units: units,
      initial_value: initial_value,
      objetive_value: objetive_value,
      forecast_value: forecast_value,
      memory: memory,
      source: source,
      initial_date: initial_date,
      objetive_date: objetive_date,
      forecast_date: forecast_date,
      initiative_id: initiative.id,

    };
   
    dispatch(postKPISecond(object));
    cleanState();
    toggleModal(false);
  };

  return  loading ? <Loading /> : (
    <div>
      <Button
        color='success'
        className='btn-sm float-right '
        onClick={() => toggleModal(true)}
      >
        {language["kpiseconds"]["form"]["create"]}
      </Button>
      <Modal
        className='modal-dialog-centered'
        isOpen={modalKPIs}

      >
        <div className='modal-body p-0'>
          <Card className='bg-secondary shadow border-0'>
            <CardBody className='px-lg-5 py-lg-2'>
              <h2 className='text-muted text-center mt-2 mb-3'>
                {language["kpiseconds"]["form"]["create"]}
              </h2>
              <Form onSubmit={e=>handleSubmitKPis(e)} role='form'>

                <FormGroup>
                  <label>
                    {language["kpiseconds"]["table"]["description"]}
                  </label>
                  <Input
                    maxLength={254}
                    className='form-control-sm'
                    type='text'
                    autoComplete='off'
                    name='description'
                    value={description}
                    onChange={(e) => setdescription(e.target.value)}
                  />
                </FormGroup>
                  
                <FormGroup>
                  <label>{language["kpiseconds"]["table"]["units"]}</label>
                  <Input
                    maxLength={254}
                    className='form-control-sm'
                    type='text'
                    name='units'
                    value={units}
                    onChange={(e) => setunits(e.target.value)}
                  />
                </FormGroup>
                <Row>
                  <Col md={6} >
                      <FormGroup  >
                      <label>
                        {language["kpiseconds"]["table"]["initial_value"]}
                      </label>
                      <Input
                        maxLength={254}
                        className='form-control-sm'
                        type='text'
                        name='initial_value'
                        value={initial_value}
                        onChange={(e) =>  !validateInputNumber(e.target.value ) ?  setinitial_value(e.target.value): initial_value}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6} >
                      <FormGroup >
                      <label>
                        {language["kpiseconds"]["table"]["initial_date"]}
                      </label>
                      <ReactDatePicker 
                        type="date" 
                        id="initial_date" 
                        dateFormat={"MM-yyyy"}
                        showMonthYearPicker
                        selected={initial_date}
                        onChange={date => setinitial_date(date)}
                        className='form-control form-control-sm'
                       />
                    </FormGroup>
                    </Col>
                </Row>
                <h4 className='text-left'>
                    {language["kpiseconds"]["promise"]}
                  </h4>
                <Row>
                  <Col md={6} >
                    <FormGroup>
                    <label>
                      {language["kpiseconds"]["table"]["objetive_value"]}
                    </label>
                    <Input
                      maxLength={254}
                      className='form-control-sm'
                      type='text'
                      name='objetive_value'
                      value={objetive_value}
                      onChange={(e) => !validateInputNumber(e.target.value ) ?  setobjetive_value(e.target.value): objetive_value}
                    />
                  </FormGroup>
                  </Col>
                  <Col md={6} >
                  <FormGroup>
                  <label>
                    {language["kpiseconds"]["table"]["objetive_date"]}
                  </label>
                  <ReactDatePicker 
                        type="date" 
                        id="objetive_date" 
                        dateFormat={"MM-yyyy"}
                        showMonthYearPicker
                        selected={objetive_date}
                        onChange={date => setobjetive_date(date)}
                        className='form-control form-control-sm'
                       />
                </FormGroup>
                  </Col>
                </Row>
                <h4 className='text-left'>
                    {language["kpiseconds"]["forecast"]}
                  </h4>
                <Row>
                  <Col md={6} >
                    <FormGroup>
                    <label>
                      {language["kpiseconds"]["table"]["forecast_value"]}
                    </label>
                    <Input
                      maxLength={254}
                      className='form-control-sm'
                      type='text'
                      name='forecast_value'
                      value={forecast_value}
                      onChange={(e) => !validateInputNumber(e.target.value ) ?  setforecast_value(e.target.value): forecast_value}
                    />
                  </FormGroup>
                  </Col>
                  <Col md={6} >
                  <FormGroup>
                  <label>
                    {language["kpiseconds"]["table"]["forecast_date"]}
                  </label>
                  <ReactDatePicker 
                        type="date" 
                        id="forecast_date" 
                        dateFormat={"MM-yyyy"}
                        showMonthYearPicker
                        selected={forecast_date}
                        onChange={date => setforecast_date(date)}
                        className='form-control form-control-sm'
                       />
                </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <label>{language["kpiseconds"]["table"]["memory"]}</label>
                  <Input
                    className='form-control-sm'
                    type='text'
                    name='memory'
                    maxLength={254}
                    value={memory}
                    onChange={(e) => setmemory(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <label>{language["kpiseconds"]["table"]["source"]}</label>
                  <Input
                    className='form-control-sm'
                    type='text'
                    name='source'
                    maxLength={254}
                    value={source}
                    onChange={(e) => setsource(e.target.value)}
                  />
                </FormGroup>
                <Input
                  hidden
                  name='initiative_id'
                  value={initiative.id}
                  onChange={() => {}}
                />
                <FormGroup>
                  <Button color='success' block className='btn-sm' type='submit'>
                    {language["kpiseconds"]["form"]["create"]}
                  </Button>
                  <Button color='danger' block className='btn-sm' onClick={e=>toggleModal(false)} >
                    {language["kpiseconds"]["form"]["close"]}
                  </Button>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default connect()(CreateKpiSecond);
