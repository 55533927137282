import React, { useState , useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Row, Col } from 'reactstrap';
import { getInitiativeExports, getAllExports, getAllExportZip } from '../../redux/actions';
import { language } from "../../assets/language";


import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Loading from '../../components/Loading/Loading';

const DateRangeModal = ({ initiativeId ,label, every=false, csv=false  }) => {
  const dispatch = useDispatch();
  const exportRequest = useSelector((state) => state.exports.exports);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading , setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleApplyClick = () => {
    if (startDate && endDate && endDate >= startDate) {
      setLoading(true);
      every ? dispatch(getAllExports(startDate, endDate)) : csv ? dispatch(getAllExportZip(startDate, endDate)) : dispatch(getInitiativeExports(initiativeId, startDate, endDate));
    } else {
      alert('End date must be after start date.');
    }
  };

  useEffect(() => {
    console.log(exportRequest.exports);
    if (exportRequest.exports && exportRequest.exports.type && loading ) {
      setLoading(false);
      toggleModal();
    }
  },[exportRequest, loading]);

  return loading ? <Loading /> :  (
    <>
    <Button 
            color='success' 
            block
            className='btn-sm' 
            onClick={() => {
              toggleModal();
            }}  
          >
            {label}
    </Button>
    <Modal isOpen={isModalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>{language["initiatives"]["modal"]["date_range"]}</ModalHeader>
      <ModalBody>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="startDate">{language["initiatives"]["modal"]["start_date"]}</Label>
              <ReactDatePicker
                type="date"
                id="startDate" 
                selected={startDate}
                showMonthYearPicker
                onChange={date => setStartDate(date)}
                className='form-control'
                dateFormat={"MM-yyyy"}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="endDate">{language["initiatives"]["modal"]["end_date"]}</Label>
              <ReactDatePicker
                type="date"
                id="endDate"
                selected={endDate}
                showMonthYearPicker
                onChange={date => setEndDate(date)}
                className='form-control'
                dateFormat={"MM-yyyy"}
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleApplyClick}>{language["initiatives"]["modal"]["apply"]}</Button>
        <Button color="secondary" onClick={toggleModal}>{language["initiatives"]["modal"]["cancel"]}</Button>
      </ModalFooter>
    </Modal>
    </>
  );
};

export default DateRangeModal;
