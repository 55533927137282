import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { patchActivity } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, Modal, Form, Input } from "reactstrap";
import Loading from "../../components/Loading/Loading";
import { getUserPermission } from "../../utils/getUserPermission";

const AcceptActivity = ({ dispatch, activity, editableInInitiative, initiativeStage }) => {
  const state = useSelector((state) => state);
  const areas = useSelector((state) => state.areas.areas);
  const users = useSelector((state) => state.users.users);
  const stages = useSelector((state) => state.stages.stages);
  const phases = useSelector((state) => state.phases.phases);


  let [modalActivities, setModalActivities] = useState(false);

  let [loadedActivity, setLoadedActivity] = useState(true);

  let [description, setDescription] = useState("");
  let [area_id, setAreaId] = useState("");
  let [stageId, setStageId] = useState("");
  let [forecast_date, setForecastDate] = useState("");
  let [responsable_user, setResponsableUser] = useState("");
  let [checked, setChecked] = useState(false);
  let [estimated, setEstimated] = useState("");

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalActivities(false);
  });

  const canEdit = (type) => {
    if (initiativeStage === "evaluation" || getUserPermission(state, type, "patch", false) ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (loadedActivity && activity) {
      setEstimated(activity.estimated_date.split("T")[0]);
      setDescription(activity.description);
      setAreaId(activity.area_id);
      setStageId(activity.stage);
      setForecastDate(activity.forecast_date);
      setResponsableUser(activity.responsable_user);
      setLoadedActivity(false);
    }
  }, [activity, loadedActivity]);

  const handleSubmitActivities = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    dispatch(patchActivity(activity.id, object));
    setModalActivities(false);
    setChecked(false);
  };

  return loadedActivity ? <Loading /> : (
    <div>
      <Button
        color='primary'
        className='btn-sm'
        onClick={() => setModalActivities(true)}
      >
        {language["activities"]["form"]["edit"]}
      </Button>
      <Modal
        className='modal-dialog-centered'
        isOpen={modalActivities}
        toggle={() => setModalActivities(false)}
      >
        <div className='modal-body p-2'>
          <Card className='bg-secondary shadow border-0'>
            <CardBody className='px-lg-5 py-lg-2'>
              <div className='card-wrapper'>
                <Form onSubmit={handleSubmitActivities}>
                  {editableInInitiative ? (
                    <div>
                      <label htmlFor='description'>
                        {language["activities"]["table"]["description"]}
                      </label>
                      <Input
                        className='form-control-sm'
                        maxLength={254}
                        type='text'
                        name='description'
                        autoComplete='off'
                        required
                        multiple={true}
                        value={description}
                        defaultValue={description}
                        onChange={(e) => setDescription(e.target.value)}
                        disabled={!canEdit("activities")}
                      />
                      <br />
                      <label>{language["activities"]["table"]["area"]}</label>
                      <Input
                        className='form-control-sm'
                        type='select'
                        name='area_id'
                        required
                        value={area_id}
                        defaultValue={area_id}
                        onChange={(e) => setAreaId(e.target.value)}
                        disabled={!canEdit("activities")}

                      >
                        {areas.map((area) => (
                          <option key={area.id} value={area.id}>
                            {area.name}
                          </option>
                        ))}
                      </Input>
                      <br />
                      <label>{language["activities"]["table"]["user"]}</label>
                      <Input
                        className='form-control-sm'
                        type='select'
                        name='responsable_user'
                        required
                        value={responsable_user}
                        defaultValue={responsable_user}
                        onChange={(e) => setResponsableUser(e.target.value)}
                        disabled={!canEdit("activities")}

                      >
                        {Object.values(users).map((user) => (
                          <option key={user.id} value={user.id}>
                            {user.full_name}
                          </option>
                        ))}
                      </Input>
                      <br />
                      <label>{language["activities"]["table"]["stage"]}</label>
                      <Input
                        className='form-control-sm'
                        name='stage'
                        type='select'
                        required
                        value={stageId}
                        defaultValue={stageId}
                        onChange={(e) => setStageId(e.target.value)}
                        disabled={!canEdit("activities")}

                      >
                        {stages
                          .filter((st) =>  st.name !== "idea" &&
                          st.name !== "evaluation" &&
                          st.name !== "finalized" &&
                          st.name !== "deleted")
                          .map((stage) => (
                            <option key={stage.id} value={stage.name}>
                              {phases.hasOwnProperty(stage.name)
                                ? phases[stage.name].name
                                : stage.name}
                            </option>
                          ))}
                      </Input>
                      <br />
                      <label>
                        {language["activities"]["table"]["forecast_date"]}
                      </label>
                      <Input
                        className='form-control-sm'
                        type='date'
                        name='forecast_date'
                        required
                        value={forecast_date.split("T")[0]}
                        defaultValue={forecast_date.split("T")[0]}
                        disabled={!canEdit("activities")}

                        onChange={(e) => {
                          if (e.target.value > estimated) {
                            setEstimated(e.target.value);
                          }
                          setForecastDate(e.target.value);
                        }}
                      />
                      <br />
                      <label htmlFor='estimated_date'>
                        {language["activities"]["table"]["estimated_date"]}
                      </label>
                      <Input
                        className='form-control-sm'
                        type='date'
                        name='estimated_date'
                        value={estimated}
                        defaultValue={estimated}
                        onChange={(e) => {
                          if (e.target.value < estimated) {
                            setForecastDate(e.target.value);
                          }
                          setEstimated(e.target.value);
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <label htmlFor='estimated_date'>
                        {language["activities"]["table"]["estimated_date"]}
                      </label>
                      <Input
                        className='form-control-sm'
                        type='date'
                        name='estimated_date'
                        value={estimated}
                        defaultValue={estimated}
                        onChange={(e) => setEstimated(e.target.value)}
                      />
                    </div>
                  )}
                  <div>
                    <br />
                    <label htmlFor='completed'>
                      {language["activities"]["table"]["completed"]}
                    </label>
                    <Input
                      className='form-control-sm'
                      type='select'
                      name='completed'
                      value={checked}
                      onChange={(e) => setChecked(e.target.value)}
                    >
                      <option value={false}>
                        {language["activities"]["completed"]["no"]}
                      </option>
                      <option value={true}>
                        {language["activities"]["completed"]["yes"]}
                      </option>
                    </Input>
                  </div>
                  <br />
                  <Button className='btn-sm' color='success' type='submit'>
                    {language["activities"]["form"]["save"]}
                  </Button>
                </Form>
              </div>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default connect()(AcceptActivity);
