// list component for areas with redux and react
// action to get areas is called getAllAreas in ./../../actions/areas.js
// area has a id, name, a description and a parent_id
// parent_id is the id of the parent area

import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { getAllAreas } from "../../redux/actions";
import { language } from "../../assets/language";
import CreateArea from "./CreateArea";
import SimpleTable from "../../components/tables/SimpleTable";
import { getUserPermission } from "../../utils/getUserPermission";
import DeleteArea from "./DeleteArea";
import EditArea from "./EditArea";

const ListAreas = ({ dispatch }) => {
  const state = useSelector((state) => state);
  const areas = useSelector((state) => state.areas.areas);

  useEffect(() => {
    dispatch(getAllAreas());
  }, [dispatch]);

  const columns = useMemo(() => {
    let col = [
      {
        Header: language.areas.table.name,
        accessor: "name",
      },
      {
        Header: language.areas.table.description,
        accessor: "description",
      },
      {
        Header: language.areas.table.parent,
        accessor: "parent",
      },
    ];
    if (getUserPermission(state, "areas", "delete")) {
      col.push({
        Header: language.general.actions,
        accessor: "actions",
      });
    }
    return col;
  }, [state]);

  // get children of an area
  const getChildren = (area, areas) => {
    let children = [];
    areas.forEach((a) => {
      if (a.parent_id === area.id) {
        children.push(a);
      }
    });
    return children.length > 0 ? children.length : null;
  };

  // render areas in list with name, description, parent_id, tutorial
  const renderAreas = () => {
    let areasList = areas.map((area) => {
      return {
        id: area.id,
        name: area.name,
        description: area.description,
        parent: area.parent_id
          ? areas.find((a) => a.id === area.parent_id) ? areas.find((a) => a.id === area.parent_id).name : areas.find((a) => a.id === area.parent_id)
          : language["areas"]["no_parent"],
        actions: <div> 
            <EditArea area={area} />
            {getChildren(area, areas) ?  "" : <DeleteArea area={area} />} 
            </div>,

      };
    });

    return <SimpleTable data={areasList} columns={columns} />;
  };

  const representChild = (child) => {
    return (
      <li key={child.id}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a>{language.trees.names[child.name] || child.name}</a>
        {child.children ? (
          <ul>
            {child.children.map((grandChild) => {
              return representChild(grandChild);
            })}
          </ul>
        ) : null}
      </li>
    );
  };

  const renderAreas2 = () => {
    return (
      <div className='tree custom-scroll' style={{ overflowX: "scroll" }}>
        <ul>
          {areas &&
            areas.map((area) => {
              if (area === areas[0]) {
                return (
                  <li key={area.id}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a>{area.name}</a>
                    {area.children ? (
                      <ul>
                        {area.children.map((child) => {
                          return representChild(child);
                        })}
                      </ul>
                    ) : null}
                  </li>
                );
              }
              return null;
            })}
        </ul>
      </div>
    );
  };

  return (
    <div>
      <CreateArea />
      {renderAreas2()}
      {renderAreas()}
    </div>
  );
};

export default connect()(ListAreas);
