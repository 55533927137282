import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { language } from "../../assets/language";
import { getValueTshiftsStats } from "../../redux/actions";
import {  Col } from "reactstrap";
import BarGraph from "../../components/Graphs/BarGraph";
import Loading from "../../components/Loading/Loading";
import { fixNumber } from "../../utils/fix_number";
import moment from "moment";



const ForecastTshift = ({ dispatch, initiatives, start, end }) => {
  const _tshifts = useSelector((state) => state.tshifts.value_tshifts);
  const [loading, setLoading] = useState(true);
  

  let [tshifts, setTshifts] = useState([]);
  const unit =  localStorage.getItem("unit") !== "undefined" ? JSON.parse(localStorage.getItem("unit")) : "";



  useEffect(() => {
    if (_tshifts) {
      setTshifts(
        _tshifts.filter((tshift) => {
          if (initiatives.includes(tshift.initiative_id)) return true;
          return false;
        })
      );
      setLoading(false);
    }
  }, [_tshifts, initiatives]);

  const [stats, setStats] = useState([]);

  useEffect(() => {
    if (start && end) {
      let startDate = moment(start, "DD-MM-YYYY");
      let endDate = moment(end, "DD-MM-YYYY");
      if(startDate.isValid() && endDate.isValid()) {
        dispatch(getValueTshiftsStats(startDate.format("DD-MM-YYYY"), endDate.format("DD-MM-YYYY")));
        setLoading(true);
      }
    }  
  }, [start, end]);

useEffect(() => {
    let _stats = {};
    tshifts.forEach((th) => {
      let inf = `${th.month}-${th.year}`;
      if (_stats.hasOwnProperty(inf)) {
        _stats[inf]["base_sum"] += fixNumber(th.base);
        if(th.real) {
          _stats[inf]["real_sum"] += fixNumber(th.real);
        }else{
          _stats[inf]["forecast_sum"] += fixNumber(th.forecast);
        }
        
      } else {
        _stats[inf] = {
          base_sum: fixNumber(th.base),
          forecast_sum: th.real ? 0 : fixNumber(th.forecast),
          real_sum: fixNumber(th.real),
        };
      }
    });
// Ordenar los datos por mes y año
    let sortedData = Object.keys(_stats).map(key => {
        let [month, year] = key.split('-').map(Number);
        return { month, year, ..._stats[key] };
    }).sort((a, b) => {
        let dateA = new Date(a.year, a.month - 1);
        let dateB = new Date(b.year, b.month - 1);
        return dateA - dateB;
    });

    // Opcional: Convertir los datos ordenados de nuevo a un formato similar a _stats si se requiere
    let sortedStats = {};
    sortedData.forEach(item => {
        let key = `${item.month}-${item.year}`;
        sortedStats[key] = {
            base_sum: fixNumber(item.base_sum),
            forecast_sum: fixNumber(item.forecast_sum),
            real_sum: fixNumber(item.real_sum),
        };
    });
      setStats(sortedStats);
      setLoading(false);

    }, [tshifts]);



    return loading ? <Loading /> : (
      <Col lg={{ size: 6, offset: 6 }}>
        <h3 className='text-center'>
          {language.initiatives.stats.initiative_value_month} {unit.value}
        </h3>
        <BarGraph stats={stats} allTrees={true}  />
      </Col>
    );
  

  
};

export default connect()(ForecastTshift);
