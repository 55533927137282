import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { deleteInitypeById } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { Button } from "reactstrap";
import DeleteModal from "../../components/Modal/DeleteModal";

const DeleteInitype = ({ dispatch, initype }) => {
  let [modalInitypes, setModalInitypes] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalInitypes(false);
  });

  const deleteInitypeButton = () => {
    if (modalInitypes) {
      return <></>;
    }
    return (
      <Button
        color='danger'
        className='btn-sm'
        onClick={() => setModalInitypes(true)}
      >
        {language["initypes"]["form"]["delete"]}
      </Button>
    );
  };

  const handleSubmitInitypes = () => {
    dispatch(deleteInitypeById(initype.id));
    setModalInitypes(false);
  };

  const renderModalFormInitypes = () => {
    if (modalInitypes) {
      return (
        <div className='form' ref={wrapperRef}>
          <div>
            <Button
              color='warning'
              className='btn-sm'
              onClick={() => setModalInitypes(false)}
            >
              {language["general"]["cancel"]}
            </Button>
          </div>
          <div>
            <Button
              color='danger'
              className='btn-sm'
              onClick={() => handleSubmitInitypes()}
            >
              {language["initypes"]["form"]["deleteConfirm"]}
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <DeleteModal
      buttonText={language["initypes"]["form"]["delete"]}
      confirmText={language["initypes"]["form"]["deleteConfirm"]}
      confirm={handleSubmitInitypes}
    />
  );
};

export default connect()(DeleteInitype);
