import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { postGroup } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { Button, CardBody, Card, Modal, Input } from "reactstrap";

const CreateGroup = ({ dispatch }) => {
  let [modalGroups, setModalGroups] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalGroups(false);
  });

  const handleSubmitGroups = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    dispatch(postGroup(object));
    setModalGroups(false);
  };

  const renderModalFormGroups = () => {
    return (
      <>
        <Button
          color='success'
          className='btn-sm float-right  mb-2'
          onClick={() => setModalGroups(true)}
        >
          {language["groups"]["form"]["create"]}
        </Button>
        <Modal
          className='modal-dialog-centered'
          isOpen={modalGroups}
          toggle={() => setModalGroups(false)}
        >
          <div className='modal-body p-0'>
            <Card className='bg-secondary shadow border-0' ref={wrapperRef}>
              <CardBody className='px-lg-5 py-lg-2'>
                <h3>{language["groups"]["form"]["create"]}</h3>
                <form onSubmit={handleSubmitGroups}>
                  <label htmlFor='name'>
                    {language["groups"]["table"]["name"]}
                  </label>
                  <Input
                    maxLength={254}
                    className='form-control-sm'
                    type='text'
                    name='name'
                    required
                  />
                   <label>{language["activities"]["table"]["user"]}</label>
                      <Input
                        className='form-control-sm'
                        type='select'
                        name='initiative'
                        required
                      >
                     
                        <option key={"role"} value={false}>
                         Rol
                        </option>
                        <option key={"responsability"} value={true}>
                         Responsabilidad
                        </option>
                      
                    </Input>

                  <br />
                  <div>
                    <Button color='success' className='btn-sm' type='submit'>
                      {language["groups"]["form"]["create"]}
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </>
    );
  };

  return <div>{renderModalFormGroups()}</div>;
};

export default connect()(CreateGroup);
