import React, { useState, useRef } from 'react';
import { connect } from "react-redux";
import { useClickAway } from 'react-use';
import { Button, Modal, Card, CardBody, FormGroup, Form, Input, Label, CustomInput } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { postDocument } from '../../redux/actions/documents'; // Assuming you have an action creator for posting documents
import "./styles.css"
import language from '../../assets/language';

const CreateInitiativeDocument = ({ dispatch, initiative }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [documentName, setDocumentName] = useState('');
  const [file, setFile] = useState(null);
  const [link, setLink] = useState('');
  const [mime_type, setMimeType] = useState('application/external-link');
  const [inputMode, setInputMode] = useState('file'); // 'file' or 'link'
  const [showMimeTypeInput, setShowMimeTypeInput] = useState(false); // Controls visibility of MIME type input


  const wrapperRef = useRef(null);
  useClickAway(wrapperRef, () => {
    setModalOpen(false);
    resetForm();
  });

  const handleFileChange = (file) => {
    setFile(file[0]);
  };

  const handleRemoveFile = () => {
    setFile(null);
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', documentName);

    const isValidUrl = (url) => {
      try {
        new URL(url);
        return true;
      } catch (e) {
        return false;
      }
    };

    if (inputMode === 'file' && file) {
      formData.append('file', file);
    } else if (inputMode === 'link' && link) {
      if (!isValidUrl(link)) {
        alert('Please enter a valid URL.');
        return; // Stop the form submission
      }
      formData.append('external_link', link);
      formData.append('is_external_link', "true");
      if (showMimeTypeInput) { // Only append mime_type if the input is visible and has a value
        formData.append('mime_type', mime_type);
      } else {
        formData.append('mime_type', 'application/external-link'); // Default MIME type for external links
      }    
    }

    if ((inputMode === 'file' && file) || (inputMode === 'link' && link)) {
      dispatch(postDocument(initiative.id, formData));
      setModalOpen(false);
      resetForm();
    }
  };

  const resetForm = () => {
    setDocumentName('');
    setFile(null);
    setLink('');
    setMimeType('application/external-link');
    setInputMode('file');
    setShowMimeTypeInput(false);
  };

  return (
    <div>
      <Button
        color='success'
        className='btn-sm float-right mt-2'
        onClick={() => setModalOpen(true)}
      >
        {language.documents.form.create}
      </Button>
      <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} className="modal-dialog-centered">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <Form onSubmit={handleSubmit} innerRef={wrapperRef}>
              <FormGroup>
                <Input
                  type="text"
                  name="documentName"
                  value={documentName}
                  onChange={(e) => setDocumentName(e.target.value)}
                  placeholder={language.documents.modal.name}
                  required
                />
              </FormGroup>
              <div className="d-flex justify-content-around mb-3">
                <Button color={inputMode === 'file' ? 'primary' : 'secondary'} onClick={() => setInputMode('file')}>
                  {language.documents.file_upload}
                </Button>
                <Button color={inputMode === 'link' ? 'primary' : 'secondary'} onClick={() => setInputMode('link')}>
                  {language.documents.external_link}
                </Button>
              </div>
              {inputMode === 'file' ? (
                <FormGroup>
                  <Dropzone onDrop={acceptedFiles => handleFileChange(acceptedFiles)}>
                    {({getRootProps, getInputProps}) => (
                      <section>
                        <div {...getRootProps({ className: 'dropzoneContainer '})}>
                          <input {...getInputProps()} />
                          <p>{language.documents.modal.dropzone}</p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </FormGroup>
              ) : (
                <>
                  <FormGroup>
                    <Input
                      type="text"
                      name="link"
                      value={link}
                      onChange={(e) => setLink(e.target.value)}
                      placeholder={language.documents.modal.link}
                      required
                    />
                  </FormGroup>
                  {/* <FormGroup>
                    <CustomInput
                      type="checkbox"
                      id="showMimeTypeInput"
                      label={language.documents.modal.advanced_options}
                      checked={showMimeTypeInput}
                      onChange={() => setShowMimeTypeInput(!showMimeTypeInput)}
                    />
                  </FormGroup> */}
                  {showMimeTypeInput && (
                    <FormGroup>
                      <Label for="mime_type">{language.documents.modal.mime_type}</Label>
                      <Input
                        type="text"
                        name="mime_type"
                        value={mime_type}
                        onChange={(e) => setMimeType(e.target.value)}
                        placeholder={language.documents.modal.mime_type}
                      />
                    </FormGroup>
                  )}
                </>
              )}
              {file && inputMode === 'file' && (
                <div className="file-details" style={{ marginBottom: "20px" }}>
                  <span>{file.name}</span>
                  <button type="button" className="remove-file-btn" onClick={handleRemoveFile}>X</button>
                </div>
              )}
              <Button type="submit" color="primary" className="mr-2" disabled={!documentName || (inputMode === 'file' && !file) || (inputMode === 'link' && !link)}>
                {language.documents.modal.upload}
              </Button>
              <Button color="secondary" onClick={() => setModalOpen(false)}>
                {language.documents.modal.cancel}
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Modal>
    </div>
  );
};

export default connect()(CreateInitiativeDocument);
