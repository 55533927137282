const initialState_Users = {
    users: {},
    error: null,
    request_time: null,
};

const users = (state = initialState_Users, action) => {
    switch (action.type) {
        case 'SET_USERS':
            return {
                ...state,
                users: action.payload.users.reduce((obj, item) => {
                    obj[item.id] = item;
                    return obj;
                }, {}),
                request_time: action.request_time,
            };
        case "NEW_USER":
            // let old_users = state.users;
            // old_users[action.payload.id] = action.payload;
            return {
                ...state,
                users: {
                    ...state.users, [action.payload.id]: action.payload,
                },
                request_time: action.request_time,
            };
        case "UPDATE_USER":
            return {
                ...state,
                users: {
                    ...state.users,
                    [action.payload.id]: action.payload,
                },
                request_time: action.request_time,
            };
        case "DELETE_USER":
            let new_users = state.users;
            delete new_users[action.payload.id];
            return {
                ...state,
                users: new_users,
                request_time: action.request_time,
            };
        case "USER_ERROR":
            return {
                ...state,
                error: action.payload.error,
                request_time: null,
                // users: {},
            };
        default:
            return state;
    }
}

export default users;
