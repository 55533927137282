import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { postApproval, reloadBlockInitiative } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import {
  Button,
  Card,
  Modal,
  Form,
  FormGroup,
  CardBody,
  Input,
} from "reactstrap";
const CreateApproval = ({ dispatch, initiative }) => {
  const users = useSelector((state) => state.users.users);
  const areas = useSelector((state) => state.areas.areas);
  // const stages = useSelector(state => state.stages.stages);
  const phases = useSelector((state) => state.phases.phases);
  let [modalApprovals, toggleModal] = useState(false);

  let [areaId, setAreaId] = useState("");
  let [stage, setStage] = useState("");
  let [userId, setUserId] = useState("");
  let [forecast, setForecast] = useState(
    new Date().toISOString().split("T")[0]
  );

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    toggleModal(false);
  });

  const handleSubmitApprovals = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    dispatch(postApproval(object));
    toggleModal(false);
    setAreaId("");
    setStage("");
    setUserId("");
    setForecast("");
    if (initiative.is_blocked) {
      dispatch(reloadBlockInitiative(initiative.id));
    }
  };

  return (
    <div>
      <Button
        color='success'
        className='btn-sm float-right '
        onClick={() => toggleModal(true)}
      >
        {language["approvals"]["form"]["create"]}
      </Button>
      <Modal
        className='modal-dialog-centered'
        isOpen={modalApprovals}
        toggle={() => toggleModal(false)}
      >
        <div className='modal-body p-0'>
          <Card className='bg-secondary shadow border-0'>
            <CardBody className='px-lg-5 py-lg-2'>
              <h2 className='text-muted text-center mt-2 mb-3'>
                {language["approvals"]["form"]["create"]}
              </h2>
              <Form onSubmit={handleSubmitApprovals} role='form'>
                <FormGroup>
                  <label>
                    {language["approvals"]["table"]["responsable_user"]}
                  </label>
                  <Input
                    className='form-control-sm'
                    type={"select"}
                    name='responsable_user'
                    onChange={(e) => setUserId(e.target.value)}
                    defaultValue={userId}
                  >
                    {Object.values(users).map((user) => {
                      return (
                        <option key={user.id} value={user.id}>
                          {user.full_name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label>{language["approvals"]["table"]["area"]}</label>
                  <Input
                    className='form-control-sm'
                    type={"select"}
                    name='area_id'
                    required
                    onChange={(e) => setAreaId(e.target.value)}
                    value={areaId}
                  >
                    {areas.map((area) => (
                      <option key={area.id} value={area.id}>
                        {area.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label>{language["approvals"]["table"]["next_stage"]}</label>
                  <Input
                    className='form-control-sm'
                    type={"select"}
                    name='next_stage'
                    required
                    onChange={(e) => setStage(e.target.value)}
                    value={stage}
                  >
                    {/* remove stage where identifier is "deleted" or "finalized" */}
                    {Object.values(phases)
                      .sort(function (a, b) {
                        return a.position - b.position;
                      })
                      .filter((ph) => ph.required_checklist === true)
                      .map((_stage) => {
                        if (
                          Object.values(phases).filter(
                            (ph) => ph.identifier === initiative.stage
                          )[0]?.position >= _stage?.position
                        )
                          return;
                        else
                          return (
                            <option key={_stage.id} value={_stage.identifier}>
                              {_stage.name}
                            </option>
                          );
                      })}
                  </Input>
                </FormGroup>

                <Input
                  className='form-control-sm'
                  hidden
                  name='initiative_id'
                  defaultValue={initiative.id}
                />
                <FormGroup>
                  <Button color='success' className='btn-sm float-right'>
                    {language["approvals"]["form"]["create"]}
                  </Button>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default connect()(CreateApproval);
