import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";


export const setGroups = (groups) => ({
    type: "SET_GROUPS",
    payload: {
        groups: groups,
    },
    request_time: Date.now()
});

export const setRoleGroups = (data, role_id) => ({
    type: "SET_ROLE_GROUPS",
    payload: {
        role_groups: data,
        role_id: role_id,
        request_time: Date.now()
    },
});

export const setUserRoleGroups = (data, role_id) => ({
    type: "SET_USER_ROLE_GROUPS",
    payload: {
        user_role_groups: data,
        request_time: Date.now()
    },
});


export const setRoleGroup = (data) => ({
    type: "SET_ROLE_GROUP",
    payload: {
       data: data
    },
    request_time: Date.now()

});

export const deleteRoleGroupById = (role_group_id) => ({
    type: "DELETE_ROLE_GROUP",
    payload: {
        id: role_group_id,
    },
    request_time: Date.now()
});

export const setGroupsLoading = (value) => ({
    type: "SET_GROUPS_LOADING",
    payload: {
        loading: value,
    },
});

export const setGroupsError = (error) => ({
    type: "SET_GROUPS_ERROR",
    payload: {
        error: error,
    },
});

export const setRoleGroupsLoading = (value) => ({
    type: "SET_ROLE_GROUPS_LOADING",
    payload: {
        loading: value,
    },
});

export const setRoleGroupsEdition = (data, role_id) => ({
    type: "SET_ROLE_GROUPS_EDITION",
    payload: {
        role_groups: data,
        role_id: role_id,
        request_time: Date.now()
    },
});

export const setGroupsEditionError = (error) => ({
    type: "SET_GROUPS_EDITION_ERROR",
    payload: {
        error: error,
    },
});

export const getAllGroups = () => {
    return (dispatch, getState) => {
        const state = getState();
        try {
                fetch(`${BACKEND_URL}/groups`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setGroups(data));
                        dispatch(setGroupsLoading(false));
                    })
                    .catch((error) => {
                        errorHandler("groups", error.message);
                        dispatch(setGroupsError(error));
                        dispatch(setGroupsLoading(false));
                    });
          
        } catch (error) {
            errorHandler("groups", error.message);
            dispatch(setGroupsError(error));
            dispatch(setGroupsLoading(false));
        }
    };
}

export const addRoleGroup = (data, role_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            fetch(`${BACKEND_URL}/roles/${role_id}/groups`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
                body: JSON.stringify(data)
            })
                .then((response) => {
                    if (response.status === 201) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    if (data != null && data !== undefined && data.id !== undefined) {
                        dispatch(setRoleGroup(data));
                    }
                })
                .catch((error) => {
                    errorHandler("groups", error.message);
                    dispatch(setGroupsError(error));
                });
        } catch (error) {
            errorHandler("groups", error.message);
            dispatch(setGroupsError(error));
        }
    };
}

export const getRoleGroups = (role_id) => {

    return (dispatch, getState) => {
        const state = getState();
        try {

                fetch(`${BACKEND_URL}/roles/${role_id}/groups`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setRoleGroups(data, role_id));
                        dispatch(setRoleGroupsLoading(false));
                    })
                    .catch((error) => {
                        errorHandler("groups", error.message);
                        dispatch(setGroupsError(error));
                        dispatch(setRoleGroupsLoading(false));
                    });

        } catch (error) {
            errorHandler("groups", error.message);
            dispatch(setGroupsError(error));
            dispatch(setRoleGroupsLoading(false));
        }
    };
}

export const getUserRoleGroups = (role_id) => {

    return (dispatch, getState) => {
        const state = getState();
        try {

                fetch(`${BACKEND_URL}/roles/${role_id}/groups`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                          
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setUserRoleGroups(data, role_id));
                        dispatch(setRoleGroupsLoading(false));
                    })
                    .catch((error) => {
                        errorHandler("groups", error.message);
                        dispatch(setGroupsError(error));
                        dispatch(setRoleGroupsLoading(false));
                    });

        } catch (error) {
            errorHandler("groups", error.message);
            dispatch(setGroupsError(error));
            dispatch(setRoleGroupsLoading(false));
        }
    };
}

export const getRoleGroupsEdition = (role_id) => {

    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "role_groups", "list", true)) {
                throw new Error("No permissions");
            }
           
                fetch(`${BACKEND_URL}/roles/${role_id}/groups`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setRoleGroupsEdition(data, role_id));
                        dispatch(setRoleGroupsLoading(false));
                    })
                    .catch((error) => {
                        errorHandler("groups", error.message);
                        dispatch(setGroupsEditionError(error));
                        dispatch(setRoleGroupsLoading(false));
                    });
           
        } catch (error) {
            errorHandler("groups", error.message);
            dispatch(setGroupsEditionError(error));
            dispatch(setRoleGroupsLoading(false));
        }
    };
}

export const deleteRoleGroup = (role_id, role_group_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "role_groups", "patch", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/roles/${role_id}/groups/${role_group_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
                .then((response) => {
                    if (response.status === 204) {
                       
                        return true;
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((vl) => {
                    // if (vl) {
                    dispatch(deleteRoleGroupById(role_group_id));
                    // }
                })
                .catch((error) => {
                    errorHandler("groups", error.message);
                    dispatch(setGroupsError(error));
                });
        } catch (error) {
            errorHandler("groups", error.message);
            dispatch(setGroupsError(error));
        }
    };
}