import { toast } from "react-toastify";
import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";
import language from "../../assets/language";


export const setKshifts = (kshifts, kpisecondId) => ({
    type: "SET_KSHIFTS",
    payload: {
        kshifts: kshifts,
        kpisecondId: kpisecondId,
    },
    request_time: Date.now()
});

export const setKshift = (kshift) => ({
    type: "SET_KSHIFT",
    payload: {
        id: kshift.id,
        kpisecond_id: kshift.kpisecond_id,
        base: kshift.base,
        forecast: kshift.forecast,
        real: kshift.real,
        month: kshift.month,
        year: kshift.year,
        request_time: Date.now()
    },
});

export const addKshift = (kshift) => ({
    type: "ADD_KSHIFT",
    payload: {
        id: kshift.id,
        kpisecond_id: kshift.kpisecond_id,
        base: kshift.base,
        forecast: kshift.forecast,
        real: kshift.real,
        month: kshift.month,
        year: kshift.year,
        request_time: Date.now()
    },
});

export const setKshiftError = (error) => ({
    type: "KSHIFT_ERROR",
    payload: {
        error: error
    }
});

export const deleteKshift = (kshift_id) => ({
    type: "DELETE_KSHIFT",
    payload: {
        id: kshift_id,
    },
    request_time: Date.now()
});

export const getKpisecondKshifts = (kpisecondId,start,end) => {

    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "kshifts", "list")) {
                throw new Error("No permissions");
            }
            if (state.kshifts.request_time + 1000 * 2 < Date.now()
                || state.kshifts.kpisecondId === null
                || state.kshifts.request_time === null
                || state.kshifts.kpisecondId !== kpisecondId) {
                console.log("Fetching kshifts");
                fetch(`${BACKEND_URL}/kpiseconds/${kpisecondId}/kshifts?startDate=${start}&endDate=${end}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                        
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setKshifts(data, kpisecondId));
                    })
                    .catch((error) => {
                        errorHandler("kshifts", error.message);
                        dispatch(setKshiftError(error));
                    });
            }
        } catch (error) {
            errorHandler("kshifts", error.message);
            dispatch(setKshiftError(error));
        }
    };
}

export const patchKshift = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (state.initiatives.initiative.leader_user === state.sessions.user.id
                || state.initiatives.initiative.sponsor_user === state.sessions.user.id
                || state.sessions.user.role === 'admin'
                || getUserPermission(state, "kshifts", "change", true)) {
                fetch(`${BACKEND_URL}/kshifts/${data.id}`, {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                    body: JSON.stringify(data)
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        if (data != null && data !== undefined && data.id !== undefined) {
                            dispatch(setKshift(data));
                           
                        }
                    })
                    .catch((error) => {
                        errorHandler("kshifts", error.message);
                        dispatch(setKshiftError(error));
                    });
            }
        } catch (error) {
            errorHandler("kshifts", error.message);
            dispatch(setKshiftError(error));
        }
    };
}

export const postKshift = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (state.initiatives.initiative.leader_user === state.sessions.user.id
                || state.initiatives.initiative.sponsor_user === state.sessions.user.id
                || state.sessions.user.role === 'admin'
                || getUserPermission(state, "kshifts", "change", true)) {
                fetch(`${BACKEND_URL}/kshifts/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                    body: JSON.stringify(data)
                })
                    .then((response) => {
                        if (response.status === 201) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        if (data != null && data !== undefined && data.id !== undefined) {
                            dispatch(addKshift(data));
                           
                        }
                    })
                    .catch((error) => {
                        errorHandler("kshifts", error.message);
                        dispatch(setKshiftError(error));
                    });
            }
        } catch (error) {
            errorHandler("kshifts", error.message);
            dispatch(setKshiftError(error));
        }
    };
}
