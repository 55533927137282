import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { deleteRoleById } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { Button } from "reactstrap";

const DeleteRole = ({ dispatch, role }) => {
  let [modalRoles, setModalRoles] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalRoles(false);
  });

  const deleteRoleButton = () => {
    if (modalRoles) {
      return <></>;
    }
    return (
      <Button
        color='danger'
        className='btn-sm'
        onClick={() => setModalRoles(true)}
      >
        {language["roles"]["form"]["delete"]}
      </Button>
    );
  };

  const handleSubmitRoles = () => {
    dispatch(deleteRoleById(role.id));
    setModalRoles(false);
  };

  const renderModalFormRoles = () => {
    if (modalRoles) {
      return (
        <div className='form' ref={wrapperRef}>
          <div>
            <Button
              color='warning'
              className='btn-sm'
              onClick={() => setModalRoles(false)}
            >
              {language["general"]["cancel"]}
            </Button>
          </div>
          <div>
            <Button
              color='danger'
              className='btn-sm'
              onClick={() => handleSubmitRoles()}
            >
              {language["roles"]["form"]["deleteConfirm"]}
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      {deleteRoleButton()}
      {renderModalFormRoles()}
    </div>
  );
};

export default connect()(DeleteRole);
