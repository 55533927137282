import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { deleteRiskById, getInitiativeRisks } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { Button } from "reactstrap";
import DeleteModal from "../../components/Modal/DeleteModal";

const DeleteRisk = ({ dispatch, risk, setLoading }) => {
  let [modalRisks, setModalRisks] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalRisks(false);
  });


  const handleSubmitRisks = () => {
    setLoading(true);
    dispatch(deleteRiskById(risk.id));
    dispatch(getInitiativeRisks(risk.initiative_id));
    setModalRisks(false);
  };


  return (
    <DeleteModal
      confirm={handleSubmitRisks}
      confirmText={language["risks"]["form"]["deleteConfirm"]}
      buttonText={language["risks"]["form"]["delete"]}
    />
  );
};

export default connect()(DeleteRisk);
