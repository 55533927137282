import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { updateTag } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Modal,
} from "reactstrap";

const EditTag = ({ tag, dispatch }) => {
  let [modal, toggleModal] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    toggleModal(false);
  });

  const handleSubmitInitypes = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    dispatch(updateTag(object));
    toggleModal(false);
  };

  return (
    <div>
      <Button
        color='warning'
        className='btn-sm float-right mb-1'
        block
        onClick={() => toggleModal(true)}
      >
        {language["tags"]["form"]["update"]}
      </Button>
      <Modal
        className='modal-dialog-centered'
        isOpen={modal}
        toggle={() => toggleModal(false)}
      >
        <div className='modal-body p-0'>
          <Card className='bg-secondary shadow border-0'>
            <CardBody className='px-lg-5 py-lg-2'>
              <h2 className='text-muted text-center mt-2 mb-3'>
                {language["tags"]["form"]["create"]}
              </h2>
              <Form onSubmit={handleSubmitInitypes} role='form'>
                <FormGroup className='mb-3'>
                  <Input
                    maxLength={254}
                    className='form-control-sm'
                    type='text'
                    placeholder={language["levers"]["table"]["name"]}
                    name='id'
                    defaultValue={tag.id}
                    hidden
                  />
                  <Input
                    className='form-control-sm'
                    maxLength={254}
                    type='text'
                    placeholder={language["levers"]["table"]["name"]}
                    name='name'
                    defaultValue={tag.name}
                    required
                  />
                </FormGroup>
                <div className='text-center mt-4'>
                  <Button
                    onClick={() => toggleModal(false)}
                    color='danger'
                    className='btn-sm'
                  >
                    Cancelar
                  </Button>
                  <Button color='success' className='btn-sm' type='submit'>
                    {language["tags"]["form"]["update"]}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default connect()(EditTag);
