import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { getAllPhases } from '../../redux/actions';
import { language } from '../../assets/language';
import SimpleTable from '../../components/tables/SimpleTable';
import { CreatePhase } from '.';
import { getUserPermission } from '../../utils/getUserPermission';
import { Button , Card, FormGroup, Form ,  Input} from "reactstrap";

const ListPhases = ({ dispatch }) => {

    const state = useSelector(state => state);
    const phases = useSelector(state => state.phases.phases);

    useEffect(() => {
        dispatch(getAllPhases());
    }, [dispatch]);

    const columns = useMemo(
        () => {
            let col = [
                {
                    Header: language.phases.table.identifier,
                    accessor: 'identifier',
                },
                {
                    Header: language.phases.table.name,
                    accessor: 'name',
                },
                {
                    Header: language.phases.table.description,
                    accessor: 'description',
                },
                {
                    Header: language.phases.table.position,
                    accessor: 'position',
                },
                {
                    Header: language.phases.table.required_checklist,
                    accessor: 'required_checklist',
                },
            ];
            if (getUserPermission(state, 'phases', 'delete')) {
                col.push({
                    Header: language.general.actions,
                    accessor: 'actions',
                });
            }
            return col;
        },
        [state]
    )

    const renderPhases = () => {
        if (!phases || phases.length === 0) {
            return <p>{language["phases"]["not_found"]}</p>
        }

        let phasesList = Object.values(phases).map((phase) => {
            return {
                id: phase.id,
                identifier: phase.identifier,
                description: phase.description,
                name: phase.name,
                position: phase.position,
                required_checklist: (phase.required_checklist) ? language.phases.required_checklist.yes : language.phases.required_checklist.no,
                actions: <CreatePhase phase={phase} />
            }
        });

        return <SimpleTable columns={columns} data={phasesList} />

    }

    return (
        <Card className="p-3">
            <h1>{language.phases.title}</h1>
            <CreatePhase />
            <br />
            {renderPhases()}
        </Card>
    );
}

export default connect()(ListPhases);