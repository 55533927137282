import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";


export const newCounterStat = (data) => ({
    type: "NEW_COUNTER_STAT",
    payload: {
        id: data.id,
        initiative_id: data.initiative_id,
        stage: data.stage,
        value: data.value,
        created_at: data.created_at,
    },
    request_time: Date.now()
});

export const setCounterStatError = (error) => ({
    type: "COUNTER_STAT_ERROR",
    payload: {
        error: error,
    }
});

export const setFilterValues = (filters) => ({
    type: "RETRIEVED_FILTER_VALUES",
    payload: {
        filters,
    },
    request_time: Date.now()
});
export const setFilterError = (filters) => ({
    type: "RETRIEVED_FILTER_VALUES",
    payload: {
        filters,
    },
    request_time: Date.now()
});

export const setLambdaError = (error) => ({
    type: "LAMBDA_ERROR",
    payload: {
        error: error,
    }
   
});

export const deleteCounterStat = (counter_stat_id) => ({
    type: "DELETE_COUNTER_STAT",
    payload: {
        id: counter_stat_id,
    },
    request_time: Date.now()
});

export const updateCounterStat = (counter_stat) => ({
    type: "UPDATE_COUNTER_STAT",
    payload: {
        id: counter_stat.id,
        initiative_id: counter_stat.initiative_id,
        stage: counter_stat.stage,
        value: counter_stat.value,
        created_at: counter_stat.created_at,
    },
    request_time: Date.now()
});

export const setCounterStats = (counter_stats) => ({
    type: "SET_COUNTER_STATS",
    payload: {
        counter_stats: counter_stats,
    },
    request_time: Date.now()
});

export const setLambda = (lambda) => ({
    type: "SET_LAMBDA",
    payload: {
        lambda: lambda,
    },
    request_time: Date.now()
});

export const getAllCounterStats = (startDate) => {

    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "counter_stats", "list", true)) {
                throw new Error("No permissions");
            }
                fetch(`${BACKEND_URL}/counter_stats?start_date=${startDate}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setCounterStats(data));
                    })
                    .catch((error) => {
                        console.log("ERORRR", error);
                        errorHandler("counter_stats", error.message);
                        dispatch(setCounterStatError(error.message));
                    });
            
        } catch (error) {
            errorHandler("counter_stats", error.message);
            dispatch(setCounterStatError(error.message));
        }
    };
}

export const postCounterStat = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "counter_stats", "create", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/counter_stats`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
                body: JSON.stringify(data)
            })
                .then((response) => {
                    if (response.status === 201) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    if (data != null && data !== undefined && data.id !== undefined) {
                        dispatch(newCounterStat(data));
                    }
                })
                .catch((error) => {
                    errorHandler("counter_stats", error.message);
                    dispatch(setCounterStatError(error.message));
                });
        } catch (error) {
            errorHandler("counter_stats", error.message);
            dispatch(setCounterStatError(error.message));
        }
    };
}

export const deleteCounterStatById = (counter_stat_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "counter_stats", "delete", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/counter_stats/${counter_stat_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
                .then((response) => {
                    if (response.status === 204) {
                        return true;
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((vl) => {
                    if (vl) {
                        dispatch(deleteCounterStat(counter_stat_id));
                    }
                })
                .catch((error) => {
                    errorHandler("counter_stats", error.message);
                    dispatch(setCounterStatError(error));
                });
        } catch (error) {
            errorHandler("counter_stats", error.message);
            dispatch(setCounterStatError(error));
        }
    };
}

export const lambda = (startDate, endDate) => {

    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "counter_stats", "list", true)) {
                throw new Error("No permissions");
            }
                fetch(`${BACKEND_URL}/lambda?start_date=${startDate}&end_date=${endDate}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 201) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setLambda(data));
                    })
                    .catch((error) => {
                        errorHandler("lambda", error.message);
                        dispatch(setLambdaError(error.message));
                    });
            
        } catch (error) {
            errorHandler("lambda", error.message);
            dispatch(setLambdaError(error.message));
        }
    };
}

export const retrieveFilterValues = () => {
    return (dispatch, getState) => {
      dispatch({ type: 'RETRIEVING_FILTER_VALUES' });
      const state = getState();

      return fetch(`${BACKEND_URL}/dashboard`, {
        headers : { 
            "Content-Type": "application/json",
            "Authorization": `Bearer ${state.sessions.token}`
         }
      })
        .then(response => { return response.json()})
        .then(json => {
            dispatch(setFilterValues(json.data))
        }
            )
        .catch(error =>  { console.log("ERROR",error);
            dispatch(setFilterError(error))
        });
    };
  };

export const updateFilterValues = (filterValues) => {
    let data = filterValues;
   
    return (dispatch, getState) => {
    const state = getState();
      dispatch({ type: 'UPDATING_FILTER_VALUES' });
      return fetch(`${BACKEND_URL}/dashboard`, {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            "Authorization": `Bearer ${state.sessions.token}`
        },
        body: JSON.stringify({ data })
      })
      .then((response) => {
        if (response.status === 200) {
            return response.json();
        } else {
            throw new Error(response.status);
        }
    })
        .then(data =>{ dispatch({ type: 'UPDATED_FILTER_VALUES', payload: {filters: data.data } })})
    };
  };