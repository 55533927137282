import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";


export const setRoles = (roles) => ({
    type: "SET_ROLES",
    payload: {
        roles: roles,
    },
    request_time: Date.now()
});

export const setRole = (data) => ({
    type: "SET_ROLE",
    payload: {
        id: data.id,
        name: data.name,
        request_time: Date.now()
    },
});

export const setRoleError = (error) => ({
    type: "ROLE_ERROR",
    payload: {
        error: error
    }
});

export const deleteRole = (role_id) => ({
    type: "DELETE_ROLE",
    payload: {
        id: role_id,
    },
    request_time: Date.now()
});

export const getAllRoles = () => {

    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "roles", "list", true)) {
                throw new Error("No permissions");
            }
            if (state.roles.request_time === null
                || state.roles.request_time + 1000 * 2 < Date.now()) {
                fetch(`${BACKEND_URL}/roles`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setRoles(data));
                    })
                    .catch((error) => {
                        errorHandler("roles", error.message);
                        dispatch(setRoleError(error));
                    });
            }
        } catch (error) {
            errorHandler("roles", error.message);
            dispatch(setRoleError(error));
        }
    };
}

export const postRole = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "roles", "create", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/roles`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
                body: JSON.stringify(data)
            })
                .then((response) => {
                    if (response.status === 201) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    if (data != null && data !== undefined && data.id !== undefined) {
                        dispatch(setRole(data));
                    }
                })
                .catch((error) => {
                    errorHandler("roles", error.message);
                    dispatch(setRoleError(error));
                });
        } catch (error) {
            errorHandler("roles", error.message);
            dispatch(setRoleError(error));
        }
    };
}

export const deleteRoleById = (role_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "roles", "delete", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/roles/${role_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
                .then((response) => {
                    if (response.status === 204) {
                        return true;
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((vl) => {
                    if (vl) {
                        dispatch(deleteRole(role_id));
                    }
                })
                .catch((error) => {
                    errorHandler("roles", error.message);
                    dispatch(setRoleError(error));
                });
        } catch (error) {
            errorHandler("roles", error.message);
            dispatch(setRoleError(error));
        }
    };
}