import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { postStage } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { Button } from "reactstrap";

const CreateStage = ({ dispatch, initiative }) => {
  let [modalStages, setModalStages] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalStages(false);
  });

  const addStage = () => {
    return (
      <Button
        color='success'
        className='btn-sm'
        onClick={() => setModalStages(true)}
      >
        {language["stages"]["form"]["create"]}
      </Button>
    );
  };

  const handleSubmitStages = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    dispatch(postStage(object));
    setModalStages(false);
  };

  const renderModalFormStages = () => {
    if (modalStages) {
      return (
        <div className='form' ref={wrapperRef}>
          <h3>{language["stages"]["form"]["create"]}</h3>
          <form onSubmit={handleSubmitStages}>
            <div>
              <label>{language["stages"]["table"]["name"]}</label>
              <input maxLength={254} type='text' name='name' required />
            </div>
            <div>
              <label>{language["stages"]["table"]["forecast_date"]}</label>
              <input type='date' name='forecast_date' required />
            </div>
            <input hidden name='initiative_id' value={initiative.id} />
            <div>
              <Button color='success' className='btn-sm' type='submit'>
                {language["stages"]["form"]["create"]}
              </Button>
            </div>
          </form>
        </div>
      );
    }
  };

  return (
    <div>
      {addStage()}
      {renderModalFormStages()}
    </div>
  );
};

export default connect()(CreateStage);
