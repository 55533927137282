import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";


export const setPermits = (permits) => ({
    type: "SET_PERMITS",
    payload: {
        permits: permits,
    },
    request_time: Date.now()
});

export const setGroupPermits = (data, group_id) => ({
    type: "SET_GROUP_PERMITS",
    payload: {
        group_permits: data,
        group_id: group_id,
        request_time: Date.now()
    },
});

export const setGroupPermit = (data) => ({
    type: "SET_GROUP_PERMIT",
    payload: {
        id: data.id,
        group_id: data.group_id,
        permit_id: data.permit_id,
        request_time: Date.now()
    }
});

export const deleteGroupPermitById = (group_permit_id) => ({
    type: "DELETE_GROUP_PERMIT",
    payload: {
        id: group_permit_id,
    },
    request_time: Date.now()
});

export const setPermitsLoading = (value) => ({
    type: "SET_PERMITS_LOADING",
    payload: {
        loading: value,
    },
});

export const setPermitsError = (error) => ({
    type: "SET_PERMITS_ERROR",
    payload: {
        error: error,
    },
});

export const setGroupPermitsLoading = (value) => ({
    type: "SET_GROUP_PERMITS_LOADING",
    payload: {
        loading: value,
    },
});

export const setGroupPermitsEdition = (data, group_id) => ({
    type: "SET_GROUP_PERMITS_EDITION",
    payload: {
        group_permits: data,
        group_id: group_id,
        request_time: Date.now()
    },
});

export const setPermitsEditionError = (error) => ({
    type: "SET_PERMITS_EDITION_ERROR",
    payload: {
        error: error,
    },
});

export const getAllGroupsPermits = () => {
    return (dispatch, getState) => {
        const state = getState();
        try {
                fetch(`${BACKEND_URL}/groups/1/permits`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setPermits(data));
                        dispatch(setPermitsLoading(false));
                    })
                    .catch((error) => {
                        errorHandler("permits", error.message);
                        dispatch(setPermitsError(error));
                        dispatch(setPermitsLoading(false));
                    });
          
        } catch (error) {
            errorHandler("permits", error.message);
            dispatch(setPermitsError(error));
            dispatch(setPermitsLoading(false));
        }
    };
}

export const addGroupPermit = (data, group_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            fetch(`${BACKEND_URL}/groups/${group_id}/permits`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
                body: JSON.stringify(data)
            })
                .then((response) => {
                    if (response.status === 201) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    if (data != null && data !== undefined && data.id !== undefined) {
                        dispatch(setGroupPermit(data));
                    }
                })
                .catch((error) => {
                    errorHandler("permits", error.message);
                    dispatch(setPermitsError(error));
                });
        } catch (error) {
            errorHandler("permits", error.message);
            dispatch(setPermitsError(error));
        }
    };
}

export const getGroupPermits = (group_id) => {

    return (dispatch, getState) => {
        const state = getState();
        try {

                fetch(`${BACKEND_URL}/groups/${group_id}/permits`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setGroupPermits(data, group_id));
                        dispatch(setGroupPermitsLoading(false));
                    })
                    .catch((error) => {
                        errorHandler("permits", error.message);
                        dispatch(setPermitsError(error));
                        dispatch(setGroupPermitsLoading(false));
                    });

        } catch (error) {
            errorHandler("permits", error.message);
            dispatch(setPermitsError(error));
            dispatch(setGroupPermitsLoading(false));
        }
    };
}

export const getGroupPermitsEdition = (group_id) => {

    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "group_permits", "list", true)) {
                throw new Error("No permissions");
            }
           
                fetch(`${BACKEND_URL}/groups/${group_id}/permits`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setGroupPermitsEdition(data, group_id));
                        dispatch(setGroupPermitsLoading(false));
                    })
                    .catch((error) => {
                        errorHandler("permits", error.message);
                        dispatch(setPermitsEditionError(error));
                        dispatch(setGroupPermitsLoading(false));
                    });
           
        } catch (error) {
            errorHandler("permits", error.message);
            dispatch(setPermitsEditionError(error));
            dispatch(setGroupPermitsLoading(false));
        }
    };
}

export const deleteGroupPermit = (group_id, group_permit_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "group_permits", "patch", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/groups/${group_id}/permits/${group_permit_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
                .then((response) => {
                    if (response.status === 204) {
                       
                        return true;
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((vl) => {
                    // if (vl) {
                    dispatch(deleteGroupPermitById(group_permit_id));
                    // }
                })
                .catch((error) => {
                    errorHandler("permits", error.message);
                    dispatch(setPermitsError(error));
                });
        } catch (error) {
            errorHandler("permits", error.message);
            dispatch(setPermitsError(error));
        }
    };
}