import React, { useState, useRef, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { patchUser } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { Button , Card, FormGroup, Form ,  Input, Modal, CardBody} from "reactstrap";

const EditUser = ({ dispatch, user }) => {
  const areas = useSelector((state) => state.areas.areas);
  const roles = useSelector((state) => state.roles.roles);

  let [modalUsers, setModalUsers] = useState(false);

  let [loadedUser, setLoadedUser] = useState(true);

  let [firstname, setFirstname] = useState(user.firstname);
  let [lastname, setLastname] = useState(user.lastname);
  let [email, setEmail] = useState(user.email);
  let [password, setPassword] = useState("");
  let [cpassword, setCPassword] = useState("");
  let [area, setArea] = useState("");
  let [role, setRole] = useState("");
  let [roleId, setRoleId] = useState(user.role_id);
  let [roleInitial, setRoleInitial] = useState(true);

  useEffect(() => {
    if (
      modalUsers &&
      roleInitial &&
      roles &&
      Object.keys(roles).length > 0 &&
      roleId !== ""
    ) {
      try {
        setRoleId(roleId);
        setRole(Object.values(roles).find((rol) => rol.id === roleId).name);
      } catch (e) {}
      setRoleInitial(false);
    }
  }, [roles, roleId, roleInitial, modalUsers]);

  useEffect(() => {
    if (role !== "" && role !== undefined) {
      setRoleId(Object.values(roles).find((rol) => rol.name === role).id);
    }
  }, [role, roles]);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalUsers(false);
  });

  useEffect(() => {
    if (loadedUser) {
      setLoadedUser(false);
    }
  }, [user, loadedUser]);

  

  const handleSubmitUsers = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    if (object["password"].length === 0) {
      delete object["password"];
    }
    dispatch(patchUser(user.id, object));
    setModalUsers(false);
  };

  const renderModalFormUsers = () => {
      return (
        <>
        <Button
        color='primary'
        className='btn-sm'
        onClick={() => setModalUsers(true)}
      >
        {language["users"]["form"]["edit"]}
      </Button>
        <Modal
        className='modal-dialog-centered'
        isOpen={modalUsers}
        toggle={() => setModalUsers(false)}
      >

        <div className='modal-body p-0'>
        
        <Card  className='bg-secondary shadow border-0' ref={wrapperRef}>
        <CardBody className='px-lg-5 py-lg-2'> 
          <h2>{language["users"]["form"]["create"]}</h2>
          <Form onSubmit={handleSubmitUsers} autoComplete='false'>
            <FormGroup>
            <label htmlFor='firstname'>
              {language["users"]["table"]["firstname"]}
            </label>
            <Input
              className="form-control-sm"
              type='text'
              name='firstname'
              required
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
            />
            </FormGroup>
            <FormGroup>
            <label htmlFor='lastname'>
              {language["users"]["table"]["lastname"]}
            </label>
            <Input
              type='text'
              className="form-control-sm"
              name='lastname'
              required
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
            />
            </FormGroup>

            <FormGroup>
            <label htmlFor='email'>{language["users"]["table"]["email"]}</label>
            <Input
              type='text'
              className="form-control-sm"
              name='email'
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            </FormGroup>
            <FormGroup>
            <label htmlFor='role'>{language["users"]["table"]["role"]}</label>
            <Input className="form-control-sm" type="select"
              name='role'
              required
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              {Object.values(roles).map((role) => (
                <option key={role.id} value={role.name}>
                  {language["roles"]["kinds"].hasOwnProperty(role.name)
                    ? language["roles"]["kinds"][role.name]
                    : role.name}
                </option>
              ))}
            </Input>
            </FormGroup>
            <FormGroup>
            <input type='hidden' name='role_id' value={roleId} />
            </FormGroup>
            <FormGroup>
            <label>{language["users"]["table"]["area"]}</label>
            <Input className="form-control-sm" type="select"
              name='area_id'
              required
              value={area}
              onChange={(e) => setArea(e.target.value)}
            >
              {areas.map((area) => (
                <option key={area.id} value={area.id}>
                  {area.name}
                </option>
              ))}
            </Input>
            </FormGroup>
            <FormGroup>
            <label htmlFor='password'>
              {language["users"]["form"]["password"]}
            </label>
            <Input
              type='password'
              className="form-control-sm"
              name='password'
              autoComplete='false'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {password !== "" ? (
              <p>{language["users"]["form"]["password_info"]}</p>
            ) : (
              <></>
            )}
            {password !== "" ? (
              <label htmlFor='password'>
                {language["users"]["form"]["confirm_password"]}
              </label>
            ) : (
              <></>
            )}
            {password !== "" ? (
              <Input
                type='password'
                className="form-control-sm"
                name='confirm_password'
                required
                value={cpassword}
                onChange={(e) => setCPassword(e.target.value)}
              />
            ) : (
              <></>
            )}
            {password !== "" && cpassword !== "" && cpassword !== password ? (
              <p>{language["users"]["form"]["cpassword_info"]}</p>
            ) : (
              <></>
            )}
            </FormGroup>
            <div>
              <Button color='success' className='btn-sm' type='submit'>
                {language["users"]["form"]["save"]}
              </Button>
            </div>
          </Form>
          </CardBody>
          </Card>
        </div>
        </Modal>
        </>
      );

  };

  return (
    <div>
      {renderModalFormUsers()}
    </div>
  );
};

export default connect()(EditUser);
