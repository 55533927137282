import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { deleteTag } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { Button } from "reactstrap";
import DeleteModal from "../../components/Modal/DeleteModal";

const DeleteTags = ({ dispatch, tag }) => {
  let [modalInitiatives, setModalInitiatives] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalInitiatives(false);
  });

  const deleteInitiativeButton = () => {
    if (modalInitiatives) {
      return <></>;
    }
    return (
      <Button
        className='btn-sm btn-icon'
        color='danger'
        onClick={() => setModalInitiatives(true)}
      >
        <span className='btn-inner--icon'>
          <i className='ni ni-fat-remove' />
        </span>
      </Button>
    );
  };

  const handleSubmitInitiatives = () => {
    dispatch(deleteTag(tag.id));
    setModalInitiatives(false);
  };

  const renderModalFormInitiatives = () => {
    if (modalInitiatives) {
      return (
        <div className='form' ref={wrapperRef}>
          <div>
            <Button
              className='btn-sm'
              onClick={() => setModalInitiatives(false)}
            >
              {language["general"]["cancel"]}
            </Button>
          </div>
          <div>
            <Button
              className='btn-sm'
              onClick={() => handleSubmitInitiatives()}
            >
              {language["tags"]["form"]["deleteConfirm"]}
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <DeleteModal
      buttonText={language["tags"]["form"]["delete"]}
      confirmText={language["tags"]["form"]["deleteConfirm"]}
      confirm={handleSubmitInitiatives}
    />
  );
};

export default connect()(DeleteTags);
