import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { DeleteInitiativeUserById } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { Button } from "reactstrap";
import DeleteModal from "../../components/Modal/DeleteModal";

const DeleteInitiativeUser = ({ dispatch, resource, setLoading }) => {

  const handleSubmitUsers = () => {
    setLoading(true);
    dispatch(DeleteInitiativeUserById(resource.initiative_id, resource.id));
  };


  return (
    <DeleteModal
      confirm={handleSubmitUsers}
      confirmText={language["users"]["form"]["deleteConfirm"]}
      buttonText={language["initiative_users"]["form"]["delete"]}
    />
  );
};

export default connect()(DeleteInitiativeUser);
