import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getCurrentUserApprovals,
  getAllPhases,
  getAllAreas,
  getCurrentUserInitiatives,
} from "../../redux/actions";
import { language } from "../../assets/language";
import SimpleTable from "../../components/tables/SimpleTable";
import AcceptApproval from "./AcceptApproval";
const ListMyApprovals = ({ dispatch }) => {
  const approvals = useSelector((state) => state.approvals.approvals);
  const phases = useSelector((state) => state.phases.phases);
  const initiatives = useSelector((state) => state.initiatives.initiatives);
  const user = useSelector((state) => state.sessions.user);
  const areas = useSelector((state) => state.areas.areas);
  const areas_dict = useMemo(() => {
    return areas.reduce((acc, area) => {
      acc[area.id] = area;
      return acc;
    }, {});
  }, [areas]);

  useEffect(() => {
    dispatch(getAllPhases());
    dispatch(getCurrentUserApprovals());
    dispatch(getAllAreas());
    dispatch(getCurrentUserInitiatives());
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: language.approvals.table.initiative,
        accessor: "initiative",
      },
      {
        Header: language.approvals.table.area,
        accessor: "area",
      },
      {
        Header: language.approvals.table.stage,
        accessor: "stage",
      },
      {
        Header: language.approvals.table.next_stage,
        accessor: "next_stage",
      },
      {
        Header: language.approvals.table.is_accepted,
        accessor: "is_accepted",
      },
      {
        Header: language.approvals.table.is_active,
        accessor: "is_active",
      },
      {
        Header: language.approvals.form.complete,
        accessor: "complete",
      },
    ],
    []
  );

  // render areas in list with name, description, parent_id
  const renderApprovals = () => {
    if (!approvals || approvals.length === 0) {
      return <p>{language["approvals"]["not_found"]}</p>;
    }

    let approvalsList = approvals
      .filter((approval) => approval && approval.responsable_user === user.id)
      .filter((approval) => approval.initiative_id != null)
      .map((approval) => {
        return {
          id: approval.id,
          initiative: (
            <Link to={`/initiative/${approval.initiative_id}`}>
              {approval.initiative !== null
                ? approval.initiative.title
                : initiatives.length > 0
                ? initiatives.find((ini) => ini.id === approval.initiative_id)
                  ? initiatives.find((ini) => ini.id === approval.initiative_id)
                      .title
                  : "-"
                : "-"}
            </Link>
          ),
          area:
            areas_dict &&
            approval["area_id"] &&
            areas_dict.hasOwnProperty(approval["area_id"])
              ? areas_dict[approval["area_id"]].name
              : "",
          stage: phases.hasOwnProperty(approval.stage)
            ? phases[approval.stage].name
            : approval.stage,
          next_stage: phases.hasOwnProperty(approval.next_stage)
            ? phases[approval.next_stage].name
            : approval.next_stage,
          is_accepted: approval.is_accepted
            ? language["approvals"]["completed"]["yes"]
            : language["approvals"]["completed"]["no"],
          is_active: approval.is_active
            ? language["general"]["yes"]
            : language["general"]["no"],
          complete:
            approval.is_accepted || !approval.is_active ? (
              <></>
            ) : (
              <AcceptApproval approval={approval} />
            ),
        };
      });

    return <SimpleTable columns={columns} data={approvalsList} />;
  };

  return (
    <div>
      <h1>{language["approvals"]["title"]}</h1>
      {renderApprovals()}
    </div>
  );
};

export default connect()(ListMyApprovals);
