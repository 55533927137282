import React from 'react';
import { connect } from 'react-redux';
// import { ListUsers }  from '../users';
import { ListAreas } from '../areas';
import { language } from '../../assets/language';
import {Card} from 'reactstrap';

const ShowOrganization = ({ dispatch }) => {

    return (
        <Card className="p-3 m-3">
            {/* <h1>{language["users"]["title"]}</h1> */}
            {/* <ListUsers /> */}
            <h1>{language["areas"]["title"]}</h1>
            <ListAreas />
        </Card>
    );

}

export default connect()(ShowOrganization);