import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import {
  getInitiativeActivities,
  getAllAreas,
  getAllUsers,
} from "../../redux/actions";
import { language } from "../../assets/language";
import SimpleTable from "../../components/tables/SimpleTable";
import { getUserPermission } from "../../utils/getUserPermission";
import { AcceptActivity, CreateActivity, DeleteActivity } from ".";

import {
  Badge,
} from "reactstrap";
import Loading from "../../components/Loading/Loading";
import { getResponsabilityPermission } from "../../utils/getResponsabilityPermission";

const ListInitiativeActivities = ({ dispatch, initiative }) => {
  const state = useSelector((state) => state);
  const activitiesResponse = useSelector(
    (state) => state.activities.initiative_activities
  );
  const inititativeAct = useSelector(
    (state) => state.activities.initiative_id
  );
  const loadingActivities = useSelector((state) => state.activities.loading);
  const users = useSelector((state) => state.users.users);
  const areas = useSelector((state) => state.areas.areas);
  const areas_dict = useMemo(() => {
    return areas.reduce((acc, area) => {
      acc[area.id] = area;
      return acc;
    }, {});
  }, [areas]);
  const phases = useSelector((state) => state.phases.phases);
  const currentUser = useSelector((state) => state.sessions.user);
  const [loading, setLoading] = useState(true);
  const [activities, setActivities] = useState();

  const [completedFilter, setCompletedFilter] = useState([false]);
  const [completedTrigger, setCompletedTrigger] = useState(true);


  useEffect(() => {

    if ( activitiesResponse && users && areas && inititativeAct === initiative.id ) {
      setActivities(activitiesResponse)
      setLoading(false);
    }
    }, [activitiesResponse, users, areas, inititativeAct]);


  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getInitiativeActivities(initiative.id));
    dispatch(getAllAreas());
  }, [initiative]);

  const columns = useMemo(() => {
    let col = [
      {
        Header: language.activities.table.title,
        accessor: "description",
      },
      {
        Header: language.activities.table.user,
        accessor: "user",
      },
      {
        Header: language.activities.table.area,
        accessor: "area",
      },
      {
        Header: language.activities.table.created_at,
        accessor: "created_at",
      },
      {
        Header: language.activities.table.forecast_date,
        accessor: "forecast_date",
      },
      {
        Header: language.activities.table.estimated_date,
        accessor: "estimated_date",
      },
      {
        Header: language.activities.table.stage,
        accessor: "stage",
      },
      {
        Header: language.activities.table.status,
        accessor: "status",
      },
      {
        Header: language.activities.table.completed,
        accessor: "completed",
      },
    ];
    if (
         getUserPermission(state, "activities", "patch", false) ||
      (
        getResponsabilityPermission(state, "activities", "change",initiative.id,"Lider")

        )
    ) {
      col.push({
        Header: language.activities.form.edit,
        accessor: "edit",
      });
    }
    if (
      (
        (
          getUserPermission(state, "activities", "delete", false))) ||
      (initiative.stage === "evaluation" &&
        (getResponsabilityPermission(state, "activities", "change",initiative.id,"Lider" )))
    ) {
      col.push({
        Header: language.activities.form.delete,
        accessor: "delete",
      });
    }
    return col;
  }, [state, currentUser, initiative]);

const renderActivities = () => {
  if (loadingActivities) {
    return <p>{language.general.loading}</p>;
  }
  if (!activities || activities.length === 0) {
    return <p>{language["activities"]["not_found"]}</p>;
  }

  let activitiesList = activities
    .filter(activity => completedFilter.includes(activity.completed))
    .sort((a, b) => {
      const aDate = a.forecast_date.split("T")[0].split("/").reverse().join("");
      const bDate = b.forecast_date.split("T")[0].split("/").reverse().join("");
      return a.completed === b.completed ? (aDate > bDate ? 1 : aDate < bDate ? -1 : 0)
        : (a.completed ? 1 : -1);
    })
    .filter(activity => activity)
    .map(activity => ({
      id: activity.id,
      description: activity.description,
      area: areas_dict && activity["area_id"] && areas_dict.hasOwnProperty(activity["area_id"])
        ? areas_dict[activity["area_id"]].name : "",
      stage: phases.hasOwnProperty(activity.stage) ? phases[activity.stage].name : activity.stage,
      user: users && users.hasOwnProperty(activity.responsable_user)
        ? users[activity.responsable_user].full_name : "",
      created_at: activity.created_at.split("T")[0] || language["activities"]["no_date"],
      forecast_date: activity.forecast_date.split("T")[0] || language["activities"]["no_date"],
      estimated_date: activity.estimated_date ? activity.estimated_date.split("T")[0]
        || language["activities"]["no_date"] : language["activities"]["no_date"],
      status: activity.status === "complete" || activity.status === "in_progress"
        ? <Badge color='success'>{language["activities"]["status"][activity.status]}</Badge>
        : <Badge color='danger'>{language["activities"]["status"][activity.status]}</Badge>,
      completed: activity.completed
        ? <Badge color='success'>{language["activities"]["completed"]["yes"]}</Badge>
        : <Badge color='danger'>{language["activities"]["completed"]["no"]}</Badge>,
      edit: (getResponsabilityPermission(state, "activities", "change", initiative.id, "Lider")
        || state.sessions.user.id === activity.responsable_user
        || getUserPermission(state, "activities", "patch", false))
        ? <AcceptActivity activity={!loading ? activity : null} editableInInitiative={true}
          initiativeStage={initiative.stage} />
        : "",
      delete: (getResponsabilityPermission(state, "activities", "change", initiative.id, "Lider")
        || state.sessions.user.id === activity.responsable_user
        || getUserPermission(state, "activities", "delete", false))
        ? <DeleteActivity activity={!loading ? activity : null} setLoading={setLoading} />
        : "",
    }));

  return <SimpleTable columns={columns} data={!loading ? activitiesList: []} />;
};

  const processCompletedFilter = (value) => {
    setCompletedTrigger(false);
    let _completed = completedFilter;
    if (completedFilter.includes(value)) {
      _completed = completedFilter.filter(function (f) {
        return f !== value;
      });
      setCompletedFilter(_completed);
    } else {
      setCompletedFilter([..._completed, value]);
    }
    setCompletedTrigger(true);
  };

  const renderCompletedFilter = () => {
    if (completedTrigger === false) {
      return <></>;
    }
    return (
      <div>
        <label className='mr-2'>
          {language["activities"]["table"]["completed"]}:{" "}
        </label>
        <div className='custom-control custom-checkbox custom-control-inline'>
          &nbsp;
          <input
            type='checkbox'
            className='custom-control-input'
            onChange={(e) => processCompletedFilter(true)}
            checked={completedFilter.includes(true)}
            id='customRadioInline2'
            name='customRadioInline2'
          />
          <label className='custom-control-label' htmlFor='customRadioInline2'>
            {language["activities"]["completed"]["yes"]}
          </label>
        </div>
        <div className='custom-control custom-checkbox custom-control-inline'>
          &nbsp;
          <input
            type='checkbox'
            className='custom-control-input'
            onChange={(e) => processCompletedFilter(false)}
            checked={completedFilter.includes(false)}
            id='customRadioInline3'
            name='customRadioInline3'
          />
          <label className='custom-control-label' htmlFor='customRadioInline3'>
            {language["activities"]["completed"]["no"]}
          </label>
        </div>
      </div>
    );
  };

  return  (
    
    <div>
       <h3 className='float-left mb-4'>
                {language["activities"]["title"]}
              </h3>
              {(initiative.stage !== "evaluation" &&
                (
                  getUserPermission(state, "activities", "patch", false)))  
                  || getResponsabilityPermission(state, "activities", "change",initiative.id,"Lider")
              ? (
                <CreateActivity initiative={initiative}  setLoading={setLoading}/>
              ) : (
                <><h2 className="p-3">{language["activities"]["not_active_in_this_stage"]}</h2></>
              )}
      <br />
      {renderCompletedFilter()}
      { loading ? <Loading /> :
      renderActivities()}
    </div>
  );
};

export default connect()(ListInitiativeActivities);
