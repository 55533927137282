import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";


export const setGroups = (groups) => ({
    type: "SET_GROUPS",
    payload: {
        groups: groups,
    },
    request_time: Date.now()
});

export const setGroup = (data) => ({
    type: "SET_GROUP",
    payload: {
        id: data.id,
        name: data.name,
        request_time: Date.now()
    },
});

export const setGroupError = (error) => ({
    type: "GROUP_ERROR",
    payload: {
        error: error
    }
});

export const deleteGroup = (name) => ({
    type: "DELETE_GROUP",
    payload: {
        name: name,
    },
    request_time: Date.now()
});

export const getAllGroups = () => {

    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "groups", "list", false)) {
                throw new Error("No permissions");
            }
            if (state.groups.request_time === null
                || state.groups.request_time + 1000 * 2 < Date.now()) {
                fetch(`${BACKEND_URL}/groups`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setGroups(data));
                    })
                    .catch((error) => {
                        errorHandler("groups", error.message);
                        dispatch(setGroupError(error));
                    });
            }
        } catch (error) {
            errorHandler("groups", error.message);
            dispatch(setGroupError(error));
        }
    };
}

export const postGroup = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "groups", "create", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/groups`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
                body: JSON.stringify(data)
            })
                .then((response) => {
                    if (response.status === 201) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    if (data != null && data !== undefined && data.id !== undefined) {
                        dispatch(setGroup(data));
                    }
                })
                .catch((error) => {
                    errorHandler("groups", error.message);
                    dispatch(setGroupError(error));
                });
        } catch (error) {
            errorHandler("groups", error.message);
            dispatch(setGroupError(error));
        }
    };
}

export const deleteGroupById = (name) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "groups", "delete", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/groups/${name}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
                .then((response) => {
                    if (response.status === 204) {
                        return true;
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((vl) => {
                    if (vl) {
                        dispatch(deleteGroup(name));
                    }
                })
                .catch((error) => {
                    errorHandler("groups", error.message);
                    dispatch(setGroupError(error));
                });
        } catch (error) {
            errorHandler("groups", error.message);
            dispatch(setGroupError(error));
        }
    };
}