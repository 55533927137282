const initialState_Role = {
    request_time: null,
    error: null,
    roles: {},
};

const roles = (state = initialState_Role, action) => {
    switch (action.type) {
        case 'SET_ROLES':
            return {
                roles: action.payload.roles.reduce((obj, item) => {
                    obj[item.name] = item;
                    return obj;
                }, {}),
                request_time: action.request_time,
            };
        case 'SET_ROLE':
            try {
                return {
                    ...state,
                    roles: {
                        ...state.roles, [action.payload.name]: action.payload,
                    },
                    request_time: action.payload.request_time,
                };
            } catch (e) {
                console.log(e);
                return state;
            }
        case 'ROLE_ERROR':
            return {
                error: action.payload.error,
                request_time: null,
                // roles: {},
            };
        case "DELETE_ROLE":
            let new_roles = state.phases;
            try {
                new_roles = Object.keys(new_roles).reduce(function (filtered, key) {
                    if (new_roles[key].id !== action.payload.id) filtered[key] = new_roles[key];
                    return filtered;
                }, {});
            } catch (e) {
                console.log(e);
            }
            return {
                ...state,
                roles: new_roles,
                request_time: action.request_time,
            };
        default:
            return state;
    }
}

export default roles;