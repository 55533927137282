import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { deleteApprovalById } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { Button } from "reactstrap";

const DeleteApproval = ({ dispatch, approval }) => {
  let [modalApprovals, setModalApprovals] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalApprovals(false);
  });

  const deleteApprovalButton = () => {
    if (modalApprovals) {
      return <></>;
    }
    return (
      <Button
        color='danger'
        className='btn-sm'
        onClick={() => setModalApprovals(true)}
      >
        {language["approvals"]["form"]["delete"]}
      </Button>
    );
  };

  const handleSubmitApprovals = () => {
    dispatch(deleteApprovalById(approval.id));
    setModalApprovals(false);
  };

  const renderModalFormApprovals = () => {
    if (modalApprovals) {
      return (
        <div className='form' ref={wrapperRef}>
          <div>
            <Button
              color='warning'
              className='btn-sm'
              onClick={() => setModalApprovals(false)}
            >
              {language["general"]["cancel"]}
            </Button>
          </div>
          <div>
            <Button
              color='danger'
              className='btn-sm'
              onClick={() => handleSubmitApprovals()}
            >
              {language["approvals"]["form"]["deleteConfirm"]}
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      {deleteApprovalButton()}
      {renderModalFormApprovals()}
    </div>
  );
};

export default connect()(DeleteApproval);
