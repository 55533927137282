import {
  ListAreas,
  ListMyActivities,
  ListUsers,
  ListMyCards,
  ListMyInitiatives,
  ShowInitiative,
  CreateCard,
  CreateArea,
  CreateUser,
  ListMyApprovals,
  ShowChecklist,
  ListPhases,
  ListInitypes,
  ListRoles,
  ListConfigs,
  ShowOrganizationTree,
  ListMyTasks,
  ShowOrganization,
  ListDecisions,
  ListTags,
  ListLevers,
  KshiftDetail,
  TshiftDetail,
} from "./pages";
import { ControlView, HomeDashboard } from "./pages/dashboard";
import language from "./assets/language";
import { ListGroup } from "./pages/groups";


export const routes = [
  {
    path: "/dashboard",
    name: language["menu"]["home"],
    icon: "ni ni-badge",
    component: HomeDashboard,
    layout: "",
    permissions: ["Mi estado", "list"]
  },
  {
    path: "/initiatives",
    name: language["menu"]["initiatives"],
    invisible: true,
    component: ListMyInitiatives,
    layout: "",
    permissions: ["initiatives", "list"],
  },
  {
    path: "/initiative/:initiativeId",
    name: language["menu"]["initiatives"],
    miniName: "",
    component: ShowInitiative,
    invisible: true,
    layout: "",
    permissions: ["initiatives", "list"],
  },
 
  {
    path: "/kpi/:kpiId",
    name: language["menu"]["kpi"],
    miniName: "",
    component: KshiftDetail,
    invisible: true,
    layout: "",
    permissions: ["kpisecond", "list"],
  },

  {
    path: "/trees/:treeId",
    name: language["menu"]["tree"],
    miniName: "",
    component: TshiftDetail,
    invisible: true,
    layout: "",
    permissions: ["tree", "list"],
  },
  {
    path: "/cards",
    name: language["menu"]["cards"],
    icon: "ni ni-bulb-61",
    component: ListMyCards,
    layout: "",
    permissions: ["Ideas", "list"],
  },
  {
    path: "/control",
    name: language["menu"]["control"],
    icon: "ni ni-settings-gear-65",
    component: ControlView,
    layout: "",
    permissions: ["Panel de control", "list"],
  },

  {
    id: "config",
    collapse: true,
    name: language["menu"]["config-menu"],
    icon: "ni ni-settings",
    state: "collapse",
    permissions: ["Configs", "list"],
    views: [
      {
        path: "/tree",
        name: language["menu"]["tree"],
        miniName: "",
        component: ShowOrganizationTree,
        layout: "",
        permissions: ["trees", "list"],
      },
      {
        path: "/organization",
        name: language["menu"]["organization"],
        miniName: "",
        component: ShowOrganization,
        layout: "",
        permissions: ["areas", "list"],
      },

      {
        path: "/users",
        name: language["menu"]["users"],
        miniName: "",
        component: ListUsers,
        layout: "",
        permissions: ["users", "list"],
      },
      {
        path: "/checklists",
        name: language["menu"]["checklists"],
        miniName: "",
        component: ShowChecklist,
        layout: "",
        permissions: ["checklists", "list"],
      },
      {
        path: "/phases",
        name: language["menu"]["phases"],
        miniName: "",
        component: ListPhases,
        layout: "",
        permissions: ["phases", "list"],
      },
      {
        path: "/levers",
        name: language["menu"]["levers"],
        miniName: "",
        component: ListLevers,
        layout: "",
        permissions: ["levers", "list"],
      },
      {
        path: "/tags",
        name: language["menu"]["tags"],
        miniName: "",
        component: ListTags,
        layout: "",
        permissions: ["levers", "list"],
      },
      {
        path: "/decisions",
        name: language["menu"]["decisions"],
        miniName: "",
        component: ListDecisions,
        layout: "",
        permissions: ["levers", "list"],
      },
      {
        path: "/initypes",
        name: language["menu"]["initypes"],
        miniName: "",
        component: ListInitypes,
        layout: "",
        permissions: ["initypes", "list"],
      },
      {
        path: "/roles",
        name: language["menu"]["roles"],
        miniName: "",
        component: ListRoles,
        layout: "",
        permissions: ["roles", "list"],
      },
      {
        path: "/groups",
        name: language["menu"]["groups"],
        miniName: "",
        component: ListGroup,
        layout: "",
        permissions: ["roles", "list"],
      },
      {
        path: "/configuration",
        name: language["menu"]["configuration"],
        miniName: "",
        component: ListConfigs,
        layout: "",
        permissions: ["configs", "list"],
      },

    ],
  },
];
export default routes;
