const initialState_Checklist = {
    request_time: null,
    error: null,
    checklists: [],
};

const checklists = (state = initialState_Checklist, action) => {
    switch (action.type) {
        case 'SET_CHECKLISTS':
            return {
                checklists: action.payload.checklists,
                request_time: action.request_time,
            };
        case 'SET_CHECKLIST':
            return {
                ...state,
                checklists: [...state.checklists, action.payload],
                request_time: action.request_time,
            };
        case "CHECKLIST_ERROR":
            return {
                ...state,
                error: action.payload.error,
                // checklists: [],
                request_time: null,
            };
        case "DELETE_CHECKLIST":
            return {
                ...state,
                checklists: state.checklists.filter(checklist => checklist.id !== action.payload.id),
                request_time: action.request_time,
            };
        default:
            return state;
    }
}

export default checklists;