import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import {
  getAllPhases,
  getInitiativeApprovals,
  getInitiativeChecklists,
  reloadAllInitiativeData,
  createAdvance,
} from "../../redux/actions";
import { language } from "../../assets/language";
import { getStages, postStageChange } from "../../redux/actions";
import { useClickAway } from "react-use";
import { getUserPermission } from "../../utils/getUserPermission";

import { Button, Card, CardBody, Modal, CardTitle, Form, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";



const ChangeStage = ({ dispatch, initiative }) => {
  const state = useSelector((state) => state);
  let [modalStages, setModalStages] = useState(false);
  let [nextStage, setNextStage] = useState("idea");
  let [nextStageId, setNextStageId] = useState("");
  let [checks, setChecks] = useState([]);
  const [stageId, setStageId] = useState("");
  const [stageApprovals, setStageApprovals] = useState([]);


  let stages = useSelector((state) => state.stages.stages);
  stages = stages.sort((a, b) => {
    a = a.position; //forecast_date.split("T")[0].split('/').reverse().join('');
    b = b.position; // forecast_date.split("T")[0].split('/').reverse().join('');
    return a > b ? 1 : a < b ? -1 : 0;
  });
  let phases = useSelector((state) => state.phases.phases);
  let approvals = useSelector((state) => state.approvals.initiative_approvals);
 
  const initiative_checklists = useSelector(
    (state) => state.initiative_checklists.checklists
  );
  // let checklists = useSelector(state => state.checklists.checklists);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalStages(false);
  });

  useEffect(() => {
    if(approvals){
      let stageA = approvals.filter((a) => a.next_stage === nextStage);
      setStageApprovals(stageA);
    }
  }, [nextStage, approvals]);
  
  useEffect(() => {
    dispatch(getStages(initiative.id));
    dispatch(getAllPhases());
    dispatch(getInitiativeApprovals(initiative.id));
    dispatch(getInitiativeChecklists(initiative.id));
  }, [dispatch, initiative.id]);

  useEffect(() => {
    if (
      stages &&
      phases &&
      Object.keys(phases).length > 0 &&
      Object.keys(stages).length > 0
    ) {
      for (let i = 0; i < stages.length - 1; i++) {
        if (
          !stages[i].completed &&
          stages[i].name === initiative.stage &&
          stages[i].name !== "deleted" &&
          stages[i].name !== "finalized"
        ) {
          setNextStage(stages[i + 1].name);
          setNextStageId(phases[stages[i + 1].name].id);
          setStageId(phases[stages[i].name].id);
          break;
        }
      }
     
    }
  }, [stages, initiative, phases]);

  useEffect(() => {
    setChecks(
      initiative_checklists.filter(
        (ic) => ic.phase_id === stageId && ic.checked === false
      )
    );
  }, [stageId, initiative_checklists]);

  const changeStage = () => {
    if (initiative.is_blocked) {
      return (
        <Button
          className='btn-sm'
          color='warning'
          onClick={() => setModalStages(true)}
        >
          {language["stages"]["form"]["confirm"]} {language["stages"]["names"][nextStage]}
        </Button>
      );
    }

    if (checks.length) {

    return (
      <>
      <Button
        className='btn-sm'
        color='warning'
        id="PopoverLegacy"
      >
        {language["stages"]["form"]["change"]}  {language["stages"]["names"][nextStage]}
      </Button>
       <UncontrolledPopover
    placement="left"
    target="PopoverLegacy"
    trigger="legacy"
  >
    <PopoverHeader>
      Se necesitan acciones
    </PopoverHeader>
    <PopoverBody>
      <ul>
        <li>
        Falta {checks.length} Checklist por aprobar
        </li>

      </ul>
  
    </PopoverBody>
  </UncontrolledPopover>
  </>
    );
    } else {
      return (
      <Button
        className='btn-sm'
        color='warning'
        block
        id="PopoverLegacy"
        onClick={() => setModalStages(true)}
      >
        {language["stages"]["form"]["change"]}  {language["stages"]["names"][nextStage]}
      </Button>
  
    );
  };

  }

  const handleSubmitStages = () => {
    dispatch(
      postStageChange(initiative.id, {
        next_stage: nextStage,
        review:
          phases[nextStage].required_checklist || approvals.length > 0
            ? true
            : false,
      })
    );
    setModalStages(false);
  };

  const handleSubmitStagesFinal = () => {
    dispatch(
      postStageChange(initiative.id, {
        next_stage: nextStage,
        review: false,
      })
    );
    setModalStages(false);
    setTimeout(() => {
      generateAdvance();
    }, 500);
  };

  const generateAdvance = () => {
    dispatch(reloadAllInitiativeData(initiative.id));
    setTimeout(() => {
      dispatch(createAdvance());
    }, 1500);
  };

  const renderButtons = () => {
    if (initiative.is_blocked) {
      return checks.length === 0 &&
        stageApprovals.filter((st) => !st.is_accepted).length === 0 ? (
        <Button
          color='primary'
          className='btn-sm m-1'
          onClick={() => handleSubmitStagesFinal()}
        >
          {language["stages"]["form"]["confirm"]}{language["stages"]["names"][nextStage]}
        </Button>
      ) : (
        <></>
      );
    } else {
      return (
        <Button
          color='primary'
          className='btn-sm m-1'
          onClick={() => handleSubmitStages()}
          disabled={checks.length > 0 }
        >
          {phases[nextStage].required_checklist || stageApprovals.length > 0
            ? language["stages"]["change"]["send_approval"]
            : language["stages"]["form"]["confirm"]}
        </Button>
      );
    }
  };

  const renderModalFormStages = () => {
    if (modalStages) {
      return (
        <Modal
          className='modal-dialog-centered'
          isOpen={modalStages}
          toggle={() => setModalStages(false)}
        >
          <div className='modal-body p-2'>
            <Card className='bg-secondary shadow border-0'>
              <CardTitle></CardTitle>
              <CardBody className='px-lg-5 py-lg-2'>
                <Form className='text-center' ref={wrapperRef}>
                  <p>
                    {language["stages"]["change"]["actual"]}:{" "}
                    {phases.hasOwnProperty(initiative.stage)
                      ? phases[initiative.stage].name
                      : initiative.stage}
                  </p>
                  <p>
                    {language["stages"]["change"]["next"]}:{" "}
                    {phases.hasOwnProperty(nextStage)
                      ? phases[nextStage].name
                      : nextStage}
                  </p>
                  <hr />
                  {!initiative.is_blocked ? (
                    <div>
                      <p>
                        {language["phases"]["table"]["required_checklist"]}:{" "}
                        {phases[nextStage].required_checklist 
                          ? language.phases.required_checklist.yes
                          : language.phases.required_checklist.no}
                      </p>
                      <p>
                        {language["stages"]["change"]["approvals"]}:{" "}
                        {stageApprovals.length > 0
                          ? language.phases.required_checklist.yes
                          : language.phases.required_checklist.no}
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p>
                        {language["stages"]["change"]["pending_checklist"]}:{" "}
                      </p>
                      <p>
                        {checks.length > 0
                          ? 'Falta ' + checks.length + language.phases.required_checklist.pending
                          : language.phases.required_checklist.no}
                      </p>
                      <p>
                        {language["stages"]["change"]["pending_approvals"]}:{" "}
                        </p>
                      <p>
                        {stageApprovals.filter((st) => !st.is_accepted).length >
                        0
                          ? 'Falta ' + stageApprovals.filter((st) => !st.is_accepted).length + language.phases.required_checklist.approvals
                          : language.phases.required_checklist.no}
                      </p>
                    </div>
                  )}
                  <Button
                    color='danger'
                    className='btn-sm'
                    onClick={() => setModalStages(false)}
                  >
                    {language["general"]["close"]}
                  </Button>
                  {renderButtons()}
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      );
    }
  };

  return (
    <div>
      {modalStages ? <></> : !getUserPermission(state, "initiatives", "patch", false) ?  <></> : changeStage()}
      {renderModalFormStages()}
    </div>
  );
};

export default connect()(ChangeStage);
