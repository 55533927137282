import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { getInitiativeActivities, getAllAreas, getAllUsers } from '../../redux/actions';
import { language } from '../../assets/language';
import SimpleTable from '../../components/tables/SimpleTable';
import { AcceptActivity, DeleteActivity } from '.';
import {
    
    Badge,
} from "reactstrap";

const ListInitiativeActivitiesSummary = ({ dispatch, initiative, n = 5 }) => {

    const activities = useSelector(state => state.activities.initiative_activities);
    const loadingActivities = useSelector(state => state.activities.loading);
    const users = useSelector(state => state.users.users);
    const areas = useSelector(state => state.areas.areas);
    const areas_dict = useMemo(() => {
        return areas.reduce((acc, area) => {
            acc[area.id] = area;
            return acc;
        }, {});
    }, [areas]);
    const phases = useSelector(state => state.phases.phases);

    useEffect(() => {
        dispatch(getAllUsers());
        dispatch(getInitiativeActivities(initiative.id));
        dispatch(getAllAreas());
    }, [dispatch, initiative]);

    const columns = useMemo(
        () => {
            let col = [
                {
                    Header: language.activities.table.title,
                    accessor: 'description',
                },
                {
                    Header: language.activities.table.area,
                    accessor: 'area',
                },
                {
                    Header: language.activities.table.user,
                    accessor: 'user',
                },
                {
                    Header: language.activities.table.created_at,
                    accessor: 'created_at',
                },
                {
                    Header: language.activities.table.estimated_date,
                    accessor: 'estimated_date',
                },
                {
                    Header: language.activities.table.status,
                    accessor: 'status',
                },
            ];
            return col;
        },
        []
    )

    const renderActivities = () => {
        if (loadingActivities) {
            return <p>{language.general.loading}</p>;
        }
        if (!activities || activities.length === 0) {
            return <p>{language["activities"]["not_found"]}</p>
        }

        let activitiesList = activities.sort((a, b) => {
            if (a.completed === b.completed) {
                a = a.forecast_date.split("T")[0].split('/').reverse().join('');
                b = b.forecast_date.split("T")[0].split('/').reverse().join('');
                return a > b ? 1 : a < b ? -1 : 0;
            }
            if (a.completed) {
                return 1;
            } else {
                return -1;
            }
        })
            .filter(activity => activity && !activity.completed).slice(0, n).map((activity) => {
                return {
                    id: activity.id,
                    description: activity.description,
                    area: (areas_dict && activity["area_id"] && areas_dict.hasOwnProperty(activity["area_id"])) ? areas_dict[activity["area_id"]].name : "",
                    stage: (phases.hasOwnProperty(activity.stage))
                        ? phases[activity.stage].name
                        : activity.stage,
                    user: (users && users.hasOwnProperty(activity.responsable_user)) ? users[activity.responsable_user].full_name : "",
                    created_at: activity.created_at.split("T")[0] || language["activities"]["no_date"],
                    forecast_date: activity.forecast_date.split("T")[0] || language["activities"]["no_date"],
                    estimated_date: (activity.estimated_date) ? (activity.estimated_date.split("T")[0] || language["activities"]["no_date"]) : language["activities"]["no_date"],
                    status:
                        activity.status === "complete" ||
                        activity.status === "in_progress" ? (
                        <Badge color='success'>
                            {language["activities"]["status"][activity.status]}
                        </Badge>
                        ) : (
                        <Badge color='danger'>
                            {language["activities"]["status"][activity.status]}
                        </Badge>
                        ),
                    completed: activity.completed ? (
                        <Badge color='success'>
                        {language["activities"]["completed"]["yes"]}
                        </Badge>
                    ) : (
                        <Badge color='danger'>
                        {language["activities"]["completed"]["no"]}
                        </Badge>
                    ),
                    edit: <AcceptActivity activity={activity} editableInInitiative={true} />,
                    actions: <DeleteActivity activity={activity} />,
                }
            });

        if (!activitiesList || activitiesList.length === 0) {
            return <p>{language["activities"]["not_active"]}</p>
        }

        return <SimpleTable columns={columns} data={activitiesList} />

    }

    return (
        <div>
            {renderActivities()}
        </div>
    );
}

export default connect()(ListInitiativeActivitiesSummary);