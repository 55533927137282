import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { getKpisecondKshifts } from '../../redux/actions';
import {  Col } from "reactstrap";
import Loading from "../../components/Loading/Loading";
import BarGraph from "../../components/Graphs/BarGraph";
import { fixNumber } from "../../utils/fix_number";
import moment from "moment";



const KPIGraph = ({  kpisecond, start, end , setProgressReady=()=>{} , progressReady={}}) => {
  const _kshifts = useSelector(state => state.kshifts.kshifts);

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [stats, setStats] = useState([]); 



  useEffect(() => {
    if(start && end){
      let startDate = moment(start, "DD-MM-YYYY");
      let endDate = moment(end, "DD-MM-YYYY");
      if(startDate.isValid() && endDate.isValid()) {
        dispatch(getKpisecondKshifts(kpisecond.id, startDate.format("DD-MM-YYYY"), endDate.format("DD-MM-YYYY")));
      }
    }
    
}, [kpisecond,start,end]);

  useEffect(() => {
    if(_kshifts && _kshifts.length > 0){
      if( _kshifts[0].kpisecond_id === kpisecond.id) {
        let _stats = {};
        _kshifts.forEach((th) => {
          let inf = `${th.month}-${th.year}`;
          if (_stats.hasOwnProperty(inf)) {
            _stats[inf]["base_sum"] += fixNumber(th.base);
            _stats[inf]["forecast_sum"] += fixNumber(th.forecast);
            _stats[inf]["real_sum"] += fixNumber(th.real);
          } else {
            _stats[inf] = {
              base_sum: fixNumber(th.base),
              forecast_sum: fixNumber(th.forecast),
              real_sum: fixNumber(th.real),
            };
          }
        });
        setStats(_stats);
        setLoading(false);
        let aux = progressReady;
        //find id kpi key and change value true
        aux[kpisecond.id] = true;
        setProgressReady(aux);
      }
  }
  }, [_kshifts, kpisecond]);




  return loading ?  <Loading /> :   (
    <Col key={kpisecond.id} style={{padding:"30px"}} >
      
      <h3  className='text-center'>
      {kpisecond.description}
      </h3>
      <h4 className='text-center'>
         Valor en {kpisecond.units}
      </h4>
      
      <BarGraph 
        stats={stats} 
        baseLine={kpisecond?.initial_value} 
        promiseLine={kpisecond?.objetive_value} 
        kpisecond_id={kpisecond.id} 
      />  
    </Col>
  );
};

export default connect()(KPIGraph);
