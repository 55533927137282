import React from "react";
import { connect } from "react-redux";
import { Container, Spinner } from "reactstrap";
const Loading = () => {
  return (
    <Container className='col-md-12' style={{ minHeight: "100vh" }}>
      <Spinner
        color='primary'
        size={"xl"}
        style={{ margin: "0 auto", display: "block", marginTop: "20vh" }}
      />
    </Container>
  );
};

export default connect()(Loading);