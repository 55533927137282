import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { postLever } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
} from "reactstrap";

const CreateLever = ({ dispatch }) => {
  let [modalLevers, setModalLevers] = useState(false);
  let [modal, toggleModal] = useState(false);
  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalLevers(false);
  });

  const handleSubmitLevers = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    dispatch(postLever(object));
    toggleModal(false);
  };

  const renderModalFormLevers = () => {
    if (modalLevers) {
      return (
        <div className='form' ref={wrapperRef}>
          <h3>{language["levers"]["form"]["create"]}</h3>
          <form onSubmit={handleSubmitLevers}>
            <label htmlFor='name'>{language["levers"]["table"]["name"]}</label>
            <input maxLength={254} type='text' name='name' required />
            <div>
              <Button color='success' className='btn-sm' type='submit'>
                {language["levers"]["form"]["create"]}
              </Button>
            </div>
          </form>
        </div>
      );
    }
  };

  return (
    <div>
      <Button
        color='success'
        className='btn-sm float-right '
        onClick={() => toggleModal(true)}
      >
        {language["levers"]["form"]["create"]}
      </Button>
      <Modal
        className='modal-dialog-centered'
        isOpen={modal}
        toggle={() => toggleModal(false)}
      >
        <div className='modal-body p-0'>
          <Card className='bg-secondary shadow border-0'>
            <CardBody className='px-lg-5 py-lg-2'>
              <h2 className='text-muted text-center mt-2 mb-3'>
                {language["levers"]["form"]["create"]}
              </h2>
              <Form onSubmit={handleSubmitLevers} role='form'>
                <FormGroup className='mb-3'>
                  <Input
                    className='form-control-sm'
                    type='text'
                    maxLength={254}
                    placeholder={language["levers"]["table"]["name"]}
                    name='name'
                    required
                  />
                </FormGroup>
                <div className='text-center mt-4'>
                  <Button
                    onClick={() => toggleModal(false)}
                    color='danger'
                    className='btn-sm'
                  >
                    Cancelar
                  </Button>
                  <Button color='success' className='btn-sm' type='submit'>
                    {language["levers"]["form"]["create"]}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default connect()(CreateLever);
