import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { deleteStageById } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import DeleteModal from "../../components/Modal/DeleteModal";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  Input,
  ListGroupItem,
  Form,
  ListGroup,
  Row,
  Col,
  FormGroup,
} from "reactstrap";
const DeleteStage = ({ dispatch, stage }) => {
  let [modalStages, setModalStages] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalStages(false);
  });

  const deleteStageButton = () => {
    if (modalStages) {
      return <></>;
    }
    return (
      <Button
        className='btn-sm'
        color='danger'
        onClick={() => setModalStages(true)}
      >
        {language["stages"]["form"]["delete"]}
      </Button>
    );
  };

  const handleSubmitStages = () => {
    dispatch(deleteStageById(stage.id));
    setModalStages(false);
  };

  const renderModalFormStages = () => {
    if (modalStages) {
      return (
        <div className='form' ref={wrapperRef}>
          <div>
            <Button
              className='btn-sm'
              color='danger'
              onClick={() => setModalStages(false)}
            >
              {language["general"]["cancel"]}
            </Button>
          </div>
          <br />
          <div>
            <Button
              className='btn-sm'
              color='danger'
              onClick={() => handleSubmitStages()}
            >
              {language["stages"]["form"]["deleteConfirm"]}
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <DeleteModal
      buttonText={language["stages"]["form"]["delete"]}
      confirmText={language["stages"]["form"]["deleteConfirm"]}
      confirm={handleSubmitStages}
    />
  );
};

export default connect()(DeleteStage);
