import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { getAllAreas, postArea } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import {
  Button,
  FormGroup,
  Input,
  Card,
  CardBody,
  Form,
  Modal,
} from "reactstrap";

const CreateArea = ({ dispatch }) => {
  const areas = useSelector((state) => state.areas.areas);

  let [modalAreas, setModalAreas] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalAreas(false);
  });

  let [name, setName] = useState("");
  let [description, setDescription] = useState("");
  let [parent_id, setParentId] = useState("");

  const handleSubmitAreas = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    dispatch(postArea(object));
    setModalAreas(false);
    setName("");
    setDescription("");
  };

  useEffect(() => {
    dispatch(getAllAreas());
  }, [dispatch]);

  const renderFormArea = () => {
    return (
      <>
        <Button
          color='success'
          className='btn-sm'
          onClick={() => setModalAreas(true)}
        >
          {language["areas"]["form"]["create"]}
        </Button>
        <Modal
          className='modal-dialog-centered'
          isOpen={modalAreas}
          toggle={() => setModalAreas(false)}
        >
          <div className='modal-body p-0'>
            <Card className='bg-secondary shadow border-0' ref={wrapperRef}>
              <CardBody className='px-lg-5 py-lg-2'>
                <h2>{language["areas"]["form"]["create"]}</h2>
                <Form onSubmit={handleSubmitAreas}>
                  <FormGroup>
                    <label htmlFor='name'>
                      {language["areas"]["table"]["name"]}
                    </label>
                    <Input
                      maxLength={254}
                      className='form-control-sm'
                      type='text'
                      name='name'
                      required
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor='description'>
                      {language["areas"]["table"]["description"]}
                    </label>
                    <Input
                      maxLength={254}
                      className='form-control-sm'
                      type='text'
                      name='description'
                      autoComplete='off'
                      required
                      multiple={true}
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>{language["areas"]["table"]["parent"]}</label>
                    <Input
                      className='form-control-sm'
                      type='select'
                      name='parent_id'
                      required
                      onChange={(e) => setParentId(e.target.value)}
                      value={parent_id}
                    >
                      {areas.map((area) => (
                        <option key={area.id} value={area.id}>
                          {area.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Button
                      color='success'
                      className='btn-sm ml-3'
                      type='submit'
                    >
                      {language["areas"]["form"]["create"]}
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </>
    );
  };

  return <div>{renderFormArea()}</div>;
};

export default connect()(CreateArea);
