import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { getAllGroups } from "../../redux/actions";
import { language } from "../../assets/language";
import SimpleTable from "../../components/tables/SimpleTable";
import { CreateGroup, DeleteGroup } from ".";
import { ListGroupPermits } from "../permits";
import { getUserPermission } from "../../utils/getUserPermission";
import { Button, Card, CardBody } from "reactstrap";
import Loading from "../../components/Loading/Loading";
import { Input } from "reactstrap";

const ListGroups = ({ dispatch }) => {
  const state = useSelector((state) => state);
  const groups = useSelector((state) => state.groups.groups);

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [hiddenList, setHiddenList ] = useState(false);

  const displayGroups = (group) => {
    setHiddenList(false);
    if (group === selectedGroup){
      setSelectedGroup(null);
    } else {
      setSelectedGroup(group);
    }
  }

  useEffect(() => {
    if (selectedGroup) {
      setHiddenList(true);
    }
    }, [selectedGroup]);

  useEffect(() => {
    dispatch(getAllGroups());
  }, []);

  const columns = useMemo(() => {
    let col = [
   
      {
        Header: language.groups.table.name,
        accessor: "name",
      },
      {
        Header: language.groups.table.type,
        accessor: "type",

      },
      {
        Header: language.groups.table.list,
        accessor: "list",
      },
      {
        Header: language.groups.table.create,
        accessor: "create",
      },
      {
        Header: language.groups.table.patch,
        accessor: "patch",
      },
      {
        Header: language.groups.table.change,
        accessor: "change",
      },
      {
        Header: language.groups.table.delete,
        accessor: "delete",
      },
     
    ];
    if (getUserPermission(state, "groups", "delete")) {
      col.push({
        Header: language.general.actions,
        accessor: "actions",
      });
    }
    return col;
  }, [state]);

  const renderGroups = () => {
    if (!groups || groups.length === 0) {
      return <p>{language["groups"]["not_found"]}</p>;
    }
    const groupTransposed = Object.entries(groups.reduce((acc, { id, name, action, initiative }) => {
      if (!acc[name]) {
        acc[name] = [];
      }
      acc[name].push({id: id, action: action , type: initiative});
      
      return acc;
    }, {}))
    let groupsList = groupTransposed.map((group) =>  {

      return {
        name: language["groups"]["kinds"][group[0]] || group[0],
        type:group[1][0].type ? language["groups"]["responsability"] : language["groups"]["role"],

        list:  <Button
            color='primary'
            className='btn-sm'
            onClick={() => displayGroups({id:group[1][0].id , name: group[0], action:group[1][0].action})}
          >
            {language["groups"]["form"]["edit_permits"]}
      </Button>,
        create: <Button
            color='success'
            className='btn-sm'
            onClick={() => displayGroups({id:group[1][1].id , name: group[0], action:group[1][1].action})}
          >
            {language["groups"]["form"]["edit_permits"]}
      </Button>,
        patch: <Button
            color='warning'
            className='btn-sm'
            onClick={() => displayGroups({id:group[1][2].id , name: group[0], action:group[1][2].action})}
          >
            {language["groups"]["form"]["edit_permits"]}
      </Button>,
        change: <Button
            color='warning'
            className='btn-sm'
            onClick={() => displayGroups({id:group[1][3].id , name: group[0], action:group[1][3].action})}
          >
            {language["groups"]["form"]["edit_permits"]}
      </Button>,
        delete: <Button
            color='danger'
            className='btn-sm'
            onClick={() => displayGroups({id:group[1][4].id , name: group[0], action:group[1][4].action})}
          >
            {language["groups"]["form"]["edit_permits"]}
      </Button>,

        actions: <DeleteGroup group={group} />,
      };
    });

    return <SimpleTable columns={columns} data={groupsList} />;
  };



  return (
    <Card className="p-3 m-3">
        <h1>{language.groups.title}</h1>
      <CardBody>
        <CreateGroup />
        {renderGroups()}
        {selectedGroup   ?   <ListGroupPermits group={selectedGroup} />: ""}
      </CardBody>
    </Card>
  );
};

export default connect()(ListGroups);
