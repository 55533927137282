import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { getAllConfigs, getAllExports } from "../../redux/actions";
import { language } from "../../assets/language";
import SimpleTable from "../../components/tables/SimpleTable";
import { EditConfig } from ".";
import { Button, Card, CardBody } from "reactstrap";
import DateRangeModal from "../general/DateRangeModal";

const ListConfigs = ({ dispatch }) => {
  const configs = useSelector((state) => state.configs.configs);


  useEffect(() => {
    dispatch(getAllConfigs());
  }, [dispatch]);

  const columns = useMemo(() => {
    let col = [
      {
        Header: language.configs.table.name,
        accessor: "name",
      },
      {
        Header: language.configs.table.value,
        accessor: "value",
      },
      {
        Header: language.general.actions,
        accessor: "actions",
      },
    ];
    return col;
  }, []);


  const renderConfigs = () => {
    if (!configs || configs.length === 0) {
      return <p>{language["configs"]["not_found"]}</p>;
    }

    let configsList = Object.values(configs).map((config) => {
      return {
        id: config.id,
        name: config.name,
        value: config.value,
        actions: <EditConfig config={config} />,
      };
    });

    return <SimpleTable columns={columns} data={configsList} />;
  };

  return (
    <>
      <Card className='mt-3'>
        <CardBody>
          <h1>{language.configs.title}</h1>
          {renderConfigs()}
        
          </CardBody>
      </Card>
      <Card className='mt-3'>
        <CardBody>
          <h1 >{language["initiatives"]["titles"]["downloads"]}</h1>
          <DateRangeModal 
            every={true} 
            label={language["initiatives"]["form"]["download_all"]} 
          />

          <DateRangeModal 
            csv={true} 
            label={language["initiatives"]["form"]["download_all_csv"]} 
          />
        </CardBody>

      </Card>
    </>
  );
};

export default connect()(ListConfigs);
