import "./App.css";
import { useSelector } from "react-redux";
import Login from "./pages/Login";
import { useDispatch } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  ListAreas,
  ListMyActivities,
  ListUsers,
  ListMyCards,
  ListMyInitiatives,
  ShowInitiative,
  CreateCard,
  CreateArea,
  CreateUser,
  ListMyApprovals,
  ShowChecklist,
  ListPhases,
  ListInitypes,
  ListRoles,
  ListConfigs,
  ListTags,
  ShowOrganizationTree,
  ListMyTasks,
  ShowOrganization,
  ListLevers,
  ListInfoStates,

} from "./pages";

import AdminLayout from "./layouts/Admin";
import { useEffect, useState } from "react";
import { checkLogged, getAllConfigs, getAllPermits, getRolePermits, getUserRoleGroups, getAllGroups, getCurrentUserInitiatives } from "./redux/actions";
import "react-toastify/dist/ReactToastify.css";
import HomeDashboard from "./pages/dashboard/HomeDashboard";
import KshiftDetail from "./pages/kshifts/KshiftDetail";
import TshiftDetail from "./pages/tshifts/TshiftDetail";
import { ControlView } from "./pages/dashboard";
import { ListDecisions } from "./pages/decisions";
import Loading from "./components/Loading/Loading";


function App() {
  // read token from redux store
  const dispatch = useDispatch();

  const token = useSelector((state) => state.sessions.token);
  const user = useSelector((state) => state.sessions.user);
  const permits = useSelector((state) => state.permits.permits);
  const role_permits = useSelector((state) => state.permits.role_permits);
  const role_groups = useSelector((state) => state.role_groups.user_role_groups);
  const groups = useSelector((state) => state.role_groups.groups);
  const configs = useSelector((state) => state.configs.configs)
  const initiatives = useSelector((state) => state.initiatives.initiatives);

  const [check, setCheck] = useState(false);

  useEffect(() => {
    if(configs){
      localStorage.setItem('unit', JSON.stringify(configs.unit_proyect))
    }
    },[configs])

  useEffect(() => {
    if (!check) {
      dispatch(getAllConfigs())
      setCheck(true);
    }
    dispatch(checkLogged());
   
  }, [dispatch]);


  useEffect(() =>{

    if (user && Object.keys(user).length !== 0) {
      if (!permits || permits.length === 0) {
        dispatch(getAllPermits());
      }
      if (!role_permits || role_permits.length === 0) {
        dispatch(getRolePermits(user.role_id));
      }
      //role_groups
      if (!role_groups || role_groups.length === 0) {
        dispatch(getUserRoleGroups(user.role_id));
      }
      //groups
      if (!groups || groups.length === 0) {
        dispatch(getAllGroups());
      }
      //initiativies
      if (!initiatives || initiatives.length === 0) {
        dispatch(getCurrentUserInitiatives());
      }
  }
  },[dispatch, user, permits, role_permits, role_groups, groups])
 
  if (!token || !user || !role_groups  || !role_groups || ! role_permits || !groups) {
    return <Login />;
  }


  return permits.length === 0 || role_permits.length === 0 || role_groups.length === 0 || groups.length === 0 || configs.length === 0 ? <Loading /> : (

    <AdminLayout
      app={
        <Switch>
           <Route  path='/' >
            <Redirect to='/dashboard' />
          </Route>
          <Redirect from="/" to="/dashboard" />
          <Route exact path='/' component={HomeDashboard} />
          <Route exact path='/areas' component={ListAreas} />
          <Route path='/areas/create' component={CreateArea} />
          <Route exact path='/activities' component={ListMyActivities} />
          <Route exact path='/users' component={ListUsers} />
          <Route path='/users/create' component={CreateUser} />
          <Route exact path='/cards' component={ListMyCards} />
          <Route path='/cards/create' component={CreateCard} />
          <Route exact path='/initiatives' component={ListMyInitiatives} />
          <Route exact path='/kpi/:kpiId' component={KshiftDetail} />
          <Route exact path='/trees/:treeId' component={TshiftDetail} />
          <Route exact path='/initiative/:initiativeId' component={ShowInitiative} />
          <Route path='/organization' component={ShowOrganization} />
          <Route path='/approvals' component={ListMyApprovals} />
          <Route path='/checklists' component={ShowChecklist} />
          <Route path='/phases' component={ListPhases} />
          <Route path='/levers' component={ListLevers} />
          <Route path='/initypes' component={ListInitypes} />
          <Route path='/roles' component={ListRoles} />
          <Route path='/tags' component={ListTags} />
          <Route path='/configuration' component={ListConfigs} />
          <Route exact path='/tree' component={ShowOrganizationTree} />
          <Route path='/tasks' component={ListMyTasks} />
          <Route path='/control' component={ControlView} />
          <Route path='/infostates' component={ListInfoStates} />
          <Route path='/decisions' component={ListDecisions} />
        </Switch>
      }
    />
  );
}

export default App;

