const initialState_Risk = {
    request_time: null,
    error: null,
    initiativeId: null,
    tshifts: [],
    value_tshifts: [],
    stats_request_time: null,
};

const sortByYearMonth = (a,b) => {
    if (a.year !== b.year) {
        return a.year - b.year;
      }
      return a.month - b.month;
}

const tshifts = (state = initialState_Risk, action) => {
    switch (action.type) {
        case 'SET_TSHIFTS':
            return {
                tshifts: action.payload.tshifts,
                request_time: action.request_time,
                initiativeId: action.payload.initiativeId,
            };
        case 'ADD_TSHIFT':
            return {
                tshifts: [...state.tshifts, action.payload].sort(sortByYearMonth),
                request_time: action.request_time,
                initiativeId: action.payload.initiativeId,
            };
        case 'SET_TSHIFT':
            return {
                tshifts: state.tshifts.map(tshift => {
                    if (tshift.id === action.payload.id) {
                        // update each if match
                        return {
                            ...tshift,
                            ...action.payload,
                        };
                    }
                    return tshift;
                }),
                request_time: action.payload.request_time,
                initiativeId: state.initiativeId,
            };
        case 'TSHIFT_ERROR':
            return {
                ...state,
                error: action.payload.error,
                request_time: null,
                // tshifts: [],
                // value_tshifts: [],
                stats_request_time: null,
            };
        case "DELETE_TSHIFT":
            return {
                ...state,
                tshifts: state.tshifts.filter(tshift => tshift.id !== action.payload.id),
                request_time: action.request_time,
            };
        case 'SET_VALUE_TSHIFTS_STATS':
            return {
                ...state,
                value_tshifts: action.payload.tshifts,
                stats_request_time: action.request_time,
            };
        default:
            return state;
    }
}

export default tshifts;