import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { deleteAreaById } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { Button, Card } from "reactstrap";
import DeleteModal from "../../components/Modal/DeleteModal";

const DeleteArea = ({ dispatch, area }) => {
  let [modalAreas, setModalAreas] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalAreas(false);
  });



  const handleSubmitAreas = () => {
    dispatch(deleteAreaById(area.id));
    setModalAreas(false);
  };


  return (
    <div>
      <DeleteModal
      buttonText={language["areas"]["form"]["delete"]}
      confirmText={language["areas"]["form"]["deleteConfirm"]}
      confirm={handleSubmitAreas}
    />
    </div>
  );
};

export default connect()(DeleteArea);
