import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { deleteChecklistById } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { Button , Card} from "reactstrap";
import DeleteModal from "../../components/Modal/DeleteModal";

const DeleteChecklist = ({ dispatch, checklist }) => {
  let [modalChecklists, setModalChecklists] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalChecklists(false);
  });

  const deleteChecklistButton = () => {
    if (modalChecklists) {
      return <></>;
    }
    return (
      <Button
        color='danger'
        className='btn-sm'
        onClick={() => setModalChecklists(true)}
      >
        {language["checklists"]["form"]["delete"]}
      </Button>
    );
  };

  const handleSubmitChecklists = () => {
    dispatch(deleteChecklistById(checklist.id));
    setModalChecklists(false);
  };

  const renderModalFormChecklists = () => {
    if (modalChecklists) {
      return (
        <div  ref={wrapperRef}>
          <Card className="p-3">
          
          <div>
            <Button
              color='danger'
              className='btn-sm'
              onClick={() => handleSubmitChecklists()}
            >
              {language["checklists"]["form"]["deleteConfirm"]}
            </Button>
          </div>
          <div>
            <Button
              color='warning'
              className='btn-sm'
              onClick={() => setModalChecklists(false)}
            >
              {language["general"]["cancel"]}
            </Button>
          </div>
          </Card>
        </div>
      );
    }
  };

  return (
    <DeleteModal
      buttonText={language["checklists"]["form"]["delete"]}
      confirmText={language["checklists"]["form"]["deleteConfirm"]}
      confirm={handleSubmitChecklists}
    />
  );
};

export default connect()(DeleteChecklist);
