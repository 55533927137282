import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { deleteTaskById } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { Button } from "reactstrap";
import DeleteModal from "../../components/Modal/DeleteModal";

const DeleteTask = ({ dispatch, task }) => {
  let [modalTasks, setModalTasks] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalTasks(false);
  });

  const deleteTaskButton = () => {
    if (modalTasks) {
      return <></>;
    }
    return (
      <Button
        color='danger'
        className='btn-sm'
        onClick={() => setModalTasks(true)}
      >
        {language["tasks"]["form"]["delete"]}
      </Button>
    );
  };

  const handleSubmitTasks = () => {


    
    dispatch(deleteTaskById(task));
    setModalTasks(false);
  };

  const renderModalFormTasks = () => {
    if (modalTasks) {
      return (
        <div className='form' ref={wrapperRef}>
          <div>
            <Button
              color='warning'
              className='btn-sm'
              onClick={() => setModalTasks(false)}
            >
              {language["general"]["cancel"]}
            </Button>
          </div>
          <div>
            <Button
              color='danger'
              className='btn-sm'
              onClick={() => handleSubmitTasks()}
            >
              {language["tasks"]["form"]["deleteConfirm"]}
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <DeleteModal
      confirmText={language["tasks"]["form"]["deleteConfirm"]}
      buttonText={language["tasks"]["form"]["delete"]}
      confirm={handleSubmitTasks}
    />
  );
};

export default connect()(DeleteTask);
