import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";

export const setGetDecisions = (data) => ({
  type: "SET_GET_DECISIONS",
  payload: data,
  request_time: Date.now(),
});

export const setUpdateDecisions = (data) => ({
  type: "SET_UPDATE_DECISIONS",
  payload: data,
  request_time: Date.now(),
});

export const setCreateDecisions = (data) => ({
  type: "SET_CREATE_DECISIONS",
  payload: data,
  request_time: Date.now(),
});
export const setDeleteDecisions = (data) => ({
  type: "SET_DELETE_DECISIONS",
  payload: data,
  request_time: Date.now(),
});

export const getDecisions = () => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (!getUserPermission(state, "initypes", "list", true)) {
        throw new Error("No permissions");
      }
      if (
        state.initypes.request_time === null ||
        state.initypes.request_time + 1000 * 2 < Date.now()
      ) {
        fetch(`${BACKEND_URL}/decisions`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.sessions.token}`,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              throw new Error(response.status);
            }
          })
          .then((data) => {
            dispatch(setGetDecisions(data));
          })
          .catch((error) => {
            errorHandler("initypes", error.message);
            dispatch(setGetDecisions(error));
          });
      }
    } catch (error) {
      errorHandler("initypes", error.message);
      dispatch(setGetDecisions(error));
    }
  };
};

export const createDecisions = (data) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (!getUserPermission(state, "initypes", "create")) {
        throw new Error("No permissions");
      }
      fetch(`${BACKEND_URL}/decisions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.sessions.token}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 201) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          if (data != null && data !== undefined) {
            dispatch(setCreateDecisions(data));
          }
        })
        .catch((error) => {
          errorHandler("decisions", error.message);
          dispatch(setCreateDecisions(error));
        });
    } catch (error) {
      errorHandler("decisions", error.message);
      dispatch(setCreateDecisions(error));
    }
  };
};

export const updateDecision = (data) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (!getUserPermission(state, "initypes", "create")) {
        throw new Error("No permissions");
      }
      fetch(`${BACKEND_URL}/decisions/${data.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.sessions.token}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          dispatch(setUpdateDecisions(data));
        })
        .catch((error) => {
          errorHandler("decisions", error.message);
          dispatch(setUpdateDecisions(error));
        });
    } catch (error) {
      errorHandler("decisions", error.message);
      dispatch(setUpdateDecisions(error));
    }
  };
};

export const deleteDecisionById = (id) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      fetch(`${BACKEND_URL}/decisions/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.sessions.token}`,
        },
      })
        .then((response) => {
          if (response.status === 204) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          dispatch(setDeleteDecisions(data));
        })
        .catch((error) => {
          errorHandler("decisions", error.message);
          dispatch(setDeleteDecisions(error));
        });
    } catch (error) {
      console.log(error);
      errorHandler("decisions", error.message);
      dispatch(setDeleteDecisions(error));
    }
  };
};
