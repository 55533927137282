import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { updateKPISecond } from "../../redux/actions";
import { language } from "../../assets/language";
import {
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  Form,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import ReactDatePicker from "react-datepicker";
import { validateInputNumber } from "../../utils/validateInputNumber";
import ConfirmModal from "../../components/Modal/ConfirmModal";
import { FaEdit } from "react-icons/fa";
const EditKpiSecond = ({ dispatch, kpi }) => {
  let [modalKPIs, toggleModal] = useState(false);
  const [description, setdescription] = useState(kpi.description);
  const [initial_value, setinitial_value] = useState(kpi.initial_value);
  const [units, setunits] = useState(kpi.units);
  const [objetive_value, setobjetive_value] = useState(kpi.objetive_value);
  const [memory, setmemory] = useState(kpi.memory);
  const [source, setsource] = useState(kpi.source);
  const [initial_date, setinitial_date] = useState(
    new Date(kpi.initial_date)
  );
  const [forecast_date, setforecast_date] = useState(
    new Date(kpi.forecast_date)
  );




  const handleSubmitKPis = () => {

    var object = { 
      id: kpi.id,
      description: description,
      initial_value: initial_value,
      units: units,
      objetive_value: objetive_value,
      memory: memory,
      source: source,
      initial_date: initial_date,
      forecast_date: forecast_date,
      
    };
   
    dispatch(updateKPISecond(object));
    toggleModal(false);
  };

  
  return (
    <div>
      <Button
        color='primary'
        className='btn-sm  mb-2'
      
        onClick={() => toggleModal(true)}
      >
      <FaEdit />
      </Button>
      <Modal
        className='modal-dialog-centered'
        isOpen={modalKPIs}
       
      >
        <div className='modal-body p-0'>
          <Card className='bg-secondary shadow border-0'>
            <CardBody className='px-lg-5 py-lg-2'>
              <h2 className='text-muted text-center mt-2 mb-3'>
                {language["kpiseconds"]["form"]["edit"]}
              </h2>
              <Form onSubmit={(e)=>handleSubmitKPis(e)} role='form'>
                <FormGroup>
                  <label>
                    {language["kpiseconds"]["table"]["description"]}
                  </label>
                  <Input
                    className='form-control-sm'
                    maxLength={254}
                    type='text'
                    autoComplete='off'
                    name='description'
                    value={description}
                    onChange={(e) => setdescription(e.target.value)}
                  />
                </FormGroup>
                {/*<FormGroup>
                  <label>
                    {language["kpiseconds"]["table"]["tree"]}
                  </label>
                  <Input
                    className='form-control-sm'
                    maxLength={254}
                    type='select'
                    autoComplete='off'
                    name='value'
                    defaultValue={tree ? tree.name: ""}
                    onChange={(e) => settree(e.target.value)}

                  >
                    {trees.map((tree) => (
                      <option key={tree.name} value={tree.id}>
                        {tree.name}
                      </option>
                    ))}
                  </Input>
                    </FormGroup>*/}
                <FormGroup>
                  <label>{language["kpiseconds"]["table"]["units"]}</label>
                  <Input
                    className='form-control-sm'
                    maxLength={254}
                    type='text'
                    name='units'
                    value={units}
                    onChange={(e) => setunits(e.target.value)}
                  />
                </FormGroup>
                <Row>
                  <Col md={6} >
                <FormGroup>
                  <label>
                    {language["kpiseconds"]["table"]["initial_value"]}
                  </label>
                  <Input
                    className='form-control-sm'
                    maxLength={254}
                    type='number'
                    name='initial_value'
                    value={initial_value}
                    onChange={(e) => !validateInputNumber(e.target.value ) ?  setinitial_value(e.target.value): initial_value}
                  />
                </FormGroup>
                
                </Col>
                <Col md={6} >
                <FormGroup>
                  <label>
                    {language["kpiseconds"]["table"]["initial_date"]}
                  </label>
                  <ReactDatePicker 
                        type="date" 
                        id="initial_date" 
                        dateFormat={"MM-yyyy"}
                        showMonthYearPicker
                        selected={initial_date}
                        o onChange={(e) => {
                          if (e > initial_date) {
                            setforecast_date(e);
                          }
                          setinitial_date(e);
                        }}
                        className='form-control form-control-sm'
                       />
                </FormGroup>
               
                </Col>
                </Row>
                <Row>
                  <Col md={6} >
                <FormGroup>
                  <label>
                    {language["kpiseconds"]["table"]["objetive_value"]}
                  </label>
                  <Input
                    className='form-control-sm'
                    type='number'
                    name='objetive_value'
                    value={objetive_value}
                    onChange={(e) => !validateInputNumber(e.target.value ) ?  setobjetive_value(e.target.value): objetive_value}
                  />
                </FormGroup>
                </Col>
                <Col md={6} >
                <FormGroup>
                  <label>
                    {language["kpiseconds"]["table"]["forecast_date"]}
                  </label>
                  <ReactDatePicker 
                        type="date" 
                        id="forecast_date" 
                        dateFormat={"MM-yyyy"}
                        showMonthYearPicker
                        selected={forecast_date}
                        onChange={(e) => {
                          if (e < initial_date) {
                            setinitial_date(e);
                          }
                          setforecast_date(e);
                        }}
                        className='form-control form-control-sm'
                       />
                 
                </FormGroup>
                </Col>
                </Row>
                <FormGroup>
                  <label>{language["kpiseconds"]["table"]["memory"]}</label>
                  <Input
                    className='form-control-sm'
                    type='text'
                    maxLength={254}
                    name='memory'
                    value={memory}
                    onChange={(e) => setmemory(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <label>{language["kpiseconds"]["table"]["source"]}</label>
                  <Input
                    className='form-control-sm'
                    maxLength={254}
                    type='text'
                    name='source'
                    value={source}
                    onChange={(e) => setsource(e.target.value)}
                  />
                </FormGroup>

                <FormGroup>
                  
                  <ConfirmModal
                   confirmText={language["kpiseconds"]["form"]["editConfirm"]}
                   confirm={handleSubmitKPis}
                   buttonText={language["kpiseconds"]["form"]["edit"]}
                 />

                  <Button color='danger' className='btn-sm' block onClick={e=>toggleModal(false)} >
                    {language["kpiseconds"]["form"]["close"]}
                  </Button>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default connect()(EditKpiSecond);
