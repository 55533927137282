import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { patchConfig } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { Button, Card, CardBody, Modal, Input, Form } from "reactstrap";

const EditConfig = ({ dispatch, config }) => {
  let [modalConfigs, setModalConfigs] = useState(false);

  let [loadedConfig, setLoadedConfig] = useState(true);

  let [_value, setValue] = useState("");

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalConfigs(false);
  });

  useEffect(() => {
    if (loadedConfig) {
      setLoadedConfig(false);
      setValue(config.value);
    }
  }, [config, loadedConfig]);

  const handleSubmitConfigs = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    dispatch(patchConfig(config.id, object));
    setModalConfigs(false);
  };

  const renderModalFormConfigs = () => {
    return (
      <>
        <Button
          color='warning'
          className='btn-sm'
          onClick={() => setModalConfigs(true)}
          disabled={config.name === "month_number" || config.name === "initial_date"}
        >
          {language["configs"]["form"]["edit"]}
        </Button>
        <Modal
          className='modal-dialog-centered'
          isOpen={modalConfigs}
          toggle={() => setModalConfigs(false)}
        >
          <div className='modal-body p-0'>
            <Card className='bg-secondary shadow border-0' ref={wrapperRef}>
              <CardBody className='px-lg-5 py-lg-2'>
                <h2>{language["configs"]["form"]["create"]}</h2>
                <Form onSubmit={handleSubmitConfigs}>
                  <div>
                    <label htmlFor='value'>
                      {language["configs"]["table"]["value"]}
                    </label>
                    <Input
                      maxLength={254}
                      className='form-control-sm'
                      type='text'
                      name='value'
                      required
                      multiple={true}
                      value={_value}
                      onChange={(e) => setValue(e.target.value)}
                    />
                  </div>
                  <br />
                  <div>
                    <Button color='success' className='btn-sm' type='submit'>
                      {language["configs"]["form"]["save"]}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </>
    );
  };

  return <div>{renderModalFormConfigs()}</div>;
};

export default connect()(EditConfig);
