import React from "react";
import { connect } from "react-redux";
import { sendActiveApprovalsEmails } from "../../redux/actions";
import { language } from "../../assets/language";
import { Button } from "reactstrap";
const SentActiveEmailsButton = ({ dispatch, initiative }) => {
  return (
    <div>
      <Button
        color='warning'
        className='btn-sm float-right  mr-1'
        onClick={() => {
          dispatch(sendActiveApprovalsEmails(initiative.id));
        }}
      >
        {language.approvals.emails.send_active}
      </Button>
    </div>
  );
};

export default connect()(SentActiveEmailsButton);
