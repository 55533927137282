const initialState_Task = {
    tasks: [],
    initiative_tasks: [],
    initiative_id: null,
    request_time: null,
    initiative_request_time: null,
    error: null,
    loading: false,
    loading_time: null
};

const tasks = (state = initialState_Task, action) => {
    switch (action.type) {
        case 'SET_TASKS':
            return {
                ...state,
                tasks: action.payload.tasks,
                request_time: action.request_time,
            };
        case 'SET_INITIATIVE_TASKS':
            return {
                ...state,
                initiative_tasks: action.payload.tasks,
                initiative_id: action.payload.initiative_id,
                initiative_request_time: action.request_time,
            };
        case "NEW_TASK":
            return {
                ...state,
                tasks: [...state.tasks, action.payload],
                initiative_tasks: [...state.initiative_tasks, action.payload],
                request_time: action.request_time,
                initiative_request_time: action.request_time,
            };
        case "UPDATE_TASK":
            let new_tasks = state.tasks;
            try {
                new_tasks = state.tasks.map(task => {
                    if (task.id === action.payload.id) {
                        return action.payload;
                    }
                    return task;
                });
            } catch (e) {
            }
            let new_initiative_tasks = state.initiative_tasks;
            try {
                new_initiative_tasks = state.initiative_tasks.map(task => {
                    if (task.id === action.payload.id) {
                        return action.payload;
                    }
                    return task;
                });
            } catch (e) {
            }
            return {
                ...state,
                tasks: new_tasks,
                initiative_tasks: new_initiative_tasks,
                request_time: action.request_time,
                initiative_request_time: action.request_time,
            };
        case "DELETE_TASK":
            return {
                ...state,
                tasks: state.tasks.filter(task => task.id !== action.payload.id),
                initiative_tasks: state.initiative_tasks.filter(task => task.id !== action.payload.id),
                request_time: action.request_time,
                initiative_request_time: action.request_time,
            };
        case "TASK_ERROR":
            return {
                ...state,
                // tasks: [],
                // initiative_tasks: [],
                request_time: null,
                error: action.payload.error,
                loading: false,
                loading_time: null
            };
        case "SET_TASKS_LOADING":
            return {
                ...state,
                loading: action.payload.loading,
                loading_time: action.request_time
            }
        default:
            return state;
    }
}

export default tasks;