import { toast } from "react-toastify";
import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";

export const setKPISeconds = (kpi_seconds, initiativeId) => ({
  type: "SET_KPI_SECONDS",
  payload: {
    kpi_seconds: kpi_seconds,
    initiativeId: initiativeId,
  },
  request_time: Date.now(),
});

export const setNewKPISecond = (data) => ({
  type: "SET_NEW_KPI_SECOND",
  payload: {
    id: data.id,
    initiative_id: data.initiative_id,
    description: data.description,
    units: data.units,
    initial_value: data.initial_value,
    objetive_value: data.objetive_value,
    forecast_value: data.forecast_value,
    initial_date: data.initial_date,
    forecast_date: data.forecast_date,
    objetive_date: data.objetive_date,
    memory: data.memory || "",
    source: data.source,
    tree_id: data.tree_id || null,
    request_time: Date.now(),
  },
});

export const setKPISecond = (data) => ({
  type: "SET_KPI_SECOND",
  payload: {
    id: data.id,
    initiative_id: data.initiative_id,
    description: data.description,
    units: data.units,
    initial_value: data.initial_value,
    objetive_value: data.objetive_value,
    forecast_value: data.forecast_value,
    initial_date: data.initial_date,
    forecast_date: data.forecast_date,
    objetive_date: data.objetive_date,
    memory: data.memory || "",
    source: data.source,
    tree_id: data.tree_id || null,
    request_time: Date.now(),
  },
});




export const setKpiSecondError = (error) => ({
  type: "KPI_SECOND_ERROR",
  payload: {
    error: error,
  },
});

export const deleteKpiSecond = (kpi_id) => ({
  type: "DELETE_KPI_SECOND",
  payload: {
    id: kpi_id,
  },
  request_time: Date.now(),
});

export const setUpdateKpiSecond = (kpi) => ({
  type: "UPDATE_KPI_SECOND",
  payload: {
    data: kpi,
    request_time: Date.now(),
  },
});

export const getInitiativeKpis = (initiativeId) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (!getUserPermission(state, "kpiseconds", "list")) {
        throw new Error("No permissions");
      }
      if (
        state.kpi_seconds.request_time + 1000 * 2 < Date.now() ||
        state.kpi_seconds.request_time === null ||
        state.kpi_seconds.initiativeId !== initiativeId
      ) {
        console.log("fetching kpis");
        fetch(`${BACKEND_URL}/initiatives/${initiativeId}/kpiseconds`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.sessions.token}`,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              throw new Error(response.status);
            }
          })
          .then((data) => {
            dispatch(setKPISeconds(data, initiativeId));
          })
          .catch((error) => {
            errorHandler("kpiseconds", error.message);
            dispatch(setKpiSecondError(error));
          });
      }
    } catch (error) {
      errorHandler("kpiseconds", error.message);
      dispatch(setKpiSecondError(error));
    }
  };
};


export const getKpiSecondById = (kpiId) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (!getUserPermission(state, "kpiseconds", "list")) {
        throw new Error("No permissions");
      }
      if (
        state.kpi_seconds.request_time + 1000 * 2 < Date.now() ||
        state.kpi_seconds.request_time === null ||
        state.kpi_seconds.kpiId !== kpiId
      ) {
        fetch(`${BACKEND_URL}/kpiseconds/${kpiId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.sessions.token}`,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              throw new Error(response.status);
            }
          })
          .then((data) => {
            dispatch(setKPISecond(data));
            
          })
          .catch((error) => {
            errorHandler("kpiseconds", error.message);
            dispatch(setKpiSecondError(error));
          });
      }
    } catch (error) {
      errorHandler("kpiseconds", error.message);
      dispatch(setKpiSecondError(error));
    }
  };
};

export const postKPISecond = (data) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      // if (!getUserPermission(state, "kpiseconds", "create", true)) {
      // throw new Error("No permissions");
      // }
      if (
        state.initiatives.initiative.leader_user === state.sessions.user.id ||
        state.initiatives.initiative.sponsor_user === state.sessions.user.id ||
        state.sessions.user.role === "admin" ||
        getUserPermission(state, "kpiseconds", "create", true)
      ) {
        fetch(`${BACKEND_URL}/kpiseconds`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.sessions.token}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            if (response.status === 201) {
              return response.json();
            } else {
              throw new Error(response.status);
            }
          })
          .then((data) => {
            if (data != null && data !== undefined && data.id !== undefined) {
              dispatch(setNewKPISecond(data));
              toast.success("KPI created");
            }
          })
          .catch((error) => {
            errorHandler("kpiseconds", error.message);
            dispatch(setKpiSecondError(error));
          });
      }
    } catch (error) {
      errorHandler("kpiseconds", error.message);
      dispatch(setKpiSecondError(error));
    }
  };
};

export const deleteKpiSecondById = (kpisecond_id) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (
        getUserPermission(state, "kpiseconds", "delete", true) ||
        state.initiatives.initiative.leader_user === state.sessions.user.id
      ) {
        fetch(`${BACKEND_URL}/kpiseconds/${kpisecond_id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.sessions.token}`,
          },
        })
          .then((response) => {
            if (response.status === 204) {
              return true;
            } else {
              throw new Error(response.status);
            }
          })
          .then((vl) => {
            if (vl) {
              dispatch(deleteKpiSecond(kpisecond_id));
              toast.success("KPI deleted");
            }
          })
          .catch((error) => {
            errorHandler("kpiseconds", error.message);
            dispatch(setKpiSecondError(error));
          });
      }
    } catch (error) {
      errorHandler("kpiseconds", error.message);
      dispatch(setKpiSecondError(error));
    }
  };
};

export const updateKPISecond = (data) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
        fetch(`${BACKEND_URL}/kpiseconds/${data.id}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.sessions.token}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              throw new Error(response.status);
            }
          })
          .then((vl) => {
            if (vl) {
              dispatch(setUpdateKpiSecond(vl));
              toast.success("KPI kshift updated");
            }
          })
          .catch((error) => {
            errorHandler("kpiseconds", error.message);
            dispatch(setKpiSecondError(error));
          });

    } catch (error) {
      errorHandler("kpiseconds", error.message);
      dispatch(setKpiSecondError(error));
    }
  };
};

export const updateKPIKshift = (data) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
        fetch(`${BACKEND_URL}/kpiseconds/${data.id}/kshifts`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.sessions.token}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              throw new Error(response.status);
            }
          })
          .then((vl) => {
            if (vl) {
              dispatch(setUpdateKpiSecond(vl));
              toast.success("KPI kshift updated");
            }
          })
          .catch((error) => {
            errorHandler("kpiseconds", error.message);
            dispatch(setKpiSecondError(error));
          });

    } catch (error) {
      errorHandler("kpiseconds", error.message);
      dispatch(setKpiSecondError(error));
    }
  };
};


export const copyKshiftPromise = (id) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
        fetch(`${BACKEND_URL}/kpiseconds/${id}/kshifts/copy`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.sessions.token}`,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              throw new Error(response.status);
            }
          })
          .then((vl) => {
            if (vl) {
              dispatch(setUpdateKpiSecond(vl));
              toast.success("KPI kshift copied");
            }
          })
          .catch((error) => {
            errorHandler("kpiseconds", error.message);
            dispatch(setKpiSecondError(error));
          });

    } catch (error) {
      errorHandler("kpiseconds", error.message);
      dispatch(setKpiSecondError(error));
    }
  };
};
