// list component for trees with redux and react
// action to get trees is called getAllTrees in ./../../actions/trees.js
// tree has a id, name, a description and a parent_id
// parent_id is the id of the parent tree

import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { getAllTrees, getTreesByInitiative } from "../../redux/actions";
import { language } from "../../assets/language";
import { EditTree, DeleteTree } from ".";
import SimpleTable from "../../components/tables/SimpleTable";
import { getUserPermission } from "../../utils/getUserPermission";

import { Button } from "reactstrap";
import Loading from "../../components/Loading/Loading";
import { getResponsabilityPermission } from "../../utils/getResponsabilityPermission";
import { Link } from "react-router-dom/cjs/react-router-dom";
const ShowStandarTree = ({
  dispatch,
  treeClickOpen,
  treeClickClose,
  withCreate = false,
  initiative = null,
}) => {
  const treesResponse = useSelector((state) => state.trees.trees);
  const currentUser = useSelector((state) => state.sessions.user);
  const state = useSelector((state) => state);
  const [selectedId, setSelectedId] = useState(null);
  const [trees, setTrees] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (initiative !== null && initiative !== undefined) {
      dispatch(getTreesByInitiative(initiative.id));
    } else {
      dispatch(getAllTrees());
    }
    setLoading(true);
  }, []);

  
  useEffect(() => {
    if (treesResponse) {
      let treeFatherSorted = treesResponse.reverse().sort((a, b) => {
        if (a.parent_id === null) {
          return -1;
        } else if (b.parent_id === null) {
          return 1;
        } else {
          return a.children.length - b.children.length;
        }
      }); 
      setTrees(treeFatherSorted);
      setLoading(false);
    }

  }, [treesResponse]);



  const columns = useMemo(() => {
    let col = [
      {
        Header: language.trees.table.name,
        accessor: "name",
      },
      {
        Header: language.trees.table.abv,
        accessor: "abv",
      },
      {
        Header: language.trees.table.calculation,
        accessor: "calculation",
      },
      {
        Header: language.trees.table.parent,
        accessor: "parent",
      },
    ];
    if (
      withCreate || (
        (initiative !== null && initiative !== undefined)
        && (
          getResponsabilityPermission(state,"tshifts", "change",initiative.id,"Lider")
          || currentUser.role === "admin" 
          || getUserPermission(state, "trees", "patch", false)
        )
      )
    ) {
      col.push({
        Header: language.general.actions,
        accessor: "actions",
      });
    }
    return col;
  }, [withCreate, initiative, currentUser, state]);

  const openTree = (tree) => {
    treeClickOpen(tree);
    setSelectedId(tree.id);
  };

  const closeTree = () => {
    treeClickClose();
    setSelectedId(null);
  };

  // render trees in list with name, description, parent_id, tutorial
  const renderTrees = () => {
    let treesList = trees.map((tree) => {

      return {
        id: tree.id,
        name: language.trees.names[tree.name] || tree.name,
        abv: tree.abv,
        calculation: tree.calculation,
        parent: (tree.parent_id && trees.find((a) => a.id === tree.parent_id)?.name) || language["trees"]["no_parent"],
        actions: (
          withCreate || (
            (initiative !== null && initiative !== undefined)
            && (
              getResponsabilityPermission(state,"tshifts", "change",initiative.id,"Lider")
              || getUserPermission(state, "trees", "patch", false)
            )
          )
        ) ? (
          <div>
            {(withCreate) ? <EditTree tree={tree} /> : <></>}
            <Link to={`/trees/${tree.id}`}>
            <Button
              className='btn-sm mb-1'
              color='warning'
              block
              onClick={() =>
                selectedId === tree.id ? closeTree() : openTree(tree)
              }
            >
              {selectedId === tree.id
                ? language.trees.form.hide
                : language.trees.form.show
                }
            </Button>
            </Link>
            {(withCreate) ? <DeleteTree tree={tree} /> : <></>}
          </div>
        ) : (
          <Link to={`/trees/${tree.id}`}>
          <Button
            className='btn-sm mb-1'
            color='info'
            block
            onClick={() =>
              selectedId === tree.id ? closeTree() : openTree(tree)
            }
          >
            {selectedId === tree.id
              ? language.trees.form.hide
              : language.trees.form.show}
          </Button>
          </Link>
        ),
      };
    });

    return <SimpleTable data={treesList} columns={columns} />;
  };

  const representChild = (child) => {
    return (
      <li key={child.id}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a>{language.trees.names[child.name] || child.name}</a>
        {
          (child.children) ?
            <ul>
              {child.children.map(grandChild => {
                return representChild(grandChild);
              })}
            </ul>
            : null
        }
      </li>
    );
  }

  const renderTrees2 = () => {

    return (
      <div className="tree-left">
        <ul>
          {trees && trees.map(tree => {
            if (tree === trees[0]) {
              return (
                <li key={tree.id}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a>{language.trees.names[tree.name] || tree.name}</a>
                  {
                    (tree.children) ?
                      <ul>
                        {tree.children.map(child => {
                          return representChild(child);
                        })}
                      </ul>
                      :
                      null
                  }
                </li>
              );
            }
            return null;
          })}
        </ul>
      </div>
    );

  }

  return loading ? <Loading /> : (
    <div>
      {renderTrees2()}
      {renderTrees()}
    </div>
  );
};

export default connect()(ShowStandarTree);
