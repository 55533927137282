const initialState_Approval = {
    approvals: [],
    initiative_approvals: [],
    initiative_id: null,
    request_time: null,
    initiative_request_time: null,
    error: null
};

const approvals = (state = initialState_Approval, action) => {
    switch (action.type) {
        case 'SET_APPROVALS':
            return {
                ...state,
                approvals: action.payload.approvals,
                request_time: action.request_time,
            };
        case 'SET_INITIATIVE_APPROVALS':
            return {
                ...state,
                initiative_approvals: action.payload.approvals,
                initiative_id: action.payload.initiative_id,
                initiative_request_time: action.request_time,
            };
        case "NEW_APPROVAL":
            return {
                ...state,
                approvals: [...state.approvals, action.payload],
                initiative_approvals: [...state.initiative_approvals, action.payload],
                request_time: action.request_time,
                initiative_request_time: action.request_time,
            };
        case "UPDATE_APPROVAL":
            return {
                ...state,
                approvals: state.approvals.map(approval => {
                    if (approval.id === action.payload.id) {
                        return action.payload;
                    }
                    return approval;
                }),
                initiative_approvals: state.approvals.map(approval => {
                    if (approval.id === action.payload.id) {
                        return action.payload;
                    }
                    return approval;
                }),
                request_time: action.request_time,
                initiative_request_time: action.request_time,
            };
        case "DELETE_APPROVAL":
            return {
                ...state,
                approvals: state.approvals.filter(approval => approval.id !== action.payload.id),
                initiative_approvals: state.initiative_approvals.filter(approval => approval.id !== action.payload.id),
                request_time: action.request_time,
                initiative_request_time: action.request_time,
            };
        case "APPROVAL_ERROR":
            return {
                ...state,
                // approvals: [],
                // initiative_approvals: [],
                request_time: null,
                error: action.payload.error,
            };
        default:
            return state;
    }
}

export default approvals;