import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { deleteGroupById } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { Button } from "reactstrap";
import DeleteModal from "../../components/Modal/DeleteModal";

const DeleteGroup= ({ dispatch, group }) => {
  let [modalGroups, setModalGroups] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalGroups(false);
  });



  const handleSubmitGroups = () => {
    dispatch(deleteGroupById(group[0]));
    setModalGroups(false);
  };



  return (
    <DeleteModal
      confirm={handleSubmitGroups}
      confirmText={language["groups"]["form"]["deleteConfirm"]}
      buttonText={language["groups"]["form"]["delete"]}
    />
  );
};

export default connect()(DeleteGroup);
