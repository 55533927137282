const initialState_Config = {
    request_time: null,
    error: null,
    configs: {},
};

const configs = (state = initialState_Config, action) => {
    switch (action.type) {
        case 'SET_CONFIGS':
            return {
                configs: action.payload.configs.reduce((obj, item) => {
                    obj[item.name] = item;
                    return obj;
                }, {}),
                request_time: action.request_time,
            };
        case 'SET_CONFIG':
            try {
                return {
                    ...state,
                    configs: {
                        ...state.configs, [action.payload.name]: action.payload,
                    },
                    request_time: action.payload.request_time,
                };
            } catch (e) {
                console.log(e);
                return state;
            }
        case 'CONFIG_ERROR':
            return {
                ...state,
                error: action.payload.error,
                request_time: null,
                // configs: {},
            };
        case "DELETE_CONFIG":
            let new_configs = state.configs;
            try {
                new_configs = Object.keys(new_configs).reduce(function (filtered, key) {
                    if (new_configs[key].id !== action.payload.id) filtered[key] = new_configs[key];
                    return filtered;
                }, {});
            } catch (e) {
                console.log(e);
            }
            return {
                ...state,
                configs: new_configs,
                request_time: action.request_time,
            };
        default:
            return state;
    }
}

export default configs;