const initialState_CounterStat = {
    counter_stats: [],
    request_time: null,
    error: null,
    lambda: null,
    filter: null,
};

const counter_stats = (state = initialState_CounterStat, action) => {
    switch (action.type) {
        case 'SET_COUNTER_STATS':
            return {
                ...state,
                counter_stats: action.payload.counter_stats,
                request_time: action.request_time,
            };
        case "NEW_COUNTER_STAT":
            return {
                ...state,
                counter_stats: [action.payload, ...state.counter_stats],
                request_time: action.request_time,
            };
        case "UPDATE_COUNTER_STAT":
            return {
                  ...state,
                counter_stats: state.counter_stats.map(counter_stat => {
                    if (counter_stat.id === action.payload.id) {
                        return action.payload;
                    }
                    return counter_stat;
                }),
                request_time: action.request_time,
            };
        case "DELETE_COUNTER_STAT":
            return {
                ...state,
                counter_stats: state.counter_stats.filter(counter_stat => counter_stat.id !== action.payload.id),
                request_time: action.request_time,
            };
        case "COUNTER_STAT_ERROR":
            return {
                ...state,
                error: action.payload.error,
                // counter_stats: [],
                request_time: null,
            };
        case "LAMBDA_ERROR":
            return {
                ...state,
                error: action.payload.error,
                // counter_stats: [],
                request_time: null,
            };
        case 'SET_LAMBDA':
            return {
                ...state,
                lambda: action.payload.lambda,
                request_time: action.request_time,
            };
        case 'RETRIEVING_FILTER_VALUES':
            return {
                ...state,
                error: null
            };
        case 'UPDATING_FILTER_VALUES':
            return {
                ...state,
                error: null
            };
        case 'RETRIEVED_FILTER_VALUES':
            return {
                ...state,
                filter: action.payload,
                error: null,
                request_time: action.request_time,
            };
        case 'ERROR_FILTER_VALUES':
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case 'UPDATED_FILTER_VALUES':
            return {
                ...state,
                filter: action.payload,
                error: null,
                request_time: action.request_time,
            };
        default:
            return state;
    }
}

export default counter_stats;