import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { getTags } from "../../redux/actions";
import { language } from "../../assets/language";
import SimpleTable from "../../components/tables/SimpleTable";
import { getUserPermission } from "../../utils/getUserPermission";
import { Card, CardBody } from "reactstrap";
import CreateTags from "./CreateTags";
import EditTag from "./EditTag";
import DeleteTags from "./DeleteTags";
import Loading from "../../components/Loading/Loading";

const ListTags = ({ dispatch }) => {
  const tags = useSelector((state) => state.tags.tags);
  const createTags = useSelector((state) => state.tags.create);
  const updateTags = useSelector((state) => state.tags.update);
  const state = useSelector((state) => state);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    dispatch(getTags());
    setloading(true);
  }, []);

  useEffect(() => {
    dispatch(getTags());
    setloading(true);
  }, [createTags]);

  useEffect(() => {
    dispatch(getTags());
    setloading(true);
  }, [updateTags]);

  useEffect(() => {
    if (tags ) {
      setloading(false);
    }
    }, [tags]);

  const columns = useMemo(() => {
    let col = [
      {
        Header: language.initypes.table.name,
        accessor: "name",
      },
    ];
    if (getUserPermission(state, "initypes", "delete")) {
      col.push({
        Header: language.general.actions,
        accessor: "actions",
      });
    }
    return col;
  }, [state]);

  const renderTags = () => {
    if (!tags || tags.length === 0) {
      return (<div className="mt-4">
                <br />
                <p>{language["tags"]["not_found"]}</p>
              </div>);
    }

    let tagsList = Object.values(tags).map((tag) => {
      return {
        id: tag.id,
        name: tag.name,
        actions: (
          <span>
            <EditTag tag={tag} />
            <DeleteTags reload={getTags} tag={tag} />
          </span>
        ),
      };
    });

    return <SimpleTable columns={columns} data={tagsList} />;
  };

  return loading ? (
    <Loading />
  ) :  (
    <Card className='mt-3'>
      <CardBody>
        <h1 className='float-left'>{language.tags.title}</h1>
        <CreateTags reload={getTags} />
        {renderTags()}
      </CardBody>
    </Card>
  );
};

export default connect()(ListTags);
