import { toast } from 'react-toastify';
import { language } from '../assets/language';


export const check_calculation = (data, other_trees=[]) => {
    try {
        let calc = data.calculation;
        if (calc !== "" && calc !== null && calc !== undefined) {
            calc = calc.replace(/[+/\\*\-^]/g, ' ');
            calc = calc.split(' ');
            other_trees.forEach(tr => { 
                calc = calc.filter(c => c !== tr.abv);
            });
            calc = calc.join('');
            calc = calc.trim()
            if (calc !== "") {
                toast.error(language.tshifts.errors.wrong_calculation);
                return false;
            }
            return true;
        }
        else if (calc === "" ) {
            return true
        }
    } catch (e) {
        console.log(e);
        return false;
    }
}
