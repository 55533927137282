import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { getAllInfoStates } from '../../redux/actions';
import { language } from '../../assets/language';
import SimpleTable from '../../components/tables/SimpleTable';
import { CreateInfoState, DeleteInfoState } from '.';
import { getUserPermission } from '../../utils/getUserPermission';

const ListInfoStates = ({ dispatch }) => {

    const state = useSelector(state => state);
    const info_states = useSelector(state => state.info_states.info_states);

    useEffect(() => {
        dispatch(getAllInfoStates());
    }, [dispatch]);

    const columns = useMemo(
        () => {
            let col = [
                {
                    Header: language.info_states.table.name,
                    accessor: 'name',
                },
            ];
            if (getUserPermission(state, 'info_states', 'delete')) {
                col.push({
                    Header: language.general.actions,
                    accessor: 'actions',
                });
            }
            return col;
        },
        [state]
    )

    const renderInfoStates = () => {
        if (!info_states || info_states.length === 0) {
            return <p>{language["info_states"]["not_found"]}</p>
        }

        let info_statesList = Object.values(info_states).map((info_state) => {
            return {
                id: info_state.id,
                name: info_state.name,
                actions: <DeleteInfoState info_state={info_state} />
            }
        });

        return <SimpleTable columns={columns} data={info_statesList} />

    }

    return (
        <div>
            <h1>{language.info_states.title}</h1>
            <CreateInfoState />
            {renderInfoStates()}
        </div>
    );
}

export default connect()(ListInfoStates);