import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getCurrentUserTasks, getAllAreas } from "../../redux/actions";
import { language } from "../../assets/language";
import SimpleTable from "../../components/tables/SimpleTable";
import { AcceptTask } from ".";
import { Badge } from "reactstrap";

const ListMyTasks = ({ dispatch }) => {
  const tasks = useSelector((state) => state.tasks.tasks);
  const loadingTasks = useSelector((state) => state.tasks.loading);
  const user = useSelector((state) => state.sessions.user);
  const areas = useSelector((state) => state.areas.areas);
  const initiatives = useSelector((state) => state.initiatives.initiatives);
  const areas_dict = useMemo(() => {
    return areas.reduce((acc, area) => {
      acc[area.id] = area;
      return acc;
    }, {});
  }, [areas]);
  const phases = useSelector((state) => state.phases.phases);

  const [completedFilter, setCompletedFilter] = useState([false]);

  useEffect(() => {
    dispatch(getCurrentUserTasks());
    dispatch(getAllAreas());
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: language.tasks.table.initiative,
        accessor: "initiative",
      },
      {
        Header: language.tasks.table.description,
        accessor: "description",
      },
      {
        Header: language.tasks.table.area,
        accessor: "area",
      },
      {
        Header: language.tasks.table.created_at,
        accessor: "created_at",
      },
      {
        Header: language.tasks.table.forecast_date,
        accessor: "forecast_date",
      },
      {
        Header: language.tasks.table.estimated_date,
        accessor: "estimated_date",
      },
      {
        Header: language.tasks.table.stage,
        accessor: "stage",
      },
      {
        Header: language.tasks.table.status,
        accessor: "status",
      },
      {
        Header: language.tasks.table.completed,
        accessor: "completed",
      },
      {
        Header: language.tasks.form.edit,
        accessor: "edit",
      },
    ],
    []
  );

  const renderTasks = () => {
    if (loadingTasks) {
      return <p>{language.general.loading}</p>;
    }
    if (!tasks || tasks.length === 0) {
      return <p>{language["tasks"]["not_found"]}</p>;
    }

    let tasksList = tasks
      .filter((task) => {
        return completedFilter.includes(task.completed);
      })
      .filter((task) => task && task.responsable_user === user.id)
      .filter((task) => {
        if (initiatives) {
          let ini = initiatives.find(
            (initiative) => initiative.id === task.initiative_id
          );
          if (ini) {
            return ini.is_deleted !== true;
          }
        }
        return true;
      })
      .map((task) => {
        return {
          id: task.id,
          initiative: (
            <Link to={`/initiative/${task.initiative_id}`}>
              {task.initiative !== null && task.initiative !== undefined
                ? task.initiative.title
                : "-"}
            </Link>
          ),
          description: task.description,
          area:
            areas_dict &&
            task["area_id"] &&
            areas_dict.hasOwnProperty(task["area_id"])
              ? areas_dict[task["area_id"]].name
              : "",
          stage: phases.hasOwnProperty(task.stage)
            ? phases[task.stage].name
            : task.stage,
          created_at:
            task.created_at.split("T")[0] || language["tasks"]["no_date"],
          forecast_date:
            task.forecast_date.split("T")[0] || language["tasks"]["no_date"],

          estimated_date: task.estimated_date
            ? task.estimated_date.split("T")[0] || language["tasks"]["no_date"]
            : language["tasks"]["no_date"],
          status:
            task.status === "complete" || task.status === "in_progress" ? (
              <Badge color='success'>
                {language["tasks"]["status"][task.status]}
              </Badge>
            ) : (
              <Badge color='danger'>
                {language["tasks"]["status"][task.status]}
              </Badge>
            ),
          completed: task.completed
            ? language["tasks"]["completed"]["yes"]
            : task.completed === false
            ? language["tasks"]["completed"]["no"]
            : language["tasks"]["completed"]["annulment"],
          edit: <AcceptTask task={task} />,
        };
      });

    return <SimpleTable columns={columns} data={tasksList} />;
  };
  const processCompletedFilter = (value) => {
    let _completed = completedFilter;
    if (completedFilter.includes(value)) {
      _completed = _completed.filter(function (f) {
        return f !== value;
      });
      setCompletedFilter(_completed);
    } else {
      _completed.push(value);
      setCompletedFilter([..._completed, value]);
    }
  };

  const renderCompletedFilter = () => {
    return (
      <div>
        <label className='mr-2'>
          {language["tasks"]["table"]["completed"]}:
        </label>
        <div className='custom-control custom-checkbox custom-control-inline'>
          <input
            type='checkbox'
            id='yes-checkbox-1'
            name='yes-checkbox-1'
            className='custom-control-input'
            checked={completedFilter.includes(true)}
            onChange={() => processCompletedFilter(true)}
          />
          <label className='custom-control-label' htmlFor='yes-checkbox-1'>
            {language["tasks"]["completed"]["yes"]}
          </label>
        </div>
        <div className='custom-control custom-checkbox custom-control-inline'>
          <input
            type='checkbox'
            id='no-checkbox'
            name='no-checkbox'
            className='custom-control-input'
            checked={completedFilter.includes(false)}
            onChange={() => processCompletedFilter(false)}
          />
          <label className='custom-control-label' htmlFor='no-checkbox'>
            {language["tasks"]["completed"]["no"]}
          </label>
        </div>
        <div className='custom-control custom-checkbox custom-control-inline'>
          <input
            type='checkbox'
            id='an-checkbox'
            name='an-checkbox'
            className='custom-control-input'
            checked={completedFilter.includes(null)}
            onChange={() => processCompletedFilter(null)}
          />
          <label className='custom-control-label' htmlFor='an-checkbox'>
            {language["tasks"]["completed"]["annulment"]}
          </label>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2>{language["tasks"]["my_title"]}</h2>
      {renderCompletedFilter()}
      {renderTasks()}
    </div>
  );
};

export default connect()(ListMyTasks);
