

import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";


export const setDocuments = (documents, initiativeId) => ({
  type: "SET_DOCUMENTS",
  payload: {
      documents: documents,
      initiativeId: initiativeId,
  },
  request_time: Date.now()
});

export const setDocument = (document) => ({
  type: "SET_DOCUMENT",
  payload: {
      id: document.id,
      name: document.name,
      url: document.url,
      initiative_id: document.initiative_id,
      request_time: Date.now()
  },
});

export const setDocumentError = (error) => ({
  type: "DOCUMENT_ERROR",
  payload: {
      error: error
  }
});

export const deleteDocument = (document_id) => ({
  type: "DELETE_DOCUMENT",
  payload: {
      id: document_id,
  },
  request_time: Date.now()
});

export const getInitiativeDocuments = (initiativeId) => {
  return (dispatch, getState) => {
    console.log("Getting documents...");
    const state = getState();
    try {
        // if (!getUserPermission(state, "documents", "list", true)) {
        //     throw new Error("No permissions");
        // }
        // if (state.documents.initiativeId === null || state.documents.initiativeId !== initiativeId) {
            fetch(`${BACKEND_URL}/initiatives/${initiativeId}/documents/`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    console.log("Got documents: ", data);
                    dispatch(setDocuments(data.documents, initiativeId));
                })
                .catch((error) => {
                    errorHandler("documents", error.message);
                    dispatch(setDocumentError(error));
                });
        
    } catch (error) {
        errorHandler("documents", error.message);
        dispatch(setDocumentError(error));
    }
};
}


// Action creator for posting a new document
export const postDocument = (initiativeId, data) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      // Checking permission for posting a document
      if ( state.sessions.user.role === 'admin'
        || getUserPermission(state, "documents", "create", true)) {
        fetch(`${BACKEND_URL}/initiatives/${initiativeId}/documents/`, {
          method: "POST",
          headers: {
            "Authorization": `Bearer ${state.sessions.token}`
          },
          body: data
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("Document posted");
            console.log(response);
            dispatch(getInitiativeDocuments(initiativeId));
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          if (data && data.id !== undefined) {
            dispatch(setDocument(data)); // Dispatch action to store document data
          }
        })
        .catch((error) => {
          errorHandler("documents", error.message); // Handle error
          dispatch(setDocumentError(error)); // Dispatch error action
        });
      }
    } catch (error) {
      errorHandler("documents", error.message);
      dispatch(setDocumentError(error));
    }
  };
}

export const editDocument = (initiativeId, document_id, data) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      // Checking permission for editing a document
      if (state.sessions.user.role === 'admin' || getUserPermission(state, "documents", "edit", true)) {
        fetch(`${BACKEND_URL}/initiatives/${initiativeId}/documents/${document_id}`, {
          method: "POST",
          headers: {
            "Authorization": `Bearer ${state.sessions.token}`
          },
          body: data
        })
          .then((response) => {
              if (response.status === 200) {
                  dispatch(getInitiativeDocuments(initiativeId));
                  return response.json();
              } else {
                  throw new Error(response.status);
              }
          })
          // .then((data) => {
          //     if (data && data.id !== undefined) {
          //         dispatch(setDocument(data)); // Dispatch action to store document data
          //     }
          // })
          .catch((error) => {
              errorHandler("documents", error.message); // Handle error
              dispatch(setDocumentError(error)); // Dispatch error action
          });
      }
    } catch (error) {
      errorHandler("documents", error.message);
      dispatch(setDocumentError(error));
    }
  };
}

// Action creator for deleting a document by ID
export const deleteDocumentById = (initiativeId, document_id) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      // Checking permission for deleting a document
      if (state.sessions.user.role === 'admin' || getUserPermission(state, "documents", "delete", true)) {
        fetch(`${BACKEND_URL}/initiatives/${initiativeId}/documents/${document_id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${state.sessions.token}`
          },
        })
          .then((response) => {
              if (response.status === 200) {
                  console.log("Document deleted");
                  return true;
              } else {
                  throw new Error(response.status);
              }
          })
          .then((vl) => {
              if (vl) {
                  dispatch(deleteDocument(document_id)); // Dispatch action to remove document from state
                  dispatch(getInitiativeDocuments(initiativeId));
              }
          })
          .catch((error) => {
              errorHandler("documents", error.message); // Handle error
              dispatch(setDocumentError(error)); // Dispatch error action
          });
      }
    } catch (error) {
      errorHandler("documents", error.message);
      dispatch(setDocumentError(error));
    }
  };
}

