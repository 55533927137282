import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { deleteInfoStateById } from '../../redux/actions';
import { language } from '../../assets/language';
import { useClickAway } from 'react-use';

const DeleteInfoState = ({ dispatch, info_state }) => {

    let [modalInfoStates, setModalInfoStates] = useState(false);

    const wrapperRef = useRef(null);

    useClickAway(wrapperRef, () => {
        setModalInfoStates(false);
    });

    const deleteInfoStateButton = () => {
        if (modalInfoStates) {
            return (<></>);
        }
        return (
            <button onClick={() => setModalInfoStates(true)}>{language["info_states"]["form"]["delete"]}</button>
        )
    }

    const handleSubmitInfoStates = () => {
        dispatch(deleteInfoStateById(info_state.id));
        setModalInfoStates(false);
    }

    const renderModalFormInfoStates = () => {
        if (modalInfoStates) {
            return (
                <div className="form" ref={wrapperRef}>
                    <div>
                        <button onClick={() => setModalInfoStates(false)}>{language["general"]["cancel"]}</button>
                    </div>
                    <div>
                        <button onClick={() => handleSubmitInfoStates()}>{language["info_states"]["form"]["deleteConfirm"]}</button>
                    </div>
                </div>
            )
        }
    }


    return (
        <div>
            {deleteInfoStateButton()}
            {renderModalFormInfoStates()}
        </div>
    )

}

export default connect()(DeleteInfoState);