import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { rejectCardById } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { Button } from "reactstrap";
import DeleteModal from "../../components/Modal/DeleteModal";

const DeleteCard = ({ dispatch, card }) => {
  let [modalCards, setModalCards] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalCards(false);
  });

  const deleteCardButton = () => {
    if (modalCards) {
      return <></>;
    }
    return (
      <Button
        color='danger'
        className='btn-sm'
        onClick={() => setModalCards(true)}
      >
        {language["cards"]["form"]["delete"]}
      </Button>
    );
  };

  const handleSubmitCards = () => {
    dispatch(rejectCardById(card));
    setModalCards(false);
  };

  return (
    <DeleteModal
      confirm={handleSubmitCards}
      confirmText={language["cards"]["form"]["rejectConfirm"]}
      buttonText={language["cards"]["form"]["reject"]}
    />
  );
};

export default connect()(DeleteCard);
