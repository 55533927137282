import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";


export const setLevers = (levers) => ({
    type: "SET_LEVERS",
    payload: {
        levers: levers,
    },
    request_time: Date.now()
});

export const setLever = (data) => ({
    type: "SET_LEVER",
    payload: {
        id: data.id,
        name: data.name,
        request_time: Date.now()
    },
});

export const setLeverError = (error) => ({
    type: "LEVER_ERROR",
    payload: {
        error: error
    }
});

export const deleteLever = (lever_id) => ({
    type: "DELETE_LEVER",
    payload: {
        id: lever_id,
    },
    request_time: Date.now()
});

export const getAllLevers = () => {

    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "levers", "list")) {
                throw new Error("No permissions");
            }
            if (state.levers.request_time === null
                || state.levers.request_time + 1000 * 2 < Date.now()) {
                fetch(`${BACKEND_URL}/levers`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setLevers(data));
                    })
                    .catch((error) => {
                        errorHandler("levers", error.message);
                        dispatch(setLeverError(error));
                    });
            }
        } catch (error) {
            errorHandler("levers", error.message);
            dispatch(setLeverError(error));
        }
    };
}

export const postLever = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "levers", "create", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/levers`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
                body: JSON.stringify(data)
            })
                .then((response) => {
                    if (response.status === 201) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    if (data != null && data !== undefined && data.id !== undefined) {
                        dispatch(setLever(data));
                    }
                })
                .catch((error) => {
                    errorHandler("levers", error.message);
                    dispatch(setLeverError(error));
                });
        } catch (error) {
            errorHandler("levers", error.message);
            dispatch(setLeverError(error));
        }
    };
}

export const deleteLeverById = (lever_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "levers", "delete", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/levers/${lever_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
                .then((response) => {
                    if (response.status === 204) {
                        return true;
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((vl) => {
                    if (vl) {
                        dispatch(deleteLever(lever_id));
                    }
                })
                .catch((error) => {
                    errorHandler("levers", error.message);
                    dispatch(setLeverError(error));
                });
        } catch (error) {
            errorHandler("levers", error.message);
            dispatch(setLeverError(error));
        }
    };
}