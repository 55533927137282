import { BACKEND_URL } from "../../utils/config";
import { getUserPermission } from "../../utils/getUserPermission";
import { getInitiativeChecklists, patchInitiativeChecklists } from "./initiative_checklists";
import { getInitiativeApprovals, patchInitiativeApprovals } from "./approvals";
import { getInitiativeActivities } from "./activities";
import { getInitiativeKpis } from "./kpi_seconds";
import { setAdvance } from "./advances";
import { getInitiativeRisks } from "./risks";
import { exportMultipleSheets } from "../../utils/exportMultipleSheets";
import { language } from '../../assets/language';
import { errorHandler } from "../../utils/error_handler";
import { toast } from 'react-toastify';
import history from './../../history';
import { getResponsabilityPermission } from "../../utils/getResponsabilityPermission";


export const newInitiative = (data) => ({
    type: "NEW_INITIATIVE",
    payload: {
        id: data.id,
        title: data.title,
        code: data.code,
        area_id: data.area_id,
    },
    request_time: Date.now()
});

export const setInitiative = (data) => ({
    type: "SET_INITIATIVE",
    payload: {
        id: data.id,
        title: data.title,
        code: data.code,
        area_id: data.area_id,
        forecast_date: data.forecast_date,
        stage: data.stage,
        status: data.status,
        leader_user: data.leader_user,
        sponsor_user: data.sponsor_user,
        initype_id: data.initype_id,
        lever_id: data.lever_id,
        card_id: data.card_id,
        ola: data.ola,
        description_sit: data.description_sit,
        description_opt: data.description_opt,
        description_sol: data.description_sol,
        description_val: data.description_val,
        current: data.current,
        last_reunion_status: data.last_reunion_status,
        actual_state: data.actual_state,
        memory: data.memory,
        last_month_value: data.last_month_value,
        is_blocked: data.is_blocked,
        is_deleted: data.is_deleted,
        request_time: Date.now()
    },
});

export const setInitiativeError = (error) => ({
    type: "INITIATIVE_ERROR",
    payload: {
        error: error
    }
});

export const deleteInitiative = (initiative_id) => ({
    type: "DELETE_INITIATIVE",
    payload: {
        id: initiative_id,
    },
    request_time: Date.now()
});

export const updateInitiative = (initiative) => ({
    type: "UPDATE_INITIATIVE",
    payload: {
        id: initiative.id,
        title: initiative.title,
        code: initiative.code,
        area_id: initiative.area_id,
        forecast_date: initiative.forecast_date,
        stage: initiative.stage,
        status: initiative.status,
        leader_user: initiative.leader_user,
        sponsor_user: initiative.sponsor_user,
        initype_id: initiative.initype_id,
        lever_id: initiative.lever_id,
        card_id: initiative.card_id,
        ola: initiative.ola,
        description_sit: initiative.description_sit,
        description_opt: initiative.description_opt,
        description_sol: initiative.description_sol,
        description_val: initiative.description_val,
        current: initiative.current,
        last_reunion_status: initiative.last_reunion_status,
        actual_state: initiative.actual_state,
        memory: initiative.memory,
        is_blocked: initiative.is_blocked,
        is_deleted: initiative.is_deleted,
        last_month_value: initiative.last_month_value || 0,
    },
    request_time: Date.now()
});

export const setInitiatives = (initiatives) => ({
    type: "SET_INITIATIVES",
    payload: {
        initiatives: initiatives,
    },
    request_time: Date.now()
});

export const setAllInitiatives = (initiatives) => ({
    type: "SET_ALL_INITIATIVES",
    payload: {
        all_initiatives: initiatives,
    },
    request_time: Date.now()
});

export const getCurrentUserInitiatives = () => {

    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "initiatives", "list", true)) {
                throw new Error("No permissions");
            }
                fetch(`${BACKEND_URL}/users/${state.sessions.user.id}/initiatives`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setInitiatives(data));
                    })
                    .catch((error) => {
                        errorHandler("initiatives", error.message);
                        dispatch(setInitiativeError(error));
                    });
        } catch (error) {
            errorHandler("initiatives", error.message);
            dispatch(setInitiativeError(error));
        }
    };
}

export const getAllInitiatives = () => {

    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "initiatives", "list", true)) {
                throw new Error("No permissions");
            }
                fetch(`${BACKEND_URL}/initiatives`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setAllInitiatives(data));
                    })
                    .catch((error) => {
                        errorHandler("initiatives", error.message);
                        dispatch(setInitiativeError(error));
                    });
            
        } catch (error) {
            errorHandler("initiatives", error.message);
            dispatch(setInitiativeError(error));
        }
    };
}

export const getInitiative = (initiativeId) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "initiatives", "list", true)) {
                throw new Error("No permissions");
            }
                // then if status = 200
                fetch(`${BACKEND_URL}/initiatives/${initiativeId}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setInitiative(data));
                    })
                    .catch((error) => {
                        errorHandler("initiatives", error.message);
                        dispatch(setInitiativeError(error.message));
                    });
            
        } catch (error) {
            errorHandler("initiatives", error.message);
            dispatch(setInitiativeError(error));
        }
    };
}

export const patchInitiative = (initiativeId, data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (
                (
                    (getResponsabilityPermission(state,"initiatives","change",initiativeId,"Lider") && state.initiatives.initiative.stage === "evaluation" )
                    || ( getUserPermission(state, "initiatives", "change", true) && state.initiatives.initiative.stage !== "evaluation"  )
                    || getUserPermission(state, "initiatives", "patch", true)
                )
            ) {
                fetch(`${BACKEND_URL}/initiatives/${initiativeId}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                    method: "PATCH",
                    body: JSON.stringify(data)
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setInitiative(data));
                        toast.success(language.initiatives.form.update_success);
                    })
                    .catch((error) => {
                        errorHandler("initiatives", error.message);
                        dispatch(setInitiativeError(error.message));
                    });
            }
        } catch (error) {
            errorHandler("initiatives", error.message);
            dispatch(setInitiativeError(error));
        }
    };
}

export const reloadBlockInitiative = (initiativeId) => {
    return (dispatch) => {
        dispatch(patchInitiativeApprovals(initiativeId));
        dispatch(patchInitiativeChecklists(initiativeId));
        toast.info(language["initiatives"]["form"]["reload_block_success"]);
    }
}

export const reloadAllInitiativeData = (initiativeId) => {
    return (dispatch) => {
        dispatch(getInitiativeActivities(initiativeId));
        dispatch(getInitiativeChecklists(initiativeId));
        dispatch(getInitiativeApprovals(initiativeId));
        dispatch(getInitiativeRisks(initiativeId));
        dispatch(getInitiativeKpis(initiativeId));
    }
}

export const createAdvance = () => {
    return (dispatch, getState) => {
        try {
            let state = getState();
            let areas_dict = state.areas.areas.reduce((acc, area) => {
                acc[area.id] = area;
                return acc;
            }, {});
            let blob_data = exportMultipleSheets(
                {
                    "initiative": [{
                        "title": state.initiatives.initiative.title,
                        "code": state.initiatives.initiative.code,
                        "current": state.initiatives.initiative.current,
                        "last_reunion_status": state.initiatives.initiative.last_reunion_status,
                        "description_sit": state.initiatives.initiative.description_sit,
                        "description_opt": state.initiatives.initiative.description_opt,
                        "description_sol": state.initiatives.initiative.description_sol,
                        "description_val": state.initiatives.initiative.description_val,
                        "memory": state.initiatives.initiative.memory,
                        "leader": state.users.users[state.initiatives.initiative.leader_user].full_name,
                        "sponsor": state.users.users[state.initiatives.initiative.sponsor_user].full_name,
                        "initype": Object.values(state.initypes.initypes).find(initype => initype.id === state.initiatives.initiative.initype_id).name,
                        "lever": Object.values(state.levers.levers).find(lever => lever.id === state.initiatives.initiative.lever_id).name,
                        "ola": state.initiatives.initiative.ola,
                        "status": language["initiatives"]["status"][state.initiatives.initiative.status],
                        "stage": (state.phases.phases.hasOwnProperty(state.initiatives.initiative.stage))
                            ? state.phases.phases[state.initiatives.initiative.stage].name
                            : state.initiatives.initiative.stage
                    }],
                    "risks": state.risks.risks.map(risk => {
                        return {
                            "description": risk.description,
                            "mitigator": risk.mitigator,
                            "severity": language["risks"]["severity"][risk.severity || '0'],
                            "risk_type": language.risks.risk_type[risk.risk_type],
                        }
                    }),
                    "checklists": state.initiative_checklists.checklists.map(checklist => {
                        return {
                            "question": state.checklists.checklists.filter( c => checklist.checklist_id === c.id)[0].question,
                            "phase": Object.values(state.phases.phases).find(phase => phase.id === checklist.phase_id).name,
                            "checked": checklist.checked,
                        };
                    }),
                    "activities": state.activities.initiative_activities.map(activity => {
                        return {
                            "description": activity.description,
                            "area": (areas_dict && activity["area_id"] && areas_dict.hasOwnProperty(activity["area_id"])) ? areas_dict[activity["area_id"]].name : "",
                            "forecast_date": activity.forecast_date.split("T")[0] || language["activities"]["no_date"],
                            "estimated_date": activity.estimated_date.split("T")[0] || language["activities"]["no_date"],
                            "real_date": (activity.real_date) ? activity.real_date.split("T")[0] || language["activities"]["no_date"] : language["activities"]["no_date"],
                            "stage": (state.phases.phases.hasOwnProperty(activity.stage))
                                ? state.phases.phases[activity.stage].name
                                : activity.stage,
                            "user": state.users.users[activity.responsable_user].full_name,
                            "status": language["activities"]["status"][activity.status],
                            "completed": (activity.completed) ? language["activities"]["completed"]["yes"] : language["activities"]["completed"]["no"],
                        }
                    }),
                    "approvals": state.approvals.initiative_approvals.map(approval => {
                        return {
                            "area": (areas_dict && approval["area_id"] && areas_dict.hasOwnProperty(approval["area_id"])) ? areas_dict[approval["area_id"]].name : "",
                            "forecast_date": approval.forecast_date.split("T")[0] || language["approvals"]["no_date"],
                            "stage": (state.phases.phases.hasOwnProperty(approval.stage))
                                ? state.phases.phases[approval.stage].name
                                : approval.stage,
                            "next_stage": (state.phases.phases.hasOwnProperty(approval.next_stage))
                                ? state.phases.phases[approval.next_stage].name
                                : approval.next_stage,
                            "user": (state.users.users[approval["responsable_user"]]) ? state.users.users[approval["responsable_user"]].full_name : "",
                            "is_accepted": (approval.is_accepted) ? language["approvals"]["completed"]["yes"] : language["approvals"]["completed"]["no"],
                            "notes": approval.notes,
                        }
                    }),
                    "kpis": state.kpi_seconds.kpi_seconds.map(kpisecond => {
                        return {
                            "description": kpisecond.description,
                            "units": kpisecond.units,
                            "initial_value": kpisecond.initial_value,
                            "objetive_value": kpisecond.objetive_value,
                            "forecast_date": kpisecond.forecast_date.split("T")[0] || language["kpiseconds"]["no_date"],
                            "source": kpisecond.source,
                        }
                    }),
                },
                "avance"
            );
            // fetch multipart form data
            let fecha = new Date();
            let texto_fecha = fecha.toLocaleString();
            const fileName = state.initiatives.initiative.title + "-" + texto_fecha + ".xlsx";
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const file = new File([blob_data], fileName);
            try {
                fetch(`${BACKEND_URL}/advances`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${state.sessions.token}`
                    },
                    body: JSON.stringify({
                        fileName: fileName,
                        fileType: fileType,
                        initiative_id: state.initiatives.initiative.id,
                    })
                }).then(response => {
                    if (response.status === 201) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                }).then(response => {
                    var signedRequest = response.signedRequest;
                    dispatch(setAdvance(response.advance))
                    var options = {
                        headers: {
                            'Content-Type': fileType
                        },
                        method: 'PUT',
                        body: file
                    };
                    fetch(signedRequest, options)
                        .then(result => {
                            console.log("Response from s3")
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                errorHandler("advances", error.message);
                console.log(error);
            }
        } catch (error) {
            errorHandler("advances", error.message);
            console.log(error);
        }

    }
}

export const deleteInitiativeById = (initiative_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "initiatives", "delete", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/initiatives/${initiative_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
                .then((response) => {
                    if (response.status === 204) {
                        return true;
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((vl) => {
                    if (vl) {
                        dispatch(deleteInitiative(initiative_id));
                        history.push("/");
                        window.location.reload();
                    }
                })
                .catch((error) => {
                    errorHandler("initiatives", error.message);
                    dispatch(setInitiativeError(error));
                    history.push("/");
                    window.location.reload();
                });
        } catch (error) {
            dispatch(setInitiativeError(error));
            history.push("/");
            window.location.reload();
        }
    };
}

