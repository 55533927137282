import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { getInitiativeAdvances } from '../../redux/actions';
import { language } from '../../assets/language';
import SimpleTable from '../../components/tables/SimpleTable';
import { DeleteAdvance } from '.';
import { getUserPermission } from '../../utils/getUserPermission';


const ListInitiativeAdvances = ({ dispatch, initiative }) => {

    const state = useSelector(state => state);
    const advances = useSelector(state => state.advances.advances);

    useEffect(() => {
        dispatch(getInitiativeAdvances(initiative.id));
    }, [dispatch, initiative]);

    const columns = useMemo(
        () => {
            let col = [
                {
                    Header: language.advances.table.name,
                    accessor: 'name',
                },
                {
                    Header: language.advances.table.url,
                    accessor: 'url',
                },
            ];
            if (getUserPermission(state, 'advances', 'delete')) {
                col.push({
                    Header: language.general.actions,
                    accessor: 'actions',
                });
            }
            return col;
        },
        [state]
    )

    const renderAdvances = () => {
        if (!advances || advances.length === 0) {
            return <p>{language["advances"]["not_found"]}</p>
        }

        let advancesList = advances.map((advance) => {
            return {
                name: advance.name || "",
                url: <a href={advance.url} target="_blank" rel="noreferrer">Link</a>,
                actions: <DeleteAdvance advance={advance} />
            }
        });

        return <SimpleTable columns={columns} data={advancesList} />

    }

    return (
        <div>
            {renderAdvances()}
        </div>
    );
}

export default connect()(ListInitiativeAdvances);