const initialExportsState = {
    request_time: null,
    error: null,
    exports: {},
}

const exports = (state = initialExportsState, action) => {
    switch (action.type) {
        case "SET_EXPORTS":
            return {
                ...state,
                exports: action.payload,
                request_time: action.request_time,
            }
        case "EXPORT_ERROR":
            return {
                ...state,
                error: action.payload.error,
                request_time: null,
                exports: {},
            }
        default:
            return state;
        }
    }
    
export default exports;
