const initialState_roleGroups = {
    groups: [],
    role_groups: [],
    user_role_groups: [],
    role_id: null,
    request_time: null,
    role_request_time: null,
    error: null,
    loading: true,
    role_loading: false,
    role_groups_edition: [],
    error_edition: null,
    loading_time: null,
    role_loading_time: null
};

const role_group = (state = initialState_roleGroups, action) => {
    switch (action.type) {
        case 'SET_GROUPS':
            return {
                ...state,
                groups: action.payload.groups,
                request_time: action.request_time,
            };
        case 'SET_ROLE_GROUPS':
            return {
                ...state,
                role_groups: action.payload.role_groups,
                role_id: action.payload.role_id,
                role_request_time: action.request_time,
            };
        case 'SET_USER_ROLE_GROUPS':
                return {
                    ...state,
                    user_role_groups: action.payload.user_role_groups,
                    role_request_time: action.request_time,
                };
        case "SET_ROLE_GROUP":
            return {
                ...state,
                role_groups: [...state.role_groups, action.payload.data],
                request_time: action.request_time,
                role_request_time: action.request_time,
            };
        case "DELETE_ROLE_GROUP":
            return {
                ...state,
                role_groups: state.role_groups.filter(per => per.id !== action.payload.id),
                request_time: action.request_time,
                role_request_time: action.request_time,
            };
        case "SET_GROUPS_ERROR":
            return {
                ...state,
                // groups: [],
                // role_groups: [],
                request_time: null,
                error: action.payload.error,
                loading_time: null,
                loading: false
            };
        case "SET_GROUPS_LOADING":
            return {
                ...state,
                loading: action.payload.loading,
                loading_time: action.request_time
            }
        case "SET_ROLE_GROUPS_LOADING":
            return {
                ...state,
                role_loading: action.payload.loading,
                role_loading_time: action.request_time
            }
        case "SET_ROLE_GROUPS_EDITION":
            return {
                ...state,
                role_groups_edition: action.payload.role_groups,
                role_id: action.payload.role_id,
                role_request_time: action.request_time,
            };
        case "SET_GROUPS_EDITION_RROR":
            return {
                ...state,
                role_groups_edition: [],
                request_time: null,
                error_edition: action.payload.error,
                role_loading: false,
                role_loading_time: null
            };
        default:
            return state;
    }
}

export default role_group;