import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import {
  getAllPermits,
  addGroupPermit,
  deleteGroupPermit,
  getGroupPermits,
} from "../../redux/actions";
import { language } from "../../assets/language";
import SimpleTable from "../../components/tables/SimpleTable";
import { Input } from "reactstrap";
import Loading  from "../../components/Loading/Loading"

const ListGroupPermits = ({ dispatch, group }) => {
  const permits = useSelector((state) => state.permits.permits);
  const group_permits = useSelector(
    (state) => state.group_permits.group_permits
  );
  const loadingPermits = useSelector((state) => state.group_permits.loading);
  const loadingGroupPermits = useSelector((state) => state.group_permits.group_loading);

  const [group_permits_change, setGroupPermitsChange] = useState(false);
  const [loading , setLoading] = useState(false);

  useEffect(() => {
    dispatch(getAllPermits());
  }, []);

  useEffect(() => {
    dispatch(getGroupPermits(group.id));
  }, [group]);

  useEffect(() => {
    if (group_permits_change && group_permits.length > 0) {
      setGroupPermitsChange(false);
      setLoading(false);
    }
  }, [group_permits, group_permits_change]);

  useEffect(() => {
    if(permits && group_permits){
      setLoading(false);
    }
    }, [permits, group_permits]);

  const changeGroupPermit = (e) => {
    setLoading(true);
    e.preventDefault();
    const permit_id = e.target.value;
    const group_permit = group_permits.find((rp) => rp.permit_id === permit_id);
    if (group_permit) {
      dispatch(deleteGroupPermit(group.id, group_permit.id));
    } else {
      dispatch(
        addGroupPermit({ group_id: group.id, permit_id: permit_id }, group.id)
      );
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: language["permits"]["table"]["resource"],
        accessor: "resource",
      },
      {
        Header: language["permits"]["table"]["action"],
        accessor: "action",
      },
      {
        Header: language["group_permits"]["table"]["exists"],
        accessor: "exists",
      },
    ],
    []
  );

  const renderGroupPermits = () => {
    if (loadingPermits || loadingGroupPermits) {
      return <p>{language.general.loading}</p>;
    }
    if (!permits || permits.length === 0 || !group_permits) {
      return <p>{language["permits"]["not_found"]}</p>;
    }
    let _permits = permits.filter(p => p.action === group.action).map((permit) => {
      const resource = group_permits.find((p) => p.permit_id === permit.id);
      return {
        id: permit.id,
        resource: language.permits.resources[permit.resource || "default"],
        action: language.permits.actions[permit.action || "default"],
        exists: 
          <Input
            className='form-control-md mt-0 pb-5'
            id={permit.id}
            type='checkbox'
            checked={resource ? true : false}
            onChange={(e) =>changeGroupPermit(e)}
            value={permit.id}
          />
       
      };
    });

    return <SimpleTable data={_permits} columns={columns} />;
  };

  return (
    <div>
      {!group_permits_change ? (
        renderGroupPermits()
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default connect()(ListGroupPermits);
