import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";


export const setAdvances = (advances, initiativeId) => ({
    type: "SET_ADVANCES",
    payload: {
        advances: advances,
        initiativeId: initiativeId,
    },
    request_time: Date.now()
});

export const setAdvance = (advance) => ({
    type: "SET_ADVANCE",
    payload: {
        id: advance.id,
        name: advance.name,
        url: advance.url,
        initiative_id: advance.initiative_id,
        request_time: Date.now()
    },
});

export const setAdvanceError = (error) => ({
    type: "ADVANCE_ERROR",
    payload: {
        error: error
    }
});

export const deleteAdvance = (advance_id) => ({
    type: "DELETE_ADVANCE",
    payload: {
        id: advance_id,
    },
    request_time: Date.now()
});

export const getInitiativeAdvances = (initiativeId) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "advances", "list", true)) {
                throw new Error("No permissions");
            }
            if (state.advances.request_time + 1000 * 2 < Date.now()
                || state.advances.initiativeId === null
                || state.advances.request_time === null
                || state.advances.initiativeId !== initiativeId) {
                fetch(`${BACKEND_URL}/initiatives/${initiativeId}/advances`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setAdvances(data, initiativeId));
                    })
                    .catch((error) => {
                        errorHandler("advances", error.message);
                        dispatch(setAdvanceError(error));
                    });
            }
        } catch (error) {
            errorHandler("advances", error.message);
            dispatch(setAdvanceError(error));
        }
    };
}

export const postAdvance = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            // if (!getUserPermission(state, "advances", "create", true)) {
            //     throw new Error("No permissions");
            // }
            if (state.initiatives.initiative.leader_user === state.sessions.user.id
                || state.initiatives.initiative.sponsor_user === state.sessions.user.id
                || state.sessions.user.role === 'admin'
                || getUserPermission(state, "advances", "create", true)) {
                fetch(`${BACKEND_URL}/advances`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                    body: JSON.stringify(data)
                })
                    .then((response) => {
                        if (response.status === 201) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        if (data != null && data !== undefined && data.id !== undefined) {
                            dispatch(setAdvance(data));
                        }
                    })
                    .catch((error) => {
                        errorHandler("advances", error.message);
                        dispatch(setAdvanceError(error));
                    });
            }
        } catch (error) {
            errorHandler("advances", error.message);
            dispatch(setAdvanceError(error));
        }
    };
}

export const deleteAdvanceById = (advance_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "advances", "delete", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/advances/${advance_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
                .then((response) => {
                    if (response.status === 204) {
                        return true;
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((vl) => {
                    if (vl) {
                        dispatch(deleteAdvance(advance_id));
                    }
                })
                .catch((error) => {
                    errorHandler("advances", error.message);
                    dispatch(setAdvanceError(error));
                });
        } catch (error) {
            errorHandler("advances", error.message);
            dispatch(setAdvanceError(error));
        }
    };
}