export const language = {
  general: {
    yes: "Si",
    no: "No",
    close: "Cerrar",
    welcome: "Bienvenido!",
    loading: "Cargando...",
    cancel: "Cancelar",
    actions: "Acciones",
    update_success: "actualizado correctamente",
    all: "Todos",
    select: "Seleccionar",
    week: "Semana",
    see_more: "Ver más...",
    months: {
      0: "Enero",
      1: "Febrero",
      2: "Marzo",
      3: "Abril",
      4: "Mayo",
      5: "Junio",
      6: "Julio",
      7: "Agosto",
      8: "Septiembre",
      9: "Octubre",
      10: "Noviembre",
      11: "Diciembre",
    },
    abv_months: {
      0: "ENE",
      1: "FEB",
      2: "MAR",
      3: "ABR",
      4: "MAY",
      5: "JUN",
      6: "JUL",
      7: "AGO",
      8: "SEP",
      9: "OCT",
      10: "NOV",
      11: "DIC",
    },
    warnings: {
      error: "No se pudo realizar la operacion en",
    },
    errors: {
      server: "Error de servidor",
      default: "Error desconocido",
    },
    emails: {
      sent_success: "Emails enviados correctamente",
    },
  },
  tags: {
    title: "Tags Ideas",
    not_found: "No se encontraron tags",
    form: {
      create: "Crear Tag de ideas",
      update: "Actualizar Tag de ideas",
      delete: "Eliminar Tag de ideas",
      deleteConfirm: "Estas seguro que quieres eliminar el tag?",
    },
  },
  decisions: {
    title: "Decisiones",
    form: {
      create: "Crear Decisión",
      update: "Actualizar Decisión",
      delete: "Eliminar Decisión",
    },
  },
  menu: {
    home: "Mi estado",
    activities: "Mis actividades",
    initiatives: "Iniciativas activas",
    decisions: "Decisiones",
    cards: "Ideas",
    areas: "Areas",
    users: "Usuarios",
    organization: "Organización",
    approvals: "Aprobaciones",
    "config-menu": "Configuración",
    checklists: "Checklists",
    tags: "Tags Ideas",
    phases: "Fases",
    levers: "Palancas estratégicas",
    initypes: "Tipos de iniciativas",
    roles: "Roles",
    configuration: "Configuración adicional",
    tree: "Árbol de valor",
    tasks: "Compromisos de reunión",
    control: "Panel de control",
    groups: "Grupos",
    info_states: "Estados de iniciativa",
  },
  sessions: {
    title: "Inicio de sesión",
    email: "Correo electrónico",
    password: "Contraseña",
    login: "Iniciar sesión",
    forgot_password: "¿Olvidaste tu contraseña?",
    register: "Registrarse",
    logout: "Cerrar sesión",
    errors: {
      401: "Correo electrónico o contraseña incorrectos",
    },
  },
  initiatives: {
    initiative: "Iniciativa",
    exporting: "Exportando...",
    title: "Mis iniciativas",
    not_found: "No se encontraron iniciativas",
    no_date: "Sin fecha",
    no_state: "Sin estado",
    initiative_actions: "Iniciativas",
    deleted: "Eliminada",
    no_task: "Sin tareas",
    no_info: "Sin información",
    no_responsible: "Sin responsable",
    revive: "Revivir",
    delete_for_ever: "Eliminar para siempre",
    table: {
      id: "ID",
      title: "Título",
      responsability: "Dueño",
      code: "Código",
      area: "Área",
      forecast_date: "Comprometida",
      stage: "Etapa",
      status: "Estado",
      leader: "Dueño",
      sponsor: "Sponsor",
      ola: "Ola",
      lever: "Palanca estratégica",
      initype: "Tipo de iniciativa",
      is_blocked: "[ Bloqueada por revisión ]",
      current: "¿Cómo vamos?",
      year_value: "V. del año",
      accumulated_total: "V. acumulado",
      accumulated_current: "V. acumulado actual",
      state: "Decisión",
      last_task: "Próximo hito",
      last_task_date: "Fecha hito",
      last_task_status: "Estado hito",
      last_task_responsible: "Responsable",
      active: "Activa",
    },
    status: {
      complete: "Etapa completada",
      in_progress: "En progreso",
      late: "Atrasada",
    },
    state: {
      text1: "Texto 1",
      text2: "Texto 2",
      text3: "Texto 3",
    },
    titles: {
      initiative_description: "Descripción de la iniciativa",
      current: "¿Cómo vamos?",
      situation: "Situación",
      oportunity: "Oportunidad",
      solution: "Solución",
      kpis: "Valor de KPIs",
      value:
        "¿Cómo la iniciativa genera valor? - Identifique las palancas que la iniciativa mejorará",
      riskQualitySecurity:
        "Riesgos y mitigaciones de calidad/seguridad por la implementación de la iniciativa",
      riskBusiness:
        "Riesgos y mitigaciones de negocio por la implementación de la iniciativa",
      memory: "Memoria de cálculo",
      link_memory: "Link Memoria de cálculo",
      last_reunion_status: "Estado de la última reunión",
      actual_state: "Decisiones",
      downloads: "Exportaciones",
    },
    modal: {
      date_range: "Selecciona rango de fechas",
      start_date: "Fecha de inicio",
      end_date: "Fecha de fin",
      apply: "Aplicar",
      cancel: "Cancelar",
    },
    form: {
      update: "Guardar cambios",
      update_success: "Iniciativa actualizada correctamente",
      delete: "Eliminar iniciativa",
      deleteConfirm: "¿Estás seguro de que quieres eliminar esta iniciativa?",
      actions: "Acciones",
      reload_block_success:
        "Se resetearon las aprobaciones y checklists activas.",
      select: "Seleccionar iniciativa",
      select_area: "Seleccionar área",
      select_lever: "Seleccionar palanca estratégica",
      select_initype: "Seleccionar tipo de iniciativa",
      select_leader: "Seleccionar líder",
      select_info_state: "Seleccionar estado de iniciativa",
      download: "Descargar Excel",
      download_all: "Descargar en Excel",
      download_all_csv: "Descargar en CSV",
      export_pdf: "Exportar PDF",
    },
    tabs: {
      general: "General",
      progress: "Progreso",
      value: "Valores",
      activities: "Hitos",
      kpis: "KPIs",
      approvals: "Aprobaciones",
      risks: "Riesgos",
      checklists: "Checklists",
      users: "Usuarios",
      advances: "Avances",
      tasks: "Tareas",
      documents: "Documentos",
    },
    errors: {
      400: "La iniciativa tiene parámetros incorrectos",
      404: "La iniciativa no existe",
    },
    //cambiar el valor en estos lugares
    stats: {
      initiative_number: "Número de Iniciativas por Etapa",
      initiative_value_stage: "Valor Iniciativas por Etapa ",
      delta_value: "Variación de Valor ",
      initiatives_value_month: "Valor Iniciativas por Mes ",
      initiative_value_month: "Valor de Iniciativa por Mes ",

      forecast_tshift: "Pronóstico acumulado de iniciativas",
      start_date_not_monday: "La fecha de inicio debe ser lunes",
      old: "Antiguas",
      new: "Nuevas",
      increment: "Incremento",
      decrement: "Decremento",
      total: "Totales",
      ended: "Finalizadas",
      deleted: "Eliminadas",
    },
    active: {
      yes: "Sí",
      no: "No",
    },
  },
  activities: {
    title: "Hitos",
    my_title: "Mis hitos",
    not_found: "No se encontraron hitos",
    not_active: "No hay hitos activos ",
    not_active_in_this_stage: "No se puede crear hitos en esta etapa",
    no_date: "Sin fecha",
    table: {
      id: "ID",
      initiative: "Iniciativa",
      description: "Descripción",
      area: "Área",
      user: "Responsable",
      created_at: "Creación",
      forecast_date: "Comprometida",
      estimated_date: "Estimada",
      stage: "Etapa",
      completed: "Completada",
      status: "Estado",
      title: "Hito",
    },
    form: {
      create: "Añadir hito",
      save: "Guardar hito",
      complete: "Completar",
      edit: "Editar hito",
      cancel: "Cancelar",
      delete: "Eliminar hito",
      deleteConfirm: "¿Estás seguro de que quieres eliminar esta hito?",
      actions: "Acciones",
      additional_info: "Información adicional",
    },
    completed: {
      yes: "Si",
      no: "No",
    },
    status: {
      complete: "Completada",
      in_progress: "En progreso",
      late: "Atrasada",
    },
    errors: {
      400: "La actividad tiene parámetros incorrectos",
      404: "La actividad no existe",
    },
  },
  cards: {
    title: "Mis ideas",
    not_found: "Aún no agregas ideas",
    table: {
      id: "ID",
      title: "Título",
      description: "Descripción",
      area: "Área",
      user: "Responsable",
      estimated_value: "Valor ",
      is_accepted: "Estado",
      action: "Accion",
      state: "Tag",
      valued: "Valor",
      ease: "Facilidad",
      fase: "Origen",
      link: "Link",
    },
    form: {
      create: "Añadir idea",
      accept: "Aceptar idea",
      delete: "Eliminar idea",
      reject: "Rechazar idea",
      deleteConfirm: "¿Estás seguro de que quieres eliminar esta idea?",
      rejectConfirm: "¿Estás seguro de que quieres rechazar esta idea?",
      select_state: "Filtrar por tag",
      select_valued: "Filtrar por valor",
      select_ease: "Filtrar por facilidad",
      edit: "Editar idea",
      update_success: "Idea actualizada correctamente",
      all: "Todas",
      select_areas: "Filtrar por área",
      select_status: "Filtrar por estado",
    },
    errors: {
      400: "La idea tiene parámetros incorrectos",
      404: "La idea no existe",
      403: "No puedes eliminar o rechazar esta idea",
    },
    states: {
      0: "Nueva",
      1: "A Evaluar",
      2: "A Implementar",
      3: "A Revisar",
      4: "Guardar",
    },
    valued: {
      0: "Baja",
      1: "Media",
      2: "Alta",
    },
    ease: {
      0: "Muy difícil",
      1: "Difícil",
      2: "Normal",
      3: "Fácil",
      4: "Muy fácil",
    },
    is_accepted: {
      accept: "Aceptada",
      reject: "Rechazada",
      pending: "Pendiente",
    },
  },
  areas: {
    title: "Organización",
    not_found: "No se encontraron áreas",
    no_parent: "Sin padre",
    no_delete: "No se puede eliminar esta área porque tiene subáreas",
    table: {
      id: "ID",
      name: "Nombre",
      description: "Descripción",
      parent: "Área padre",
    },
    form: {
      create: "Añadir área",
      edit: "Editar área",
      delete: "Eliminar área",
      deleteConfirm: "¿Estás seguro de que quieres eliminar esta área?",
    },
    errors: {
      400: "El área tiene parámetros incorrectos",
      404: "El área no existe",
      403: "No puedes eliminar esta área porque está en uso o tiene hijos",
    },
  },
  users: {
    title: "Usuarios",
    not_found: "No se encontraron usuarios",
    table: {
      id: "ID",
      firstname: "Nombre",
      lastname: "Apellidos",
      email: "Correo electrónico",
      role: "Rol",
      area: "Área",
      password: "Contraseña",
      full_name: "Nombre completo",
      responsability: "Responsabilidad",
    },
    form: {
      create: "Añadir usuario",
      delete: "Eliminar usuario",
      deleteConfirm: "¿Estás seguro de que quieres eliminar este usuario?",
      cancel: "Cancelar",
      password_info:
        "*Debe contener al menos 8 caracteres, una mayúscula, una minúscula y un número",
      password: "Cambiar contraseña",
      cpassword_info: "Contraseñas no coinciden",
      edit: "Editar usuario",
      confirm_password: "Confirmar contraseña",
      save: "Guardar cambios",
      update_success: "Usuario actualizado correctamente",
    },
    errors: {
      400: "El usuario tiene parámetros incorrectos",
      404: "El usuario no existe",
      403: "No puedes eliminar este usuario porque tiene recursos asociados",
    },
  },
  kpiseconds: {
    title: "KPIs",
    kpi: "KPI",
    not_found: "No se encontraron KPIs",
    no_date: "Sin fecha",
    value: "Valor de KPI por Mes ",
    tableValue: "Tabla de Valores KPI: ",
    initial_value: "Valor inicial",
    final_value: "Valor final",
    initial_date: "Fecha inicial",
    final_date: "Fecha final",
    promise: "Promesa",
    forecast: "Forecast",

    table: {
      id: "ID",
      description: "Descripción",
      tree:"Valor",
      units: "Unidad",
      initial_value: "Linea base",
      objetive_value: "Valor régimen",
      forecast_value: "Valor Forecast",
      initial_date: "Fecha base",
      objetive_date: "Fecha régimen",
      forecast_date: "Fecha Forecast",
      


      memory: "Memoria de cálculo",
      source: "Fuente",
    },
    form: {
      create: "Añadir KPI",
      edit: "Editar KPI",
      editValues: "Editar Valores",
      delete: "Eliminar KPI",
      close: "Cerrar",
      updateWith: "Actualizar Valor",
      updateWithout: "Ajustar Valores Intermedios",
      copy: "Replicar Promesa",
      deleteConfirm: "¿Estás seguro de que quieres eliminar este KPI?",
      editConfirm: "¿Estás seguro de que quieres editar este KPI? Esto puede provocar cambios en los valores",
    },
    kshifts: {
      show: "Editar Valores",
      hide: "Ocultar Valores",
    },
    errors: {
      400: "El KPI tiene parámetros incorrectos",
      404: "El KPI no existe",
    },
  },
  risks: {
    title: "Riesgos",
    not_found: "No se encontraron riesgos",
    table: {
      id: "ID",
      description: "Riesgo",
      severity: "Severidad",
      mitigator: "Mitigaciones",
      risk_type: "Tipo de riesgo",
      probability: "Probabilidad",
      edit: "Editar riesgo",
      delete: "Eliminar riesgo",
    },
    form: {
      create: "Añadir riesgo",
      edit: "Editar riesgo",
      delete: "Eliminar riesgo",
      deleteConfirm: "¿Estás seguro de que quieres eliminar este riesgo?",
    },
    severity: {
      1: "Baja",
      2: "Media",
      3: "Alta",
    },
    probability: {
      1: "Baja",
      2: "Media",
      3: "Alta",
    },
    risk_type: {
      quality: "Calidad",
      business: "Negocio",
    },
    errors: {
      400: "El riesgo tiene parámetros incorrectos",
      404: "El riesgo no existe",
    },
  },
  stages: {
    title: "Etapas",
    not_found: "No se encontraron etapas",
    success: "Se ha actualizado correctamente",
    table: {
      id: "ID",
      name: "Nombre",
      forecast_date: "Compromiso",
      end_date: "Fecha comprometida",
      estimated_date: "Nuevo acuerdo",
      real_date: "Fecha real",
      completed: "Completado",
      start_date: "Comparacion Semana actual con Semana del",
    },
    names: {
      idea: "Idea",
      evaluation: "Evaluación",
      execution: "Ejecución",
      result: "Resultado",
      adoption: "Adopción",
      deleted: "Eliminado",
      finalized: "Finalizado",
      no_stage: "Sin etapa",
    },
    completed: {
      yes: "Si",
      no: "No",
    },
    form: {
      create: "Añadir etapa",
      change: "Avanzar a Etapa de ", 
      confirm: "Confirmar cambio de Etapa a ",
      delete: "Eliminar etapa",
      deleteConfirm: "¿Estás seguro de que quieres eliminar esta etapa?",
      save: "Guardar etapa",
    },
    change: {
      actual: "Etapa actual",
      next: "Siguiente etapa",
      approvals: "¿Tiene aprobaciones?",
      send_approval: "Enviar para su aprobación",
      pending_checklist: "Pendiente de checklist",
      pending_approvals: "Pendiente de aprobaciones",
      initiativeStageChanged: "La etapa de la iniciativa ha cambiado",
      initiativeBlocked: "La iniciativa ha sido bloqueada",
    },
    errors: {
      400: "La etapa tiene parámetros incorrectos",
      404: "La etapa no existe",
      403: "No puedes eliminar esta etapa porque está en uso",
    },
  },
  phases: {
    title: "Etapas",
    not_found: "No se encontraron etapas",
    table: {
      id: "ID",
      name: "Nombre",
      description: "Descripción",
      identifier: "Identificador",
      required_checklist: "Requiere checklist",
      position: "Posición",
    },
    form: {
      create: "Añadir fase",
      delete: "Eliminar fase",
      deleteConfirm: "¿Estás seguro de que quieres eliminar esta fase?",
    },
    select: "Seleccionar",
    required_checklist: {
      yes: "Sí",
      no: "No",
      pending: "checklists por aprobar",
      approvals:  "Aprobaciones por aceptar"

    },
    errors: {
      400: "La fase tiene parámetros incorrectos",
      404: "La fase no existe",
    },
  },
  approvals: {
    title: "Aprobaciones",
    not_found: "No se encontraron aprobaciones",
    table: {
      id: "ID",
      responsable_user: "Responsable",
      area: "Área",
      notes: "Notas",
      stage: "Etapa actual",
      next_stage: "Siguiente etapa",
      is_accepted: "Estado",
      is_active: "Activo",
      forecast_date: "Comprometida",
      initiative: "Iniciativa",
    },
    form: {
      create: "Añadir aprobación",
      save: "Guardar aprobación",
      edit: "Editar",
      complete: "Completar",
      delete: "Eliminar aprobación",
      deleteConfirm: "¿Estás seguro de que quieres eliminar esta aprobación?",
    },
    completed: {
      yes: "Listo",
      no: "Pendiente",
    },
    errors: {
      400: "La aprobación tiene parámetros incorrectos",
      404: "La aprobación no existe",
    },
    emails: {
      send_active: "Enviar correos de aprobaciones activas",
    },
  },
  checklists: {
    title: "Listas de verificación",
    not_found: "No se encontraron listas de verificación",
    not_found_active: "No se encontraron listas de verificación activas",
    not_found_old: "No se encontraron listas de verificación antiguas",
    phase: "Para pasar a la fase de",
    stage: "En la fase de",
    old: "Otros Checklists",
    table: {
      id: "ID",
      question: "Pregunta",
      phase: "Fase",
      checked: "Completada",
      check: "",
    },
    form: {
      create: "Añadir pregunta",
      createQuestion: "Añadir pregunta",
      phase: "Para pasar a la fase",
      accept: "Aceptar",
      reject: "Rechazar",
      delete: "Eliminar pregunta",
      deleteConfirm: "¿Estás seguro de que quieres eliminar esta pregunta?",
    },
    completed: {
      yes: "Sí",
      no: "No",
    },
    errors: {
      400: "La pregunta tiene parámetros incorrectos",
      404: "La  pregunta no existe",
      403: "La Pregunta esta siendo utilizada"
    },
  },
  levers: {
    title: "Palancas estratégicas",
    not_found: "No se encontraron palancas estratégicas",
    table: {
      id: "ID",
      name: "Nombre",
    },
    form: {
      create: "Añadir palanca estratégica",
      delete: "Eliminar palanca estratégica",
      deleteConfirm:
        "¿Estás seguro de que quieres eliminar esta palanca estratégica?",
    },
    errors: {
      400: "La palanca estratégica tiene parámetros incorrectos",
      404: "La palanca estratégica no existe",
      403: "No puedes eliminar esta palanca estratégica porque está en uso",
    },
  },
  initypes: {
    title: "Tipos de iniciativas",
    not_found: "No se encontraron tipos de iniciativas",
    table: {
      id: "ID",
      name: "Nombre",
    },
    form: {
      create: "Añadir tipo de iniciativa",
      delete: "Eliminar tipo de iniciativa",
      deleteConfirm:
        "¿Estás seguro de que quieres eliminar este tipo de iniciativa?",
    },
    errors: {
      400: "El tipo de iniciativa tiene parámetros incorrectos",
      404: "El tipo de iniciativa no existe",
      403: "No puedes eliminar este tipo de iniciativa porque está en uso",
    },
  },
  roles: {
    title: "Roles",
    not_found: "No se encontraron roles",
    table: {
      id: "ID",
      name: "Nombre",
    },
    form: {
      create: "Añadir rol",
      edit_permits: "Editar permisos",
      delete: "Eliminar rol",
      deleteConfirm: "¿Estás seguro de que quieres eliminar este rol?",
    },
    kinds: {
      admin: "Administrador",
      user: "Usuario",
      change: "Mejora continua",
    },
    errors: {
      400: "El rol tiene parámetros incorrectos",
      404: "El rol no existe",
      403: "No puedes eliminar este rol porque está en uso",
    },
  },
  groups: {
    title: "Grupos de permisos",
    not_found: "No se encontraron grupos",
    role: "Rol",
    responsability: "Responsabilidad",
    table: {
      id: "ID",
      name: "Nombre",
      type: "Tipo",
      resource: "Recurso",
      list: "Ver",
      create: "Crear",
      patch: "Editar",
      change: "Modificar",
      delete: "Eliminar",
      action: "Permiso",
      exists: "Existe",
    },
    form: {
      create: "Añadir grupo",
      edit_permits: "Editar permisos",
      delete: "Eliminar grupo",
      deleteConfirm: "¿Estás seguro de que quieres eliminar este grupo?",
    },
    kinds: {
      admin: "Administrador",
      user: "Usuario",
      change: "Mejora continua",
      revision: "Revisor",
    },
    errors: {
      400: "El grupo tiene parámetros incorrectos",
      404: "El grupo no existe",
      403: "No puedes eliminar este grupo porque está en uso",
    },
  },
  permits: {
    title: "Permisos",
    not_found: "No se encontraron permisos",
    table: {
      id: "ID",
      resource: "Recurso",
      action: "Acción",
    },
    errors: {
      400: "El permiso tiene parámetros incorrectos",
      404: "El permiso no existe",
    },
    resources: {
      default: "Recurso",
      users: "Usuarios",
      stages: "Etapas",
      approvals: "Aprobaciones",
      checklists: "Listas de verificación",
      levers: "Palancas estratégicas",
      initiatives: "Iniciativas",
      initypes: "Tipos de iniciativas",
      roles: "Roles",
      risks: "Riesgos",
      kpiseconds: "KPIs",
      areas: "Áreas",
      activities: "Actividades",
      cards: "Ideas",
      permits: "Permisos",
      role_permits: "Permisos de rol",
      kshifts: "Cambios de kpi",
      tshifts: "Cambios Valores",
      trees: "Árbol de valor",
      advances: "Avances",
      configs: "Configuraciones adicionales",
      tasks: "Tareas",
      phases: "Fases",
      initiative_users: "Responable de iniciativa",
      config_menu: "Menú de configuración",
      info_states: "Estados de iniciativa",
      counter_stats: "Estadísticas de contadores",
      tags: "Tags",
      groups: "Grupos",
      group_permits: "Permisos de grupo",
      role_groups: "Grupos de roles",
      group_users: "Usuarios de grupo",
      group_roles: "Roles de grupo",
      role_group_permits: "Permisos de grupos de roles",

      
    },
    actions: {
      default: "Acción",
      create: "Crear",
      list: "Listar",
      list_all: "Listar todos",
      change: "Modificar",
      patch: "Editar",
      delete: "Eliminar",
      show: "Mostrar",
      "change-stage": "Cambiar etapa",
    },
  },
  role_permits: {
    title: "Permisos de rol",
    not_found: "No se encontraron permisos de rol",
    table: {
      id: "ID",
      role: "Rol",
      permit: "Permiso",
      exists: "Existe",
    },
    form: {
      update: "Actualizar permisos de rol",
    },
    errors: {
      400: "El permiso de rol tiene parámetros incorrectos",
      404: "El permiso de rol no existe",
    },
  },
  group_permits: {
    title: "Permisos de grupo",
    not_found: "No se encontraron permisos de grupo",
    table: {
      id: "ID",
      role: "grupo",
      permit: "Permiso",
      exists: "Existe",
    },
    form: {
      update: "Actualizar permisos de grupo",
    },
    errors: {
      400: "El permiso de grupo tiene parámetros incorrectos",
      404: "El permiso de grupo no existe",
    },
  },
  initiative_users: {
    title: "Responsables de iniciativa",
    not_found: "No se encontraron Responsables de iniciativa",
    table: {
      id: "ID",
      name: "Nombre",
      email: "Correo",
      responsability: "Responsabilidad",
      action: "Acciones",
    },
    form: {
      create: "Añadir Resposnable de iniciativa",
      delete: "Eliminar responsable",
    },
    errors: {
      400: "El responsable de la iniciativa tiene parámetros incorrectos",
      404: "El responsable de la iniciativa no existe",
      403: "No puedes eliminar este responsable de la iniciativa porque es líder o sponsor",
    },
  },
  advances: {
    title: "Avances",
    not_found: "No se encontraron avances",
    table: {
      id: "ID",
      name: "Nombre",
      url: "URL",
    },
    form: {
      create: "Añadir avance",
      delete: "Eliminar avance",
      deleteConfirm: "¿Estás seguro de que quieres eliminar este avance?",
    },
    errors: {
      400: "El avance tiene parámetros incorrectos",
      404: "El avance no existe",
    },
  },
  documents: {
    title: "Documentos",
    not_found: "No se encontraron documentos",
    external: "Link",
    file_upload: "Subir archivo",
    external_link: "Link externo",
    table: {
      name: "Nombre",
      url: "URL",
      type: "Tipo",
      version_number: "Versión",
      actions: "Acciones"
    },
    modal: {
      title: "Subir documento",
      name: "Nombre del documento",
      link: "Link del documento",
      advanced_options: "Opciones avanzadas",
      mime_label: "Tipo de archivo",
      mime_type: "Tipo de archivo",
      dropzone: "Arrastra y suelta un archivo aquí o haz clic para seleccionar un archivo",
      upload: "Subir",
      cancel: "Cancelar",
    },
    form: {
      create: "Añadir documento",
      edit: "Editar documento",
      delete: "Eliminar documento",
      deleteConfirm: "¿Estas seguro de que quieres eliminar este documento?",
      confirm: "Si",
      reject: "No",
    },
    errors: {
      400: "El documento tiene parametros incorrectos",
      404: "El documento no existe"
    },
  },
  kshifts: {
    title: "Cambios",
    not_found: "No se encontraron cambios",
    success: "actualizado correctamente",
    add_month: "+",
    edit_values: "editar",
    table: {
      id: "ID",
      kpisecond: "KPI",
      base: "Promesa",
      forecast: "Forecast",
      real: "Real",
      month: "Mes",
      year: "Año",
    },
    form: {
      create: "Añadir cambio",
      save: "Guardar cambio",
      edit: "Editar cambio",
    },
    errors: {
      400: "El cambio tiene parámetros incorrectos",
      404: "El cambio no existe",
    },
  },
  configs: {
    title: "Configuraciones",
    not_found: "No se encontraron configuraciones",
    table: {
      id: "ID",
      name: "Nombre",
      value: "Valor",
    },
    form: {
      create: "Añadir configuración",
      save: "Guardar configuración",
      edit: "Editar configuración",
    },
    errors: {
      400: "La configuración tiene parámetros incorrectos",
      404: "La configuración no existe",
    },
  },
  trees: {
    title: "Árbol de valor",

    not_found: "No se encontraron valores",
    no_parent: "No tiene padre",
    no_children: "No tiene hijos",

    update_tshift: "Actualizar cambios",
    table: {
      id: "ID",
      name: "Nombre",
      abv: "Abreviatura",
      parent: "Padre",
      calculation: "Cálculo",
      initial_value: "Valor inicial",
      initial_date: "Fecha inicial",
      promise_value: "Valor de promesa",
      forecast_value: "Valor de forecast",
      promise_date: "Fecha de promesa",
      forecast_date: "Fecha de forecast",
    },
    form: {
      create: "Añadir valor",
      save: "Guardar valor",
      edit: "Editar valor",
      show: "Mostrar valores",
      hide: "Ocultar valores",
      delete: "Eliminar rama",
      deleteConfirm: "¿Estás seguro de que quieres eliminar este valor?",
      update: "Actualizar cambios",
      updateConfirm: "¿Estás seguro de que quieres actualizar este valor? Esto puede provocar varios cambios en los valores",
      updateWith: "Actualizar Valor",
      updateWithout: "Ajustar Valores Intermedios",
      copy: "Replicar Promesa",
    },
    names: {
      value: "Valor",
      table: "Tabla de ",
    },
    errors: {
      400: "El valor tiene parámetros incorrectos",
      404: "El valor no existe",
      calculation: "La fórmula de cálculo no es válida",
      403: "No puedes eliminar esta rama porque tiene hijos",
    },
  },
  tshifts: {
    title: "Cambios",
    not_found: "No se encontraron cambios",
    table: {
      id: "ID",
      initiative: "Iniciativa",
      tree: "Valor",
      base: {
        org: "Base",
        ini: "Promesa",
      },
      forecast: "Forecast",
      real: "Real",
      month: "Mes",
      year: "Año",
      initiative_value: "Valor de iniciativas",
    },
    form: {
      create: "Añadir cambio",
      save: "Guardar cambio",
      edit: "Editar cambio",
    },
    success: "actualizado correctamente" ,
    errors: {
      400: "El cambio tiene parámetros incorrectos",
      404: "El cambio no existe",
      wrong_calculation:
        "Fórmula de cálculo incorrecta.\nDebe contener abreviaciones y operaciones +-*/^",
    },
  },
  tasks: {
    title: "Compromisos de reunión",
    my_title: "Mis compromisos de reunión",
    not_found: "No se encontraron compromisos",
    not_active: "No se encontraron compromisos activas",
    no_date: "Sin fecha",
    date: "Fecha",
    table: {
      id: "ID",
      initiative: "Iniciativa",
      description: "Compromiso",
      area: "Área",
      user: "Responsable",
      forecast_date: "Comprometida",
      estimated_date: "Estimada",
      stage: "Etapa",
      created_at: "Creación",
      completed: "Completada",
      status: "Estado",
      estimated_date_max: "Fecha máxima estimada",
    },
    form: {
      create: "Añadir compromiso",
      save: "Guardar compromiso",
      complete: "Completar",
      edit: "Editar compromiso",
      cancel: "Cancelar",
      delete: "Eliminar compromiso",
      deleteConfirm: "¿Estás seguro de que quieres eliminar este compromiso?",
      actions: "Acciones",
    },
    completed: {
      yes: "Si",
      no: "No",
      annulment: "Anulada",
    },
    status: {
      complete: "Completada",
      in_progress: "En progreso",
      late: "Atrasada",
    },
    errors: {
      400: "El compromiso tiene parámetros incorrectos",
      404: "El compromiso existe",
    },
  },
  home: {
    titles: {
      summary: "Resumen de mis iniciativas",
    },
  },
  control: {
    titles: {
      summary: "Resumen",
    },
    tabs: {
      general: "Iniciativas",
      progress: "Resumen",
    },
  },
  info_states: {
    title: "Estados de iniciativa",
    not_found: "No se encontraron estados",
    table: {
      id: "ID",
      name: "Nombre",
    },
    form: {
      create: "Añadir estado",
      delete: "Eliminar estado",
      deleteConfirm: "¿Estás seguro de que quieres eliminar este estado?",
      select: "Seleccionar decisión",
    },
    errors: {
      400: "El estado tiene parámetros incorrectos",
      404: "El estado no existe",
      403: "No puedes eliminar este estado porque está en uso",
    },
  },
};

export default language;
