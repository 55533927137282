import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getCurrentUserActivities, getAllAreas } from "../../redux/actions";
import { language } from "../../assets/language";
import SimpleTable from "../../components/tables/SimpleTable";
import { AcceptActivity } from ".";

import { Badge } from "reactstrap";
const ListMyActivities = ({ dispatch }) => {
  const activities = useSelector((state) => state.activities.activities);
  const loadingActivities = useSelector((state) => state.activities.loading);
  const user = useSelector((state) => state.sessions.user);
  const areas = useSelector((state) => state.areas.areas);
  const areas_dict = useMemo(() => {
    return areas.reduce((acc, area) => {
      acc[area.id] = area;
      return acc;
    }, {});
  }, [areas]);
  const phases = useSelector((state) => state.phases.phases);
  const initiatives = useSelector((state) => state.initiatives.initiatives);
  const [completedFilter, setCompletedFilter] = useState([false]);

  useEffect(() => {
    dispatch(getCurrentUserActivities());
    dispatch(getAllAreas());
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: language.activities.table.title,
        accessor: "description",
      },
      {
        Header: language.activities.table.initiative,
        accessor: "initiative",
      },

      {
        Header: language.activities.table.area,
        accessor: "area",
      },
      {
        Header: language.activities.table.created_at,
        accessor: "created_at",
      },
      {
        Header: language.activities.table.forecast_date,
        accessor: "forecast_date",
      },
      {
        Header: language.activities.table.estimated_date,
        accessor: "estimated_date",
      },
      {
        Header: language.activities.table.stage,
        accessor: "stage",
      },
      {
        Header: language.activities.table.status,
        accessor: "status",
      },
      {
        Header: language.activities.table.completed,
        accessor: "completed",
      },
      {
        Header: language.activities.form.edit,
        accessor: "edit",
      },
    ],
    []
  );

  const renderActivities = () => {
    if (loadingActivities) {
      return <p>{language.general.loading}</p>;
    }

    let activitiesList = activities
      .filter((activity) =>  activity.responsable_user === user.id)
      .filter((activity) => {
        return completedFilter.includes(activity.completed);
      })
      .filter((activity) => {
        if (initiatives) {
          let ini = initiatives.find(
            (initiative) => initiative.id === activity.initiative_id
          );
          if (ini) {
            return ini.is_deleted !== true;
          }
        }
        return true;
      })
      .map((activity) => {
        return {
          id: activity.id,

          description: activity.description,
          initiative: (
            <Link to={`/initiative/${activity.initiative_id}`}>
              {activity.initiative !== null && activity.initiative !== undefined
                ? activity.initiative.title
                : "-"}
            </Link>
          ),

          area:
            areas_dict &&
            activity["area_id"] &&
            areas_dict.hasOwnProperty(activity["area_id"])
              ? areas_dict[activity["area_id"]].name
              : "",
          stage: phases.hasOwnProperty(activity.stage)
            ? phases[activity.stage].name
            : activity.stage,
          created_at:
            activity.created_at.split("T")[0] ||
            language["activities"]["no_date"],
          forecast_date:
            activity.forecast_date.split("T")[0] ||
            language["activities"]["no_date"],
          estimated_date: activity.estimated_date
            ? activity.estimated_date.split("T")[0] ||
              language["activities"]["no_date"]
            : language["activities"]["no_date"],
          status:
            activity.status === "complete" ||
            activity.status === "in_progress" ? (
              <Badge color='success'>
                {language["activities"]["status"][activity.status]}
              </Badge>
            ) : (
              <Badge color='danger'>
                {language["activities"]["status"][activity.status]}
              </Badge>
            ),
          completed: activity.completed ? (
            <Badge color='success'>
              {language["activities"]["completed"]["yes"]}
            </Badge>
          ) : (
            <Badge color='danger'>
              {language["activities"]["completed"]["no"]}
            </Badge>
          ),
          edit: <AcceptActivity activity={activity}  />,
        };
      });
    if (!activitiesList || activitiesList.length === 0) {
      return <p>{language["activities"]["not_found"]}</p>;
    }

    return <SimpleTable columns={columns} data={activitiesList} />;
  };

  const processCompletedFilter = (value) => {
    let _completed = completedFilter;
    if (completedFilter.includes(value)) {
      _completed = completedFilter.filter(function (f) {
        return f !== value;
      });
      setCompletedFilter(_completed);
    } else {
      setCompletedFilter([..._completed, value]);
    }
  };

  const renderCompletedFilter = () => {
    return (
      <>
        <label className='mr-2'>
          {language["activities"]["table"]["completed"]}:
        </label>
        <div className='custom-control custom-checkbox custom-control-inline'>
          <input
            type='checkbox'
            id='yes-checkbox'
            name='yes-checkbox'
            className='custom-control-input'
            checked={completedFilter.includes(true)}
            onChange={() => processCompletedFilter(true)}
          />
          <label className='custom-control-label' htmlFor='yes-checkbox'>
            {language["activities"]["completed"]["yes"]}
          </label>
        </div>
        <div className='custom-control custom-checkbox custom-control-inline'>
          <input
            type='checkbox'
            id='no-checkbox'
            name='no-checkbox'
            className='custom-control-input'
            checked={completedFilter.includes(false)}
            onChange={() => processCompletedFilter(false)}
          />
          <label className='custom-control-label' htmlFor='no-checkbox'>
            {language["activities"]["completed"]["no"]}
          </label>
        </div>
      </>
    );
  };

  return (
    <div>
      <h2>{language["activities"]["my_title"]}</h2>
      {renderCompletedFilter()}
      {renderActivities()}
    </div>
  );
};

export default connect()(ListMyActivities);
