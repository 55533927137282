import React, { useState } from "react";
import { connect } from "react-redux";
import
{
    Button,
    Card,
    CardBody,
    Modal,
} from "reactstrap";
const ConfirmModal = ({  confirm, buttonText, confirmText, disabled=false, className="btn-sm mb-1", color="success", outline=false}) =>
{
    let [modal, toggleModal] = useState(false);


    return (
        <div>
            <Button
                color={color}
                className={ className}
                onClick={() => toggleModal(true)}
                block
                outline={outline}
                disabled={disabled}
            >
                {buttonText}
            </Button>
            <Modal
                className='modal-dialog-centered'
                isOpen={modal}
                toggle={() => toggleModal(false)}
            >
                <div className='modal-body p-2'>
                    <Card className='bg-secondary shadow border-0'>
                        <CardBody className='px-lg-5 py-lg-2'>
                            <p>{confirmText}</p>

                            <div className='text-center mt-4'>
                                <Button
                                    onClick={() => toggleModal(false)}
                                    color='danger'
                                    className='btn-sm'
                                >
                                    No
                                </Button>
                                <Button color='success' onClick={() => { toggleModal(false); confirm() }} className='btn-sm' type='submit'>
                                    Si
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        </div>
    );
};

export default connect()(ConfirmModal);
