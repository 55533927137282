import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import {
    getInitiativeUsers,
} from '../../redux/actions';
import { language } from '../../assets/language';
import SimpleTable from '../../components/tables/SimpleTable';
import { CreateInitiativeUser, DeleteInitiativeUser } from '.';
import { getUserPermission } from '../../utils/getUserPermission';
import { getResponsabilityPermission } from '../../utils/getResponsabilityPermission';

const ListInitiativeUsers = ({ dispatch, initiative }) => {

    const initiative_users = useSelector(state => state.initiative_users.users);
    const initiativeIdResponse = useSelector(state => state.initiative_users.initiative_id);
    const state = useSelector(state => state);
    const currentUser = useSelector(state => state.sessions.user);

    const [loading, setLoading] = useState(true);
    

    
    useEffect(() => {
        dispatch(getInitiativeUsers(initiative.id));
    }, [dispatch, initiative]);

    useEffect(() => {
        if (initiative_users && initiative_users.length > 0) {
            setLoading(false);
        }
    }, [initiative_users]);


       

    

    const columns = useMemo(
        () => {
            let col = [
                {
                    Header: language.users.table.full_name,
                    accessor: 'full_name',
                },
                {
                    Header: language.users.table.email,
                    accessor: 'email',
                },
                {
                    Header: language.users.table.responsability,
                    accessor: 'responsability',
                }

            ];
            if (
                (initiative.stage !== "evaluation" && (
                    getUserPermission(state, "initiative_users", "delete", false)
                ))
                || (initiative.stage === "evaluation" && (
                    initiative.leader_user === currentUser.id ||
                    getUserPermission(state, "initiative_users", "delete", false)
                ))
            ) {
                col.push({
                    Header: language.general.actions,
                    accessor: 'actions',
                });

            };
            return col;
        },
        [state]
    );

    const renderUsers = () => {

        let _initiative_users = initiative_users.map(c => {

            return {
                id: c.id,
                initiative_id: c.initiative_id,
                user_id: c.user_id,
                full_name: c.full_name,
                email: c.email,
                responsability: c.user_id === initiative.sponsor_user
                ? language.initiatives.table.sponsor
                : language.initiatives.table.leader ,
              
                actions: c.user_id === initiative.leader_user  || c.user_id === initiative.sponsor_user ? "" : <DeleteInitiativeUser resource={c} setLoading={setLoading}  />
            }
        });

        if (!_initiative_users || _initiative_users.length === 0) {
            return <p>{language["users"]["not_found"]}</p>
        }

        return <SimpleTable data={_initiative_users} columns={columns} />

    };

    return (
        <div>
            {((getUserPermission(state, "initiative_users", "patch", false))) || ((initiative_users.map(u => u.user_id).includes(currentUser.id) && getResponsabilityPermission(state, "initiative_users", "create",initiative.id, "Lider"))) 
            ? (
                <CreateInitiativeUser initiative={initiative} setLoading={setLoading} />
            ) : null}
            <h1>{language.users.title}</h1>
            <br />

            {initiativeIdResponse === initiative.id && !loading ? renderUsers(): ""}
        </div>
    );
}

export default connect()(ListInitiativeUsers);
