import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";

export const setReviveInitiative = (data) => ({
  type: "SET_REVIVE_INITIATIVE",
  payload: data,
  request_time: Date.now(),
});

export const setPermanentDeleteInitiative = (data) => ({
  type: "SET_PERMANENT_DELETE_INITIATIVE",
  payload: data,
  request_time: Date.now(),
});

export const permanentDeleteInitiative = (id) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (!getUserPermission(state, "initiatives", "delete")) {
        throw new Error("No permissions");
      }
      fetch(`${BACKEND_URL}/initiatives/${id}/stages`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.sessions.token}`,
        },
        body: JSON.stringify({ id }),
      })
        .then((response) => {
          if (response.status === 204) {
            return { message: "OK" };
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          if (data != null && data !== undefined) {
            dispatch(setPermanentDeleteInitiative(data));
          }
        })
        .catch((error) => {
          errorHandler("decisions", error.message);
          dispatch(setPermanentDeleteInitiative(error));
        });
    } catch (error) {
      errorHandler("decisions", error.message);
      dispatch(setPermanentDeleteInitiative(error));
    }
  };
};

export const reviveInitiative = (id) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (!getUserPermission(state, "initypes", "create")) {
        throw new Error("No permissions");
      }
      fetch(`${BACKEND_URL}/initiatives/${id}/stages`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.sessions.token}`,
        },
        body: JSON.stringify({ id }),
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          dispatch(setReviveInitiative(data));
        })
        .catch((error) => {
          errorHandler("initiatives", error.message);
          dispatch(setReviveInitiative(error));
        });
    } catch (error) {
      errorHandler("initiatives", error.message);
      dispatch(setReviveInitiative(error));
    }
  };
};
