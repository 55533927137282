import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";


export const setTrees = (trees) => ({
    type: "SET_TREES",
    payload: {
        trees: trees,
    },
    request_time: Date.now()
});

export const setNewTree = (data) => ({
    type: "NEW_TREE",
    payload: {
        id: data.id,
        name: data.name,
        abv: data.abv,
        parent_id: data.parent_id,
        calculation: data.calculation,
        initial_value: data.initial_value,
        forecast_value: data.forecast_value,
        promise_value: data.promise_value,
        initial_date: data.initial_date,
        promise_date: data.promise_date,
        forecast_date: data.forecast_date,
        initiative_id: data.initiative_id,

        request_time: Date.now()
    },
});

export const setTree = (data) => ({
    type: "SET_TREE",
    payload: {
        id: data.id,
        name: data.name,
        abv: data.abv,
        parent_id: data.parent_id,
        calculation: data.calculation,
        initial_value: data.initial_value,
        forecast_value: data.forecast_value,
        promise_value: data.promise_value,
        initial_date: data.initial_date,
        promise_date: data.promise_date,
        forecast_date: data.forecast_date,
        initiative_id: data.initiative_id,

        request_time: Date.now()
    },
});

export const updateTree = (data) => ({
    type: "UPDATE_TREE",
    payload: {
        id: data.id,
        name: data.name,
        abv: data.abv,
        parent_id: data.parent_id,
        calculation: data.calculation,
        initial_value: data.initial_value,
        forecast_value: data.forecast_value,
        promise_value: data.promise_value,
        initial_date: data.initial_date,
        promise_date: data.promise_date,
        forecast_date: data.forecast_date,
        initiative_id: data.initiative_id,
        request_time: Date.now()
    },
});

export const setTreeError = (error) => ({
    type: "TREE_ERROR",
    payload: {
        error: error
    }
});

export const deleteTree = (tree_id) => ({
    type: "DELETE_TREE",
    payload: {
        id: tree_id,
    },
    request_time: Date.now()
});

export const getAllTrees = () => {

    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "trees", "list")) {
                throw new Error("No permissions");
            }
                fetch(`${BACKEND_URL}/trees`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setTrees(data));
                    })
                    .catch((error) => {
                        errorHandler("trees", error.message);
                        dispatch(setTreeError(error));
                    });
        
        } catch (error) {
            errorHandler("trees", error.message);
            dispatch(setTreeError(error));
        }
    };
}

//gettressbyInitiative
export const getTreesByInitiative = (initiative_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "trees", "list", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/initiatives/${initiative_id}/trees`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    if (data !== null && data !== undefined ) {
                        dispatch(setTrees(data));
                    } else {
                        dispatch(setTrees([]));
                    }

                }).catch((error) => {
                    errorHandler("trees", error.message);
                    dispatch(setTreeError(error));
                })
        } catch (error) {
            errorHandler("trees", error.message);
            dispatch(setTreeError(error));
        }
    };
}



export const getTreeById = (tree_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "trees", "list", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/trees/${tree_id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    if (data !== null && data !== undefined && data.id !== undefined) {
                        dispatch(setTree(data));
                    }
                })
                .catch((error) => {
                    errorHandler("trees", error.message);
                    dispatch(setTreeError(error));
                });
        } catch (error) {
            errorHandler("trees", error.message);
            dispatch(setTreeError(error));
        }
    };
}

export const postTree = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "trees", "create", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/trees`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
                body: JSON.stringify(data)
            })
                .then((response) => {
                    if (response.status === 201) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    if (data != null && data !== undefined && data.id !== undefined) {
                        dispatch(setNewTree(data));
                    }
                })
                .catch((error) => {
                    errorHandler("trees", error.message);
                    dispatch(setTreeError(error));
                });
        } catch (error) {
            errorHandler("trees", error.message);
            dispatch(setTreeError(error));
        }
    };
}

export const deleteTreeById = (tree_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "trees", "delete", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/trees/${tree_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
                .then((response) => {
                    if (response.status === 204) {
                        return true;
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((vl) => {
                    if (vl) {
                        dispatch(deleteTree(tree_id));
                    }
                })
                .catch((error) => {
                    errorHandler("trees", error.message);
                    dispatch(setTreeError(error));
                });
        } catch (error) {
            errorHandler("trees", error.message);
            dispatch(setTreeError(error));
        }
    };
}

export const patchTree = (tree) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (getUserPermission(state, "trees", "patch", true)) {
                fetch(`${BACKEND_URL}/trees/${tree.id}`, {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                    body: JSON.stringify(tree)
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        if (data != null && data !== undefined && data.id !== undefined) {
                            dispatch(updateTree(data));
                        }
                    })
                    .catch((error) => {
                        errorHandler("trees", error.message);
                        dispatch(setTreeError(error));
                    });
            }
        } catch (error) {
            errorHandler("trees", error.message);
            dispatch(setTreeError(error));
        }
    };
}

export const updateTreeTshifts = (tree) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (getUserPermission(state, "trees", "patch", true)) {
                fetch(`${BACKEND_URL}/trees/${tree.id}/tshifts`, {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                    body: JSON.stringify(tree)
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        if (data != null && data !== undefined && data.id !== undefined) {
                            dispatch(updateTree(data));
                        }
                    })
                    .catch((error) => {
                        errorHandler("trees", error.message);
                        dispatch(setTreeError(error));
                    });
            }
        } catch (error) {
            errorHandler("trees", error.message);
            dispatch(setTreeError(error));
        }
    };
}


export const copyTshiftPromise = (id) => {
    return (dispatch, getState) => {
      const state = getState();
      try {
          fetch(`${BACKEND_URL}/trees/${id}/tshifts/copy`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${state.sessions.token}`,
            },
          })
            .then((response) => {
              if (response.status === 200) {
                return response.json();
              } else {
                throw new Error(response.status);
              }
            })
            .then((vl) => {
              if (vl) {
                dispatch(updateTree(vl));
              }
            })
            .catch((error) => {
              errorHandler("kpiseconds", error.message);
              dispatch(setTreeError(error));
            });
  
      } catch (error) {
        errorHandler("kpiseconds", error.message);
        dispatch(setTreeError(error));
      }
    };
  };