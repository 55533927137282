import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { getAllInitypes } from "../../redux/actions";
import { language } from "../../assets/language";
import SimpleTable from "../../components/tables/SimpleTable";
import { CreateInitype, DeleteInitype } from ".";
import { getUserPermission } from "../../utils/getUserPermission";
import { Card, CardBody } from "reactstrap";

const ListInitypes = ({ dispatch }) => {
  const initypes = useSelector((state) => state.initypes.initypes);
  const state = useSelector((state) => state);

  useEffect(() => {
    dispatch(getAllInitypes());
  }, [dispatch]);

  const columns = useMemo(() => {
    let col = [
      {
        Header: language.initypes.table.name,
        accessor: "name",
      },
    ];
    if (getUserPermission(state, "initypes", "delete")) {
      col.push({
        Header: language.general.actions,
        accessor: "actions",
      });
    }
    return col;
  }, [state]);

  const renderInitypes = () => {
    if (!initypes || initypes.length === 0) {
      return <p>{language["initypes"]["not_found"]}</p>;
    }

    let initypesList = Object.values(initypes).map((initype) => {
      return {
        id: initype.id,
        name: initype.name,
        actions: <DeleteInitype initype={initype} />,
      };
    });

    return <SimpleTable columns={columns} data={initypesList} />;
  };

  return (
    <Card className='mt-3'>
      <CardBody>
        <h1 className='float-left'>{language.initypes.title}</h1>
        <CreateInitype />
        {renderInitypes()}
      </CardBody>
    </Card>
  );
};

export default connect()(ListInitypes);
