import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { getDecisions } from "../../redux/actions";
import { language } from "../../assets/language";
import SimpleTable from "../../components/tables/SimpleTable";
import { getUserPermission } from "../../utils/getUserPermission";
import { Button, Card, CardBody } from "reactstrap";
import CreateDecision from "./CreateDecision";
import EditDecision from "./EditDecision";
import { deleteDecisionById } from "../../redux/actions";
import DeleteDecisions from "./DeleteDecisions";

const ListDecisions = ({ dispatch }) => {
  const decisions = useSelector((state) => state.decisions.decisions);
  const createDecision = useSelector((state) => state.decisions.create);
  const updateDecision = useSelector((state) => state.decisions.update);
  const state = useSelector((state) => state);

  useEffect(() => {
    dispatch(getDecisions());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDecisions());
  }, [createDecision]);

  useEffect(() => {
    dispatch(getDecisions());
  }, [updateDecision]);

  const columns = useMemo(() => {
    let col = [
      {
        Header: language.initypes.table.name,
        accessor: "name",
      },
    ];
    if (getUserPermission(state, "initypes", "delete")) {
      col.push({
        Header: language.general.actions,
        accessor: "actions",
      });
    }
    return col;
  }, [state]);

  const renderDecisions = () => {
    if (!decisions || decisions.length === 0) {
      return <p>{language["initypes"]["not_found"]}</p>;
    }

    let tagsList = Object.values(decisions).map((decision) => {
      return {
        id: decision.id,
        name: decision.name,
        actions: (
          <span>
            <EditDecision decision={decision} />
            <DeleteDecisions decision={decision} />
          </span>
        ),
      };
    });

    return <SimpleTable columns={columns} data={tagsList} />;
  };

  return (
    <Card className='mt-3'>
      <CardBody>
        <h1 className='float-left'>{language.decisions.title}</h1>
        <CreateDecision />
        {renderDecisions()}
      </CardBody>
    </Card>
  );
};

export default connect()(ListDecisions);
