import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";

export const setInitypes = (initypes) => ({
  type: "SET_INITYPES",
  payload: {
    initypes: initypes,
  },
  request_time: Date.now(),
});

export const updateInitypes = (initypes) => ({
  type: "PATCH_INITYPES",
  payload: {
    initypes: initypes,
  },
  request_time: Date.now(),
});

export const setInitype = (data) => ({
  type: "SET_INITYPE",
  payload: {
    id: data.id,
    name: data.name,
    request_time: Date.now(),
  },
});

export const setInitypeError = (error) => ({
  type: "INITYPE_ERROR",
  payload: {
    error: error,
  },
});

export const deleteInitype = (initype_id) => ({
  type: "DELETE_INITYPE",
  payload: {
    id: initype_id,
  },
  request_time: Date.now(),
});

export const getAllInitypes = () => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (!getUserPermission(state, "initypes", "list", true)) {
        throw new Error("No permissions");
      }
      if (
        state.initypes.request_time === null ||
        state.initypes.request_time + 1000 * 2 < Date.now()
      ) {
        fetch(`${BACKEND_URL}/initypes`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.sessions.token}`,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              throw new Error(response.status);
            }
          })
          .then((data) => {
            dispatch(setInitypes(data));
          })
          .catch((error) => {
            errorHandler("initypes", error.message);
            dispatch(setInitypeError(error));
          });
      }
    } catch (error) {
      errorHandler("initypes", error.message);
      dispatch(setInitypeError(error));
    }
  };
};

export const postInitype = (data) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (!getUserPermission(state, "initypes", "create")) {
        throw new Error("No permissions");
      }
      fetch(`${BACKEND_URL}/initypes`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.sessions.token}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 201) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          if (data != null && data !== undefined && data.id !== undefined) {
            dispatch(setInitype(data));
          }
        })
        .catch((error) => {
          errorHandler("initypes", error.message);
          dispatch(setInitypeError(error));
        });
    } catch (error) {
      errorHandler("initypes", error.message);
      dispatch(setInitypeError(error));
    }
  };
};

export const deleteInitypeById = (initype_id) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (!getUserPermission(state, "initypes", "delete", true)) {
        throw new Error("No permissions");
      }
      fetch(`${BACKEND_URL}/initypes/${initype_id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.sessions.token}`,
        },
      })
        .then((response) => {
          if (response.status === 204) {
            return true;
          } else {
            throw new Error(response.status);
          }
        })
        .then((vl) => {
          if (vl) {
            dispatch(deleteInitype(initype_id));
          }
        })
        .catch((error) => {
          errorHandler("initypes", error.message);
          dispatch(setInitypeError(error));
        });
    } catch (error) {
      errorHandler("initypes", error.message);
      dispatch(setInitypeError(error));
    }
  };
};

export const updateInitype = (data) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (!getUserPermission(state, "initypes", "create")) {
        throw new Error("No permissions");
      }
      fetch(`${BACKEND_URL}/initypes`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.sessions.token}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 202) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          if (data != null && data !== undefined && data.id !== undefined) {
            dispatch(updateInitypes(data));
          }
        })
        .catch((error) => {
          errorHandler("initypes", error.message);
          dispatch(setInitypeError(error));
        });
    } catch (error) {
      errorHandler("initypes", error.message);
      dispatch(setInitypeError(error));
    }
  };
};
