import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { postRole } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { Button, CardBody, Card, Modal, Input } from "reactstrap";

const CreateRole = ({ dispatch }) => {
  let [modalRoles, setModalRoles] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalRoles(false);
  });

  const handleSubmitRoles = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    dispatch(postRole(object));
    setModalRoles(false);
  };

  const renderModalFormRoles = () => {
    return (
      <>
        <Button
          color='success'
          className='btn-sm float-right  mb-2'
          onClick={() => setModalRoles(true)}
        >
          {language["roles"]["form"]["create"]}
        </Button>
        <Modal
          className='modal-dialog-centered'
          isOpen={modalRoles}
          toggle={() => setModalRoles(false)}
        >
          <div className='modal-body p-0'>
            <Card className='bg-secondary shadow border-0' ref={wrapperRef}>
              <CardBody className='px-lg-5 py-lg-2'>
                <h3>{language["roles"]["form"]["create"]}</h3>
                <form onSubmit={handleSubmitRoles}>
                  <label htmlFor='name'>
                    {language["roles"]["table"]["name"]}
                  </label>
                  <Input
                    maxLength={254}
                    className='form-control-sm'
                    type='text'
                    name='name'
                    required
                  />
                  <br />
                  <div>
                    <Button color='success' className='btn-sm' type='submit'>
                      {language["roles"]["form"]["create"]}
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </>
    );
  };

  return <div>{renderModalFormRoles()}</div>;
};

export default connect()(CreateRole);
