import { toast } from "react-toastify";
import { language } from "../../assets/language";
import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";

export const newApproval = (data) => ({
    type: "NEW_APPROVAL",
    payload: {
        id: data.id,
        area_id: data.area_id,
        forecast_date: data.forecast_date,
        stage: data.stage,
        next_stage: data.next_stage,
        responsable_user: data.responsable_user,
        is_accepted: data.is_accepted,
        is_active: data.is_active,
        initiative_id: data.initiative_id ? data.initiative_id : null,
        initiative: data.initiative ? data.initiative : null,
        notes: data.notes,
    },
    request_time: Date.now()
});

export const setApprovalError = (error) => ({
    type: "APPROVAL_ERROR",
    payload: {
        error: error
    }
});

export const deleteApproval = (approval_id) => ({
    type: "DELETE_APPROVAL",
    payload: {
        id: approval_id,
    },
    request_time: Date.now()
});

export const updateApproval = (approval) => ({
    type: "UPDATE_APPROVAL",
    payload: {
        id: approval.id,
        area_id: approval.area_id,
        forecast_date: approval.forecast_date,
        stage: approval.stage,
        next_stage: approval.next_stage,
        responsable_user: approval.responsable_user,
        is_accepted: approval.is_accepted,
        is_active: approval.is_active,
        initiative_id: approval.initiative_id ? approval.initiative_id : null,
        initiative: approval.initiative ? approval.initiative : null,
        notes: approval.notes,
    },
    request_time: Date.now()
});

export const setApprovals = (approvals) => ({
    type: "SET_APPROVALS",
    payload: {
        approvals: approvals,
    },
    request_time: Date.now()
});

export const setInitiativeApprovals = (approvals, initiative_id) => ({
    type: "SET_INITIATIVE_APPROVALS",
    payload: {
        approvals: approvals,
        initiative_id: initiative_id,
    },
    request_time: Date.now()
});

export const getCurrentUserApprovals = () => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "approvals", "list", true)) {
                throw new Error("No permissions");
            }
            if (state.approvals.request_time === null || state.approvals.request_time + 1000 * 2 < Date.now()) {
                fetch(`${BACKEND_URL}/users/${state.sessions.user.id}/approvals`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setApprovals(data));
                    })
                    .catch((error) => {
                        errorHandler("approvals", error.message);
                        dispatch(setApprovalError(error));
                    });
            }
        } catch (error) {
            errorHandler("approvals", error.message);
            dispatch(setApprovalError(error));
        }
    };
}

export const postApproval = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            // if (!getUserPermission(state, "approvals", "create", true)) {
            // throw new Error("No permissions");
            // }
            if (state.initiatives.initiative.leader_user === state.sessions.user.id
                || state.initiatives.initiative.sponsor_user === state.sessions.user.id
                || state.sessions.user.role === 'admin'
                || getUserPermission(state, "approvals", "create", true)) {
                fetch(`${BACKEND_URL}/approvals`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                    body: JSON.stringify(data)
                })
                    .then((response) => {
                        if (response.status === 201) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        if (data != null && data !== undefined && data.id !== undefined) {
                            dispatch(newApproval(data));
                        }
                    })
                    .catch((error) => {
                        errorHandler("approvals", error.message);
                        dispatch(setApprovalError(error));
                    });
            }
        } catch (error) {
            errorHandler("approvals", error.message);
            dispatch(setApprovalError(error));
        }
    };
}

export const getInitiativeApprovals = (initiative_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "approvals", "list", true)) {
                throw new Error("No permissions");
            }
            if (state.approvals.initiative_request_time === null || state.approvals.initiative_request_time + 1000 * 2 < Date.now()
                || state.approvals.initiative_id !== initiative_id) {
                fetch(`${BACKEND_URL}/initiatives/${initiative_id}/approvals`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setInitiativeApprovals(data, initiative_id));
                    })
                    .catch((error) => {
                        errorHandler("approvals", error.message);
                        dispatch(setApprovalError(error));
                    });
            }
        } catch (error) {
            errorHandler("approvals", error.message);
            dispatch(setApprovalError(error));
        }
    };
}

export const patchApproval = (approvalId, data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "approvals", "patch", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/approvals/${approvalId}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
                method: "PATCH",
                body: JSON.stringify(data)
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    dispatch(updateApproval(data));
                })
                .catch((error) => {
                    errorHandler("approvals", error.message);
                    dispatch(setApprovalError(error.message));
                });
        } catch (error) {
            errorHandler("approvals", error.message);
            dispatch(setApprovalError(error));
        }
    };
}

export const acceptApproval = (approvalId, data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "approvals", "patch", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/approvals/${approvalId}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
                method: "POST",
                body: JSON.stringify(data)
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    dispatch(updateApproval(data));
                })
                .catch((error) => {
                    errorHandler("approvals", error.message);
                    dispatch(setApprovalError(error.message));
                });
        } catch (error) {
            errorHandler("approvals", error.message);
            dispatch(setApprovalError(error));
        }
    };
}

export const patchInitiativeApprovals = (initiativeId, data = {}) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "approvals", "patch")) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/initiatives/${initiativeId}/approvals`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
                method: "PATCH",
                body: JSON.stringify(data)
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    dispatch(setInitiativeApprovals(data));
                })
                .catch((error) => {
                    errorHandler("approvals", error.message);
                    dispatch(setApprovalError(error.message));
                });
        } catch (error) {
            errorHandler("approvals", error.message);
            dispatch(setApprovalError(error));
        }
    };
}

export const deleteApprovalById = (approval_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            // if (!getUserPermission(state, "approvals", "delete", true)) {
            // throw new Error("No permissions");
            // }
            if (state.initiatives.initiative.leader_user === state.sessions.user.id
                || state.initiatives.initiative.sponsor_user === state.sessions.user.id
                || state.sessions.user.role === 'admin'
                || getUserPermission(state, "approvals", "delete", true)) {
                fetch(`${BACKEND_URL}/approvals/${approval_id}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 204) {
                            return true;
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((vl) => {
                        if (vl) {
                            dispatch(deleteApproval(approval_id));
                        }
                    })
                    .catch((error) => {
                        errorHandler("approvals", error.message);
                        dispatch(setApprovalError(error));
                    });
            }
        } catch (error) {
            errorHandler("approvals", error.message);
            dispatch(setApprovalError(error));
        }
    };
}

export const sendActiveApprovalsEmails = (initiativeId) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "approvals", "create", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/initiatives/${initiativeId}/approvals/send_active`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    toast.success(language.general.emails.sent_success);
                })
                .catch((error) => {
                    errorHandler("approvals", error.message);
                });
        } catch (error) {
            errorHandler("approvals", error.message);
        }
    };
}