import { toast } from "react-toastify";
import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";
import language from "../../assets/language";
import { getResponsabilityPermission } from "../../utils/getResponsabilityPermission";

export const newActivity = (data) => ({
    type: "NEW_ACTIVITY",
    payload: {
        id: data.id,
        description: data.description,
        area_id: data.area_id,
        created_at: data.created_at,
        forecast_date: data.forecast_date,
        estimated_date: data.estimated_date,
        real_date: data.real_date,
        stage: data.stage,
        responsable_user: data.responsable_user,
        completed: data.completed,
        status: data.status,
        initiative: data.initiative ? data.initiative : null,
        initiative_id: data.initiative_id ? data.initiative_id : null,
    },
    request_time: Date.now()
});

export const setActivityError = (error) => ({
    type: "ACTIVITY_ERROR",
    payload: {
        error: error
    }
});

export const deleteActivity = (activity_id) => ({
    type: "DELETE_ACTIVITY",
    payload: {
        id: activity_id,
    },
    request_time: Date.now()
});

export const updateActivity = (activity) => ({
    type: "UPDATE_ACTIVITY",
    payload: {
        id: activity.id,
        description: activity.description,
        area_id: activity.area_id,
        created_at: activity.created_at,
        forecast_date: activity.forecast_date,
        estimated_date: activity.estimated_date,
        real_date: activity.real_date,
        stage: activity.stage,
        status: activity.status,
        responsable_user: activity.responsable_user,
        completed: activity.completed,
        initiative: activity.initiative ? activity.initiative : null,
        initiative_id: activity.initiative_id ? activity.initiative_id : null,
    },
    request_time: Date.now()
});

export const setActivities = (activities) => ({
    type: "SET_ACTIVITIES",
    payload: {
        activities: activities,
    },
    request_time: Date.now()
});

export const setInitiativeActivities = (activities, initiative_id) => ({
    type: "SET_INITIATIVE_ACTIVITIES",
    payload: {
        activities: activities,
        initiative_id: initiative_id,
    },
    request_time: Date.now()
});

export const setActivitiesLoading = (value) => ({
    type: "SET_ACTIVITIES_LOADING",
    payload: {
        loading: value,
    },
    request_time: Date.now()
});

export const getCurrentUserActivities = () => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "activities", "list", true)) {
                throw new Error("No permissions");
            }
            if ((!state.activities.loading && (state.activities.request_time === null || state.activities.request_time + 1000 * 2 < Date.now()))
                || (state.activities.loading && (state.activities.loading_time === null || state.activities.loading_time + 1000 * 5 < Date.now()))
            ) {
                dispatch(setActivitiesLoading(true));
                fetch(`${BACKEND_URL}/users/${state.sessions.user.id}/activities`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setActivitiesLoading(false));
                        dispatch(setActivities(data));
                    })
                    .catch((error) => {
                        dispatch(setActivitiesLoading(false));
                        errorHandler("activities", error.message);
                        dispatch(setActivityError(error));
                    });
            }
        } catch (error) {
            dispatch(setActivitiesLoading(false));
            errorHandler("activities", error.message);
            dispatch(setActivityError(error));
        }
    };
}

export const postActivity = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            // if (!getUserPermission(state, "activities", "create", true)) {
            // throw new Error("No permissions");
            // }
            if (state.initiatives.initiative.leader_user === state.sessions.user.id
                || state.initiatives.initiative.sponsor_user === state.sessions.user.id
                || state.sessions.user.role === 'admin'
                || getUserPermission(state, "activities", "create", true)) {
                fetch(`${BACKEND_URL}/activities`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                    body: JSON.stringify(data)
                })
                    .then((response) => {
                        if (response.status === 201) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        if (data != null && data !== undefined && data.id !== undefined) {
                            dispatch(newActivity(data));
                        }
                    })
                    .catch((error) => {
                        errorHandler("activities", error.message);
                        dispatch(setActivityError(error));
                    });
            }
        } catch (error) {
            errorHandler("activities", error.message);
            dispatch(setActivityError(error));
        }
    };
}

export const getInitiativeActivities = (initiative_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "activities", "list", true)) {
                throw new Error("No permissions");
            }
            if ((!state.activities.loading && (state.activities.initiative_request_time === null || state.activities.initiative_request_time + 1000 * 2 < Date.now() || state.activities.initiative_id !== initiative_id))
                || (state.activities.loading && (state.activities.loading_time === null || state.activities.loading_time + 1000 * 5 < Date.now()))
            ) {
                dispatch(setActivitiesLoading(true));
                fetch(`${BACKEND_URL}/initiatives/${initiative_id}/activities`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setActivitiesLoading(false));
                        dispatch(setInitiativeActivities(data, initiative_id));
                    })
                    .catch((error) => {
                        dispatch(setActivitiesLoading(false));
                        errorHandler("activities", error.message);
                        dispatch(setActivityError(error));
                    });
            }
        } catch (error) {
            dispatch(setActivitiesLoading(false));
            errorHandler("activities", error.message);
            dispatch(setActivityError(error));
        }
    };
}

export const patchActivity = (activityId, data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "activities", "change", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/activities/${activityId}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
                method: "PATCH",
                body: JSON.stringify(data)
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    dispatch(updateActivity(data));
                    toast.success(language.update_success)
                })
                .catch((error) => {
                    errorHandler("activities", error.message);
                    dispatch(setActivityError(error.message));
                });
        } catch (error) {
            errorHandler("activities", error.message);
            dispatch(setActivityError(error));
        }
    };
}

export const deleteActivityById = (activity_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            // if (!getUserPermission(state, "activities", "delete", true)) {
            // throw new Error("No permissions");
            // }
            if (getResponsabilityPermission(state, "activities", "delete", "", "Lider")
              
               
                || getUserPermission(state, "activities", "delete", true)) {
                fetch(`${BACKEND_URL}/activities/${activity_id}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 204) {
                            return true;
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((vl) => {
                        if (vl) {
                            dispatch(deleteActivity(activity_id));
                            toast.success("se ha elimiado correctamente")
                        }
                    })
                    .catch((error) => {
                        errorHandler("activities", error.message);
                        dispatch(setActivityError(error));
                    });
            }
        } catch (error) {
            errorHandler("activities", error.message);
            dispatch(setActivityError(error));
        }
    };
}