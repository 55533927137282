import React, { useEffect, useMemo, useState} from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { getInitiativeRisks } from '../../redux/actions';
import { language } from '../../assets/language';
import SimpleTable from '../../components/tables/SimpleTable';
import { DeleteRisk } from '.';
import { getUserPermission } from '../../utils/getUserPermission';
import { getResponsabilityPermission } from '../../utils/getResponsabilityPermission';
import UpdateRisk from './UpdateRisk';
import Loading from '../../components/Loading/Loading';


const ListInitiativeRisks = ({ dispatch, initiative }) => {

    const state = useSelector(state => state);
    const risks = useSelector(state => state.risks.risks);
    const currentUser = useSelector(state => state.sessions.user);
    const initiative_id = useSelector(state => state.risks.initiativeId);
    const [loading, setLoading] = useState(true);



    useEffect(() => {
        if (initiative.id === initiative_id ) {
            setLoading(false);
        } else {
            dispatch(getInitiativeRisks(initiative.id));
        }
    }, [dispatch, initiative, initiative_id, risks]);
            

    const columns = useMemo(
        () => {
            let col = [
                {
                    Header: language.risks.table.description,
                    accessor: 'description',
                },
                {
                    Header: language.risks.table.severity,
                    accessor: 'severity',
                },
                {
                    Header: language.risks.table.mitigator,
                    accessor: 'mitigator',
                },
                {
                    Header: language.risks.table.probability,
                    accessor: 'probability',
                },
                {
                    Header: language.risks.table.risk_type,
                    accessor: 'risk_type',
                },
            ];
            if (
                ((
                    getUserPermission(state, "risks", "patch", false)
                ))
                || (initiative.stage === "evaluation" && (
                    initiative.sponsor_user === currentUser.id ||
                    getResponsabilityPermission(state, "risks", "change", initiative.id, "Lider")
                    
                ))
            ) {
                col.push({
                    Header: language.risks.table.edit,
                    accessor: 'edit',
                });
            }
            if (
                ((
                    getUserPermission(state, "risks", "delete", false)
                ))
                || (initiative.stage === "evaluation" && (
                    initiative.sponsor_user === currentUser.id ||
                    getResponsabilityPermission(state, "risks", "delete", initiative.id, "Lider")
                    
                ))
            ) {
                col.push({
                    Header: language.risks.table.delete,
                    accessor: 'delete',
                });
            }
            return col;
        },
        [ initiative, currentUser, state],
        
    )

    const renderRisks = () => {
        if (!risks || risks.length === 0) {
            return <p>{language["risks"]["not_found"]}</p>
        }

        let risksList = risks.map((risk) => {
            return {
                id: risk.id,
                description: risk.description,
                severity: language["risks"]["severity"][risk.severity || 1],
                mitigator: risk.mitigator,
                probability: language["risks"]["probability"][risk.probability || 1],
                risk_type: language.risks.risk_type[risk.risk_type],
                edit: ( <UpdateRisk risk={risk} initiative={initiative} dispatch={dispatch} />),
                delete: (<DeleteRisk risk={risk} setLoading={setLoading} />),
                
            }
        });

        return <SimpleTable columns={columns} data={risksList} />

    }

    return  loading ? <Loading /> : (
        <div>
            {renderRisks()}
        </div>
    );
}

export default connect()(ListInitiativeRisks);