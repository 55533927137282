import React, { useState } from "react";
import { connect } from "react-redux";
import { language } from "../../assets/language";
import
{
    Button,
    Card,
    CardBody,
    Modal,
    CardTitle,
} from "reactstrap";

const DescriptionModal = ({ description, title, root, dashboard, handle }) =>
{
    let [modal, toggleModal] = useState(false);

    return (
        <div>
            <Button
                color='primary'
                className='btn-sm'
                onClick={() => toggleModal(true)}
            >
                {language['general']['see_more']}
            </Button>
            <Modal
                className='modal-dialog-centered'
                isOpen={modal}
                toggle={() => toggleModal(false)}
            >
                <div className='modal-body p-2'>
                    <Card className='bg-secondary shadow border-0'>
                        <CardTitle>
                            <h3 className='p-3'>{title}</h3>
                        </CardTitle>
                        <CardBody className='px-lg-5 py-lg-2'>
                            <p>{description}</p>

                            <div className='text-center mt-4'>
                                <Button
                                    onClick={() => toggleModal(false)}
                                    color='default'
                                    className='btn-sm'
                                >
                                    {language['general']['close']}
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        </div >
    );
};

export default connect()(DescriptionModal);
