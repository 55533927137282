const initialState_Phase = {
    request_time: null,
    error: null,
    phases: {},
};

const phases = (state = initialState_Phase, action) => {
    switch (action.type) {
        case 'SET_PHASES':
            return {
                phases: action.payload.phases.reduce((obj, item) => {
                    obj[item.identifier] = item;
                    return obj;
                }, {}),
                request_time: action.request_time,
            };
        case 'SET_PHASE':
            // let old_phases = state.phases;
            // old_phases[action.payload.identifier] = action.payload;
            return {
                ...state,
                phases: {
                    ...state.phases, [action.payload.identifier]: action.payload,
                },
                request_time: action.request_time,
            };
        case 'PHASE_ERROR':
            return {
                ...state,
                error: action.payload.error,
                request_time: null,
                // phases: {},
            };
        case "DELETE_PHASE":
            let new_phases = state.phases;
            try {
                new_phases = Object.keys(new_phases).reduce(function (filtered, key) {
                    if (new_phases[key].id !== action.payload.id) filtered[key] = new_phases[key];
                    return filtered;
                }, {});
            } catch (e) {
                console.log(e);
            }
            return {
                ...state,
                phases: new_phases,
                request_time: action.request_time,
            };
        default:
            return state;
    }
}

export default phases;