import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { deleteLeverById } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { Button } from "reactstrap";
import DeleteModal from "../../components/Modal/DeleteModal";
const DeleteLever = ({ dispatch, lever }) => {
  let [modalLevers, setModalLevers] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalLevers(false);
  });

  const deleteLeverButton = () => {
    if (modalLevers) {
      return <></>;
    }
    return (
      <Button
        color='danger'
        className='btn-sm'
        onClick={() => setModalLevers(true)}
      ></Button>
    );
  };

  const handleSubmitLevers = () => {
    dispatch(deleteLeverById(lever.id));
    setModalLevers(false);
  };

  const renderModalFormLevers = () => {
    if (modalLevers) {
      return (
        <div className='form' ref={wrapperRef}>
          <div>
            <Button
              color='danger'
              className='btn-sm'
              onClick={() => setModalLevers(false)}
            >
              {language["general"]["cancel"]}
            </Button>
          </div>
          <div>
            <Button
              color='danger'
              className='btn-sm'
              onClick={() => handleSubmitLevers()}
            >
              {language["levers"]["form"]["deleteConfirm"]}
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <DeleteModal
        buttonText={language["levers"]["form"]["delete"]}
        confirmText={language["levers"]["form"]["deleteConfirm"]}
        confirm={handleSubmitLevers}
      />
    </div>
  );
};

export default connect()(DeleteLever);
