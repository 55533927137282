import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { getInitiativeTasks, getAllAreas, getAllUsers } from '../../redux/actions';
import { language } from '../../assets/language';
import SimpleTable from '../../components/tables/SimpleTable';
import { getUserPermission } from '../../utils/getUserPermission';
import { AcceptTask, DeleteTask } from '.';

const ListInitiativeTasks = ({ dispatch, initiative }) => {

    const state = useSelector(state => state);
    const tasks = useSelector(state => state.tasks.initiative_tasks);
    const loadingTasks = useSelector(state => state.tasks.loading);
    const users = useSelector(state => state.users.users);
    const areas = useSelector(state => state.areas.areas);
    const areas_dict = useMemo(() => {
        return areas.reduce((acc, area) => {
            acc[area.id] = area;
            return acc;
        }, {});
    }, [areas]);
    const phases = useSelector(state => state.phases.phases);

    useEffect(() => {
        dispatch(getAllUsers());
        dispatch(getInitiativeTasks(initiative.id));
        dispatch(getAllAreas());
    }, [dispatch, initiative]);

    const columns = useMemo(
        () => {
            let col = [
                {
                    Header: language.tasks.table.description,
                    accessor: 'description',
                },
                {
                    Header: language.tasks.table.area,
                    accessor: 'area',
                },
                {
                    Header: language.tasks.table.user,
                    accessor: 'user',
                },
                {
                    Header: language.tasks.table.created_at,
                    accessor: 'created_at',
                },
                {
                    Header: language.tasks.table.forecast_date,
                    accessor: 'forecast_date',
                },
                {
                    Header: language.tasks.table.estimated_date,
                    accessor: 'estimated_date',
                },
                {
                    Header: language.tasks.table.stage,
                    accessor: 'stage',
                },
                {
                    Header: language.tasks.table.status,
                    accessor: 'status',
                },
                {
                    Header: language.tasks.table.completed,
                    accessor: 'completed',
                },
                {
                    Header: language.tasks.form.actions,
                    accessor: 'actions',
                }
            ];
            if (getUserPermission(state, 'tasks', 'patch')) {
                col.push({
                    Header: language.tasks.form.edit,
                    accessor: 'edit',
                });
            }
            // if (getUserPermission(state, 'tasks', 'delete')) {
            //     col.push({
            //         Header: language.tasks.form.actions,
            //         accessor: 'actions',
            //     });
            // }
            return col;
        },
        [state]
    )

    // render areas in list with name, description, parent_id
    const renderTasks = () => {
        if (loadingTasks) {
            return <p>{language.general.loading}</p>;
        }
        if (!tasks || tasks.length === 0) {
            return <p>{language["tasks"]["not_found"]}</p>
        }

        let tasksList = tasks.sort((a, b) => {
            if (a.completed === b.completed) {
                a = a.forecast_date.split("T")[0].split('/').reverse().join('');
                b = b.forecast_date.split("T")[0].split('/').reverse().join('');
                return a > b ? 1 : a < b ? -1 : 0;
            }
            if (a.completed) {
                return 1;
            } else {
                return -1;
            }
        })
        .filter(task => task).map((task) => {
            return {
                id: task.id,
                description: task.description,
                area: (areas_dict && task["area_id"] && areas_dict.hasOwnProperty(task["area_id"])) ? areas_dict[task["area_id"]].name : "",
                stage: (phases.hasOwnProperty(task.stage))
                    ? phases[task.stage].name
                    : task.stage,
                user: (users && users.hasOwnProperty(task.responsable_user)) ? users[task.responsable_user].full_name : "",
                created_at: task.created_at.split("T")[0] || language["tasks"]["no_date"],
                forecast_date: task.forecast_date.split("T")[0] || language["tasks"]["no_date"],
                estimated_date: (task.estimated_date) ? (task.estimated_date.split("T")[0] || language["tasks"]["no_date"]) : language["tasks"]["no_date"],
                status: language["tasks"]["status"][task.status],
                completed: (task.completed) ? language["tasks"]["completed"]["yes"] : language["tasks"]["completed"]["no"],
                edit: <AcceptTask task={task} editableInInitiative={true} />,
                actions: <DeleteTask task={task} />,
            }
        });

        return <SimpleTable columns={columns} data={tasksList} />

    }

    return (
        <div>
            {renderTasks()}
        </div>
    );
}

export default connect()(ListInitiativeTasks);