import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import {
  getAllChecklists,
  getInitiativeChecklists,
  getStages,
  patchInitiativeChecklist,
} from "../../redux/actions";
import { language } from "../../assets/language";
import SimpleTable from "../../components/tables/SimpleTable";
import { Button, Input } from "reactstrap";

const ListInitiativeChecklists = ({ dispatch, initiative, phases }) => {
  const checklists = useSelector((state) => state.checklists.checklists);
  const stages = useSelector((state) => state.stages.stages);
  const [loading, setloading] = useState(true);
  const initiative_checklists = useSelector(
    (state) => state.initiative_checklists.checklists
  );
  const [stageId, setStageId] = useState("");
  const [phaseList, setPhaseList] = useState([]);
  const [phase, setPhase] = useState("");

  const renderPhaseSelector = () => {
    // return select with phases name and id
    return (
      <div>
        <label>{language.checklists.stage}</label>
        <Input className="form-control-sm" type="select"
          onChange={(e) => {
            setPhase(e.target.value);
          }}
          defaultValue={""}
        >
            {(Object.keys(phases).length === 0) ? <option value="">{language.phases.select}</option> : <></>}
            {Object.values(phases).filter(ph => ph.required_checklist === true
            ).map(p => <option key={p.id} value={p.id}>{p.name}</option>)}
              <option value="">{language.phases.select}</option>
        </Input>
      </div>
    )
  };

  useEffect(() => {
    dispatch(getAllChecklists());
    dispatch(getInitiativeChecklists(initiative?.id));
    dispatch(getStages(initiative?.id));

  }, [initiative]);


  useEffect(() => {
    if (stages) {
      let stage = stages.filter((stage) => stage.name === initiative.stage)[0];
      setStageId(stage?.id);
      let phaseList = Object.values(phases)
      setPhaseList(phaseList);
    }
  }, [stages]);
   


  
  const columns = useMemo(
    () => [
      {
        Header: language.checklists.table.question,
        accessor: "question",
      },
      {
        Header: language.checklists.table.checked,
        accessor: "checked",
      },
      {
        Header: language.checklists.table.check,
        accessor: "check",
      },
    ],
    []
  );

  const oldColumns = useMemo(
    () => [
      {
        Header: language.checklists.table.phase,
        accessor: "phase",
      },
      {
        Header: language.checklists.table.question,
        accessor: "question",
      },
      {
        Header: language.checklists.table.checked,
        accessor: "checked",
      },
      {
        Header: language.checklists.table.check,
        accessor: "check",
      },
    ],
    []
  );
  useEffect(() => {
    if (initiative_checklists && checklists) {
    setloading(false);
    }

  }, [initiative_checklists, checklists]);

  const sendCheck = (ch) => {
    dispatch(
      patchInitiativeChecklist(initiative.id, ch.id, {
        checked: !ch.checked,
      })
    );
  };

  const prepareChecklists = (active) => {
    if (active) {
      return initiative_checklists.filter((c) => c.stage_id === stageId).sort((a, b) => a.checked - b.checked);
    } else {
      return initiative_checklists.filter((c) => c.phase_id === phase).sort((a, b) => a.checked - b.checked);
    }
    
  };

  const renderActiveChecklists = () => {
    let _initiative_checklists = prepareChecklists(true).map((c) => {
      return {
        id: c.id,
        checked: c.checked
          ? language.checklists.completed.yes
          : language.checklists.completed.no,
        initiative_id: c.initiative_id,
        checklist_id: c.checklist_id,
        question:
          checklists !== undefined && checklists.length > 0
            ? checklists.find((cl) => cl.id === c.checklist_id) !== undefined
              ? checklists.find((cl) => cl.id === c.checklist_id).question
              : ""
            : "",
        check: (
          <div className="pb-2">
          
            <Input
              className='form-control-md mt-0 pt-0' 
              id={c.id}
              type='checkbox'
              checked={c.checked}
              onChange={() => sendCheck(c)}
              value={
                !c.checked}
            />
            </div>
        
          
        ),
      };
    });

    if (!_initiative_checklists || _initiative_checklists.length === 0) {
      return <p>{language["checklists"]["not_found_active"]}</p>;
    }

    return <SimpleTable data={_initiative_checklists} columns={columns} />;
  };

  const renderOldChecklists = () => {
    let _initiative_checklists = prepareChecklists(false).map((c) => {
      return {
        id: c.id,
        phase: phaseList !== undefined && phaseList.find((s) => s.id === c.phase_id)
        ? phaseList.find((s) => s.id === c.phase_id).name : "",
        checked: c.checked
          ? language.checklists.completed.yes
          : language.checklists.completed.no,
        initiative_id: c.initiative_id,
        checklist_id: c.checklist_id,
        question:
          checklists !== undefined && checklists.length > 0
            ? checklists.find((cl) => cl.id === c.checklist_id) !== undefined
              ? checklists.find((cl) => cl.id === c.checklist_id).question
              : ""
            : "",
      };
    });


    return(
      <div>
        <h3>{language.checklists.old}</h3>
          {renderPhaseSelector()}
          {!_initiative_checklists || _initiative_checklists.length === 0 ? 
            <p>{language["checklists"]["not_found_old"]}</p> 
            : 
            <SimpleTable data={_initiative_checklists} columns={oldColumns} />
          }
      </div>



    ) 
  };

  return (
    <div>
      <h1>{language.checklists.title}</h1>
      <div>
      {renderActiveChecklists()}
      </div>
      {renderOldChecklists()}
    </div>
  );
};

export default connect()(ListInitiativeChecklists);
