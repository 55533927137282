const initialState_InitiativeChecklist = {
    request_time: null,
    error: null,
    checklists: [],
};

const initiative_checklists = (state = initialState_InitiativeChecklist, action) => {
    switch (action.type) {
        case 'SET_INITIATIVE_CHECKLISTS':
            return {
                checklists: action.payload.checklists,
                request_time: action.request_time,
            };
        case 'SET_INITIATIVE_CHECKLIST':
            try {
                const index = state.checklists.findIndex(checklist => checklist.id === action.payload.id);
                if (index === -1) {
                    return {
                        ...state,
                        checklists: [...state.checklists, action.payload],
                        request_time: action.request_time,
                    };
                } else {
                    return {
                        ...state,
                        checklists: [
                            ...state.checklists.slice(0, index),
                            action.payload,
                            ...state.checklists.slice(index + 1),
                        ],
                        request_time: action.request_time,
                    };
                }
            } catch (e) {
                console.log(e);
                return state;
            }
        case 'INITIATIVE_CHECKLIST_ERROR':
            return {
                ...state,
                error: action.payload.error,
                request_time: null,
                // checklists: [],
            };
        default:
            return state;
    }
}

export default initiative_checklists;