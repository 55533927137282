const initialState_KpiSecond = {
  request_time: null,
  error: null,
  initiativeId: null,
  kpi_seconds: [],
  kpi_second: null,
};

const kpi_seconds = (state = initialState_KpiSecond, action) => {
  switch (action.type) {
    case "SET_KPI_SECONDS":
      return {
        kpi_seconds: action.payload.kpi_seconds,
        request_time: action.request_time,
        initiativeId: action.payload.initiativeId,
      };
    case "UPDATE_KPI_SECOND":
      return {
        kpi_seconds: [...state.kpi_seconds.filter(
          (kpisecond) => kpisecond.id !== action.payload.data.id
        ), action.payload.data],
        kpi_second: action.payload.data,
        request_time: action.request_time,
      };
    case "SET_NEW_KPI_SECOND":
      return {
        kpi_seconds: [...state.kpi_seconds, action.payload],
        kpi_second: action.payload,
        request_time: action.payload.request_time,
        initiativeId: state.initiativeId,
      };
    case "SET_KPI_SECOND":
      return {
        ...state,
        kpi_second: action.payload,
        request_time: action.request_time,
      }
    case "KPI_SECOND_ERROR":
      return {
        ...state,
        error: action.payload.error,
        request_time: null,
        // kpi_seconds: [],
      };
    case "DELETE_KPI_SECOND":
      return {
        ...state,
        kpi_seconds: state.kpi_seconds.filter(
          (kpisecond) => kpisecond.id !== action.payload.id
        ),
        request_time: action.request_time,
      };
    default:
      return state;
  }
};

export default kpi_seconds;
