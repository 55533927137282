import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { acceptApproval, patchApproval } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import {
  Button,
  Card,
  Modal,
  Form,
  FormGroup,
  CardBody,
  Input,
} from "reactstrap";
const AcceptApproval = ({ dispatch, approval }) => {
  let [modalApprovals, setModalApprovals] = useState(false);

  let [notes, setNotes] = useState("");
  let [checked, setChecked] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalApprovals(false);
  });

  const addApproval = () => {
    if (modalApprovals) {
      return <></>;
    }
    return (
      <Button
        color='warning'
        className='btn-sm'
        onClick={() => setModalApprovals(true)}
      >
        {language["approvals"]["form"]["edit"]}
      </Button>
    );
  };

  const handleSubmitApprovals = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    if (object["is_accepted"] === "true") {
      dispatch(acceptApproval(approval.id, object));
    } else {
      dispatch(patchApproval(approval.id, object));
    }
    setModalApprovals(false);
    setNotes("");
    setChecked(false);
  };

  const renderModalFormApprovals = () => {
    return (
      <div>
        <Button
          color='warning'
          className='btn-sm'
          onClick={() => setModalApprovals(true)}
        >
          {language["approvals"]["form"]["edit"]}
        </Button>
        <Modal
          className='modal-dialog-centered'
          isOpen={modalApprovals}
          toggle={() => setModalApprovals(false)}
        >
          <Card className='bg-secondary shadow border-0'>
            <CardBody className='px-lg-5 py-lg-2'>
              <h2 className='text-muted text-center mt-2 mb-3'>
                {language["approvals"]["form"]["create"]}
              </h2>
              <div ref={wrapperRef}>
                <Form onSubmit={handleSubmitApprovals}>
                  <FormGroup>
                    <label for='is_accepted'>
                      {language["approvals"]["table"]["is_accepted"]}
                    </label>
                    <Input
                      type='select'
                      name='is_accepted'
                      value={checked}
                      onChange={(e) => setChecked(e.target.value)}
                    >
                      <option value={false}>
                        {language["approvals"]["completed"]["no"]}
                      </option>
                      <option value={true}>
                        {language["approvals"]["completed"]["yes"]}
                      </option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <label for='notes'>
                      {language["approvals"]["table"]["notes"]}
                    </label>
                    <Input
                      maxLength={254}
                      type='textarea'
                      name='notes'
                      onChange={(e) => setNotes(e.target.value)}
                      value={notes}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Button color='success' className='btn-sm' type='submit'>
                      {language["approvals"]["form"]["save"]}
                    </Button>
                  </FormGroup>
                </Form>
              </div>
            </CardBody>
          </Card>
        </Modal>
      </div>
    );
  };

  return <div>{renderModalFormApprovals()}</div>;
};

export default connect()(AcceptApproval);
