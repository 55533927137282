import { saveInitiativeUsersByArea, setInitiative } from ".";
import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";
import history from "./../../history";
import { toast } from "react-toastify";
import { language } from "../../assets/language";

export const newCard = (data) => ({
  type: "NEW_CARD",
  payload: {
    id: data.id,
    title: data.title,
    area_id: data.area_id,
    description: data.description,
    estimated_value: data.estimated_value,
    is_accepted: data.is_accepted,
    state: data.state || 0,
    valued: data.valued || 0,
    ease: data.ease || 0,
    fase: data.fase,
    original_user: data.original_user || 0,
    initiative_id: data.initiative_id || null,
  },

  request_time: Date.now(),
});

export const setCardError = (error) => ({
  type: "CARD_ERROR",
  payload: {
    error: error,
  },
});

export const deleteCard = (card_id) => ({
  type: "DELETE_CARD",
  payload: {
    id: card_id,
  },
  request_time: Date.now(),
});

export const updateCard = (card) => ({
  type: "UPDATE_CARD",
  payload: {
    id: card.id,
    title: card.title,
    area_id: card.area_id,
    description: card.description,
    estimated_value: card.estimated_value,
    is_accepted: card.is_accepted,
    state: card.state || 0,
    valued: card.valued || 0,
    ease: card.ease || 0,
    fase: card.fase,
    original_user: card.original_user || 0,
    initiative_id: card.initiative_id || null,
  },
  request_time: Date.now(),
});

export const setCards = (cards) => ({
  type: "SET_CARDS",
  payload: {
    cards: cards,
  },
  request_time: Date.now(),
});

export const getCurrentUserCards = () => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (!getUserPermission(state, "cards", "list", true)) {
        throw new Error("No permissions");
      }
      fetch(`${BACKEND_URL}/users/${state.sessions.user.id}/cards`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.sessions.token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          dispatch(setCards(data));
        })
        .catch((error) => {
          errorHandler("cards", error.message);
          console.log(error);
          dispatch(setCardError(error.message));
        });
    } catch (error) {
      errorHandler("cards", error.message);
      dispatch(setCardError(error.message));
    }
  };
};

export const postCard = (data) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (!getUserPermission(state, "cards", "create", true)) {
        throw new Error("No permissions");
      }
      fetch(`${BACKEND_URL}/cards`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.sessions.token}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 201) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          if (data != null && data !== undefined && data.id !== undefined) {
            dispatch(newCard(data));
          }
        })
        .catch((error) => {
          errorHandler("cards", error.message);
          dispatch(setCardError(error.message));
        });
    } catch (error) {
      errorHandler("cards", error.message);
      dispatch(setCardError(error.message));
    }
  };
};

export const acceptCardToInitiative = (cardId) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (!getUserPermission(state, "initiatives", "create", true)) {
        throw new Error("No permissions");
      }
      fetch(`${BACKEND_URL}/cards/${cardId}/accept`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.sessions.token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          if (data != null && data !== undefined && data.id !== undefined) {
            dispatch(setInitiative(data));
            //dispatch(saveInitiativeUsersByArea(data.id, { area_id: data.area_id,}));
            // wait 2 seconds
            setTimeout(() => {
              history.push(`/initiative/${data.id}`);
              window.location.reload();
            }, 1500);
          }
        })
        .catch((error) => {
          errorHandler("cards", error.message);
          dispatch(setCardError(error.message));
        });
    } catch (error) {
      errorHandler("cards", error.message);
      dispatch(setCardError(error.message));
    }
  };
};

export const rejectCardById = (card) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (
        card.original_user !== state.sessions.user.id ||
        !getUserPermission(state, "cards", "delete", true)
      ) {
        throw new Error("No permissions");
      }
      fetch(`${BACKEND_URL}/cards/${card.id}/reject`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.sessions.token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          if (data != null && data !== undefined && data.id !== undefined) {
            dispatch(updateCard(data));
          }
        })
        .catch((error) => {
          errorHandler("cards", error.message);
          dispatch(setCardError(error.message));
        });
    } catch (error) {
      errorHandler("cards", error.message);
      dispatch(setCardError(error.message));
    }
  };
};

export const deleteCardById = (card) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (
        card.original_user !== state.sessions.user.id ||
        !getUserPermission(state, "cards", "delete", true)
      ) {
        throw new Error("No permissions");
      }
      fetch(`${BACKEND_URL}/cards/${card.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.sessions.token}`,
        },
      })
        .then((response) => {
          if (response.status === 204) {
            return true;
          } else {
            throw new Error(response.status);
          }
        })
        .then((vl) => {
          if (vl) {
            dispatch(deleteCard(card.id));
          }
        })
        .catch((error) => {
          errorHandler("cards", error.message);
          dispatch(setCardError(error));
        });
    } catch (error) {
      errorHandler("cards", error.message);
      dispatch(setCardError(error));
    }
  };
};

export const patchCard = (card, data) => {
  return (dispatch, getState) => {
    const state = getState();
    try {
      if (
        card.original_user === state.sessions.user.id ||
        getUserPermission(state, "cards", "patch", true)
      ) {
        fetch(`${BACKEND_URL}/cards/${card.id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.sessions.token}`,
          },
          method: "PATCH",
          body: JSON.stringify(data),
        })
          .then((response) => {
            if (response.status === 200) {

              return response.json();
            } else {
              throw new Error(response.status);
            }
          })
          .then((data) => {
            dispatch(updateCard(data));
            toast.success(language.cards.form.update_success);
          })
          .catch((error) => {
            errorHandler("cards", error.message);
            dispatch(setCardError(error.message));
          });
      }
    } catch (error) {
      errorHandler("cards", error.message);
      dispatch(setCardError(error));
    }
  };
};
