import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useClickAway } from "react-use";
import { connect } from "react-redux";
import {
  getInitiative,
  getStages,
  patchInitiative,
  getAllUsers,
  getAllAreas,
  getAllPhases,
  getAllLevers,
  reloadBlockInitiative,
  saveInitiativeUsersByArea,
  getInitiativeUsers,
  getInitiativeExports,
  
} from "../../redux/actions";
import { language } from "../../assets/language";
import { ChangeStage, ListInitiativeStages } from "../stages";

import { getUserPermission } from "../../utils/getUserPermission";
import { DeleteInitiative } from ".";
import Loading from "../../components/Loading/Loading";
import {
  Button,
  Card,
  CardBody,
  Input,
  Row,
  Col,
  Badge,
} from "reactstrap";
import { toast } from "react-toastify";
import { getResponsabilityPermission } from "../../utils/getResponsabilityPermission";
import DateRangeModal from "./DateRangeModal";


const General = ({ dispatch, match, setShouldPrintInitiative }) => {
  const state = useSelector((state) => state);

  const [loading, setloading] = useState(true);
  const initiativeResponse = useSelector((state) => state.initiatives);
  const stages = useSelector((state) => state.stages.stages);
  const users = useSelector((state) => state.users.users);
  const areas = useSelector((state) => state.areas.areas);
  const phases = useSelector((state) => state.phases.phases);
  const levers = useSelector((state) => state.levers.levers);
  const initypes = useSelector((state) => state.initypes.initypes);
  const initiative_users = useSelector((state) => state.initiative_users.users);
  const [initiative, setInitiative] = useState({});

  let [loadInitiative, setLoadInitiative] = useState(false);
  let [loadOther, setLoadOther] = useState(true);

  let [title, setTitle] = useState("");
  let [code, setCode] = useState("");
  let [description_sit, setDescriptionSit] = useState("");
  let [description_opt, setDescriptionOpt] = useState("");
  let [description_sol, setDescriptionSol] = useState("");
  let [description_val, setDescriptionVal] = useState("");
  let [memory, setMemory] = useState("");
  let [ola, setOla] = useState("");
  let [actual_state, setActualState] = useState("");
  let [status, setStatus] = useState("");

  let [leader, setLeader] = useState("");
  let [sponsor, setSponsor] = useState("");
  let [lever, setLever] = useState("");
  let [initype, setInitype] = useState("");
  let [area, setArea] = useState("");

  let [editTitle, setEditTitle] = useState(false);
  let [editCode, setEditCode] = useState(false);
  let [editOla, setEditOla] = useState(false);








  const wrapperRef1 = useRef(null);




  useClickAway(wrapperRef1, () => {
    setEditTitle(false);
  });
  const wrapperRef2 = useRef(null);
  useClickAway(wrapperRef2, () => {
    setEditCode(false);
  });

  const wrapperRef3 = useRef(null);
  useClickAway(wrapperRef3, () => {
    setEditOla(false);
  });

  const setAttributes = (initiative) => {
    setDescriptionSit(initiative.description_sit || "");
    setDescriptionOpt(initiative.description_opt || "");
    setDescriptionSol(initiative.description_sol || "");
    setDescriptionVal(initiative.description_val || "");
    setMemory(initiative.memory || "");
    setLeader(initiative.leader_user || "");
    setSponsor(initiative.sponsor_user || "");
    setLever(initiative.lever_id || "");
    setInitype(initiative.initype_id || "");
    setArea(initiative.area_id || "");
    setTitle(initiative.title || "");
    setCode(initiative.code || "");
    setOla(initiative.ola || "");
    setActualState(initiative.actual_state || "");
    setStatus(initiative.status || "");
  };

  useEffect(() => {
    if (!loadInitiative) {
      dispatch(getInitiative(match.params.initiativeId));
      setLoadInitiative(true);
    } 

  }, []);




  useEffect(() => {
    if ( initiativeResponse.initiative && initiativeResponse.initiative.id === match.params.initiativeId ) {
      setInitiative(initiativeResponse.initiative);
      setAttributes(initiativeResponse.initiative);
      setEditCode(false);
      setEditTitle(false);
      setEditOla(false);
      if (loadInitiative){
        dispatch(getStages(match.params.initiativeId));
        dispatch(getAllUsers());
        dispatch(getInitiativeUsers(match.params.initiativeId));
        dispatch(getAllAreas());
        dispatch(getAllPhases());
        dispatch(getAllLevers());
        setLoadInitiative(false)
     }
     setLoadOther(true);
   
   }
 }, [initiativeResponse]);


 useEffect(()=>  {
  if (stages  && users && areas && phases && levers && initypes && initiative_users){
    setLoadOther(false);
  }
 },[
    stages,
    users,
    areas,
    phases,
    levers,
    initypes,
    initiative_users,
 ])

  useEffect(() => {
    if (!loadOther && initiative) {
      setloading(false);
    }
  }, [loadOther, initiative]);


  const setLoadIni = (value) => {
    setloading(value)
  };

  const canEdit = (type) => {

    if ( getUserPermission(state, type, "patch", false) ) {
      return true
    } else if (initiative.stage === "evaluation" && getResponsabilityPermission(state,type,"change", initiative.id, "Lider")) {
      return true
    } else {
      return false
    }
  }


  const canPatch = (type) => {

    if ( getUserPermission(state, type, "patch", false) ) {
      return true
    }
    else {
      return false
    }
  }

  const updateInitiative = () => {
    if (callUpdateInitiative() === 0) {
      return <></>;
    }
    return (
      <Button
        color='primary'
        className='btn-sm float-right'
        onClick={() => callUpdateInitiative(true)}
        disabled={!canEdit("initiatives")}
      >
        {language["initiatives"]["form"]["update"]} 
      </Button>
    );
  };

  const callUpdateInitiative = (check = false) => {
    var object = {};
    let update_area = false;

    if (title !== initiative.title && title !== "") {
      object.title = title;
    }
    if (code !== initiative.code && code !== "") {
      object.code = code;
    }
    if (ola !== initiative.ola && ola !== "") {
      object.ola = ola;
    }
    if (
      description_sit !== initiative.description_sit &&
      description_sit !== ""
    ) {
      object.description_sit = description_sit;
    }
    if (
      description_opt !== initiative.description_opt &&
      description_opt !== ""
    ) {
      object.description_opt = description_opt;
    }
    if (
      description_sol !== initiative.description_sol &&
      description_sol !== ""
    ) {
      object.description_sol = description_sol;
    }
    if (
      description_val !== initiative.description_val &&
      description_val !== ""
    ) {
      object.description_val = description_val;
    }
    if (memory !== initiative.memory && memory !== "") {
      object.memory = memory;
    }
    if (leader !== initiative.leader_user && leader !== "") {
      object.leader_user = leader;
    }
    if (sponsor !== initiative.sponsor_user && sponsor !== "") {
      object.sponsor_user = sponsor;
    }
    if (lever !== initiative.lever_id && lever !== "") {
      object.lever_id = lever;
    }
    if (initype !== initiative.initype_id && initype !== "") {
      object.initype_id = initype;
    }
    if (area !== initiative.area_id && area !== "") {
      object.area_id = area;
      update_area = true;
    }
    if (check && Object.keys(object).length > 0) {
      if (!canEdit("initiatives")) {
        toast.error("No tienes permisos para editar esta iniciativa");
          return;
        
      }
      dispatch(patchInitiative(initiative.id, object));
      if (initiative.is_blocked) {
        dispatch(reloadBlockInitiative(initiative.id));
      }
      if (update_area) {
        dispatch(
          saveInitiativeUsersByArea(initiative.id, {
            area_id: area,
          })
        );
        update_area = false;
      }
    }
    if (!check) {
      return Object.keys(object).length;
    }
  };



  const handlePrint = async () => {
    setShouldPrintInitiative(true); 
  };





  const renderContent = () => {

        return (
          <Card className='mt-2'>
            <CardBody>
              <br />
             

              {updateInitiative()}
              <Row>
                <div className='col-md-6'>
                  {canPatch("initiatives") && !editTitle ?  (
                    <h1  onClick={() => {setEditTitle(true)}} >
                      {language["initiatives"]["table"]["title"]}: {title}
                      <i className='fas fa-edit ml-3'  ></i>
                    </h1>
                  ) : editTitle ? (
                    <div>
                      <label>
                        {language["initiatives"]["table"]["title"]}:
                      </label>
                      <Input
                        maxLength={254}
                        className='form-control-sm'
                        ref={wrapperRef1}
                        type='text'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  ): <h1>
                      {language["initiatives"]["table"]["title"]}: {title}
                  </h1>
                }
                </div>

                <div className='col-md-4'>
                  {canPatch("initiatives") &&  !editCode ? (
                    <h1 onClick={() => setEditCode(true)}>
                      {language["initiatives"]["table"]["code"]}: {code}
                      <i className='fas fa-edit ml-3'></i>
                    </h1>
                  ) : editCode ? (
                    <div>
                      <label>{language["initiatives"]["table"]["code"]}:</label>
                      <Input
                        className='form-control-sm'
                        maxLength={254}
                        ref={wrapperRef2}
                        type='text'
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                      />
                    </div>
                  ):
                  <h1 >
                  {language["initiatives"]["table"]["code"]}: {code}
                </h1>
                  }
                </div>
                {initiative.stage !== "deleted" &&
              initiative.stage !== "finalized" ? (
                <div className="col-md-2" >
                  <ChangeStage initiative={initiative} />
                <Row className="m-0 mt-1">
                  <div className="col-md-6 px-0" >
                    <DateRangeModal
                      label={language["initiatives"]["form"]["download"]}
                      initiativeId={initiative.id}
                    />
                  </div>
                  <div className="col-md-6 pl-1 pr-0" >
                    <Button 
                      color='primary' 
                      className='btn-sm' 
                      block
                      onClick={() => {
                        handlePrint()
                      }}
                    >
                      {language["initiatives"]["form"]["export_pdf"]}
                    </Button>
                  </div>
                </Row>
                </div>
              ) : (
                <></>
              )}
              </Row>
              {initiative.is_blocked ? (
                <h3>{language["initiatives"]["table"]["is_blocked"]}</h3>
              ) : (
                <></>
              )}
              <hr />
              {renderAbstractInitiative()}
              <hr />
              <h3>
                {language["initiatives"]["titles"]["initiative_description"]}
              </h3>
              <hr />
              {renderDescriptions()}
              <hr />
              <h3>{language["stages"]["title"]}</h3>
              {/* <CreateStage initiative={initiative} /> */}
              <ListInitiativeStages
                initiative={initiative}
                setLoadIni={setLoadIni}
                editForecast={
                  (initiative.stage === "evaluation" && getResponsabilityPermission(state,"stages","change",initiative.id,"Lider"))
                  || getUserPermission(state,"stages","patch")
                    ? true
                    : false
                }
                editEstimated={false}
              />
              <hr />
              {renderMemory()}
              <hr />
              {!initiative.is_deleted &&
              getUserPermission(state, "initiatives", "delete", false) ? (
                <div>
                  <h3>{language.initiatives.form.delete}</h3>
                  <DeleteInitiative initiative={initiative} />
                </div>
              ) : null}
            </CardBody>
          </Card>
        );
     
  };

  const renderAbstractInitiative = () => {
    if (initiative) {
      return (
        <div>
          <Row>
            <div className='col-md-3 text-center'>
              {canPatch("initiatives") && !editOla ? (
                <h3 onClick={() => setEditOla(true)}>
                  {language["initiatives"]["table"]["ola"]}: {initiative["ola"]}
                  <i className='fas fa-edit ml-3'></i>
                </h3>
              ) : editOla ? (
                <div>
                  <label>{language["initiatives"]["table"]["ola"]}:</label>
                  <Input
                    className='form-control-sm'
                    maxLength={254}
                    ref={wrapperRef3}
                    type='text'
                    value={ola}
                    onChange={(e) => setOla(e.target.value)}
                  />
                </div>
              ) :
              (
                <h3>
                  {language["initiatives"]["table"]["ola"]}: {initiative["ola"]}
                </h3>
              )}
            </div>
            <hr />
            <div className='col-md-3 text-center'>
              <h3>
                {language["initiatives"]["table"]["status"]}:{" "}
                {status === "complete" || status === "in_progress" ? (
                  <Badge color='success'>
                    {language["initiatives"]["status"][status]}
                  </Badge>
                ) : (
                  <Badge color='danger'>
                    {language["initiatives"]["status"][status]}
                  </Badge>
                )}
              </h3>
            </div>

            <div className='col-md-3 text-center'>
              <h3>
                {language["initiatives"]["table"]["stage"]}:{" "}
                {phases.hasOwnProperty(initiative.stage)
                  ? phases[initiative.stage].name
                  : initiative.stage}
              </h3>
            </div>
            <div className='col-md-3 text-center'>
              <h3>
                {language["initiatives"]["table"]["year_value"]}:{" "}
                {(initiative.last_month_value * 12 || 0).toFixed(2)}
              </h3>
            </div>
          </Row>
          <Row>
            <div className='col-md-4'>
              <label>{language["initiatives"]["table"]["leader"]}: </label>
              <Input
                className='form-control-sm'
                type='select'
                onChange={(e) => setLeader(e.target.value)}
                defaultValue={initiative.leader_user}
                disabled={canPatch("initiatives") ? false : true}
              >
                {Object.values(users).filter(user => user.id !== initiative.sponsor_user).map((user) => {
                  return (
                    <option key={user.id} value={user.id}>
                      {user.full_name}
                    </option>
                  );
                })}
              </Input>
            </div>
            <div className='col-md-4'>
              <label>{language["initiatives"]["table"]["sponsor"]}: </label>
              <Input
                className='form-control-sm'
                type='select'
                onChange={(e) => setSponsor(e.target.value)}
                defaultValue={initiative.sponsor_user}
                disabled={canPatch("initiatives") ? false : true}
              >
                {Object.values(users).filter(user => user.id !== initiative.leader_user).map((user) => {
                  return (
                    <option key={user.id} value={user.id}>
                      {user.full_name}
                    </option>
                  );
                })}
              </Input>
            </div>
            <div className='col-md-4'>
              <label>{language["initiatives"]["table"]["area"]}: </label>
              <Input
                className='form-control-sm'
                type='select'
                onChange={(e) => setArea(e.target.value)}
                defaultValue={initiative.area_id}
                disabled={canPatch("initiatives") ? false : true}
              >
                {areas.map((ar) => {
                  return (
                    <option key={ar.id} value={ar.id}>
                      {ar.name}
                    </option>
                  );
                })}
              </Input>
            </div>
          </Row>
          <br />
          {/* <p>{language["initiatives"]["table"]["area"]}: {(areas_dict && initiative["area_id"] && areas_dict.hasOwnProperty(initiative["area_id"])) ? areas_dict[initiative["area_id"]].name : ""}</p> */}
          <Row>
            <div className='col-md-6'>
              <label>{language["initiatives"]["table"]["lever"]}: </label>
              <Input
                className='form-control-sm'
                type='select'
                onChange={(e) => setLever(e.target.value)}
                defaultValue={initiative.lever_id}
                disabled={canPatch("initiatives") ? false : true}
              >
                {Object.values(levers).map((lev) => {
                  return (
                    <option key={lev.id} value={lev.id}>
                      {lev.name}
                    </option>
                  );
                })}
              </Input>
            </div>
            <div className='col-md-6'>
              <label>{language["initiatives"]["table"]["initype"]}: </label>
              <Input
                className='form-control-sm'
                type='select'
                onChange={(e) => setInitype(e.target.value)}
                defaultValue={initiative.initype_id}
                disabled={canPatch("initiatives") ? false : true}
              >
                {Object.values(initypes).map((type) => {
                  return (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  );
                })}
              </Input>
            </div>
          </Row>
        </div>
      );
    }
  };

  const renderDescriptions = () => {
    return (
      <div>
        <Row>
          <Col>
            <h4>{language["initiatives"]["titles"]["situation"]}</h4>
            <Input
              type='textarea'
              value={description_sit}
              onChange={(e) => setDescriptionSit(e.target.value)}
              cols={40}
              rows={5}
              disabled={canEdit("initiatives") ? false : true}
            />
          </Col>
          <Col>
            <h4>{language["initiatives"]["titles"]["oportunity"]}</h4>
            <Input
              type='textarea'
              value={description_opt}
              onChange={(e) => setDescriptionOpt(e.target.value)}
              cols={40}
              rows={5}
              disabled={canEdit("initiatives") ? false : true}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <br />
            <h4>{language["initiatives"]["titles"]["solution"]}</h4>
            <Input
              type='textarea'
              value={description_sol}
              onChange={(e) => setDescriptionSol(e.target.value)}
              cols={40}
              rows={5}
              disabled={canEdit("initiatives") ? false : true}
            />
          </Col>
          <Col>
            <h4>{language["initiatives"]["titles"]["value"]}</h4>
            <Input
              type='textarea'
              value={description_val}
              onChange={(e) => setDescriptionVal(e.target.value)}
              cols={40}
              rows={5}
              disabled={canEdit("initiatives") ? false : true}
            />
            <br />
          </Col>
        </Row>
      </div>
    );
  };



  const renderMemory = () => {
    return (
      <div>
        <h3>{language["initiatives"]["titles"]["memory"]}</h3>
        <Input
          className='form-control-sm m-2 p-2'
          type='textarea'
          value={memory}
          onChange={(e) => setMemory(e.target.value)}
          cols={40}
          rows={5}
          disabled={canEdit("initiatives") ? false : true}
        />
      </div>
    );
  };


  return loading ? (
    <Loading />
  ) : initiative ?  (
    <div>
      {renderContent()}
    </div>
  ): (<div>{language["initiatives"]["notFound"]}</div>);
};

export default connect()(General);
