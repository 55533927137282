import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { postPhase } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import {
  Button,
  Card,
  FormGroup,
  Form,
  Input,
  Modal,
  CardBody,
} from "reactstrap";

const CreatePhase = ({ dispatch, initiative }) => {
  let [modalPhases, setModalPhases] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalPhases(false);
  });

  const handleSubmitPhases = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    dispatch(postPhase(object));
    setModalPhases(false);
  };

  const renderModalFormPhases = () => {
    return (
      <>
        <Button
          color='success'
          className='btn-sm'
          onClick={() => setModalPhases(true)}
        >
          {language["phases"]["form"]["create"]}
        </Button>
        <Modal
          className='modal-dialog-centered'
          isOpen={modalPhases}
          toggle={() => setModalPhases(false)}
        >
          <div className='modal-body p-0'>
            <Card className='bg-secondary shadow border-0' ref={wrapperRef}>
              <CardBody className='px-lg-5 py-lg-2'>
                <h1>{language["phases"]["form"]["create"]}</h1>
                <Form onSubmit={handleSubmitPhases}>
                  <FormGroup>
                    <label htmlFor='identifier'>
                      {language["phases"]["table"]["identifier"]}
                    </label>
                    <Input
                      className='form-control-sm'
                      type='text'
                      maxLength={254}
                      name='identifier'
                      required
                      multiple={true}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor='name'>
                      {language["phases"]["table"]["name"]}
                    </label>
                    <Input
                      className='form-control-sm'
                      type='text'
                      maxLength={254}
                      name='name'
                      required
                      multiple={true}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor='description'>
                      {language["phases"]["table"]["description"]}
                    </label>
                    <Input
                      className='form-control-sm'
                      maxLength={254}
                      type='text'
                      name='description'
                      autoComplete='off'
                      required
                      multiple={true}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor='position'>
                      {language["phases"]["table"]["position"]}
                    </label>
                    <Input
                      className='form-control-sm'
                      type='number'
                      name='position'
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor='required_checklist'>
                      {language["phases"]["table"]["required_checklist"]}
                    </label>
                    <Input
                      className='form-control-sm'
                      type='select'
                      name='required_checklist'
                      required
                      defaultValue='false'
                    >
                      <option value='false'>
                        {language["phases"]["required_checklist"]["no"]}
                      </option>
                      <option value='true'>
                        {language["phases"]["required_checklist"]["yes"]}
                      </option>
                    </Input>
                  </FormGroup>
                  <div>
                    <Button color='success' className='btn-sm' type='submit'>
                      {language["phases"]["form"]["create"]}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </>
    );
  };

  return <div>{renderModalFormPhases()}</div>;
};

export default connect()(CreatePhase);
