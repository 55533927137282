import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { ControlDashboard } from ".";
import { language } from "../../assets/language";
import { CounterStage, ForecastTshift } from ".";
import classnames from "classnames";
import {
  getAllAreas,
  getAllInfoStates,
  getAllInitypes,
  getAllLevers,
  getAllUsers,
  getDecisions,
  getAllPhases,
  retrieveFilterValues,
  updateFilterValues,
  getAllInitiatives,
} from "../../redux/actions";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import { FormGroup, Input, Row, Col,  } from "reactstrap";
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Button,

} from "reactstrap";
import Loading from "../../components/Loading/Loading";
import { useRef } from "react";
import SidebarRight from "../../components/SidebarRight/SidebarRight";
import moment from "moment/moment";
import { formatValidDate } from "../../utils/validFormatDate";

const ControlView = ({ dispatch }) => {
  const [selectedTab, setSelectedTab] = useState("progress");
  const [init, setInit] = useState(true);
  const handle = useFullScreenHandle();
  const reff = useRef();



  const dashboardFilters = useSelector((state)=> state.counter_stats.filter)
  const [filter, setFilter] = useState(null)
  const [update, setUpdate] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingDashboard, setLoadingDashboard] = useState(true)
 

  const [area, setArea] = useState(null);
  const [lever, setLever] = useState(null);
  const [initype, setInitype] = useState(null);
  const [leader, setLeader] = useState(false);
  const [initiatives, setInitiatives] = useState([]);
  const [stateFilter, setStateFilter] = useState([null]);
  const [activeFilter, setActiveFilter] = useState([true]);
  const [phaseFilter, setPhaseFilter] = useState([
    "evaluation",
    "execution",
    "result",
    "adoption",
  ]);
  let [checked, setChecked] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const _initiatives = useSelector((state) => state.initiatives.all_initiatives);
  const areas = useSelector((state) => state.areas.areas);
  const _levers = useSelector((state) => state.levers.levers);
  const levers = Object.values(_levers);
  const _initypes = useSelector((state) => state.initypes.initypes);
  const initypes = Object.values(_initypes);
  const _users = useSelector((state) => state.users.users);
  const [fullscreen, setfullscreen] = useState(true);
  const users = Object.values(_users);
  const _info_states = useSelector((state) => state.info_states.info_states);
  const info_states = Object.values(_info_states);
  const phases = useSelector((state) => state.phases.phases);
  const decisions = useSelector((state) => state.decisions.decisions);

  const tabsList = [
    {
      id: "progress",
      name: language.control.tabs.progress,
    },
    {
      id: "general",
      name: language.control.tabs.general,
    },
  ];

  useEffect(() => {
    if (init) {
      dispatch(getAllInitiatives());
      dispatch(getAllAreas());
      dispatch(getDecisions());
      dispatch(getAllLevers());
      dispatch(getAllInitypes());
      dispatch(getAllUsers());
      dispatch(getAllInfoStates());
      dispatch(getAllPhases());
      dispatch(retrieveFilterValues())
      setInit(false);
    }
  }, [init]);


    useEffect(() => { 
      if (dashboardFilters && dashboardFilters.filters) {
        let filters = dashboardFilters.filters
        setArea(filters.area ? filters.area : area);
        setLever(filters.lever ? filters.lever : lever);
        setInitype(filters.initype ? filters.initype : initype);
        setLeader(filters.leader ? filters.leader : leader);
        setStateFilter(filters.stateFilter ? filters.stateFilter : [null]);
        setActiveFilter(filters.activeFilter ? filters.activeFilter : activeFilter);
        setPhaseFilter(filters.phaseFilter ? filters.phaseFilter : phaseFilter);
        setSelectedTab(filters.selectedTab ? filters.selectedTab : selectedTab);
        setChecked(filters.checked ? filters.checked : checked);
        setStartDate(formatValidDate(filters.startDate) );
        setEndDate(formatValidDate(filters.endDate));
        if (!update && loadingDashboard) {
          setFilter(filters)
          setLoading(false)
          setLoadingDashboard(false)
        }
      }
    }, [dashboardFilters]);



  useEffect(() => {
    if (areas && levers && initypes && users && info_states && phases && decisions && _initiatives){
    let data = _initiatives
      .filter((initiative) => {
        if (area) {
          return initiative.area_id === area.id;
        }
        return true;
      })
      .filter((initiative) => {
        if (lever) {
          return initiative.lever_id === lever.id;
        }
        return true;
      })
      .filter((initiative) => {
        if (initype) {
          return initiative.initype_id === initype.id;
        }
        return true;
      })
      .filter((initiative) => {
        if (leader) {
          return initiative.leader_user === leader.id;
        }
        return true;
      })
      .filter((initiative) => {
        return stateFilter.includes(initiative.actual_state);
      })
      .filter((initiative) => {
        if (activeFilter.includes(true)) {
          if (
            initiative.stage !== "finalized" &&
            initiative.stage !== "deleted"
          ) {
            return true;
          }
        }
        if (activeFilter.includes(false)) {
          if (
            initiative.stage === "finalized" ||
            initiative.stage === "deleted"
          ) {
            return true;
          }
        }
        return false;
      })
      .filter((initiative) => {
        return phaseFilter.includes(initiative.stage);
      });
      
    setInitiatives(data);   
  }
   
  }, [
    area,
    lever,
    initype,
    leader,
    stateFilter,
    activeFilter,
    phaseFilter,
    _initiatives,
  ]);

  useEffect(() => {
    if (update) {
      dispatch(updateFilterValues(filter));
      setUpdate(false)
      setLoadingDashboard(true)
    } 
  }, [filter, update])


const selectArea = (areaId) => {
    // if exists area with id = areaId
    let aux_area = null;
    if (areas.find((area) => area.id === areaId)) {
      aux_area = areas.find((area) => area.id === areaId);
    } 
    setArea(aux_area)
    setFilter({...filter, area: aux_area})
    setUpdate(true);
  };


  const selectLever = (leverId) => {
    // if exists lever with id = leverId
    let aux_lever = null;
    if (leverId) {
      aux_lever = levers.find((lever) => lever.id === leverId);
    }
    setLever(aux_lever)
    setFilter({...filter, lever: aux_lever})
    setUpdate(true);
  };

  const selectInitype = (initypeId) => {

    // if exists initype with id = initypeId
    let aux_init = null;
    if (initypeId) {
      aux_init = initypes.find((initype) => initype.id === initypeId);
    }
    setInitype(aux_init)
    setFilter({...filter, initype: aux_init})
    setUpdate(true);
  };

  const selectLeader = (leaderId) => {
    // if exists user with id = leaderId
    let aux_leader = null;
    if (leaderId) {
      aux_leader = users.find((user) => user.id === leaderId);

    }
    setLeader(aux_leader)
    setFilter({...filter, leader: aux_leader})
    setUpdate(true);
  };

  const renderSelectArea = () => {
    if (areas.length > 0) {
      return (
        <FormGroup>
          <label>{language.initiatives.form.select_area}: </label>
          <Input
            className='form-control-sm'
            type='select'
            onChange={(e) => selectArea(e.target.value)}
            value={area ? area.id : ""}
          >
            <option value=''>{language.general.all}</option>
            {areas.map((area, index) => {
              return (
                <option key={index} value={area.id}>
                  {area.name}
                </option>
              );
            })}
          </Input>
        </FormGroup>
      );
    }
  };

  const renderSelectLever = () => {
    if (levers.length > 0) {
      return (
        <FormGroup>
          <label>{language.initiatives.form.select_lever}: </label>
          <Input
            className='form-control-sm'
            type='select'
            onChange={(e) => selectLever(e.target.value)}
            value={lever ? lever.id : ""}
          >
            <option value=''>{language.general.all}</option>
            {levers.map((lever, index) => {
              return (
                <option key={index} value={lever.id}>
                  {lever.name}
                </option>
              );
            })}
          </Input>
        </FormGroup>
      );
    }
  };

  const renderSelectInitype = () => {
    if (initypes.length > 0) {
      return (
        <FormGroup>
          <label>{language.initiatives.form.select_initype}: </label>
          <Input
            className='form-control-sm'
            type='select'
            onChange={(e) => selectInitype(e.target.value)}
            value={initype ? initype.id : ""}
          >
            <option value=''>{language.general.all}</option>
            {initypes.map((initype, index) => {
              return (
                <option key={index} value={initype.id}>
                  {initype.name}
                </option>
              );
            })}
          </Input>
        </FormGroup>
      );
    }
  };

  const renderSelectLeader = () => {
    if (users.length > 0) {
      return (
        <FormGroup>
          <label>{language.initiatives.form.select_leader}: </label>
          <Input
            className='form-control-sm'
            type='select'
            onChange={(e) => selectLeader(e.target.value)}
            value={leader ? leader.id : ""}
          >
            <option value=''>{language.general.all}</option>
            {users.map((user, index) => {
              return (
                <option key={index} value={user.id}>
                  {user.full_name}
                </option>
              );
            })}
          </Input>
        </FormGroup>
      );
    }
  };

  const processStateFilter = (value) => {
    let _statefilter = stateFilter;
    if (stateFilter.includes(value)) {
      _statefilter = stateFilter.filter(function (f) {
        return f !== value;
      });
      setStateFilter(_statefilter);
      setFilter({...filter, stateFilter: _statefilter})
    } else {
      setStateFilter([..._statefilter, value]);
      setFilter({...filter, stateFilter:[..._statefilter, value]});
    }
    setUpdate(true);
  };

  const renderStateFilter = () => {
    return decisions.length > 0 && (
      <>
        <h4 className='mr-2'>{language["initiatives"]["table"]["state"]}: </h4>
        <div
          key='state'
          className='custom-control custom-checkbox custom-control-inline'
        >
          <input
            type='checkbox'
            className='custom-control-input'
            onChange={(e) => processStateFilter(null)}
            id='customRadioInline1'
            name='customRadioInline1'
            checked={stateFilter.includes(null)}
          />
          <label className='custom-control-label' htmlFor='customRadioInline1'>
            {language["initiatives"]["no_state"]}
          </label>
        </div>
        {decisions.map((info_state, index) => {
          return (
            <div
              key={"state-" + index}
              className='custom-control custom-checkbox custom-control-inline'
            >
              <input
                type='checkbox'
                id={index}
                name={index}
                className='custom-control-input'
                onChange={(e) => processStateFilter(info_state.id)}
                checked={stateFilter.includes(info_state.id)}
              />
              <label className='custom-control-label' htmlFor={index}>
                {info_state.name}
              </label>
            </div>
          );
        })}
      </>
    );
  };

  const processActiveFilter = (value) => {
    let _activefilter = activeFilter;
    if (activeFilter.includes(value)) {
      _activefilter = activeFilter.filter(function (f) {
        return f !== value;
      });
      if (value === false) {
        let phase = phaseFilter;
        phase = phase.filter((f) => {
          return f !== "finalized" && f !== "deleted";
        });
        setPhaseFilter(phase);
        setFilter({...filter, phaseFilter: phase})
      }
      setActiveFilter(_activefilter);
      setFilter({...filter, activeFilter: _activefilter})
    } else {
      setActiveFilter([..._activefilter, value]);
      setFilter({...filter, activeFilter:[..._activefilter, value]});
    }
    setUpdate(true);
  };

  const renderActiveFilter = () => {
    return (
      <div>
        <h4 className='mr-2'>{language["initiatives"]["table"]["active"]}: </h4>
        <div
          key='active'
          className='custom-control custom-checkbox custom-control-inline'
        >
          <input
            type='checkbox'
            className='custom-control-input'
            onChange={(e) => processActiveFilter(true)}
            checked={activeFilter.includes(true)}
            id='active-1'
            name='active-1'
          />
          <label className='custom-control-label' htmlFor='active-1'>
            {language["initiatives"]["active"]["yes"]}
          </label>
        </div>
        <div
          key='nnoactive'
          className='custom-control custom-checkbox custom-control-inline'
        >
          <input
            type='checkbox'
            className='custom-control-input'
            onChange={(e) => processActiveFilter(false)}
            checked={activeFilter.includes(false)}
            id='active-2'
            name='active-2'
          />
          <label className='custom-control-label' htmlFor='active-2'>
            {language["initiatives"]["active"]["no"]}
          </label>
        </div>
      </div>
    );
  };
  const processPhaseFilter = (value) => {
    let _phasefilter = phaseFilter;
    if (phaseFilter.includes(value)) {
      _phasefilter = phaseFilter.filter(function (f) {
        return f !== value;
      });
      setPhaseFilter(_phasefilter);
      setFilter({...filter, phaseFilter: _phasefilter})
    } else {
      setPhaseFilter([..._phasefilter, value]);
      setFilter({...filter, phaseFilter:[..._phasefilter, value]});

    }
    setUpdate(true);
  };

  const renderPhaseFilter = () => {
    return (
      <div>
        <h4>{language["initiatives"]["table"]["stage"]}: </h4>
        {Object.values(phases)
          .filter((ph) => {
            if (ph.identifier !== "idea") {
              if (activeFilter.includes(true)) {
                if (
                  ph.identifier !== "finalized" &&
                  ph.identifier !== "deleted"
                ) {
                  return true;
                }
              }
              if (activeFilter.includes(false)) {
                if (
                  ph.identifier === "finalized" ||
                  ph.identifier === "deleted"
                ) {
                  return true;
                }
              }
            }
            return false;
          })
          .map((phase, index) => {
            return (
              <div
                key={index}
                className='custom-control custom-checkbox custom-control-inline'
              >
                <input
                  key={index}
                  type='checkbox'
                  className='custom-control-input'
                  onChange={(e) => processPhaseFilter(phase.identifier)}
                  checked={phaseFilter.includes(phase.identifier)}
                  id={"p-" + index}
                  name={"p-" + index}
                />
                <label className='custom-control-label' htmlFor={"p-" + index}>
                  {phase.name}
                </label>
              </div>
            );
          })}
      </div>
    );
  };

  const handleFilterApply = (start, end) => {
    const startDateFormatted = moment(start, "DD-MM-YYYY");
    const endDateFormatted = moment(end,"DD-MM-YYYY");
    setStartDate(startDateFormatted);
    setEndDate(endDateFormatted);
    setFilter({...filter, startDate: startDateFormatted, endDate: endDateFormatted})
  };

  const renderTabs = () => {
    return (
      <div className='nav-wrapper'>
        <Nav
          className='nav-fill flex-column flex-md-row'
          id='tabs-icons-text'
          pills
          role='tablist'
        >
          {tabsList.map((tab, index) => {
            return (
              <NavItem key={"navr-" + index}>
                <NavLink
                  aria-selected={selectedTab === tab.id}
                  className={classnames("mb-sm-3 mb-md-0", {
                    "bg-default text-white": selectedTab === tab.id,
                  })}
                  onClick={() => {
                    setSelectedTab(tab.id); 
                    setFilter({...filter, selectedTab: tab.id})
                    setUpdate(true);
                   }}
                  role='tab'
                  key={tab.id}
                >
                  {tab.name}
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
      </div>
    );
  };

  const renderContent = () => {
    switch (selectedTab) {
      case "general":
        return loading ? <Loading /> : (
          <div>
            <ControlDashboard
              _initiatives={initiatives}
              handle={handle}
              fullscreen={fullscreen}
            />
          </div>
        );
      case "progress":
        let ini_ids = initiatives.map((ini) => {
          return ini.id;
        });
        return loading ? <Loading /> : (
          <Row>
            <SidebarRight  onFilterApply={handleFilterApply}/> 
            <Col>
              <CounterStage initiatives={ini_ids}  />
              <br />
              <ForecastTshift initiatives={ini_ids} start={startDate}  end={endDate} />
            </Col>
          </Row>
        );
      default:
        return <div></div>;
    }
  };

  return loadingDashboard ? <Loading /> : (
    <>
        
    
    
      <Button
        color='default'
        size='sm'
        className='mt-2'
        onClick={(e) => {
          handle.enter(e);
        }}
      >
        Modo presentación
      </Button>
      <FullScreen
        style={{ overflowY: "scroll" }}
        handle={handle}
        onChange={() => setfullscreen(!fullscreen)}
      >
       
        <Card style={{ overflowY: "scroll" }} ref={reff} className='mt-3'>
          <CardBody>
           
            {loading ? <Loading /> : <>
            {renderTabs()}
            <br />
           
            <Row>
              <Col>
                {renderSelectArea()}
                {renderSelectLever()}
              </Col>
              <Col>
                {renderSelectInitype()}
                {renderSelectLeader()}
              </Col>
            </Row>
            <Row>
              <Col>{renderStateFilter()}</Col>
              <Col>{renderActiveFilter()}</Col>
              <Col>{renderPhaseFilter()}</Col>
            </Row>
         

            </>}
            
            {renderContent()}
          </CardBody>
        </Card>
      </FullScreen>
    </>
  );
};

export default connect()(ControlView);
