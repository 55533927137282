import React from "react";
import { connect } from "react-redux";
import { login } from "../redux/actions";
import { language } from "../assets/language";
import classnames from "classnames";
import AuthHeader from "../components/Headers/AuthHeader.js";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

const Login = ({ dispatch }) => {
  
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  return (
    <>
      <AuthHeader title='FARO APP' lead='¡Bienvenido!' />
      <Container className='mt--8 pb-5'>
        <Row className='justify-content-center'>
          <Col lg='5' md='7'>
            <Card className='bg-secondary border-0 mb-0'>
              <CardHeader className='bg-transparent pb-5'>
                <h1 className='text-center'>{language["sessions"]["title"]}</h1>
              </CardHeader>
              <CardBody className='px-lg-5 py-lg-5'>
                <Form
                  role='form'
                  onSubmit={(e) => {
                    e.preventDefault();
                    dispatch(login(email, password));
                  }}
                >
                  <FormGroup
                    className={classnames("mb-3")}
                  >
                    <InputGroup className='input-group-merge input-group-alternative'>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ni ni-email-83' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className='form-control-sm'
                        placeholder='Email'
                        type='email'
                        name='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                  >
                    <InputGroup className='input-group-merge input-group-alternative'>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ni ni-lock-circle-open' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className='form-control-sm'
                        placeholder='Password'
                        type='password'
                        value={password}
                        name='password'
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className='text-center'>
                    <Button className='my-4' color='default' type='submit'>
                      {language["sessions"]["login"]}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default connect()(Login);
