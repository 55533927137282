import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import {  reloadBlockInitiative, updateRisk } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Modal,
  Form,
  Input,
} from "reactstrap";
const UpdateRisk = ({ dispatch, initiative, risk }) => {
  const [modalRisks, toggleModal] = useState(false);
  const [description, setDescription] = useState(risk.description);
  const [severity, setSeverity] = useState(risk.severity);
  const [mitigator, setMitigator] = useState(risk.mitigator);
  const [probability, setProbability] = useState(risk.probability);
  const [risk_type, setRiskType] = useState(risk.risk_type);



  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    toggleModal(false);
  });

  const handleSubmitRisks = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    dispatch(updateRisk(risk.id,object));
    toggleModal(false);
    if (initiative.is_blocked) {
      dispatch(reloadBlockInitiative(initiative.id));
    }
  };

  return (
    <div>
      <Button
        color='success'
        className='btn-sm'
        block
        onClick={() => toggleModal(true)}
      >
        {language["risks"]["form"]["edit"]}
      </Button>
      <Modal
        className='modal-dialog-centered'
        isOpen={modalRisks}
        toggle={() => toggleModal(false)}
      >
        <div className='modal-body p-0'>
          <Card className='bg-secondary shadow border-0'>
            <CardBody className='px-lg-5 py-lg-2'>
              <h2 className='text-muted text-center mt-2 mb-3'>
                {language["risks"]["form"]["create"]}
              </h2>
              <Form onSubmit={handleSubmitRisks} role='form'>
                <FormGroup>
                  <label>{language["risks"]["table"]["description"]}</label>
                  <Input
                    maxLength={254}
                    className='form-control-sm'
                    type='text'
                    autoComplete='off'
                    name='description'
                    required
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <label>{language["risks"]["table"]["severity"]}</label>
                  <Input
                    className='form-control-sm'
                    type='select'
                    name='severity'
                    required
                    value={severity}
                    onChange={(e) => setSeverity(e.target.value)}
                  >
                    <option value='1'>
                      {language["risks"]["severity"]["1"]}
                    </option>
                    <option value='2'>
                      {language["risks"]["severity"]["2"]}
                    </option>
                    <option value='3'>
                      {language["risks"]["severity"]["3"]}
                    </option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label>{language["risks"]["table"]["risk_type"]}</label>
                  <Input
                    className='form-control-sm'
                    type='select'
                    name='risk_type'
                    required
                    value={risk_type}
                    onChange={(e) => setRiskType(e.target.value)}
                  >
                    <option value='quality'>
                      {language["risks"]["risk_type"]["quality"]}
                    </option>
                    <option value='business'>
                      {language["risks"]["risk_type"]["business"]}
                    </option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label>{language["risks"]["table"]["probability"]}</label>
                  <Input
                    className='form-control-sm'
                    type='select'
                    name='probability'
                    required
                    value={probability}
                    onChange={(e) => setProbability(e.target.value)}
                  >
                    <option value='1'>
                      {language["risks"]["probability"]["1"]}
                    </option>
                    <option value='2'>
                      {language["risks"]["probability"]["2"]}
                    </option>
                    <option value='3'>
                      {language["risks"]["probability"]["3"]}
                    </option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label>{language["risks"]["table"]["mitigator"]}</label>
                  <Input
                    className='form-control-sm'
                    type='text'
                    name='mitigator'
                    required
                    value={mitigator}
                    onChange={(e) => setMitigator(e.target.value)}
                  />
                </FormGroup>
                <input
                  hidden
                  name='initiative_id'
                  defaultValue={initiative.id}
                />
                <div>
                  <Button color='success' className='btn-sm' type='submit'>
                    {language["risks"]["form"]["edit"]}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default connect()(UpdateRisk);
