import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { postInfoState } from '../../redux/actions';
import { language } from '../../assets/language';
import { useClickAway } from 'react-use';

const CreateInfoState = ({ dispatch }) => {

    let [modalInfoStates, setModalInfoStates] = useState(false);

    const wrapperRef = useRef(null);

    useClickAway(wrapperRef, () => {
        setModalInfoStates(false);
    });

    const addInfoState = () => {
        return (
            <button onClick={() => setModalInfoStates(true)}>{language["info_states"]["form"]["create"]}</button>
        )
    }

    const handleSubmitInfoStates = (e) => {
        e.preventDefault();
        const form = e.target;
        const data = new FormData(form);
        var object = {};
        data.forEach(function (value, key) {
            object[key] = value;
        });
        dispatch(postInfoState(object));
        setModalInfoStates(false);
    }

    const renderModalFormInfoStates = () => {
        if (modalInfoStates) {
            return (
                <div className="form" ref={wrapperRef}>
                    <h3>{language["info_states"]["form"]["create"]}</h3>
                    <form onSubmit={handleSubmitInfoStates}>
                        <label htmlFor="name">{language["info_states"]["table"]["name"]}</label>
                        <input type="text" name="name" required />
                        <div>
                            <button type="submit">{language["info_states"]["form"]["create"]}</button>
                        </div>
                    </form>
                </div>
            )
        }
    }


    return (
        <div>
            {addInfoState()}
            {renderModalFormInfoStates()}
        </div>
    )

}

export default connect()(CreateInfoState);