import { toast } from "react-toastify";
import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";
import { getResponsabilityPermission } from "../../utils/getResponsabilityPermission";
import language from "../../assets/language";


export const setTshifts = (tshifts, initiativeId) => ({
    type: "SET_TSHIFTS",
    payload: {
        tshifts: tshifts,
        initiativeId: initiativeId,
    },
    request_time: Date.now()
});

export const setTshift = (tshift) => ({
    type: "SET_TSHIFT",
    payload: {
        id: tshift.id,
        initiative_id: tshift.initiative_id,
        tree_id: tshift.tree_id,
        base: tshift.base,
        forecast: tshift.forecast,
        real: tshift.real,
        month: tshift.month,
        year: tshift.year,
        request_time: Date.now()
    },
});

export const addTshift = (tshift) => ({
    type: "ADD_TSHIFT",
    payload: {
        id: tshift.id,
        initiative_id: tshift.initiative_id,
        tree_id: tshift.tree_id,
        base: tshift.base,
        forecast: tshift.forecast,
        real: tshift.real,
        month: tshift.month,
        year: tshift.year,
        request_time: Date.now()
    },
});

export const setTshiftError = (error) => ({
    type: "TSHIFT_ERROR",
    payload: {
        error: error
    }
});

export const deleteTshift = (tshift_id) => ({
    type: "DELETE_TSHIFT",
    payload: {
        id: tshift_id,
    },
    request_time: Date.now()
});

export const setValueTshiftsStats = (tshifts) => ({
    type: "SET_VALUE_TSHIFTS_STATS",
    payload: {
        tshifts: tshifts,
    },
    request_time: Date.now()
});

export const getInitiativeTshifts = (initiativeId, start, end) => {

    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "tshifts", "list")) {
                throw new Error("No permissions");
            }
            if (state.tshifts.request_time + 1000 * 2 < Date.now()
                || state.tshifts.initiativeId === null
                || state.tshifts.request_time === null
                || state.tshifts.initiativeId !== initiativeId) {
                
                fetch(`${BACKEND_URL}/initiatives/${initiativeId}/tshifts?startDate=${start}&endDate=${end}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setTshifts(data, initiativeId));
                    })
                    .catch((error) => {
                        errorHandler("tshifts", error.message);
                        dispatch(setTshiftError(error));
                    });
            }
        } catch (error) {
            errorHandler("tshifts", error.message);
            dispatch(setTshiftError(error));
        }
    };
}

//get treetshifts cerate base funtion getkpisecondkshits
export const getTreeTshifts = (treeId, start, end) => {

    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "tshifts", "list")) {
                throw new Error("No permissions");
            }
            if (state.tshifts.request_time + 1000 * 2 < Date.now()
                || state.tshifts.treeId === null
                || state.tshifts.request_time === null
                || state.tshifts.treeId !== treeId) {
                fetch(`${BACKEND_URL}/trees/${treeId}/tshifts?startDate=${start}&endDate=${end}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setTshifts(data, treeId));

                    })
                    .catch((error) => {
                        errorHandler("tshifts", error.message);
                        dispatch(setTshiftError(error));

                    })
            }
        } catch (error) {
            errorHandler("tshifts", error.message);
            dispatch(setTshiftError(error));
        }
    };
}



export const getOrganizationTshifts = (initiativeId) => {

    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "tshifts", "list")) {
                throw new Error("No permissions");
            }
            if (state.tshifts.request_time + 1000 * 2 < Date.now()
                || state.tshifts.request_time === null
                || state.tshifts.initiativeId === null
                || state.tshifts.initiativeId !== initiativeId) {
                fetch(`${BACKEND_URL}/organization/tshifts`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setTshifts(data, initiativeId));
                    })
                    .catch((error) => {
                        errorHandler("tshifts", error.message);
                        dispatch(setTshiftError(error));
                    });
            }
        } catch (error) {
            errorHandler("tshifts", error.message);
            dispatch(setTshiftError(error));
        }
    };
}

export const patchTShift = (data) => {
    return (dispatch, getState) => {

        const state = getState();
        try {
            if ((state.initiatives.initiative.stage  === "evaluation" 
                && getResponsabilityPermission(state,"tshifts","change",state.initiatives.initiative.id, "Lider"))
                || state.sessions.user.role === 'admin'
                || getUserPermission(state, "tshifts", "change", true)) {

                fetch(`${BACKEND_URL}/tshifts/${data.id}`, {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                    body: JSON.stringify(data)
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        if (data != null && data !== undefined && data.id !== undefined) {
                            dispatch(setTshift(data));
                           
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        dispatch(setTshiftError(error));
                    });
            }

        } catch (error) {
            console.log(error);
            dispatch(setTshiftError(error));
        }
    };
}

export const getValueTshiftsStats = (start, end) => {

    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "tshifts", "list", true)) {
                throw new Error("No permissions");
            }
                fetch(`${BACKEND_URL}/tshifts/stats?startDate=${start}&endDate=${end}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                }).then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setValueTshiftsStats(data));
                    })
                    .catch((error) => {
                        errorHandler("tshifts", error.message);
                        dispatch(setTshiftError(error));
                    });
            
        } catch (error) {
            errorHandler("tshifts", error.message);
            dispatch(setTshiftError(error));
        }
    };
}


export const postTshift = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (state.initiatives.initiative.leader_user === state.sessions.user.id
                || state.initiatives.initiative.sponsor_user === state.sessions.user.id
                || state.sessions.user.role === 'admin'
                || getUserPermission(state, "tshifts", "patch", true)) {
                fetch(`${BACKEND_URL}/tshifts/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                    body: JSON.stringify(data)
                })
                    .then((response) => {
                        if (response.status === 201) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        if (data != null && data !== undefined && data.id !== undefined) {
                            dispatch(addTshift(data));
                           
                        }
                    })
                    .catch((error) => {
                        errorHandler("tshifts", error.message);
                        dispatch(setTshiftError(error));
                    });
            }
        } catch (error) {
            errorHandler("tshifts", error.message);
            dispatch(setTshiftError(error));
        }
    };
}