const initialState_Document = {
  request_time: null,
  error: null,
  initiativeId: null,
  documents: [],
};

const documents = (state = initialState_Document, action) => {
  switch (action.type) {
      case 'SET_DOCUMENTS':
          return {
              documents: action.payload.documents,
              request_time: action.request_time,
              initiativeId: action.payload.initiativeId,
          };
      case 'SET_DOCUMENT':
          return {
              documents: [...state.documents, action.payload],
              request_time: action.payload.request_time,
              initiativeId: state.initiativeId,
          };
      case 'DOCUMENT_ERROR':
          return {
              ...state,
              error: action.payload.error,
              request_time: null,
              // documents: [],
          };
      case "DELETE_DOCUMENT":
          return {
              ...state,
              documents: state.documents.filter(document => document.id !== action.payload.id),
              request_time: action.request_time,
          };
      default:
          return state;
  }
}

export default documents;
