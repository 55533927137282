// from redux state check if user has permission
// permits are stored in state.permits.permits each has id resource and action
// role_permits are stored in state.permits.role_permits each has id role_id and permit_id
// given and resource and action, check if user has permission

import { toast } from "react-toastify";
import { language } from "../assets/language";

export const getResponsabilityPermission = (state, resource, action, initiative_id, responsability, scream = false) => {
  try {
    const user_responsability =  state.initiative_users.users.find(
      (user) => user.user_id === state.sessions.user.id 
     )
    if (user_responsability && user_responsability.responsability === responsability) {

    const permit = state.permits.permits.find(
      (p) => p.resource === resource && p.action === action
    );
    if (permit) {
      const userPermission = state.permits.role_permits.find(
        (rolePermit) => rolePermit.permit_id === permit.id
      );
      if (undefined === userPermission) {
        throw new Error("User has no permission");
      }
      return userPermission;
    }else {
      const group = state.role_groups.groups.find(
        (g) => g.name === resource && g.action === action
      );
      if (undefined === group) {
        throw new Error("User has no permission");
      }

      const roleGroup = state.role_groups.user_role_groups.find(
        (rg) => rg.permission_group_id === group.id
      );
      if (undefined === roleGroup) {
        throw new Error("User has no permission");
      }

      return roleGroup;
    }

    }

  } catch (e) {
    console.log(e);
    if (scream) {
     toast.error(`${language.permits.not_found}: ${resource} - ${language.permits.actions[action]}`);
      // alert(`${resource} ${action} permission not found`);
    }
    return null;
  }
};
