import React, { useState, useRef } from 'react';

import { connect } from "react-redux";
import { language } from '../../assets/language';


import { deleteDocumentById } from '../../redux/actions/documents'; // Assuming you have an action creator for posting documents
import DeleteModal from "../../components/Modal/DeleteModal";




const DeleteDocument = ({ dispatch, initiativeId, documentId }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const wrapperRef = useRef(null);

  const handleDelete = () => {
    dispatch(deleteDocumentById(initiativeId, documentId));
    setModalOpen(false);
  };

  return (
    <DeleteModal
      confirmText={language["documents"]["form"]["deleteConfirm"]}
      confirm={handleDelete}
      buttonText={language["documents"]["form"]["delete"]}
    />
  );
}


export default connect()(DeleteDocument);
