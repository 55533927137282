import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { getInitiativeTasks, getAllAreas, getAllUsers } from '../../redux/actions';
import { language } from '../../assets/language';
import SimpleTable from '../../components/tables/SimpleTable';
import { AcceptTask, DeleteTask } from '.';
import { getUserPermission } from '../../utils/getUserPermission';
import { FormGroup, Input, Row, Col, Container } from "reactstrap";
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";


const ListInitiativeTasks = ({ dispatch, initiative, n = 5 }) => {

    const state = useSelector(state => state);
    const currentUser = useSelector(state => state.sessions.user);
    const tasks = useSelector(state => state.tasks.initiative_tasks);
    const loadingTasks = useSelector(state => state.tasks.loading);
    const users = useSelector(state => state.users.users);
    const areas = useSelector(state => state.areas.areas);
    const areas_dict = useMemo(() => {
        return areas.reduce((acc, area) => {
            acc[area.id] = area;
            return acc;
        }, {});
    }, [areas]);
    const phases = useSelector(state => state.phases.phases);



    let [creator_task, setCreatorTask] = useState(false);
    const [completedFilter, setCompletedFilter] = useState([false]);
    
    useEffect(() => {
        if (initiative && !tasks) {
            dispatch(getAllUsers());
            dispatch(getInitiativeTasks(initiative.id));
            dispatch(getAllAreas());
        }
    }, [dispatch, initiative]);

    useEffect(() => {
        if (tasks && tasks.length > 0) {
            for (let task of tasks) {
                if (task.creator === currentUser.id) {
                    setCreatorTask(true);
                    break;
                }
            }
        }
    }, [tasks, currentUser]);

    const columns = useMemo(
        () => {
            let col = [
                {
                    Header: language.tasks.table.description,
                    accessor: 'description',
                },
                {
                    Header: language.tasks.table.area,
                    accessor: 'area',
                },
                {
                    Header: language.tasks.table.user,
                    accessor: 'user',
                },
                {
                    Header: language.tasks.table.created_at,
                    accessor: 'created_at',
                },
                {
                    Header: language.tasks.table.estimated_date,
                    accessor: 'estimated_date',
                },
                {
                    Header: language.tasks.table.status,
                    accessor: 'status',
                },
            ];
            if (
                (
                    initiative.leader_user === currentUser.id ||
                    initiative.sponsor_user === currentUser.id ||
                    creator_task ||
                    getUserPermission(state, "tasks", "patch", false)
                )
            ) {
                col.push({
                    Header: language.tasks.form.edit,
                    accessor: 'edit',
                });
            }
            if (
                (
                    initiative.leader_user === currentUser.id ||
                    initiative.sponsor_user === currentUser.id ||
                    creator_task ||
                    getUserPermission(state, "tasks", "delete", false)
                )
            ) {
                col.push({
                    Header: language.tasks.form.delete,
                    accessor: 'delete',
                });
            }
            return col;
        },
        [state]
    )

    const renderTasks = () => {
        if (loadingTasks) {
            return <p>{language.general.loading}</p>;
        }
        if (!tasks || tasks.length === 0) {
            return <p>{language["tasks"]["not_found"]}</p>
        }

        let tasksList = tasks
            .filter(task => {
                return completedFilter.includes(task.completed);
            })
            .sort((a, b) => {
                if (a.completed === b.completed) {
                    a = a.forecast_date.split("T")[0].split('/').reverse().join('');
                    b = b.forecast_date.split("T")[0].split('/').reverse().join('');
                    return a > b ? 1 : a < b ? -1 : 0;
                }
                if (a.completed) {
                    return 1;
                } else {
                    return -1;
                }
            })
            .filter(task => task).map((task) => {
                return {
                    id: task.id,
                    description: task.description,
                    area: (areas_dict && task["area_id"] && areas_dict.hasOwnProperty(task["area_id"])) ? areas_dict[task["area_id"]].name : "",
                    stage: (phases.hasOwnProperty(task.stage))
                        ? phases[task.stage].name
                        : task.stage,
                    user: (users && users.hasOwnProperty(task.responsable_user)) ? users[task.responsable_user].full_name : "",
                    created_at: task.created_at.split("T")[0] || language["tasks"]["no_date"],
                    forecast_date: task.forecast_date.split("T")[0] || language["tasks"]["no_date"],
                    estimated_date: (task.estimated_date) ? (task.estimated_date.split("T")[0] || language["tasks"]["no_date"]) : language["tasks"]["no_date"],
                    status: language["tasks"]["status"][task.status],
                    completed: (task.completed) ? language["tasks"]["completed"]["yes"] :
                        (
                            (task.completed === false) ?
                                language["tasks"]["completed"]["no"]
                                : language["tasks"]["completed"]["annulment"]
                        )
                    ,
                    edit: <AcceptTask task={task} editableInInitiative={
                        (
                            initiative.leader_user === currentUser.id ||
                            initiative.sponsor_user === currentUser.id ||
                            task.creator === currentUser.id ||
                            getUserPermission(state, "tasks", "patch", false)
                        ) ? true : false
                    } />,
                    delete: (
                        initiative.leader_user === currentUser.id ||
                        initiative.sponsor_user === currentUser.id ||
                        task.creator === currentUser.id ||
                        getUserPermission(state, "tasks", "delete", false)
                    ) ? <DeleteTask task={task} /> : <></>
                }
            });

        if (!tasksList || tasksList.length === 0) {
            return <p>{language["tasks"]["not_active"]}</p>
        }


        return <SimpleTable columns={columns} data={tasksList} />

    }

    const processCompletedFilter = (value) => {
        let _completed = completedFilter;
        if (completedFilter.includes(value)) {
            _completed = completedFilter.filter(function (f) { return f !== value });
            setCompletedFilter(_completed);
        } else {
            setCompletedFilter([..._completed, value]);
        }
    }

    const renderCompletedFilter = () => {
        return (
            <div>
                <h4>{language["tasks"]["table"]["completed"]}:</h4>
                <div className="custom-control custom-checkbox custom-control-inline">
                <input key={1}
                    type="checkbox"
                    className="custom-control-input"
                    checked={completedFilter.includes(true)} 
                    onChange={() => processCompletedFilter(true)} 
                    id={"pb-1" }
                    name={"pb-1"}
                    />
                <label
                    className="custom-control-label"
                    htmlFor={"pb-1"}>
                    {language["tasks"]["completed"]["yes"]}
                </label>
                </div>
                <div className="custom-control custom-checkbox custom-control-inline">
                <input key={2}
                    type="checkbox"
                    className="custom-control-input"
                    checked={completedFilter.includes(false)} 
                    onChange={() => processCompletedFilter(false)} 
                    id={"pb-2" }
                    name={"pb-2"}
                    />
                <label
                    className="custom-control-label"
                    htmlFor={"pb-2"}>
                    {language["tasks"]["completed"]["no"]}
                </label>
                </div>
                <div className="custom-control custom-checkbox custom-control-inline">
                <input key={3}
                    type="checkbox"
                    className="custom-control-input"
                    checked={completedFilter.includes(null)} 
                    onChange={() => processCompletedFilter(null)} 
                    id={"pb-3" }
                    name={"pb-3"}
                    />
                <label
                    className="custom-control-label"
                    htmlFor={"pb-3"}>
                    {language["tasks"]["completed"]["annulment"]}
                </label></div>
            </div>
        );
    }

    

    

    return (
        <div>
            <Row>
                <Col>
                    {renderCompletedFilter()}
                </Col>
            </Row>
            {renderTasks()}
        </div>
    );
}

export default connect()(ListInitiativeTasks);
