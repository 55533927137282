import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";


export const setConfigs = (configs) => ({
    type: "SET_CONFIGS",
    payload: {
        configs: configs,
    },
    request_time: Date.now()
});

export const setConfig = (data) => ({
    type: "SET_CONFIG",
    payload: {
        id: data.id,
        name: data.name,
        value: data.value,
        request_time: Date.now()
    },
});

export const setConfigError = (error) => ({
    type: "CONFIG_ERROR",
    payload: {
        error: error
    }
});

export const deleteConfig = (config_id) => ({
    type: "DELETE_CONFIG",
    payload: {
        id: config_id,
    },
    request_time: Date.now()
});

export const getAllConfigs = () => {

    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "configs", "list")) {
                throw new Error("No permissions");
            }
            if (state.configs.request_time === null
                || state.configs.request_time + 1000 * 2 < Date.now()) {
                fetch(`${BACKEND_URL}/configs`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setConfigs(data));
                    })
                    .catch((error) => {
                        errorHandler("configs", error.message);
                        dispatch(setConfigError(error));
                    });
            }
        } catch (error) {
            errorHandler("configs", error.message);
            dispatch(setConfigError(error));
        }
    };
}

export const deleteConfigById = (config_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "configs", "delete", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/configs/${config_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
                .then((response) => {
                    if (response.status === 204) {
                        return true;
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((vl) => {
                    if (vl) {
                        dispatch(deleteConfig(config_id));
                    }
                })
                .catch((error) => {
                    errorHandler("configs", error.message);
                    dispatch(setConfigError(error));
                });
        } catch (error) {
            errorHandler("configs", error.message);
            dispatch(setConfigError(error));
        }
    };
}

export const patchConfig = (configId, data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "configs", "patch", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/configs/${configId}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
                method: "PATCH",
                body: JSON.stringify(data)
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    dispatch(setConfig(data));
                })
                .catch((error) => {
                    errorHandler("configs", error.message);
                    dispatch(setConfigError(error.message));
                });
        } catch (error) {
            errorHandler("configs", error.message);
            dispatch(setConfigError(error));
        }
    };
}
