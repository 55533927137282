import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import SimpleTable from '../../components/tables/SimpleTable';

import { language } from '../../assets/language';
import { getUserPermission } from '../../utils/getUserPermission';

import CreateInitiativeDocument from './CreateInitiativeDocument';
import DeleteDocument from './DeleteDocument';
import EditInitiativeDocument from './EditInitiativeDocument';
import { getInitiativeDocuments } from '../../redux/actions/documents';





const ListInitiativeDocuments = ({ dispatch, initiative }) => {
  const [loading, setLoading] = useState(true);
  const [expandedGroups, setExpandedGroups] = useState({});

  const documents = useSelector(state => state.documents.documents);

  const mimeTypeToFileExtension = (mimeType) => {
    const typeMapping = {
      "image/jpeg": ".jpeg",
      "image/png": ".png",
      "image/gif": ".gif",
      "image/bmp": ".bmp",
      "image/webp": ".webp",
      "image/svg+xml": ".svg",
      "application/pdf": ".pdf",
      "text/plain": ".txt",
      "application/msword": ".doc",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ".docx",
      "application/vnd.ms-excel": ".xls",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx",
      "application/zip": ".zip",
      "application/x-rar-compressed": ".rar",
      "text/csv": ".csv",
      "text/html": ".html",
      "application/json": ".json",
      "application/xml": ".xml",
      "text/markdown": ".md",
      "text/css": ".css",
      "application/external-link": language.documents.external,
    };
  
    return typeMapping[mimeType] || "";
  };


  useEffect(() => {
    dispatch(getInitiativeDocuments(initiative.id));
  }, [dispatch, initiative]);

  useEffect(() => {
    console.log("Documents: ", documents);
    setLoading(false);
  }, [documents]);


  const toggleGroup = (index) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };


  const renderDocumentGroup = (documentGroup, index) => {
    const isExpanded = expandedGroups[index];

    return (
      <div key={`document-group-${index}`} className="document-group" >
        {documentGroup.map((document, docIndex) => (
          <React.Fragment key={`document-${docIndex}`}>
            {(docIndex === 0 || isExpanded) && (
              <div className={`document-row ${docIndex !== 0 ? "versioned-document" : "last-version"}`}>
                <p className="document-attribute"><a href={document.file_path} target="_blank" rel="noreferrer">{document.name || "-"}</a></p>
                <p className="document-attribute">{document.version_number || "-"}</p>
                <p className="document-attribute">
                  {mimeTypeToFileExtension(document.mime_type) || document.mime_type}
                </p>
                <div className="document-actions">
                  <DeleteDocument initiativeId={initiative.id} documentId={document.id} />
                  {docIndex === 0 && document.mime_type !== "application/external-link" && (
                    <EditInitiativeDocument initiativeId={initiative.id} documentId={document.id} initialName={document.name} filePath={document.file_path} />
                  )}
                  {docIndex === 0 && documentGroup.length > 1 && (
                    <>
                      <div className="toggle-button-container">
                        <button className="toggle-button" onClick={() => toggleGroup(index)}>
                          {isExpanded ? <i className="fa fa-chevron-up" /> : <i className="fa fa-chevron-down" />}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  const renderDocuments = () => {
    if (!documents || documents.length === 0) {
      return <p>{language["documents"]["not_found"]}</p>;
    }
    return (
      <div>
        <div className="document-header" >
          <p className="document-attribute"><b>{language.documents.table.name}</b></p>
          <p className="document-attribute"><b>{language.documents.table.version_number}</b></p>
          <p className="document-attribute"><b>{language.documents.table.type}</b></p>
          <p className="document-actions"><b>{language.documents.table.actions}</b></p>
        </div>
        {documents.map((documentGroup, index) => renderDocumentGroup(documentGroup, index))}
      </div>
    )
  };

  return (
    <div>
      <CreateInitiativeDocument initiative={initiative} setLoading={setLoading} />
      <h1>{language.documents.title}</h1>
      <br />
      {loading ? <p>Loading...</p> : renderDocuments()}
    </div>
  );
};

export default connect()(ListInitiativeDocuments)
