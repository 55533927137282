import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import {
  getAllPhases,
  getInitiative,
} from "../../redux/actions";
import { language } from "../../assets/language";
import { CreateRisk, ListInitiativeRisks } from "../risks";
import { CreateKpiSecond, ListInitiativeKpis } from "../kpiseconds";
import {
  ListInitiativeApprovals,
  CreateApproval,
  SendActiveEmailsButton,
} from "../approvals";
import { ListInitiativeChecklists } from "../checklists";
import { ListInitiativeUsers } from "../users";
import { ListInitiativeAdvances } from "../advances";
import ListInitiativeActivities from "../activities/ListInitiativeActivities";
import { ShowInitiativeTree } from "../trees";
import { CreateTask, ListInitiativeTasks } from "../tasks";
import { getUserPermission } from "../../utils/getUserPermission";
import { General } from "../general";
import Loading from "../../components/Loading/Loading";
import {
  Card,
  CardBody,
  NavLink,
  Nav,
  NavItem,
} from "reactstrap";
import InitiativeHeader from "./InitiativeHeader";
import Progress from "../progress";
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import { getResponsabilityPermission } from "../../utils/getResponsabilityPermission";

import PrintInitiative from "./PrintInitiative";
import ListInitiativeDocuments from "../documents/ListInitiativeDocuments";


const ShowInitiative = ({ dispatch, match }) => {
  const state = useSelector((state) => state);
  const history = useHistory();
  const [loading, setloading] = useState(true);
  let [loadInitiative, setLoadInitiative] = useState(false);

  const initiativeResponse = useSelector((state) => state.initiatives);
  const initiatives = useSelector((state) => state.initiatives.initiatives);
  const phases = useSelector((state) => state.phases.phases);

  const [initiative, setInitiative] = useState({});
  const [shouldPrintInitiative, setShouldPrintInitiative] = useState(false);



  let [selectedTab, setSelectedTab] = useState("progress");

  
  useEffect(() => {
    let tab = localStorage.getItem("tab") !== undefined ? JSON.parse(localStorage.getItem("tab")) : null;
    if (tab !== null && tab.initiative === match.params.initiativeId) {
      setSelectedTab(tab.tab);
    } else {
      setSelectedTab("progress");
    }
  }, [])




  let tabsList = useMemo(() => {
    return [
      {
        id: "general",
        name: language.initiatives.tabs.general,
      },
      {
        id: "progress",
        name: language.initiatives.tabs.progress,
      },
      {
        id: "value",
        name: language.initiatives.tabs.value,
      },
      {
        id: "kpis",
        name: language.initiatives.tabs.kpis,
      },
      {
        id: "activities",
        name: language.initiatives.tabs.activities,
      },
      {
        id: "approvals",
        name: language.initiatives.tabs.approvals,
      },
      // {
      //   id: "tasks",
      //   name: language.initiatives.tabs.tasks,
      // },
      {
        id: "risks",
        name: language.initiatives.tabs.risks,
      },
      {
        id: "users",
        name: language.initiatives.tabs.users,
      },
      {
        id: "checklists",
        name: language.initiatives.tabs.checklists,
      },
      {
        id: "documents",
        name: language.initiatives.tabs.documents,
      }
      // {
      //   id: "advances",
      //   name: language.initiatives.tabs.advances,
      // },
    ];
  }, []);


  useEffect(() => {
    if (!loadInitiative) {
      dispatch(getInitiative(match.params.initiativeId));
      dispatch(getAllPhases());
      setLoadInitiative(true);
    } 

  }, [loadInitiative]);


  useEffect(()=> {
    let initiativesId = [];
    if (initiatives) {
      initiativesId = initiatives.map((initiative) => initiative.id);
      if (!initiativesId.includes(match.params.initiativeId)) {
        toast.error("No tienes acceso a esta iniciativa");
        if (history.length > 1) {
          history.goBack();
        } else {
          history.push("/");
        }
      }
  }
  },[initiatives, history, match.params.initiativeId]);



  useEffect(() => {
    if (initiativeResponse.initiative && initiativeResponse.initiative.id === match.params.initiativeId && phases ) {
      setInitiative(initiativeResponse.initiative);
      setloading(false);
   }
 }, [initiativeResponse, phases]);


  const canEdit = (type) => {

    if ( getUserPermission(state, type, "patch", false) ) {
      return true
    } else if (initiative.stage === "evaluation" && getResponsabilityPermission(state,type,"change", initiative.id, "Lider")) {
      return true
    } else {
      return false
    }
  }


  const renderTabs = () => {
    return (
      <div className='nav-wrapper'>
        <Nav
          className='nav-fill flex-column flex-md-row'
          id='tabs-icons-text'
          pills
          role='tablist'
        >
          {tabsList.map((tab, index) => {
            return (
              <NavItem key={index}>
                <NavLink
                  className={`pointer mb-sm-3 mb-md-0 ${
                    selectedTab === tab.id ? "bg-default text-white" : ""
                  }`}
                  onClick={() => {
                    setSelectedTab(tab.id);
                    localStorage.setItem("tab", JSON.stringify( {initiative: initiative.id ,tab: tab.id}))
                    ;}}
                  role='tab'
                >
                  {tab.name}
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
      </div>
    );
  };

  const renderContent = () => {
    switch (selectedTab) {
      case "general":
        return  <General match={match} setShouldPrintInitiative={setShouldPrintInitiative} />
      case "progress":
        return initiative.id === match.params.initiativeId ? (
          <Card className='mt-2'>
            <InitiativeHeader initiative={initiative}  phases={phases}/>
            <hr />
            <CardBody>
              <Progress initiative={initiative} initiative_id={match.params.initiativeId} />
            </CardBody>
          </Card>
        ) : "" ;
      case "value":
        return (
          <Card className='mt-2'>
            <InitiativeHeader initiative={initiative}  phases={phases}/>
            <hr />
            <CardBody>
              <h3>{language["initiatives"]["tabs"]["value"]}</h3>
              <ShowInitiativeTree initiative={initiative} />
            </CardBody>
          </Card>
        );
      case "activities":
        return (
          <Card className='mt-2'>
            <InitiativeHeader initiative={initiative}  phases={phases}/>
            <hr />
            <CardBody>
              <ListInitiativeActivities initiative={initiative} />
            </CardBody>
          </Card>
        );
      case "kpis":
        return (
          <Card className='mt-2'>
            <InitiativeHeader initiative={initiative}  phases={phases}/>
            <hr />
            <CardBody>
              <h2 className='float-left mb-4'>
                {language["kpiseconds"]["title"]}
              </h2>
              {
                (
                  getUserPermission(state, "kpiseconds", "patch", false)) ||
              (initiative.stage === "evaluation" &&
                (getResponsabilityPermission(state, "activities", "change",initiative.id,"Lider" )
                  )) ? (
                <CreateKpiSecond initiative={initiative}  />

              ) : (
                <></>
              )}
              <ListInitiativeKpis initiative={initiative} />
            </CardBody>
          </Card>
        );
      case "approvals":
        return (
          <Card className='mt-2'>
            <InitiativeHeader initiative={initiative}  phases={phases}/>
            <hr />
            <CardBody>
              <h3 className='float-left mb-4'>
                {language["approvals"]["title"]}
              </h3>
              { (getUserPermission(state, "approvals", "patch")) ? (
                <CreateApproval initiative={initiative} />
              ) : (
                <></>
              )}
              {getUserPermission(state, "approvals", "patch") ? (
                <SendActiveEmailsButton initiative={initiative} />
              ) : (
                <></>
              )}
              <ListInitiativeApprovals initiative={initiative} />
            </CardBody>
          </Card>
        );
      case "tasks":
        return (
          <Card className='mt-2'>
            <InitiativeHeader initiative={initiative}  phases={phases}/>
            <hr />
            <CardBody>
              <h3 className='float-left mb-4'>{language["tasks"]["title"]}</h3>
              {
                (initiative.stage !== "evaluation" &&
                (
                  getUserPermission(state, "tasks", "patch", false))) ||
              (canEdit("tasks")) ? (
                <CreateTask initiative={initiative} />
              ) : (
                <></>
              )
              }
              <ListInitiativeTasks initiative={initiative} />
            </CardBody>
          </Card>
        );
      case "risks":
        return (
          <Card className='mt-2'>
            <InitiativeHeader initiative={initiative}  phases={phases}/>
            <hr />
            <CardBody>
              <h3 className='float-left mb-4'>{language["risks"]["title"]}</h3>
              {(initiative.stage !== "evaluation" &&
                (
                  getUserPermission(state, "risks", "patch", false))) ||
              (canEdit("risks")) ? (
                <CreateRisk initiative={initiative} />
              ) : (
                <></>
              )}
              <ListInitiativeRisks initiative={initiative} />
            </CardBody>
          </Card>
        );
      case "checklists":
        return (
          <Card className='mt-2'>
            <InitiativeHeader initiative={initiative}  phases={phases}/>
            <hr />
            <CardBody>
              <ListInitiativeChecklists
                initiative={initiative}
                phases={phases}
              />
            </CardBody>
          </Card>
        );
      case "users":
        return (
          <Card className='mt-2'>
            <InitiativeHeader initiative={initiative}  phases={phases}/>
            <hr />
            <CardBody>
              <ListInitiativeUsers initiative={initiative} />
            </CardBody>
          </Card>
        );
      case "advances":
        return (
          <Card className='mt-2'>
            <InitiativeHeader initiative={initiative}  phases={phases}/>
            <hr />
            <CardBody>
              <h3 className='float-left mb-4'>
                {language["advances"]["title"]}
              </h3>
              <ListInitiativeAdvances initiative={initiative} />
            </CardBody>
          </Card>
        );
      case "documents":
        return (
          <Card className='mt-2'>
            <InitiativeHeader initiative={initiative}  phases={phases}/>
            <hr />
            <CardBody>
              {/* <h3 className='float-left mb-4'> {language["documents"]["title"]} </h3> */}
              <ListInitiativeDocuments initiative={initiative} />
            </CardBody>
          </Card>
        );
      default:
        return <div></div>;
    }
  };


  return loading ? (
    <Loading />
  ) : initiative ?  (
    <div>
      {renderTabs()}
      {renderContent()}
      
      {shouldPrintInitiative && <PrintInitiative 
        initiative={initiative} 
        phases={phases} 
        state={state} 
        match={match} 
        shouldPrintInitiative={shouldPrintInitiative} 
        setShouldPrintInitiative={setShouldPrintInitiative} 
      />}
    </div>
  ): (<div>{language["initiatives"]["notFound"]}</div>);
};

export default connect()(ShowInitiative);
