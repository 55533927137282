import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { getAllAreas, patchCard, getTags } from "../../redux/actions";
import { language } from "../../assets/language";
import { getUserPermission } from "../../utils/getUserPermission";
import { useClickAway, useLogger } from "react-use";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
} from "reactstrap";
import Loading from "../../components/Loading/Loading";

const EditCard = ({ card, dispatch }) => {
  const areas = useSelector((state) => state.areas.areas);
  const tags = useSelector((state) => state.tags.tags);
  const updateCard = useSelector((state) => state.cards.cards);
  const [loading, setLoading] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const unit =  localStorage.getItem("unit") !== "undefined" ? JSON.parse(localStorage.getItem("unit")) : "";


  

  let [modalCards, setModalCards] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalCards(false);
  });

  let [title, setTitle] = useState(card.title);
  let [description, setDescription] = useState(card.description);
  const state = useSelector((state) => state);
  let [estimated_value, setEstimated] = useState(card.estimated_value);
  let [area_id, setAreaId] = useState(card.area_id);
  let [font, setFont] = useState(card.fase);
  let [cardState, setCardState] = useState(card.state);
  let [cardValued, setCardValued] = useState(card.valued);
  let [cardEase, setCardEase] = useState(card.ease);

  const currentUser = useSelector((state) => state.sessions.user);
  let valued_cards = useMemo(() => ["0", "1", "2"], []);
  let ease_cards = useMemo(() => ["0", "1", "2", "3", "4"], []);


  useEffect(() => {
    if(updateCard && isUpdate ) {
      let cardUp = updateCard.filter(c => c.id === card.id)[0]
      setTitle(cardUp.title);
      setDescription(cardUp.description);
      setEstimated(cardUp.estimated_value);
      setAreaId(cardUp.area_id);
      setFont(cardUp.fase);
      setCardState(cardUp.state);
      setCardValued(cardUp.valued);
      setCardEase(cardUp.ease);
      setModalCards(false);
      setIsUpdate(false);
      setLoading(false);
    }
  }, [updateCard])
    

  useEffect(() => {
    dispatch(getAllAreas());
  }, []);

  useEffect(() => {
    if(areas && tags){
      setLoading(false);
    }
  },[areas, tags])

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    setLoading(true);
    setIsUpdate(true);
    dispatch(patchCard(card, object));
    setTitle("");
    setDescription("");
    setEstimated("");
    setAreaId("");
    setFont("");
    setCardState(tags[0].id);
    setCardValued(0);
    setCardEase(0);
  };

  const renderFormCard = () => {
    return loading ? <Loading /> :  (
      <div>
        <Button
          color='warning'
          className=' btn-sm mb-1'
          block
          onClick={() => setModalCards(true)}
        >
          {language["cards"]["form"]["edit"]}
        </Button>
        <Modal
          className='modal-dialog-centered'
          isOpen={modalCards}
          toggle={() => setModalCards(false)}
        >
          <div className='modal-body p-0'>
            <Card className='bg-secondary shadow border-0'>
              <CardBody className='px-lg-5 py-lg-2'>
                <Form onSubmit={handleSubmit} ref={wrapperRef}>
                  <div>
                    <h2 className='text-muted text-center mt-2 mb-3'>
                      {language["cards"]["form"]["edit"]}
                    </h2>
                    <FormGroup>
                      <label htmlFor='title'>
                        {" "}
                        {language["cards"]["table"]["title"]}
                      </label>
                      <Input
                        className='form-control-sm'
                        maxLength={254}
                        type='text'
                        name='title'
                        required
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor='description'>
                        {language["cards"]["table"]["description"]}
                      </label>
                      <Input
                        className='form-control-sm'
                        type='text-area'
                        maxLength={254}
                        name='description'
                        autoComplete='off'
                        required
                        multiple={true}
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor='estimated_value'>
                        {language["cards"]["table"]["estimated_value"]} {unit.value}
                      </label>
                      <Input
                        className='form-control-sm'
                        type='number'
                        name='estimated_value'
                        required
                        onChange={(e) => setEstimated(e.target.value)}
                        value={estimated_value}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>{language["cards"]["table"]["area"]}</label>
                      <Input
                        className='form-control-sm'
                        type='select'
                        name='area_id'
                        required
                        onChange={(e) => setAreaId(e.target.value)}
                        value={area_id}
                      >
                        {areas.map((area) => (
                          <option key={area.id} value={area.id}>
                            {area.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <label>{language["cards"]["table"]["state"]}</label>
                      <Input
                        disabled={
                          getUserPermission(state, "cards", "patch", false) ||
                          card.original_user === currentUser.id
                            ? false
                            : true
                        }
                        className='form-control-sm'
                        type='select'
                        name='state'
                        required
                        onChange={(e) => setCardState(e.target.value)}
                        value={cardState}
                      >
                        {Array.isArray(tags)
                          ? tags.map((tag) => (
                              <option key={tag.id} value={tag.id}>
                                {tag.name}
                              </option>
                            ))
                          : ""}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <label>{language["cards"]["table"]["valued"]}</label>
                      <Input
                        disabled={
                          getUserPermission(state, "cards", "patch", false) ||
                          card.original_user === currentUser.id
                            ? false
                            : true
                        }
                        className='form-control-sm'
                        type='select'
                        name='valued'
                        required
                        onChange={(e) => setCardValued(e.target.value)}
                        value={cardValued}
                      >
                        {valued_cards.map((st) => (
                          <option key={st} value={st}>
                            {language.cards.valued[st || "0"]}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <label>{language["cards"]["table"]["ease"]}</label>
                      <Input
                        disabled={
                          getUserPermission(state, "cards", "patch", false) ||
                          card.original_user === currentUser.id
                            ? false
                            : true
                        }
                        className='form-control-sm'
                        type='select'
                        name='ease'
                        required
                        onChange={(e) => setCardEase(e.target.value)}
                        value={cardEase}
                      >
                        {ease_cards.map((st) => (
                          <option key={st} value={st}>
                            {language.cards.ease[st || "0"]}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor='fase'>
                        {language["cards"]["table"]["fase"]}
                      </label>
                      <Input
                        className='form-control-sm'
                        name='fase'
                        required
                        onChange={(e) => setFont(e.target.value)}
                        value={font}
                      />
                    </FormGroup>
                  </div>
                  <Button color='success' type='submit'>
                    {language["cards"]["form"]["edit"]}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </div>
    );
  };

  return <div>{renderFormCard()}</div>;
};

export default connect()(EditCard);
