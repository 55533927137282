import { BACKEND_URL } from "../../utils/config";
import { getAllPermits, getAllGroups, getRolePermits, getUserRoleGroups, getCurrentUserInitiatives } from ".";
import { errorHandler } from "../../utils/error_handler";

export const setUser = (data) => ({
  type: "LOGIN",
  payload: {
    id: data.user.id,
    firstname: data.user.firstname,
    lastname: data.user.lastname,
    email: data.user.email,
    role: data.user.role,
    role_id: data.user.role_id,
    token: data.token,
  },
});

export const setUserError = (error) => ({
  type: "LOGIN_ERROR",
  payload: {
    error: error,
  },
});

export const logout = () => ({
  type: "LOGOUT",
});

export const login = (email, password) => {
  return (dispatch) => {
    fetch(`${BACKEND_URL}/sessions/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.status);
        }
      })
      .then((data) => {
        if (data.error) {
          dispatch(setUserError(data.error));
        } else {
          dispatch(setUser(data));
          dispatch(getAllPermits());
          dispatch(getRolePermits(data.user.role_id));
          dispatch(getUserRoleGroups(data.user.role_id));
          dispatch(getAllGroups());
          dispatch(getCurrentUserInitiatives())
        }
      })
      .catch((error) => {
        errorHandler("sessions", error.message);
        dispatch(setUserError(error));
      });
  };
};

export const getCurrentUser = () => {
  // get state
  return (dispatch, getState) => {
    try {
      const state = getState();
      if (!state.sessions.user.id) {
        dispatch(logout());
      } else {
        fetch(`${BACKEND_URL}/users/${state.sessions.user.id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.sessions.token}`,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              throw new Error(response.status);
            }
          })
          .then((data) => {
            if (data.error) {
              dispatch(logout());
            } else {
              dispatch(setUser({ user: data }));
              dispatch(getAllPermits());
              dispatch(getRolePermits(data.role_id));
              dispatch(getUserRoleGroups(data.role_id));
              dispatch(getAllGroups());

            }
          })
          .catch((error) => {
            console.log(error);
            dispatch(logout());
          });
      }
    } catch (error) {
      console.log(error);
      //errorHandler("sessions", error.message);
    }
  };
};

export const checkLogged = () => {
  // get state
  return (dispatch, getState) => {
    try {
      const state = getState();
      if (!state.sessions.user || !state.sessions.user.id) {
        dispatch(logout());
      } else {
        fetch(`${BACKEND_URL}/hello`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.sessions.token}`,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              throw new Error(response.status);
            }
          })
          .then((data) => {
            if (data.status === 0) {
              dispatch(logout());
            }
          })
          .catch((error) => {
            console.log(error);
            dispatch(logout());
          });
      }
    } catch (error) {
      console.log(error);
      dispatch(logout());
      //errorHandler("sessions", error.message);
    }
  };
};
