const initialState_Stage = {
    request_time: null,
    error: null,
    initiativeId: null,
    stages: [],
    stagesError: null,
    all_stages: [],
    stats_request_time: null,
};

const stages = (state = initialState_Stage, action) => {
    switch (action.type) {
        case "STAGES_ERROR":
            return {
                ...state,
                stagesError: action.payload.error,
                // stages: [],
                initiativeId: state.initiativeId,
                request_time: null,
                stats_request_time: null,
            };
        case "SET_STAGE":
            return {
                ...state,
                stages: [...state.stages, action.payload],
                request_time: action.payload.request_time,
                initiativeId: state.initiativeId,
            };
        case "UPDATE_STAGE":
            return {
                ...state,
                stages: state.stages.map(stage => {
                    if (stage.id === action.payload.id) {
                        return action.payload;
                    }
                    return stage;
                }),
                request_time: action.request_time,
                initiativeId: state.initiativeId
            };
        case 'SET_STAGES':
            return {
                ...state,
                stages: action.payload.stages,
                request_time: action.request_time,
                initiativeId: action.payload.initiativeId
            };
        case 'SET_ALL_STAGES':
            return {
                ...state,
                all_stages: action.payload.stages,
                stats_request_time: action.request_time,
            };
        case "DELETE_STAGE":
            return {
                ...state,
                stages: state.stages.filter(stage => stage.id !== action.payload.id),
                request_time: action.request_time,
            };
        default:
            return state;
    }
}

export default stages;