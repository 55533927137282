const initialState_Session = {};

const sessions = (state = initialState_Session, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                user: {
                    id: action.payload.id,
                    firstname: action.payload.firstname,
                    lastname: action.payload.lastname,
                    email: action.payload.email,
                    role: action.payload.role,
                    role_id: action.payload.role_id,
                },
                token: action.payload.token,
                isLoggedIn: true,
                isError: false
            }
        case 'LOGOUT':
            return {
                user: {},
                isLoggedIn: false,
                isError: false
            };
        case 'LOGIN_ERROR':
            return {
                error: action.error,
                isLoggedIn: false,
                isError: true,
                user: null
            };
        default:
            return state;
    }
}

export default sessions;
