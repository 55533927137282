const initialState_Initiative = {
    initiatives: [],
    request_time: null,
    error: null,
    initiative: null,
    all_initiatives: [],
};

const initiatives = (state = initialState_Initiative, action) => {
    switch (action.type) {
        case 'SET_INITIATIVES':
            return {
                ...state,
                initiatives: action.payload.initiatives,
                request_time: action.request_time,
            };
        case 'SET_ALL_INITIATIVES':
            return {
                ...state,
                all_initiatives: action.payload.all_initiatives,
                request_time: action.request_time,
            };
        case "NEW_INITIATIVE":
            return {
                ...state,
                initiatives: [...state.initiatives, action.payload],
                request_time: action.request_time,
                initiative: action.payload,
            };
        case "SET_INITIATIVE":
            // search action.payload.initiative.id in state.initiatives
            let index = state.initiatives.findIndex(
                (initiative) => initiative.id === action.payload.id
            );
            if (index === -1) {
                return {
                    ...state,
                    initiatives: [
                        ...state.initiatives,
                        action.payload
                    ],
                    initiative: action.payload,
                };
            } else {
                // replace the initiative in state.initiatives.initiatives
                return {
                    ...state,
                    initiative: action.payload,
                    initiatives: [
                        ...state.initiatives.slice(0, index),
                        action.payload,
                        ...state.initiatives.slice(index + 1)
                    ],
                };
            }
        case "UPDATE_INITIATIVE":
            return {
                ...state,
                initiatives: state.initiatives.map(initiative => {
                    if (initiative.id === action.payload.id) {
                        return action.payload;
                    }
                    return initiative;
                }),
                request_time: action.request_time,
                initiative: state.initiative
            };
        case "DELETE_INITIATIVE":
            return {
                ...state,
                request_time: null,
                initiative: null,
            };
        case "INITIATIVE_ERROR":
            return {
                ...state,
                initiative: null,
                error: action.payload.error,
                // initiatives: [],
            };
        default:
            return state;
    }
}

export default initiatives;