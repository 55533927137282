import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { getAllUsers, getAllAreas } from '../../redux/actions';
import { language } from '../../assets/language';
import { CreateUser, DeleteUser, EditUser }  from '.';
import SimpleTable from '../../components/tables/SimpleTable';
import { getUserPermission } from '../../utils/getUserPermission';
import { Button, Card } from "reactstrap";

const ListUsers = ({ dispatch }) => {

    const state = useSelector(state => state);
    const users = useSelector(state => state.users.users);
    const currentUser = useSelector(state => state.sessions.user);
    const areas = useSelector(state => state.areas.areas);
    const areas_dict = useMemo(() => {
        return areas.reduce((acc, area) => {
            acc[area.id] = area;
            return acc;
        }, {});
    }, [areas]);

    useEffect(() => {
        dispatch(getAllUsers());
        dispatch(getAllAreas());
    }, [dispatch]);

    const columns = useMemo(
        () => {
            let col = [
                {
                    Header: language.users.table.full_name,
                    accessor: 'full_name',
                },
                {
                    Header: language.users.table.email,
                    accessor: 'email',
                },
                {
                    Header: language.users.table.role,
                    accessor: 'role',
                },
                {
                    Header: language.users.table.area,
                    accessor: 'area',
                },
            ];
            if (getUserPermission(state, "users", "patch")) {
                col.push({
                    Header: language.users.form.edit,
                    accessor: 'edit',
                });
            }
            if (getUserPermission(state, "users", "delete")) {
                col.push({
                    Header: language.users.form.delete,
                    accessor: 'delete',
                });
            }
            return col;
        },
        [state]
    );

    const renderUsers = () => {
        let usersList = Object.values(users).map(user => {
            return {
                id: user.id,
                firstname: user.firstname,
                lastname: user.lastname,
                full_name: user.full_name,
                // full_name: <Link to={`/users/${user.id}`}>
                // {user.full_name}
                // </Link>,
                email: user.email,
                area: (areas_dict && user["area_id"] && areas_dict.hasOwnProperty(user["area_id"])) ? areas_dict[user["area_id"]].name : "",
                role: language.roles.kinds[user.role] || user.role,
                edit: <EditUser user={user} />,
                delete: (user.id !== currentUser.id) ? <DeleteUser user={user} /> : <></>
            }
        });

        return <SimpleTable columns={columns} data={usersList} />
    }

    return (
        <Card className="p-3 m-3">
            <h1>{language.users.title}</h1>
            <CreateUser />
            <br />
            {renderUsers()}
        </Card>
    );
}

export default connect()(ListUsers);