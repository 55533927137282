const initialState_Initype = {
    request_time: null,
    error: null,
    initypes: {},
};

const initypes = (state = initialState_Initype, action) => {
    switch (action.type) {
        case 'SET_INITYPES':
            return {
                initypes: action.payload.initypes.reduce((obj, item) => {
                    obj[item.name] = item;
                    return obj;
                }, {}),
                request_time: action.request_time,
            };
        case 'SET_INITYPE':
            try {
                return {
                    ...state,
                    initypes: {
                        ...state.initypes, [action.payload.name]: action.payload,
                    },
                    request_time: action.payload.request_time,
                };
            } catch (e) {
                console.log(e);
                return state;
            }
        case 'INITYPE_ERROR':
            return {
                ...state,
                error: action.payload.error,
                request_time: null,
                // initypes: {},
            };
        case "DELETE_INITYPE":
            let new_initypes = state.initypes;
            try {
                new_initypes = Object.keys(new_initypes).reduce(function (filtered, key) {
                    if (new_initypes[key].id !== action.payload.id) filtered[key] = new_initypes[key];
                    return filtered;
                }, {});
            } catch (e) {
                console.log(e);
            }
            return {
                ...state,
                initypes: new_initypes,
                request_time: action.request_time,
            };
        default:
            return state;
    }
}

export default initypes;