import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { language } from "../../assets/language";
import { ShowStandardTree } from ".";
import { ListTreeTshifts } from "../tshifts";
import { CreateTree } from ".";
import { getAllTrees } from "../../redux/actions";
import { Card } from "reactstrap";
import Loading from "../../components/Loading/Loading";




const ShowOrganizationTree = ({ dispatch }) => {
  let [showTshifts, setShowTshifts] = useState(false);
  let [treeSelected, setTreeSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const trees = useSelector((state) => state.trees.trees);

  useEffect(() => {
    if (trees ) {
      setLoading(false);
    }
  }, [trees]);


  useEffect(() => {
    if (!trees) {
      setLoading(true);
      dispatch(getAllTrees());
    }
  }, []);





  return  loading ? (<Loading />) : (
    <Card className='p-3 m-3'>
      <h1>{language.trees.title}</h1>
      <CreateTree />
      {showTshifts ? (
        <ListTreeTshifts tree={treeSelected} initiativeId={null} />
      ) : null}
      <ShowStandardTree
        treeClickOpen={(tree) => {
          setTreeSelected(tree);
          setShowTshifts(true);
        }}
        treeClickClose={() => {
          setTreeSelected(null);
          setShowTshifts(false);
        }}
        withCreate={true}
      />
    </Card>
  );
};

export default connect()(ShowOrganizationTree);
