import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { getAllChecklists, postChecklist } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import {
  Button,
  Card,
  FormGroup,
  Form,
  Input,
  Modal,
  CardBody,
} from "reactstrap";

const CreateChecklist = ({ dispatch, phase }) => {
  const phases = useSelector((state) => state.phases.phases);

  let [modalChecklists, setModalChecklists] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalChecklists(false);
  });

  let [question, setQuestion] = useState("");
  let [phase_id, setPhaseId] = useState("");

  useEffect(() => {
    if (phase !== undefined) {
      setPhaseId(phase);
    }
  }, [phase]);

  const handleSubmitChecklists = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    dispatch(postChecklist(object));
    setModalChecklists(false);
    setQuestion("");
  };

  useEffect(() => {
    dispatch(getAllChecklists());
  }, [dispatch]);

  const renderFormChecklist = () => {
    return (
      <>
        <Button
          color='success'
          className='btn-sm'
          onClick={() => setModalChecklists(true)}
        >
          {language["checklists"]["form"]["create"]}
        </Button>
        <Modal
          className='modal-dialog-centered'
          isOpen={modalChecklists}
          toggle={() => setModalChecklists(false)}
        >
          <div className='modal-body p-0'>
            <Card className='bg-secondary shadow border-0' ref={wrapperRef}>
              <CardBody className='px-lg-5 py-lg-2'>
                <h2>{language["checklists"]["form"]["create"]}</h2>
                <Form onSubmit={handleSubmitChecklists}>
                  <FormGroup>
                    <label>{language["checklists"]["form"]["phase"]}</label>
                    <Input
                      className='form-control-sm'
                      type='select'
                      name='phase_id'
                      required
                      onChange={(e) => setPhaseId(e.target.value)}
                      value={phase_id}
                    >
                      {Object.values(phases)
                        .filter((ph) => ph.required_checklist === true)
                        .map((phase) => (
                          <option key={phase.id} value={phase.id}>
                            {phase.name}
                          </option>
                        ))}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor='question'>
                      {language["checklists"]["table"]["question"]}
                    </label>
                    <Input
                      type='text'
                      className='form-control-sm'
                      maxLength={254}
                      name='question'
                      required
                      autoComplete='off'
                      onChange={(e) => setQuestion(e.target.value)}
                      value={question}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Button color='success' className='btn-sm' type='submit'>
                      {language["checklists"]["form"]["createQuestion"]}
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </>
    );
  };

  return <div>{renderFormChecklist()}</div>;
};

export default connect()(CreateChecklist);
