import React, { useState, useEffect } from 'react';
import { Collapse, Button, FormGroup, Label, Row, Col } from 'reactstrap';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { retrieveFilterValues, updateFilterValues } from '../../redux/actions';
import ReactDatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import Loading from '../Loading/Loading';

const SidebarRight = ({ onFilterApply }) => {

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const dashboardFilters = useSelector((state)=> state.counter_stats.filter)
  const [filter, setFilter] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
   if (dashboardFilters && dashboardFilters.filters) {
    let filters = dashboardFilters.filters
    const currentDate = new Date();
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
    const sixMonthsAhead = new Date();
    sixMonthsAhead.setMonth(currentDate.getMonth() + 6);

    if ( new Date(filters.startDate).getTime() && new Date(filters.endDate).getTime()) {
       onFilterApply(filters.startDate,filters.endDate);
       setStartDate(new Date(filters.startDate));
       setEndDate(new Date(filters.endDate));
    } else {
        onFilterApply(sixMonthsAgo,sixMonthsAhead);
        filters.startDate = sixMonthsAgo;
        filters.endDate = sixMonthsAhead;
        setStartDate((sixMonthsAgo));
        setEndDate((sixMonthsAhead)); 
    }
    setFilter(filters)
    setLoading(false)
    onFilterApply(startDate, endDate);
   }
  }, [dashboardFilters]);


  const toggle = () => {
    setIsOpen(!isOpen);
  };


  const handleFilterClick = () => {
    // Validar las restricciones de fechas aquí antes de aplicar los filtros
    if (startDate && endDate && endDate >= startDate) {
      // Aplicar filtros
      onFilterApply(startDate, endDate);
      let aux_filter = filter
      aux_filter.startDate = startDate;
      aux_filter.endDate = endDate;
      dispatch(updateFilterValues(aux_filter));
      setIsOpen(false);
    } else {
      // Mostrar mensaje de error o realizar alguna acción
      toast.error('Error en las fechas seleccionadas');

    }
  };




  return loading ? <Loading /> : (
    <div className="sidebar-container">
      <div className={`sidebar-toggle ${isOpen ? 'open' : ''}`} onClick={toggle}>
        {isOpen ? <FaAngleRight className="sidebar-icon" /> : <FaAngleLeft className="sidebar-icon" />}
      </div>
      <Collapse isOpen={isOpen}>
        <div className="sidebar">
          <h2>Filtros de fechas</h2>
          <Row>
            <Col md={6} >
            <FormGroup>
            <Label for="startDate">Fecha de inicio</Label>
            <ReactDatePicker 
              type="date" 
              id="startDate" 
              selected={startDate} 
              dateFormat={"MM-yyyy"}
              showMonthYearPicker
              className='form-control'
              onChange={(e)=>{setStartDate(e)}} />

          </FormGroup>

            </Col>
            <Col md={6} >
            <FormGroup>
            <Label for="endDate">Fecha de fin</Label>
            <ReactDatePicker
              type="date" 
              id="endDate" 
              dateFormat={"MM-yyyy"}
              showMonthYearPicker
              selected={endDate} 
              className='form-control'
              onChange={(e)=>setEndDate(e)} />
          </FormGroup>
                
            </Col>
          </Row>
          
          
          <Button color="primary" onClick={handleFilterClick}>Aplicar filtros</Button>
        </div>
      </Collapse>
    </div>
  );
};

export default SidebarRight;
