
import React, { useEffect, useState, useRef } from "react";

import { language } from "../../assets/language";
import PreparingPDF from "../../components/Loading/export"
import Progress from '../progress';
import {
  Card,
  CardBody,
} from "reactstrap";
import { General } from '../general';
import { ShowInitiativeTree } from "../trees";
import { getUserPermission } from "../../utils/getUserPermission";
import { ListInitiativeChecklists } from "../checklists";
import { ListInitiativeUsers } from "../users";
import ListInitiativeActivities from "../activities/ListInitiativeActivities";
import { getResponsabilityPermission } from "../../utils/getResponsabilityPermission";
import { CreateKpiSecond, ListInitiativeKpis } from "../kpiseconds";
import ListInitiativeDocuments from "../documents/ListInitiativeDocuments";
import {
  ListInitiativeApprovals,
  SendActiveEmailsButton,
} from "../approvals";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { CreateRisk, ListInitiativeRisks } from "../risks";
import { useDispatch, useSelector } from 'react-redux';
import Loading from "../../components/Loading/Loading";




// Create Document Component

const PrintInitiative = ({ initiative, match, phases, state, shouldPrintInitiative, setShouldPrintInitiative }) => {


  const [loading, setLoading] = useState(true);
  const kpiseconds = useSelector((state) => state.kpi_seconds.kpi_seconds);
  const filters = useSelector((state)=> state.counter_stats.filter)
  const decisions = useSelector((state) => state.decisions.decisions);
  const _kshifts = useSelector(state => state.kshifts.kshifts);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [progressReady, setProgressReady] = useState({});


  const contentRef = useRef(null);

  const printDocument = async () => {
    const sectionIds = ["section-general", "section-progress", "section-value", "section-kpis", "section-activities", "section-approvals", "section-risks", "section-users", "section-checklists", "section-documents"];
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    await new Promise (resolve => setTimeout(resolve, 2000));
  
    if (contentRef.current) {
      for (const sectionId of sectionIds) {
        const section = contentRef.current.querySelector(`#${sectionId}`);
        if (sectionId === "section-progress") {
          if (kpiseconds.length > 0) {
            await new Promise(resolve => {
              const checkInterval = setInterval(() => {
                console.log(progressReady);
                if ((Object.values(progressReady).every(value => value === true) && kpiseconds.length === Object.keys(progressReady).length) || kpiseconds.length === 0) {
                  clearInterval(checkInterval);
                  resolve();
                }
              }, 500); // Check every 500ms
            });
          }
        }
        if (section) {
          section.style.background = 'white';
          const canvas = await html2canvas(section);
          
          const imgWidth = canvas.width;
          const imgHeight = canvas.height;
          let finalWidth, finalHeight;
          const scaleX = pageWidth / imgWidth;
          const scaleY = pageHeight / imgHeight;
          const scale = Math.min(scaleX, scaleY);

          finalWidth = imgWidth * scale;
          finalHeight = imgHeight * scale;
  
          let offsetX = (pageWidth - finalWidth) / 2;
          let offsetY = 10;
          const imgData = canvas.toDataURL('image/jpeg', 0.7); 
          if (sectionId !== sectionIds[0]) {
            pdf.addPage();
          }
          pdf.addImage(imgData, 'JPEG', offsetX, offsetY, finalWidth, finalHeight);
        }
      }
      const date = new Date().toLocaleDateString();
      setShouldPrintInitiative(false);
      
      pdf.save(`${initiative.code}-initiative-details-${date}.pdf`);
    } else {
      alert("No se puede imprimir");
    }
  };

  useEffect(() => {
    if (loadingPDF && contentRef.current && shouldPrintInitiative ) {
      if (decisions && filters && kpiseconds && _kshifts ) {
        printDocument()
        setLoadingPDF(false)
      }
    }
  }, [loadingPDF, decisions, filters, kpiseconds, _kshifts, contentRef, shouldPrintInitiative]); 


  useEffect(() => {
    if (initiative && phases && match && loading && _kshifts && kpiseconds) {
      setLoading(false);
      setLoadingPDF(true);
    }
  }, [initiative, phases, match, loading, _kshifts]);


  const canEdit = (type) => {
    if ( getUserPermission(state, type, "patch", false) ) {
      return true
    } else if (initiative.stage === "evaluation" && getResponsabilityPermission(state,type,"change", initiative.id, "Lider")) {
      return true
    } else {
      return false
    }
  }

  return loading ? <Loading/> : (
    <div>
      { shouldPrintInitiative && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          backgroundColor: 'rgba(255, 255, 255, 1)', 
          zIndex: 1000
        }}>
          <PreparingPDF />
        </div>
      )}
      <div style={{ display: shouldPrintInitiative ? "block" : "none", backgroundColor: 'rgba(255, 255, 255, 1)' }} ref={contentRef}>
        <div id="section-general" style={{backgroundColor: 'rgba(255, 255, 255, 1)'}} >
          <General match={match} />
        </div>
        <div id="section-progress">
          { initiative.id === match.params.initiativeId && (
            <Card className='mt-2'>
              <hr />
              <CardBody>
                <h3>{language["initiatives"]["tabs"]["progress"]}</h3>
                <Progress initiative={initiative} initiative_id={match.params.initiativeId} noStage={true} setProgressReady={setProgressReady} progressReady={progressReady} />
              </CardBody>
            </Card>
          )}
        </div>
      
        <div id="section-value">
          <Card className='mt-2'>
            <hr />
            <CardBody>
              <h3>{language["initiatives"]["tabs"]["value"]}</h3>
              <ShowInitiativeTree initiative={initiative} />
            </CardBody>
          </Card>
        </div>
        
        <div id="section-kpis">
          <Card className='mt-2'>
            <hr />
            <CardBody>
              <h2 className='float-left mb-4'>
                {language["kpiseconds"]["title"]}
              </h2>
              {
                (
                  getUserPermission(state, "kpiseconds", "patch", false)) ||
              (initiative.stage === "evaluation" &&
                (getResponsabilityPermission(state, "activities", "change",initiative.id,"Lider" )
                  )) ? (
                <CreateKpiSecond initiative={initiative}  />

              ) : (
                <></>
              )}
              <ListInitiativeKpis initiative={initiative} />
            </CardBody>
          </Card>
        </div>
             
        
         <div id="section-activities">
          <Card className='mt-2'>
            <hr />
            <CardBody>
              <ListInitiativeActivities initiative={initiative} />
            </CardBody>
          </Card>
        </div>
       
       
        
        
        <div id="section-approvals">
          <Card className='mt-2'>
            <hr />
            <CardBody>
              <h3 className='float-left mb-4'>
                {language["approvals"]["title"]}
              </h3>
              { (getUserPermission(state, "approvals", "patch")) ? (
                <></>
                // <CreateApproval initiative={initiative} />
              ) : (
                <></>
              )}
              {getUserPermission(state, "approvals", "patch") ? (
                <SendActiveEmailsButton initiative={initiative} />
              ) : (
                <></>
              )}
              <ListInitiativeApprovals initiative={initiative} />
            </CardBody>
          </Card>
        </div>
       
        
        <div id="section-risks">
          <Card className='mt-2'>
            <hr />
            <CardBody>
              <h3 className='float-left mb-4'>{language["risks"]["title"]}</h3>
              {(initiative.stage !== "evaluation" &&
                (
                  getUserPermission(state, "risks", "patch", false))) ||
              (canEdit("risks")) ? (
                <CreateRisk initiative={initiative} />
              ) : (
                <></>
              )}
              <ListInitiativeRisks initiative={initiative} />
            </CardBody>
          </Card>
        </div>
        
        <div id="section-users">
          <Card className='mt-2'>
            <hr />
            <CardBody>
              <ListInitiativeUsers initiative={initiative} />
            </CardBody>
          </Card>
        </div>
        
        <div id="section-checklists">
          <Card className='mt-2'>
            <hr />
            <CardBody>
              <ListInitiativeChecklists
                initiative={initiative}
                phases={phases}
              />
            </CardBody>
          </Card>
        </div>

        <div id="section-documents">
          <Card className='mt-2'>
            <hr />
            <CardBody>
              {/* <h3 className='float-left mb-4'> {language["documents"]["title"]} </h3> */}
              <ListInitiativeDocuments initiative={initiative} />
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default PrintInitiative;

