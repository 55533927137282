

const initialState_Tree = {
  trees: [],
  error: null,
  request_time: null,
  tree: null,
};

const addChildrenToTrees = (_trees) => {
  let trees = _trees.map((tree) => {
    tree.children = [];
    tree.calc_parents = [];
    return tree;
  });
  return trees.map((tree) => {
    tree.n = tree.n || 0;
    if (tree.parent_id !== null) {
      let parent = trees.find((a) => a.id === tree.parent_id);
      if (parent) {
        parent.n = parent.n ? parent.n + 1 : 1;
        if (!parent.children) {
          parent.children = [];
        } else if (parent.children.find((a) => a.id === tree.id)) {
          return tree;
        }
        parent.children.push(tree);
      }
    }
    if (
      tree.calculation !== "" &&
      tree.calculation !== null &&
      tree.calculation !== undefined
    ) {
      let abvs = tree.calculation.split(/[+-/*]/);
      abvs.forEach((abv) => {
        let parent = trees.find((a) => a.abv === abv.trim());
        if (parent) {
          parent.calc_parents.push(tree.abv);
        }
      });
    }
    return tree;
  });
};

const trees = (state = initialState_Tree, action) => {
  let _trees;
  switch (action.type) {
    case "SET_TREES":      
      return {
        ...state,
        trees: addChildrenToTrees(action.payload.trees),
        request_time: action.request_time,
      };
    case "SET_TREE":
      return {
        ...state,
        tree: action.payload,
      }
    case "NEW_TREE":
      try {
        _trees = addChildrenToTrees([...state.trees, action.payload]);
        return {
          ...state,
          trees: _trees,
          request_time: action.request_time,
          tree: action.payload,
        };
      } catch (e) {
        console.log(e);
        return state;
      }
    case "UPDATE_TREE":
      try {
        _trees = addChildrenToTrees(
          state.trees.map((tree) => {
            if (tree.id === action.payload.id) {
              return {
                ...tree,
                ...action.payload,
              };
            }
            return tree;
          })
        );
        return {
          ...state,
          trees: _trees,
          request_time: action.request_time,
          tree: action.payload,
        };
      } catch (e) {
        console.log(e);
        return state;
      }
    case "DELETE_TREE":
      _trees = addChildrenToTrees(
        state.trees.filter((tree) => tree.id !== action.payload.id)
      );
      return {
        ...state,
        trees: _trees,
        request_time: action.request_time,
        tree: null,
      };
    case "TREE_ERROR":
      return {
        ...state,
        error: action.payload.error,
        trees: [],
        request_time: null,
      };
    default:
      return state;
  }
};

export default trees;
