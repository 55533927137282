import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  reviveInitiative,
  permanentDeleteInitiative,
} from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { useSelector } from "react-redux";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Spinner,
  Modal,
} from "reactstrap";

const ActivateInitiative = ({ initiativeId, dispatch }) => {
  let [modal, toggleModal] = useState(false);
  const [loading, setloading] = useState(false);
  const _initiatives = useSelector((state) => state.initiatives.initiatives);
  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    toggleModal(false);
  });

  const handleSubmitDelete = (e) => {
    setloading(true);
    dispatch(permanentDeleteInitiative(initiativeId));
    toggleModal(false);
  };

  const handleSubmitRevive = (e) => {
    setloading(true);
    dispatch(reviveInitiative(initiativeId));
    toggleModal(false);
  };
  useEffect(() => {
    setloading(false);
  }, [_initiatives]);

  return (
    <div>
      <Button
        color='danger'
        className='btn-sm'
        onClick={() => toggleModal(true)}
      >
        {loading ? <Spinner size='sm' /> : language["initiatives"]["deleted"]}
      </Button>
      <Modal
        className='modal-dialog-centered'
        isOpen={modal}
        toggle={() => toggleModal(false)}
      >
        <div className='modal-body p-0'>
          <Card className='bg-secondary shadow border-0'>
            <CardBody className='px-lg-5 py-lg-2'>
              <h2 className='text-muted text-center mt-2 mb-3'>
                {language["initiatives"]["initiative_actions"]}
              </h2>
              <div className='text-center mt-4'>
                <Button
                  onClick={() => handleSubmitDelete()}
                  color='danger'
                  className='btn-sm'
                >
                  {language["initiatives"]["delete_for_ever"]}
                </Button>
                <Button
                  onClick={() => handleSubmitRevive()}
                  color='success'
                  className='btn-sm'
                  type='submit'
                >
                  {language["initiatives"]["revive"]}
                </Button>
              </div>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default connect()(ActivateInitiative);
