import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import {
  getAllAreas,
  getAllInitypes,
  getAllLevers,
  getCurrentUserInitiatives,
  getAllPhases,
  getDecisions,
} from "../../redux/actions";
import Loading from "../../components/Loading/Loading";
import { language } from "../../assets/language";
import { ListInitiativeSummary } from "../initiatives";
import { ListMyActivities } from "../activities";
import { ListMyTasks } from "../tasks";
import { getUserPermission } from "../../utils/getUserPermission";

import {
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { ListMyApprovals } from "../approvals";

const HomeDashboard = ({ dispatch }) => {
  const [loading, setloading] = useState(true);
  let [checked, setChecked] = useState(true);
  let [initiatives, setInitiatives] = useState(null);
  const [stateFilter, setStateFilter] = useState([null]);
  const [activeFilter, setActiveFilter] = useState([true]);
  const [phaseFilter, setPhaseFilter] = useState([
    "evaluation",
    "execution",
    "result",
    "adoption",
  ]);
  const decisions = useSelector((state) => state.decisions.decisions);
  const _initiatives = useSelector((state) => state.initiatives.initiatives);
  const user = useSelector((state) => state.sessions.user);
  const state = useSelector((state) => state);
  const phases = useSelector((state) => state.phases.phases);

  useEffect(() => {
    dispatch(getAllAreas());
    dispatch(getAllLevers());
    dispatch(getAllInitypes());
    dispatch(getDecisions());
    dispatch(getAllPhases());
    dispatch(getCurrentUserInitiatives());
    setloading(true);
  }, []);



  useEffect(() => {
    if (!Array.isArray(decisions)) {
      return;
    }
    if (checked && decisions && decisions.length > 0) {
      setStateFilter([...decisions.map((state) => state.id), null]);
      setChecked(false);
    }
  }, [checked, decisions]);

  useEffect(() => {
    if (_initiatives ) {
    let data = _initiatives
      .filter((initiative) => {
        return (initiative.leader_user === user.id || initiative.sponsor_user === user.id);
      })
      .filter((initiative) => {
        return stateFilter.includes(initiative.actual_state);
      })
      .filter((initiative) => {
        if (activeFilter.includes(true)) {
          if (
            initiative.stage !== "finalized" &&
            initiative.stage !== "deleted"
          ) {
            return true;
          }
        }
        if (activeFilter.includes(false)) {
          if (
            initiative.stage === "finalized" ||
            initiative.stage === "deleted"
          ) {
            return true;
          }
        }
        return false;
      })
      .filter((initiative) => {
        return phaseFilter.includes(initiative.stage);
      });
    setInitiatives(data);
    setloading(false);

    } 

  }, [_initiatives, user, stateFilter, activeFilter, phaseFilter, decisions]);




  const processStateFilter = (value) => {
    let _statefilter = stateFilter;
    if (stateFilter.includes(value)) {
      _statefilter = stateFilter.filter(function (f) {
        return f !== value;
      });
      setStateFilter(_statefilter);
    } else {
      setStateFilter([..._statefilter, value]);
    }
  };

  const renderStateFilter = () => {
    return (
      <>
        <h4 className='mr-2'>{language["initiatives"]["table"]["state"]}: </h4>
        <div className='custom-control custom-checkbox custom-control-inline'>
          <input
            type='checkbox'
            className='custom-control-input'
            onChange={(e) => processStateFilter(null)}
            id='customRadioInline1'
            name='customRadioInline1'
            checked={stateFilter.includes(null)}
          />
          <label className='custom-control-label' htmlFor='customRadioInline1'>
            {language["initiatives"]["no_state"]}
          </label>
        </div>
        {Array.isArray(decisions)
          ? decisions.map((info_state, index) => {
              return (
                <div
                  key={index}
                  className='custom-control custom-checkbox custom-control-inline'
                >
                  <input
                    type='checkbox'
                    id={index}
                    name={index}
                    className='custom-control-input'
                    onChange={(e) => processStateFilter(info_state.id)}
                    checked={stateFilter.includes(info_state.id)}
                  />
                  <label className='custom-control-label' htmlFor={index}>
                    {info_state.name}
                  </label>
                </div>
              );
            })
          : ""}
      </>
    );
  };

  const processActiveFilter = (value) => {
    let _activefilter = activeFilter;
    if (activeFilter.includes(value)) {
      _activefilter = activeFilter.filter(function (f) {
        return f !== value;
      });
      setActiveFilter(_activefilter);
    } else {
      setActiveFilter([..._activefilter, value]);
    }
  };

  const renderActiveFilter = () => {
    return (
      <div>
        <h4 className='mr-2'>{language["initiatives"]["table"]["active"]}: </h4>
        <div
          key='active-1'
          className='custom-control custom-checkbox custom-control-inline'
        >
          <input
            type='checkbox'
            className='custom-control-input'
            onChange={(e) => processActiveFilter(true)}
            checked={activeFilter.includes(true)}
            id='active-1'
            name='active-1'
          />
          <label className='custom-control-label' htmlFor='active-1'>
            {language["initiatives"]["active"]["yes"]}
          </label>
        </div>
        <div
          key='active-2'
          className='custom-control custom-checkbox custom-control-inline'
        >
          <input
            type='checkbox'
            className='custom-control-input'
            onChange={(e) => processActiveFilter(false)}
            checked={activeFilter.includes(false)}
            id='active-2'
            name='active-2'
          />
          <label className='custom-control-label' htmlFor='active-2'>
            {language["initiatives"]["active"]["no"]}
          </label>
        </div>
      </div>
    );
  };

  const processPhaseFilter = (value) => {
    let _phasefilter = phaseFilter;
    if (phaseFilter.includes(value)) {
      _phasefilter = phaseFilter.filter(function (f) {
        return f !== value;
      });
      setPhaseFilter(_phasefilter);
    } else {
      setPhaseFilter([..._phasefilter, value]);
    }
  };

  const renderPhaseFilter = () => {
    return (
      <div key='10'>
        <h4>{language["initiatives"]["table"]["stage"]}: </h4>
        {Object.values(phases)
          .filter((ph) => {
            if (ph.identifier !== "idea") {
              if (activeFilter.includes(true)) {
                if (
                  ph.identifier !== "finalized" &&
                  ph.identifier !== "deleted"
                ) {
                  return true;
                }
              }
              if (activeFilter.includes(false)) {
                if (
                  ph.identifier === "finalized" ||
                  ph.identifier === "deleted"
                ) {
                  return true;
                }
              }
            }
            return false;
          })
          .map((phase, index) => {
            return (
              <div
                key={index}
                className='custom-control custom-checkbox custom-control-inline'
              >
                <input
                  key={index}
                  type='checkbox'
                  className='custom-control-input'
                  onChange={(e) => processPhaseFilter(phase.identifier)}
                  checked={phaseFilter.includes(phase.identifier)}
                  id={"p-" + index}
                  name={"p-" + index}
                />
                <label className='custom-control-label' htmlFor={"p-" + index}>
                  {phase.name}
                </label>
              </div>
            );
          })}
      </div>
    );
  };

  return loading ? (
    <Loading />
  ) : (
    <div style={{ marginTop: 10 }}>
      <Card key='1'>
        <CardBody>
          <h1>{language["home"]["titles"]["summary"]}</h1>
          <Row>
            <Col key='active-1'>{renderStateFilter()}</Col>
            <Col key='active-2'>{renderActiveFilter()}</Col>
            <Col key='active-3'>{renderPhaseFilter()}</Col>
          </Row>
          <ListInitiativeSummary initiatives={initiatives} />
        </CardBody>
      </Card>
      <Card key='2'>
        <CardBody>
          {getUserPermission(state, "activities", "list") ? (
            <ListMyActivities />
          ) : (
            <></>
          )}
        </CardBody>
      </Card>
      <Card key='3'>
        <CardBody>
          {getUserPermission(state, "tasks", "list") ? <ListMyTasks /> : <></>}
        </CardBody>
      </Card>
      <Card key='4'>
        <CardBody>
          {getUserPermission(state, "tasks", "list") ? (
            <ListMyApprovals />
          ) : (
            <></>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default connect()(HomeDashboard);
