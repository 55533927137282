import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { getAllLevers } from "../../redux/actions";
import { language } from "../../assets/language";
import SimpleTable from "../../components/tables/SimpleTable";
import { CreateLever, DeleteLever } from ".";
import { getUserPermission } from "../../utils/getUserPermission";
import { Card, CardBody } from "reactstrap";

const ListLevers = ({ dispatch }) => {
  const state = useSelector((state) => state);
  const levers = useSelector((state) => state.levers.levers);

  useEffect(() => {
    dispatch(getAllLevers());
  }, [dispatch]);

  const columns = useMemo(() => {
    let col = [
      {
        Header: language.levers.table.name,
        accessor: "name",
      },
    ];
    if (getUserPermission(state, "levers", "delete")) {
      col.push({
        Header: language.general.actions,
        accessor: "actions",
      });
    }
    return col;
  }, [state]);

  const renderLevers = () => {
    if (!levers || levers.length === 0) {
      return <p>{language["levers"]["not_found"]}</p>;
    }

    let leversList = Object.values(levers).map((lever) => {
      return {
        id: lever.id,
        name: lever.name,
        actions: <DeleteLever lever={lever} />,
      };
    });

    return <SimpleTable columns={columns} data={leversList} />;
  };

  return (
    <Card className={"mt-3"}>
      <CardBody>
        <h1 className='float-left'>{language.levers.title}</h1>
        <CreateLever />
        {renderLevers()}
      </CardBody>
    </Card>
  );
};

export default connect()(ListLevers);
