import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";


export const setInitiativeUsers = (users, initiative_id) => ({
    type: "SET_INITIATIVE_USERS",
    payload: {
        users: users,
        initiative_id: initiative_id
    },
    request_time: Date.now()
});

export const setInitiativeUser = (data) => ({
    type: "SET_INITIATIVE_USER",
    payload: data,
    request_time: Date.now()
});

export const setInitiativeUserError = (error) => ({
    type: "SET_INITIATIVE_USER_ERROR",
    payload: {
        error: error
    },
    request_time: Date.now()
});

export const deleteInitiativeUser = (initiative_id, res_id) => ({
    type: "DELETE_INITIATIVE_USER",
    payload: {
        id: res_id,
        initiative_id: initiative_id

    },
    request_time: Date.now()
});

export const getInitiativeUsers = (initiative_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "initiative_users", "list", true)) {
                throw new Error("No permissions");
            }
            
            fetch(`${BACKEND_URL}/initiatives/${initiative_id}/users`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    dispatch(setInitiativeUsers(data, initiative_id));
                })
                .catch((error) => {
                    errorHandler("initiative_users", error.message);
                    dispatch(setInitiativeUserError(error));
                });
            
        } catch (error) {
            errorHandler("initiative_users", error.message);
            dispatch(setInitiativeUserError(error));
        }
    };
}

export const patchInitiativeUser = (initiative_id, initiativeUser_id, data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "initiative_users", "patch", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/initiatives/${initiative_id}/users/${initiativeUser_id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
                method: "PATCH",
                body: JSON.stringify(data)
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    dispatch(setInitiativeUser(data));
                })
                .catch((error) => {
                    errorHandler("initiative_users", error.message);
                    dispatch(setInitiativeUserError(error.message));
                });
        } catch (error) {
            errorHandler("initiative_users", error.message);
            dispatch(setInitiativeUserError(error));
        }
    };
}

export const postInitiativeUser = (initiative_id, data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            // if (!getUserPermission(state, "initiative_users", "create", true)) {
            // throw new Error("No permissions");
            // }
            if (state.initiatives.initiative.leader_user === state.sessions.user.id  
                || state.sessions.user.role === 'admin'
                || getUserPermission(state, "initiative_users", "create", true)) {
                fetch(`${BACKEND_URL}/initiatives/${initiative_id}/users`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                    method: "POST",
                    body: JSON.stringify(data)
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setInitiativeUser(data));
                    })
                    .catch((error) => {
                        errorHandler("initiative_users", error.message);
                        dispatch(setInitiativeUserError(error.message));
                    });
            }
        } catch (error) {
            errorHandler("initiative_users", error.message);
            dispatch(setInitiativeUserError(error));
        }
    };
}

export const DeleteInitiativeUserById = (initiative_id, res_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "initiative_users", "delete", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/initiatives/${initiative_id}/users/${res_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
                .then((response) => {
                    if (response.status === 204) {
                        return true;
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((vl) => {
                    if (vl) {
                        dispatch(deleteInitiativeUser(initiative_id,res_id));
                    }
                })
                .catch((error) => {
                    errorHandler("initiative_users", error.message);
                    dispatch(setInitiativeUserError(error));
                });
        } catch (error) {
            errorHandler("initiative_users", error.message);
            dispatch(setInitiativeUserError(error));
        }
    };
}

export const saveInitiativeUsersByArea = (initiative_id, data={}) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            // if (!getUserPermission(state, "initiative_users", "create", true)) {
            // throw new Error("No permissions");
            // }
            if (state.initiatives.initiative.leader_user === state.sessions.user.id
                || state.initiatives.initiative.sponsor_user === state.sessions.user.id
                || state.sessions.user.role === 'admin'
                || getUserPermission(state, "initiative_users", "patch", true)) {
                fetch(`${BACKEND_URL}/initiatives/${initiative_id}/users/update_by_area`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                    method: "POST",
                    body: JSON.stringify(data)
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setInitiativeUser(data));
                    })
                    .catch((error) => {
                        errorHandler("initiative_users", error.message);
                        dispatch(setInitiativeUserError(error.message));
                    });
            }
        } catch (error) {
            errorHandler("initiative_users", error.message);
            dispatch(setInitiativeUserError(error));
        }
    };
}