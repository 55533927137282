import { language } from "../assets/language";
import { toast } from 'react-toastify';

export const errorHandler = (resource, _errorStatus, errorMessage=null) => {
    let errorStatus = _errorStatus.toString();
    console.log(resource, errorStatus, errorMessage);
    try {
        if (errorStatus === "500") {
            toast.warning(`${language.general.warnings.error} ${resource}`);
        } else {
            toast.error(language[resource].errors[errorStatus]);
        }
        if (errorStatus === "401") {
            
        }
    } catch (error) {
        console.log(error);
        toast.error(language.general.errors.default);
        toast.error(_errorStatus);
    }
}