import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";


export const setPermits = (permits) => ({
    type: "SET_PERMITS",
    payload: {
        permits: permits,
    },
    request_time: Date.now()
});

export const setRolePermits = (data, role_id) => ({
    type: "SET_ROLE_PERMITS",
    payload: {
        role_permits: data,
        role_id: role_id,
        request_time: Date.now()
    },
});

export const setRolePermit = (data) => ({
    type: "SET_ROLE_PERMIT",
    payload: {
        id: data.id,
        role_id: data.role_id,
        permit_id: data.permit_id,
        request_time: Date.now()
    }
});

export const deleteRolePermitById = (role_permit_id) => ({
    type: "DELETE_ROLE_PERMIT",
    payload: {
        id: role_permit_id,
    },
    request_time: Date.now()
});

export const setPermitsLoading = (value) => ({
    type: "SET_PERMITS_LOADING",
    payload: {
        loading: value,
    },
});

export const setPermitsError = (error) => ({
    type: "SET_PERMITS_ERROR",
    payload: {
        error: error,
    },
});

export const setRolePermitsLoading = (value) => ({
    type: "SET_ROLE_PERMITS_LOADING",
    payload: {
        loading: value,
    },
});

export const setRolePermitsEdition = (data, role_id) => ({
    type: "SET_ROLE_PERMITS_EDITION",
    payload: {
        role_permits: data,
        role_id: role_id,
        request_time: Date.now()
    },
});

export const setPermitsEditionError = (error) => ({
    type: "SET_PERMITS_EDITION_ERROR",
    payload: {
        error: error,
    },
});

export const getAllPermits = () => {
    return (dispatch, getState) => {
        const state = getState();
        try {
                fetch(`${BACKEND_URL}/permits`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setPermits(data));
                        dispatch(setPermitsLoading(false));
                    })
                    .catch((error) => {
                        errorHandler("permits", error.message);
                        dispatch(setPermitsError(error));
                        dispatch(setPermitsLoading(false));
                    });
          
        } catch (error) {
            errorHandler("permits", error.message);
            dispatch(setPermitsError(error));
            dispatch(setPermitsLoading(false));
        }
    };
}

export const addRolePermit = (data, role_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            fetch(`${BACKEND_URL}/roles/${role_id}/permits`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
                body: JSON.stringify(data)
            })
                .then((response) => {
                    if (response.status === 201) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    if (data != null && data !== undefined && data.id !== undefined) {
                        dispatch(setRolePermit(data));
                    }
                })
                .catch((error) => {
                    errorHandler("permits", error.message);
                    dispatch(setPermitsError(error));
                });
        } catch (error) {
            errorHandler("permits", error.message);
            dispatch(setPermitsError(error));
        }
    };
}

export const getRolePermits = (role_id) => {

    return (dispatch, getState) => {
        const state = getState();
        try {

                fetch(`${BACKEND_URL}/roles/${role_id}/permits`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setRolePermits(data, role_id));
                        dispatch(setRolePermitsLoading(false));
                    })
                    .catch((error) => {
                        errorHandler("permits", error.message);
                        dispatch(setPermitsError(error));
                        dispatch(setRolePermitsLoading(false));
                    });

        } catch (error) {
            errorHandler("permits", error.message);
            dispatch(setPermitsError(error));
            dispatch(setRolePermitsLoading(false));
        }
    };
}

export const getRolePermitsEdition = (role_id) => {

    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "role_permits", "list", true)) {
                throw new Error("No permissions");
            }
           
                fetch(`${BACKEND_URL}/roles/${role_id}/permits`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setRolePermitsEdition(data, role_id));
                        dispatch(setRolePermitsLoading(false));
                    })
                    .catch((error) => {
                        errorHandler("permits", error.message);
                        dispatch(setPermitsEditionError(error));
                        dispatch(setRolePermitsLoading(false));
                    });
           
        } catch (error) {
            errorHandler("permits", error.message);
            dispatch(setPermitsEditionError(error));
            dispatch(setRolePermitsLoading(false));
        }
    };
}

export const deleteRolePermit = (role_id, role_permit_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "role_permits", "patch", true)) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/roles/${role_id}/permits/${role_permit_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
                .then((response) => {
                    if (response.status === 204) {
                       
                        return true;
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((vl) => {
                    // if (vl) {
                    dispatch(deleteRolePermitById(role_permit_id));
                    // }
                })
                .catch((error) => {
                    errorHandler("permits", error.message);
                    dispatch(setPermitsError(error));
                });
        } catch (error) {
            errorHandler("permits", error.message);
            dispatch(setPermitsError(error));
        }
    };
}