import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { connect } from "react-redux";

import { language } from '../../assets/language';

import { useClickAway } from 'react-use';
import { Button, Modal, Card, CardBody, FormGroup, Form, Input } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { editDocument } from '../../redux/actions/documents'; // Assuming you have an action creator for posting documents


const EditDocument = ({ dispatch, initiativeId, documentId, initialName, filePath }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [documentName, setDocumentName] = useState(initialName);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  

  const wrapperRef = useRef(null);
  useClickAway(wrapperRef, () => {
    setModalOpen(false);
    resetForm();
  });

  useEffect(() => {
    setDocumentName(initialName);
    setFileName(filePath.split('/').pop()); // Assuming filePath is a URL or path string
  }, [initialName, filePath]);

  const handleFileChange = (files) => {
    setFile(files[0]);
    setFileName(files[0].name);
  };

  const handleRemoveFile = () => {
    setFile(null);
    setFileName('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', documentName);
    if (file) {
      formData.append('file', file);
    }

    dispatch(editDocument(initiativeId, documentId, formData));
    setModalOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setDocumentName(initialName);
    setFile(null);
    setFileName(filePath.split('/').pop());
  };

  return (
    <div>
      <Button color="primary" className="btn-sm" onClick={() => setModalOpen(true)}>
        <i className="fas fa-edit"></i>
      </Button>
      <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} className="modal-dialog-centered">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <Form onSubmit={handleSubmit} innerRef={wrapperRef}>
              <FormGroup>
                <Input
                  type="text"
                  name="documentName"
                  value={documentName}
                  onChange={(e) => setDocumentName(e.target.value)}
                  placeholder={language["documents"]["modal"]["name"]}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Dropzone onDrop={handleFileChange}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps({ className: 'dropzoneContainer' })}>
                        <input {...getInputProps()} />
                        <p>{language["documents"]["modal"]["dropzone"]}</p>
                      </div>
                    </section>
                  )}
                </Dropzone>
                {fileName && (
                  <div className="file-details" style={{ marginBottom: "20px" }}>
                    <span>{fileName}</span>
                    <button type="button" onClick={handleRemoveFile}>X</button>
                  </div>
                )}
              </FormGroup>
              <Button type="submit" color="success">
                {language["documents"]["modal"]["upload"]}
              </Button>
              <Button color="warning" onClick={() => setModalOpen(false)}>
                {language["documents"]["modal"]["cancel"]}
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Modal>
    </div>
  );
};


export default connect()(EditDocument);
