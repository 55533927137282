const initialState_Advance = {
    request_time: null,
    error: null,
    initiativeId: null,
    advances: [],
};

const advances = (state = initialState_Advance, action) => {
    switch (action.type) {
        case 'SET_ADVANCES':
            return {
                advances: action.payload.advances,
                request_time: action.request_time,
                initiativeId: action.payload.initiativeId,
            };
        case 'SET_ADVANCE':
            return {
                advances: [...state.advances, action.payload],
                request_time: action.payload.request_time,
                initiativeId: state.initiativeId,
            };
        case 'ADVANCE_ERROR':
            return {
                ...state,
                error: action.payload.error,
                request_time: null,
                // advances: [],
            };
        case "DELETE_ADVANCE":
            return {
                ...state,
                advances: state.advances.filter(advance => advance.id !== action.payload.id),
                request_time: action.request_time,
            };
        default:
            return state;
    }
}

export default advances;