import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


export const exportMultipleSheets = (csvData, fileName) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    // for key in csvData
    let wb = { Sheets: {}, SheetNames: [] };
    let ws;
    Object.keys(csvData).forEach(key => {
        ws = XLSX.utils.json_to_sheet(csvData[key]);
        wb.SheetNames.push(key);
        wb.Sheets[key] = ws;
    });
    // const ws = XLSX.utils.json_to_sheet(csvData);
    // const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    return data;
}