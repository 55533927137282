import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { getKpisecondKshifts, patchKshift, postKshift } from '../../redux/actions';
import { language } from '../../assets/language';
import { Button, Input, Table } from "reactstrap";
import { getUserPermission } from '../../utils/getUserPermission';
import { getResponsabilityPermission } from '../../utils/getResponsabilityPermission';
import { validateInputNumber } from '../../utils/validateInputNumber.js';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading/Loading';
import ValueLine from '../../components/ValueLine/ValueLine';
import { getMonthYearDate } from "../../utils/getMonthYearDate";
import { notNull } from '../../utils/notNull.js';



const ListKpisecondKshifts = ({ dispatch, kpisecond, kpiId }) => {
    const state = useSelector(state => state);
    const kshifts = useSelector(state => state.kshifts.kshifts);
    const initiative = useSelector(state => state.initiatives.initiative);
    const [kshiftsNewValues, setKshiftsNewValues] = useState([]);
    const [loading, setLoading] = useState(true);
    const [update, setUpdate] = useState(false);
    const initialDate = getMonthYearDate(kpisecond.initial_date);
    const objetiveDate = getMonthYearDate(kpisecond.objetive_date);
    const forecastDate = getMonthYearDate(kpisecond.forecast_date);




    useEffect(() => {
        if (kpiId === kpisecond.id) {
        dispatch(getKpisecondKshifts(kpisecond.id));  
      }
    }, [kpiId,kpisecond]);


    useEffect(() => {
      if (kshifts !== null && kshifts !== undefined) {
        setLoading(false);
      }
    },[kshifts])


    useEffect(() => {
      if (kshiftsNewValues.length > 0) {
        setUpdate(true);
      }
    }, [kshiftsNewValues]);



    const addMonthKShift = () => {
      //get last kshift
      setLoading(true);
      let lastKshift = kshifts[kshifts.length - 1];
      //get next year and month 
      let nextYear = lastKshift.year;
      let nextMonth = lastKshift.month + 1;
      if (nextMonth > 11) {
        nextMonth = 0;
        nextYear = nextYear + 1;
      }
        let newKshift = {
          kpisecond_id: kpisecond.id,
          month: nextMonth,
          year: nextYear,
          base: lastKshift.base,
          forecast: lastKshift.forecast,
        };
        dispatch(postKshift(newKshift));
    }

    
    const addMonthKShiftBefore = () => {
      setLoading(true);
      let firstKshift = kshifts[0];
      let previousYear = firstKshift.year;
      let previousMonth = firstKshift.month - 1;
      if (previousMonth < 0) {
        previousMonth = 11;
        previousYear = previousYear - 1;
      }
      let newKshift = {
        kpisecond_id: kpisecond.id,
        month: previousMonth,
        year: previousYear,
        base: firstKshift.base,
        forecast: firstKshift.forecast,
      }
      dispatch(postKshift(newKshift));
    }



      const handlePost = (shifts) => {
        setLoading(true);
        shifts.forEach((k) => {
          dispatch(patchKshift(k));
        });
          setUpdate(false);
          setKshiftsNewValues([]);
          toast.success(language.kshifts.success)
      }



    const addKShift = (kshift, value, type) => {
  
        let error =  validateInputNumber(value);

        if (value ==="") {
          value = null
        }
        if (error) {
            return 
        }
        let newKshifts = [...kshiftsNewValues];
        let finder = newKshifts.find((t) => t.id === kshift.id)
        if (finder) {
          newKshifts = newKshifts.filter((t) => t.id !== kshift.id)
          if (type === "base") {
            finder.base = value;
          }  else if (type === "forecast") {
            finder.forecast = value;
          } else {
            finder.real = value;
          }
          newKshifts.push(finder);
        } else {
          if (type === "base") {
            kshift.base = value;
          }  else if (type === "forecast") {
            kshift.forecast = value;
          } else {
            kshift.real =value
          }
          newKshifts.push(kshift);
        }
        setKshiftsNewValues(newKshifts);
      }

      const sendChanges = () => {
        setLoading(true);
        kshiftsNewValues.forEach((k) => {
          dispatch(patchKshift(k));
        });
          setUpdate(false);
          setKshiftsNewValues([]);
          toast.success(language.kshifts.success)
      };

    const renderKshifts2 = () => {
        if (!kshifts || kshifts.length === 0) {
            return <p>{language["kshifts"]["not_found"]}</p>
        }

        return loading ? <Loading /> : (
            <>
           
            <Table hover responsive className='align-items-center'>
                <thead  className='thead-light'>
                    <tr>
                        <th> 
                          <Button
                        variant="contained"
                        color="success"
                        className='btn-sm'
                        block
                        onClick={() => addMonthKShiftBefore()}
                      > {language.kshifts.add_month} 
                      
                      </Button>
                      </th>
                        {
                            kshifts.map((kshift) => {
                                
                                return( 
                                   <th  style={{ textAlign: "center", maxWidth: "100px" }} > {language.general.abv_months[kshift.month || 0]}-{kshift.year}</th>

                                ) 
                            })
                        }
                        <th>
                        <Button
                        variant="contained"
                        color="success"
                        className='btn-sm'
                        block
                        onClick={() => addMonthKShift()}
                      > {language.kshifts.add_month} 
                      
                      </Button>
                        </th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{language.kshifts.table.base}</td>
                        {
                            kshifts.map((kshift) => {
                              let dateKshift = new Date(kshift.year, kshift.month,1);
                              return <td
                                style={{ textAlign: "center", maxWidth: "100px" }}
                                key={kshift.id +"base"}
                                >
                                    <Input 
                                    
                                    className={kshiftsNewValues.find(t => t.id === kshift.id ) ?  'form-control-sm text-center border-primary' : 'form-control-sm text-center' }
                                    disabled={((initiative.stage === "evaluation" &&
                                    getResponsabilityPermission(state,"kpiseconds","change",initiative.id, "Lider")) || getUserPermission(state, "kshifts", "patch") ? false : true)
                                    || 
                                    //date not between initialDate and forecast date
                                    (dateKshift.getTime() <= initialDate.getTime()
                                    ||
                                    dateKshift.getTime() >= objetiveDate.getTime())

                                  }
                                    
                      
                                    value={kshiftsNewValues.find(t => t.id === kshift.id ) 
                                        ? kshiftsNewValues.find(t => t.id === kshift.id ).base 
                                        :  notNull(kshift.base) ? kshift.base : "" }
                                    key={kshift.id}
                                    type="text"
                                   
                                    onChange={(e) => {addKShift(kshift, e.target.value, "base");}} />
                                </td>
                            })
                        }
                         <td>
                        </td> 
                    </tr>
                    <tr>
                        <td>{language.kshifts.table.forecast}</td>
                        {
                            kshifts.map((kshift) => {
                              let dateKshift = new Date(kshift.year, kshift.month,1);
                                return <td
                                style={{ textAlign: "center", maxWidth: "100px" }}
                                >
                                  <Input
                                    style={{ textAlign: 'center' }}
                                    className={kshiftsNewValues.find(t => t.id === kshift.id ) ?  'form-control-sm text-center border-primary' : 'form-control-sm text-center' }
                                    value={kshiftsNewValues.find(t => t.id === kshift.id ) 
                                        ? kshiftsNewValues.find(t => t.id === kshift.id ).forecast 
                                        :  notNull(kshift.forecast)  ? kshift.forecast : "" }
                                    key={kshift.id}
                                    type="text"
                                    disabled={ (getResponsabilityPermission(state,"kpiseconds","change",initiative.id, "Lider") || getUserPermission(state, "kshifts", "patch")  ? false : true)
                                    ||  (dateKshift.getTime() <= initialDate.getTime()
                                    ||
                                    dateKshift.getTime() >= forecastDate.getTime())
                                    }
                                    onChange={(e) => {addKShift(kshift, e.target.value, "forecast");}}
 />
                                </td>
                            })
                        }
                        <td>
                       
                         
                        </td> 
                    </tr>
                    <tr>
                        <td>{language.kshifts.table.real}</td>
                        {
                            kshifts.map((kshift) => {

                                return kshift.real != null ?
                                (<td
                                  style={{ textAlign: "center", maxWidth: "100px" }}
                                >
                                    <Input

                                    className={kshiftsNewValues.find(t => t.id === kshift.id ) ?  'form-control-sm text-center border-primary' : 'form-control-sm text-center' }
                                    value={kshiftsNewValues.find(t => t.id === kshift.id ) 
                                        ? kshiftsNewValues.find(t => t.id === kshift.id ).real 
                                        :  notNull(kshift.real)
                                        ?  kshift.real 
                                        :  ""
                                      }
                                    key={kshift.id}
                                    type="text"
                                    disabled={ getResponsabilityPermission(state,"kpiseconds","change",initiative.id, "Lider") || getUserPermission(state, "kshifts", "patch") ? false : true}
                                    onChange={(e) => {addKShift(kshift, e.target.value, "real");}}
                                    />
                                </td>)
                                : // compare today vs new date of kshift month and year
                                (new Date(kshift.year, kshift.month, 1).getTime() <= new Date().getTime()) ?
                                
                                (<td
                                  style={{ maxWidth: "100px", textAlign: "center" }}
                                >
                                  <Button
                                    variant="contained"
                                    color="success"
                                    className='btn-sm'
                                    disabled={getResponsabilityPermission(state,"kpiseconds","change",initiative.id, "Lider") || getUserPermission(state, "kshifts", "patch") ? false : true}
                                    onClick={() => addKShift(kshift, "0.0", "real")}
                                  >
                                    {"+"}
                                  </Button>
                                </td>)
                                : <td></td>

                            })
                        }
                        <td>
                      
                        </td>


                    </tr>
                </tbody>

            </Table>
            </>
        )
    }


    return (
        <div>
            {/* {renderKshifts()} */}
            {renderKshifts2()}
            {
          update ? ( <Button
            variant="contained"
            color="success"
            className='btn-sm  m-2 p-1 float-right'
            onClick={() => sendChanges()}
          > {language.trees.update_tshift}</Button>
          ) : ""
        }
        </div>
    );
}

export default connect()(ListKpisecondKshifts);
