const initialState_Group = {
    request_time: null,
    error: null,
    groups: {},
};

const groups = (state = initialState_Group, action) => {
    switch (action.type) {
        case 'SET_GROUPS':
            return {
                groups: action.payload.groups,
                request_time: action.request_time,
            };
        case 'SET_GROUP':
            try {
                return {
                    ...state,
                    groups: {
                        ...state.groups, [action.payload.name]: action.payload,
                    },
                    request_time: action.payload.request_time,
                };
            } catch (e) {
                console.log(e);
                return state;
            }
        case 'GROUP_ERROR':
            return {
                error: action.payload.error,
                request_time: null,
                // groups: {},
            };
        case "DELETE_GROUP":
            let new_groups = state.groups;
            try {
                new_groups = new_groups.filter((group)=> group.name !== action.payload.name)
            } catch (e) {
                console.log(e);
            }
            return {
                ...state,
                groups: new_groups,
                request_time: action.request_time,
            };
        default:
            return state;
    }
}

export default groups;