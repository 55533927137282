import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getResponsabilityPermission } from "../../utils/getResponsabilityPermission";
import { getUserPermission } from "../../utils/getUserPermission";


export const setInitiativeChecklists = (checklists, initiative_id) => ({
    type: "SET_INITIATIVE_CHECKLISTS",
    payload: {
        checklists: checklists,
        initiative_id: initiative_id
    },
    request_time: Date.now()
});

export const setInitiativeChecklist = (data) => ({
    type: "SET_INITIATIVE_CHECKLIST",
    payload: {
        id: data.id,
        initiative_id: data.initiative_id,
        checklist_id: data.checklist_id,
        phase_id: data.phase_id,
        stage_id: data.stage_id,
        checked: data.checked,
        request_time: Date.now()
    },
});

export const setInitiativeChecklistError = (error) => ({
    type: "INITIATIVE_CHECKLIST_ERROR",
    payload: {
        error: error,
    }
});

export const getInitiativeChecklists = (initiative_id) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "checklists", "list")) {
                throw new Error("No permissions");
            }
            if (state.initiative_checklists.request_time === null
                || state.initiative_checklists.request_time + 1000 * 1 < Date.now()
                || state.initiative_checklists.initiative_id !== initiative_id) {
                fetch(`${BACKEND_URL}/initiatives/${initiative_id}/checklists`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setInitiativeChecklists(data, initiative_id));
                    })
                    .catch((error) => {
                        errorHandler("checklists", error.message);
                        dispatch(setInitiativeChecklistError(error));
                    });
            }
        } catch (error) {
            errorHandler("checklists", error.message);
            dispatch(setInitiativeChecklistError(error));
        }
    };
}

export const patchInitiativeChecklist = (initiative_id, initiativeChecklist_id, data) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            // if (!getUserPermission(state, "checklists", "patch", true)) {
            // throw new Error("No permissions");
            // }
            if (getResponsabilityPermission(state, "checklists", "change",initiative_id,"Lider")
                || state.sessions.user.role === 'admin'
                || getUserPermission(state, "checklists", "patch", true)) {
                fetch(`${BACKEND_URL}/initiatives/${initiative_id}/checklists/${initiativeChecklist_id}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.sessions.token}`
                    },
                    method: "PATCH",
                    body: JSON.stringify(data)
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then((data) => {
                        dispatch(setInitiativeChecklist(data));
                    })
                    .catch((error) => {
                        errorHandler("checklists", error.message);
                        dispatch(setInitiativeChecklistError(error.message));
                    });
            }
        } catch (error) {
            errorHandler("checklists", error.message);
            dispatch(setInitiativeChecklistError(error));
        }
    };
}

export const patchInitiativeChecklists = (initiativeId, data = {}) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "checklists", "patch")) {
                throw new Error("No permissions");
            }
            fetch(`${BACKEND_URL}/initiatives/${initiativeId}/checklists`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.sessions.token}`
                },
                method: "PATCH",
                body: JSON.stringify(data)
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    dispatch(setInitiativeChecklists(data));
                })
                .catch((error) => {
                    errorHandler("checklists", error.message);
                    dispatch(setInitiativeChecklistError(error.message));
                });
        } catch (error) {
            errorHandler("checklists", error.message);
            dispatch(setInitiativeChecklistError(error));
        }
    };
}