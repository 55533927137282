//Component modal 
//4 inputs, initial value, final value, initial date, final, date
// de component get a function
import React, { useState } from "react";
import { connect } from "react-redux";
import { language } from "../../assets/language";
import
{
    Button,
    Card,
    CardBody,
    Modal,
    Form,
    FormGroup, Input, Row, Col
} from "reactstrap";
import { validateInputNumber } from "../../utils/validateInputNumber";
import ReactDatePicker from "react-datepicker";
import { FaEdit } from "react-icons/fa";
const ValueLine = ({  shifts, type , disabled, handlePost, minDate, maxDate }) =>
{
    let [modal, toggleModal] = useState(false);
    let [initialValue, setInitialValue] = useState(0);
    let [finalValue, setFinalValue] = useState(0);
    let [initialDate, setInitialDate] = useState("");
    let [finalDate, setFinalDate] = useState("");

    const handleLine = (e) => {
        e.preventDefault();

        let count = 1;
        let newValues =  shifts.filter(
            (shift) =>
            (new Date(shift.year, shift.month,1) >= new Date(initialDate)) && 
            (new Date(shift.year, shift.month,1) <= new Date(finalDate))
           
        )

        let step =  Math.round((finalValue - initialValue) /( newValues.length - 1), 2)
        for (let i = 0; i < newValues.length ; i++) {
            if (i === 0 ){
                newValues[i][type] = initialValue
            }
            else if (i === newValues.length - 1){
                newValues[i][type] = finalValue
            }
            else{
                newValues[i][type] = initialValue + (step * count)
                count++
            }
        }
        handlePost(newValues)

    }




    return (
        <div>
            <Button
               variant="outlined"
               color="warning"
               className='btn-sm '
               block
               onClick={() => toggleModal(true)}
               disabled={disabled}
            >
                 <FaEdit />
            </Button>
            <Modal
                className='modal-dialog-centered'
                isOpen={modal}
                toggle={() => toggleModal(false)}
            >
                <div className='modal-body p-2'>
                    <Card className='bg-secondary shadow border-0'>
                        <div className='modal-header'>
                            <h4 className='modal-title'>
                                {type}
                            </h4>
                        </div>
                        <CardBody className='px-lg-5 py-lg-2'>
                            
                        <Form onSubmit={e=>handleLine(e)} role='form'>

                            <Row>
                            <Col md={6} >
                                <FormGroup  >
                                <label>
                                    {language["kpiseconds"]["initial_value"]}
                                </label>
                                <Input
                                    maxLength={254}
                                    className='form-control-sm'
                                    type='text'
                                    name='initial_value'
                                    value={initialValue}
                                    onChange={(e) =>  !validateInputNumber(e.target.value ) ?  setInitialValue(parseFloat(e.target.value)): initialValue}
                                />
                                </FormGroup>
                            </Col>
                            <Col md={6} >
                                <FormGroup >
                                <label>
                                    {language["kpiseconds"]["initial_date"]}
                                </label>
                                <ReactDatePicker 
                                    type="date" 
                                    id="initial_date" 
                                    dateFormat={"MM-yyyy"}
                                    showMonthYearPicker
                                    selected={initialDate}
                                    minDate={minDate}
                                    onChange={date => setInitialDate(date)}
                                    className='form-control form-control-sm'
                                />
                                </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                            <Col md={6} >
                                <FormGroup>
                                <label>
                                {language["kpiseconds"]["final_value"]}
                                </label>
                                <Input
                                maxLength={254}
                                className='form-control-sm'
                                type='text'
                                name='objetive_value'
                                value={finalValue}
                                onChange={(e) => !validateInputNumber(e.target.value ) ?  setFinalValue(parseFloat(e.target.value)): finalValue}
                                />
                            </FormGroup>
                            </Col>
                            <Col md={6} >
                            <FormGroup>
                            <label>
                                {language["kpiseconds"]["final_date"]}
                            </label>
                            <ReactDatePicker 
                                    type="date" 
                                    id="forecast_date" 
                                    dateFormat={"MM-yyyy"}
                                    showMonthYearPicker
                                    selected={finalDate}
                                    maxDate={maxDate}
                                    onChange={date => setFinalDate(date)}
                                    className='form-control form-control-sm'
                                />
                            </FormGroup>
                            </Col>
                            </Row>
                            <FormGroup>
                            <Button color='success' block className='btn-sm' type='submit'>
                                {language["kpiseconds"]["form"]["editValues"]}
                            </Button>
                            <Button color='danger' block className='btn-sm' onClick={e=>toggleModal(false)} >
                                {language["kpiseconds"]["form"]["close"]}
                            </Button>
                            </FormGroup>
                            </Form>
                            
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        </div>
    );
};

export default connect()(ValueLine);
