import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { deleteKpiSecondById } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { Button } from "reactstrap";
import DeleteModal from "../../components/Modal/DeleteModal";

const DeleteKpiSecond = ({ dispatch, kpisecond }) => {
  let [modalKpiSeconds, setModalKpiSeconds] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalKpiSeconds(false);
  });



  const handleSubmitKpiSeconds = () => {
    dispatch(deleteKpiSecondById(kpisecond.id));
    setModalKpiSeconds(false);
  };



  return (
    <DeleteModal
      confirmText={language["kpiseconds"]["form"]["deleteConfirm"]}
      confirm={handleSubmitKpiSeconds}
      buttonText={language["kpiseconds"]["form"]["delete"]}
    />
  );
};

export default connect()(DeleteKpiSecond);
