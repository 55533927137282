import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import {  copyTshiftPromise,  getInitiative,  getTreeById,  getTreeTshifts,  patchTree, updateTreeTshifts } from '../../redux/actions';
import { language } from '../../assets/language';
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Row } from "reactstrap";
import { validateInputNumber } from '../../utils/validateInputNumber.js';
import Loading from '../../components/Loading/Loading';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import ReactDatePicker from 'react-datepicker';
import { FaAngleLeft, FaEdit } from 'react-icons/fa';
import InitiativeHeader from '../initiatives/InitiativeHeader';
import ListTreeTshifts from './ListTreeTshifts';
import TreeGraph from '../progress/TreeGraph';
import ConfirmModal from '../../components/Modal/ConfirmModal';




const TshiftDetail = ({ dispatch,  match  }) => {
    const history = useHistory();
    const initiative = useSelector(state => state.initiatives.initiative);
    const phases = useSelector(state => state.phases.phases);
    const treeId = match.params.treeId;
    const tree = useSelector(state => state.trees.tree);
    const trees = useSelector(state => state.trees.trees);
    const [loading, setLoading] = useState(true);
    const [editAble, seteditAble] = useState(false);
    const [name, setName] = useState("");
    const [abv, setAbv] = useState("");
    const [calculation, setCalculation] = useState("");
    const [parent, setParent] = useState("");
    const [initial_value, setinitial_value] = useState("");
    const [forecast_value, setforecast_value] = useState("");
    const [promise_value, setpromise_value] = useState("");
    const [initial_date, setinitial_date] = useState(
      new Date()
    )
    const [promise_date, setpromise_date] = useState(
      new Date()
    )
    const [forecast_date, setforecast_date] = useState(
      new Date()
    )
    const [isInitiative, setIsInitiative] = useState(false);




    const goBack = () => {
      history.goBack();
    }
  

    useEffect(() => {
        if (treeId && loading) {
          dispatch(getTreeById(treeId));
          dispatch(getTreeTshifts(treeId));
      }
    }, [treeId, loading]);


    useEffect(() => {
        if ( tree !== null && tree !== undefined && tree.id === treeId) {
          console.log(tree);
          setName(tree.name ? tree.name : "");
          setAbv(tree.abv ? tree.abv : "");
          setCalculation(tree.calculation ? tree.calculation : "");
          setParent(tree.parent_id ? tree.parent_id : "");
          setinitial_value(!validateInputNumber(tree.initial_value) ? tree.initial_value : "");
          setforecast_value(!validateInputNumber(tree.forecast_value) ? tree.forecast_value : "");
          setpromise_value(!validateInputNumber(tree.promise_value) ? tree.promise_value : "");
          setinitial_date(tree.initial_date ?  new Date(tree.initial_date) : new Date());
          setpromise_date(tree.promise_date ? new Date(tree.promise_date) : new Date());
          setforecast_date(tree.forecast_date ? new Date(tree.forecast_date) : new Date());
          setIsInitiative(tree.initiative_id ? true : false);
          dispatch(getTreeTshifts(treeId));
          setLoading(false);
        }
      },[tree]);


      const handleSubmitTree = () => {
        console.log("galnde");

       let object = {
         id: treeId,
         name: name,
         abv: abv,
         calculation: calculation,
         parent_id: parent ? parent : null,
         initial_value: !validateInputNumber(initial_value) ? initial_value : null,
         initial_date: initial_date,
         promise_value: !validateInputNumber(promise_value) ? promise_value : null,
         promise_date: promise_date,
         forecast_value: !validateInputNumber(forecast_value) ? forecast_value : null,
         forecast_date: forecast_date,
       };
       dispatch(patchTree(object));
       setLoading(true);

      }

      const handleUpdateTreeTshift = (object) => {
        object.id = treeId;
        console.log("update", object);
        dispatch(updateTreeTshifts(object));
        if(object.forecast_date){
          dispatch(getInitiative(tree.initiative_id))
        }
        setLoading(true);
      
      };

      const copyBase = () => {
       
        dispatch(copyTshiftPromise(treeId))
        setLoading(true);
      }
    

    return loading ? <Loading /> : (
        <Card className='mt-4'>
          {isInitiative ? <InitiativeHeader initiative={initiative}  phases={phases}/> : <h1 className='m-3 text-center' >{language["trees"]["title"]}</h1>} 
          <CardHeader className='text-center'>
           
          </CardHeader>
          <CardBody>
           
          <Row
              className='justify-content-center align-items-center'
            >
              <Col md={1}>  
                <Button className='btn-sm m-2 float-left' onClick={goBack}>
                  <FaAngleLeft className="sidebar-icon" />
                </Button>
              </Col>
            <Col md={11}>
          
            <h1 className='text-center'>{language["trees"]["names"]["table"]}{name}</h1>
            
            </Col>
        
            </Row>
          <Form  role='form' onSubmit={(e)=>handleSubmitTree(e)} >

            <Row className=' mb-3 justify-content-center align-items-center '>
              <Col md={12}>

                <Row className='justify-content-center align-items-center' >
                 
                  <Col md={4}  >
                  {editAble  ? 
                   <Button
                   color='success'
                   className='btn-sm m-2 float-right'
                   onClick={() => {
                    seteditAble(!editAble);
                  
                  }}
                 >
                 Guardar
               </Button>
               : !isInitiative ?
               <Button
                 color='warning'
                 className='btn-sm m-2 float-right'
                 onClick={() => seteditAble(!editAble)}
               >
                 <FaEdit />
               </Button>
                : <></>
                }
                 
                  <FormGroup>
                   
                    <label>{language["trees"]["table"]["abv"]}</label>
                    <Input
                      className='form-control-sm'
                      maxLength={254}
                      type='text'
                      name='units'
                      value={abv}
                      disabled={!editAble}
                      onChange={(e) => setAbv(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>{language["trees"]["table"]["calculation"]}</label>
                    <Input
                      className='form-control-sm'
                      type='text'
                      maxLength={254}
                      name='memory'
                      value={calculation}
                      disabled={!editAble}
                      onChange={(e) => calculation(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>{language["trees"]["table"]["parent"]}</label>
                    {trees && trees.length > 0 ? (
                    <Input
                      className='form-control-sm'
                      type='select'
                      name='parent'
                      required
                      disabled={!editAble}
                      onChange={(e) => {
                        setParent(e.target.value);
                      }}
                      value={parent}
                    >
                      <option value={""}>{language["trees"]["no_parent"]}</option>
                      {trees.map((tree) => (
                        <option key={tree.id} value={tree.id}>
                          {language.trees.names[tree.name] || tree.name}
                        </option>
                      ))}
                    </Input>
                  ) : (
                    <></>
                  )}
                  
                  </FormGroup>
                  </Col>
                  <Col md={8} className='p-0' >
                  <TreeGraph initiative_id={initiative ? initiative.id : null} tree={tree} is_initiative={false} height='70vh' />
                  </Col>
                </Row>
              </Col>


              <Col md={12}>
                <Row>
                <Col md={3} >
                <FormGroup>
                  <label>
                    {language["trees"]["table"]["initial_value"]}
                  </label>
                  <Input
                    className='form-control-sm'
                    maxLength={254}
                    type='text'
                    name='initial_value'
                    value={initial_value}
                    disabled={calculation ? true : false}
                    onChange={(e) => !validateInputNumber(e.target.value ) ?  setinitial_value(e.target.value): initial_value}
                  />
                </FormGroup>
                </Col>

                <Col md={3} >
                <FormGroup>
                  <label>
                    {language["trees"]["table"]["initial_date"]}
                  </label>
                  <ReactDatePicker 
                        type="date" 
                        id="initial_date" 
                        dateFormat={"MM-yyyy"}
                        showMonthYearPicker
                        disabled={calculation ? true : false}
                        selected={initial_date}
                        o onChange={(e) => {
                          if (e > initial_date) {
                            setforecast_date(e);
                          }
                          setinitial_date(e);
                        }}
                        className='form-control form-control-sm'
                       />
                </FormGroup>
                </Col>
                <Col md={3} >
                  <ConfirmModal
                    color='warning'
                    className='btn-sm py-2 mt-4'
                    disabled={calculation ? true : false}
                    outline={true}
                    confirm={() => handleUpdateTreeTshift({
                      initial_date: initial_date,
                      initial_value: initial_value,
                    })}
                    buttonText={language["trees"]["form"]["updateWith"]}
                    confirmText={language["trees"]["form"]["updateConfirm"]}
                  />
                </Col>
                <Col md={3} >
                <ConfirmModal
                    color='warning'
                    className='btn-sm py-2 mt-4'
                    disabled={calculation ? true : false}
                    confirm={handleSubmitTree}
                    buttonText={language["trees"]["form"]["updateWithout"]}
                    confirmText={language["trees"]["form"]["updateConfirm"]}
                  />
                </Col>

                </Row>
              
                <Row>
                <Col md={3} >
                <FormGroup>
                  <label>
                    {language["trees"]["table"]["promise_value"]}
                  </label>
                  <Input
                    className='form-control-sm'
                    type='text'
                    name='promise_value'
                    value={promise_value}
                    disabled={calculation ? true : false}
                  
                    onChange={(e) => !validateInputNumber(e.target.value ) ?  setpromise_value(e.target.value): promise_value}
                  />
                </FormGroup>
                </Col>
                <Col md={3} >
                <FormGroup>
                  <label>
                    {language["trees"]["table"]["promise_date"]}
                  </label>
                  <ReactDatePicker 
                        type="date" 
                        id="promise_date" 
                        dateFormat={"MM-yyyy"}
                        showMonthYearPicker
                        selected={promise_date}
                        disabled={calculation ? true : false}
                        onChange={(e) => {
                          if (e < initial_date) {
                            setinitial_date(e);
                          }
                          setpromise_date(e);
                        }}
                        className='form-control form-control-sm'
                       />
                 
                </FormGroup>
                </Col>
                <Col md={3}  >
                  
                  <ConfirmModal
                    color='warning'
                    className='btn-sm py-2 mt-4'
                    disabled={calculation ? true : false}
                    outline={true}
                    confirm={() => handleUpdateTreeTshift({
                      promise_date: promise_date,
                      promise_value: promise_value,
                    })}
                    buttonText={language["trees"]["form"]["updateWith"]}
                    confirmText={language["trees"]["form"]["updateConfirm"]}
                  />
                </Col>
                <Col md={3} >
                  <ConfirmModal
                    color='warning'
                    className='btn-sm py-2 mt-4'
                    disabled={calculation ? true : false}
                    confirm={(e)=>handleSubmitTree(e)}
                    buttonText={language["trees"]["form"]["updateWithout"]}
                    confirmText={language["trees"]["form"]["updateConfirm"]}
                  />
                </Col>
                </Row>

                <Row>
                <Col md={3} >
                <FormGroup>
                  <label>
                    {language["trees"]["table"]["forecast_value"]}
                  </label>
                  <Input
                    className='form-control-sm'
                    name='forecast_value'
                    disabled={calculation ? true : false}
                    value={forecast_value}
                    onChange={(e) => !validateInputNumber(e.target.value ) ?  setforecast_value(e.target.value): forecast_value}
                  />
                </FormGroup>
                </Col>
                <Col md={3} >
                <FormGroup>
                  <label>
                    {language["trees"]["table"]["forecast_date"]}
                  </label>
                  <ReactDatePicker 
                        type="date" 
                        id="forecast_date" 
                        dateFormat={"MM-yyyy"}
                        showMonthYearPicker
                        selected={forecast_date}
                        disabled={calculation ? true : false}
                        onChange={(e) => {
                          if (e < initial_date) {
                            setinitial_date(e);
                          }
                          setforecast_date(e);
                        }}
                        className='form-control form-control-sm'
                       />
                 
                </FormGroup>
                </Col>
                <Col md={3}  >
                  
                  <ConfirmModal
                    color='warning'
                    className='btn-sm py-2 mt-4'
                    disabled={calculation ? true : false}
                    outline={true}
                    confirm={() => handleUpdateTreeTshift({
                      forecast_date: forecast_date,
                      forecast_value: forecast_value,
                    })}
                    buttonText={language["trees"]["form"]["updateWith"]}
                    confirmText={language["trees"]["form"]["updateConfirm"]}
                  />
                  
                </Col>
               
                <Col md={3} >
                 
                  <ConfirmModal
                    color='primary'
                    className='btn-sm py-2 mt-4'
                    disabled={calculation ? true : false}
                    confirm={() => copyBase()}
                    buttonText={language["trees"]["form"]["copy"]}
                    confirmText={language["trees"]["form"]["updateConfirm"]}
                  />
                </Col>
                </Row>
              </Col>
            </Row>
            </Form>

            <Row className='align-items-center'>
              <Col>
               <ListTreeTshifts initiativeId={isInitiative ? initiative.id : null } tree={tree} />  
              </Col>
            </Row>



          </CardBody>           
        </Card>
    );
}

export default connect()(TshiftDetail);
