import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { language } from "../../assets/language";
import { getAllPhases, getAllCounterStats } from "../../redux/actions";
import Loading from "../../components/Loading/Loading";
import { Row, Col } from "reactstrap";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { format } from "date-fns";
Chart.register(ChartDataLabels);
registerLocale("es", es);
setDefaultLocale("es");
Chart.defaults.set("plugins.datalabels", {
  display: function (context) {
    return context.dataset.data[context.dataIndex] > 0;
  },
  color: "black",
  align: "end",
  anchor: "end",
  font: { size: "12" },
});

function firstDayOfWeek(dateObject, firstDayOfWeekIndex) {
  const dayOfWeek = dateObject.getDay(),
    firstDayOfWeek = new Date(dateObject),
    diff =
      dayOfWeek >= firstDayOfWeekIndex
        ? dayOfWeek - firstDayOfWeekIndex
        : 6 - dayOfWeek;

  firstDayOfWeek.setDate(dateObject.getDate() - diff);
  firstDayOfWeek.setHours(0, 0, 0, 0);

  return firstDayOfWeek;
}

const CounterStage = ({ dispatch, initiatives }) => {
  const all_stats = useSelector((state) => state.counter_stats.counter_stats);
  const phases = useSelector((state) => state.phases.phases);
  const [stats, setStats] = useState({
    s: null,
    e: null,
    data: [],
  });
  const [graphdata, setGraphdata] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [phasesSorted, setPhasesSorted] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [start, setStart] = useState(true);
  const [loading, setloading] = useState(true);
  const unit =  localStorage.getItem("unit") !== "undefined" ? JSON.parse(localStorage.getItem("unit")) : "";
  let green = "rgb(91, 166, 145)";
  let blue = "rgb(60, 85, 115 )";

  const labelsgraph2 = [
    language.initiatives.stats.old,
    language.initiatives.stats.new,
    language.initiatives.stats.increment,
    language.initiatives.stats.decrement,
    language.initiatives.stats.ended,
    language.initiatives.stats.deleted,
    language.initiatives.stats.total,
  ];

  const labelsPhases = [
    language.stages.names.evaluation,
    language.stages.names.execution,
    language.stages.names.result,
    language.stages.names.adoption,
    language.stages.names.finalized,
    language.stages.names.deleted,
  ];
  const legendMargin = {
    id: "legendMargin",
    beforeInit(chart, legend, options) {
      const fitValue = chart.legend.fit;
      chart.legend.fit = function fit() {
        fitValue.bind(chart.legend)();
        return (this.height += 20);
      };
    },
  };


  useEffect(() => {
    if (phases) {
      let phasesSortedAux= phases
      ? Object.keys(phases).sort((a, b) => {
          return phases[a].postition - phases[b].postion;
        })
      : [];
      setPhasesSorted(phasesSortedAux);
    }
  }, [phases]);


  useEffect(() => {
    if (start) {
      let s = new Date(startDate);
      let start_week = firstDayOfWeek(s, 1);
      start_week.setDate(start_week.getDate() - 7);
      setStartDate(start_week);
      setStart(false);
    }
  }, []);

  useEffect(() => {
    if (startDate !== null && !start) {
      dispatch(getAllCounterStats(format(startDate, "yyyy-MM-dd")));
      dispatch(getAllPhases());
      setloading(true);
    }
  }, [startDate, dispatch, start]);


  useEffect(() => {

    if (phases !== undefined &&startDate !== ""  && all_stats !== undefined) {
      statByPhases();
      statByVariant();
      setloading(false);
    }
  }, [all_stats, initiatives, phases, startDate]);


  const statByPhases = () => {
    let stat = {
      s: format(startDate, "yyyy-MM-dd"),
      data: [],
      initiatives: initiatives,
    };
    let _weeks = [];
    let _inicial = new Date(startDate);
    for (let index = 0; index <= 1; index++) {
      let _final = new Date(_inicial);
      //_inicial.setMonth(_inicial.getMonth());
      //_inicial.setFullYear(_inicial.getFullYear());
      _final.setDate(_final.getDate() + 6);
      _weeks.push([
        format(_inicial, "yyyy-MM-dd"),
        format(_final, "yyyy-MM-dd"),
      ]);
      // eslint-disable-next-line no-loop-func
      phasesSorted.map((idx) => {
        let phase = phases[idx];
        if (phase.identifier === "idea") {
          return;
        }
        let found = stat.data.find(
          (s) =>
          s.init === format(_inicial, "yyyy-MM-dd") &&
            s.name === phase.identifier
        );

        if (!found) {
          stat.data.push({
            name: phase.identifier,
            init: format(_inicial, "yyyy-MM-dd"),
            final: format(_final, "yyyy-MM-dd"),
            count: 0,
            value: 0,
          });
        }
      });

      for (let idx in all_stats) {
        let info = all_stats[idx];     
        if (
          info.created_at !== null &&
          info.created_at !== undefined &&
          initiatives.includes(info.initiative_id) &&
          info.stage !== "idea"
        ) {
          let created = new Date(info.created_at);
          if (
            format(_inicial, "yyyy-MM-dd") ===
            created.toISOString().split("T")[0] ) {
            
            let found = stat.data.find(
              (s) =>
              s.init === format(_inicial, "yyyy-MM-dd") &&
                s.name === info.stage
            );


            if (found) {
              found.count++;
              found.value += ~~info.value;
            } else {
              stat.data.push({
                name: info.stage,
                init: format(_inicial, "yyyy-MM-dd"),
                final: format(_final, "yyyy-MM-dd"),
                value: ~~info.value,
                count: 1,
              });
            }
          }
        }
      }

      let today = new Date();
      while (today.getDay() !== 1) {
        today.setDate(today.getDate() - 1);
      }
      _inicial.setDate(today.getDate());
      _inicial.setMonth(today.getMonth());
      _inicial.setFullYear(today.getFullYear());
    }
    setWeeks(_weeks);
    setStats(stat);
    stat ={};
  }


  const statByVariant = () => {
    let data = {
      old: [0, 0],
      new: [0, 0],
      increment: [0, 0],
      decrement: [0, 0],
      ended: [0, 0],
      deleted: [0, 0],
      total: [0, 0],
    };

    let visited = [];
    let old_data = {};
    let exists_dict = {};
    let next_week = new Date(startDate);
    next_week.setDate(next_week.getDate() + 7);

    let today = new Date();
    while (today.getDay() !== 1) {
      today.setDate(today.getDate() - 1);
    }
    for (let idx in all_stats) {
      let info = all_stats[idx];
      if (
        info.created_at !== null &&
        info.created_at !== undefined &&
        info.stage !== "idea" &&
        initiatives.includes(info.initiative_id)
      ) {
        let created = new Date(info.created_at);
        if (
          format(startDate, "yyyy-MM-dd") ===
          created.toISOString().split("T")[0]
        ) {
          data.old[0] += ~~info.value;
          data.old[1]++;
          visited.push(info.initiative_id);
          old_data[info.initiative_id] = ~~info.value;
          exists_dict[info.initiative_id] = ~~info.value;
        } else {
          if (
            visited.includes(info.initiative_id) &&
            info.stage !== "deleted"
          ) {
            delete exists_dict[info.initiative_id];
          }
          if (!visited.includes(info.initiative_id)) {
            data.new[0] += ~~info.value;
            data.new[1]++;
          } else if (old_data[info.initiative_id] < info.value) {
            data.increment[0] += ~~info.value - ~~old_data[info.initiative_id];
            data.increment[1]++;
          } else if (old_data[info.initiative_id] > info.value) {
            data.decrement[0] += ~~old_data[info.initiative_id] - ~~info.value;
            data.decrement[1]++;
          } else if (info.stage === "finalized") {
            data.ended[0] += ~~info.value;
            data.ended[1]++;
          }
        }
      }
    }

    Object.values(exists_dict).map((o) => {
      data.deleted[0] += ~~o;
      data.deleted[1]++;
      return true;
    });

    data.total[0] =
    ~~data.old[0] +
    ~~data.new[0] +
    ~~data.increment[0] -
    ~~data.decrement[0] -
    ~~ data.ended[0] -
    ~~data.deleted[0];
    data.total[1] = data.old[1] + data.new[1] - data.ended[1] - data.deleted[1];
    let candles = candleStickGraphData(data);
    setGraphdata([data, candles]);
  }

  const handleDateChange = (date) => {
    let start = firstDayOfWeek(date, 1);
    setStartDate(start);
  };

  const candleStickGraphData = (data) => {
    var oldCol = [0, data.old[0]];
    var newCol = [oldCol[1], oldCol[1] + data.new[0]];
    var incrementCol = [newCol[1], newCol[1] + data.increment[0]];
    var decrementCol = [incrementCol[1], incrementCol[1] - data.decrement[0]];
    var finishCol = [decrementCol[1], decrementCol[1] - data.ended[0]];
    var deletedCol = [finishCol[1], finishCol[1] - data.deleted[0]];
    var total = [0, data.total[0]];
    return [
      oldCol,
      newCol,
      incrementCol,
      decrementCol,
      finishCol,
      deletedCol,
      total,
    ];
  };

  let dataG = stats.data ? stats.data : [];
  let week = weeks ? weeks : [];
  
  const datagraphValue = {
    labels: labelsPhases,
    datasets: [
      {
        label: week[0] ? "Valor Semana " + week[0][0] : "Valor Semana 1",
        backgroundColor: "rgb(31, 97, 141 )",
        borderWidth: 2,
        data: dataG
          .filter((d) => d.init === week[0][0])
          .map((v) => v.value),
          barPercentage: 0.9,
      },
      {
        label: week[1] ? "Valor Semana " + week[1][0] : "Valor Semana 2",
        backgroundColor: "rgb(17, 122, 101)",
        borderWidth: 2,
        data: dataG
          .filter((d) => d.init === week[1][0])
          .map((v) => v.value),
          barPercentage: 0.9,
      },
    ],
  };
  const  datagraphInitiative = {
    labels: labelsPhases,
    datasets: [
      {
        label: week[0]
          ? "Iniciativas Semana " + week[0][0]
          : "Iniciativas Semana1",
        backgroundColor: "rgb(21, 67, 96)",
        borderWidth: 2,
        data: dataG
          .filter((d) => d.init === week[0][0])
          .map((v) => v.count),
        fill: true,
      },
      {
        label: week[1]
          ? "Iniciativas Semana " + week[1][0]
          : "Iniciativas Semana 2",
        backgroundColor: "rgb(20, 90, 50 )",
        data: dataG
          .filter((d) => d.init === week[1][0])
          .map((v) => v.count),
        fill: true,
      },
    ],
  };


  let data = graphdata ? graphdata[1] : [];
  let red = "red";

  const datagraph2 = {
    labels: labelsgraph2,
    datasets: [
      {
        label: "Cascada de Iniciativas",
        data: data,
        backgroundColor: [blue, green, green, green, red, red, green],
        datalabels: {
          labels: {
            index: {
              align: "end",
              anchor: "end",
              color: "black",
              font: { size: 16 },
              formatter: function (value, context) {
                let init = Object.keys(graphdata[0]);
                init = init.map((g) => {
                  return graphdata[0][g][1];
                });
                return init[context.dataIndex];
              },
            },
            name: {
              align: "center",
              font: { size: 16 },
              formatter: function (value, context) {
                return Math.abs(
                  context.dataset.data[context.dataIndex][1] -
                    context.dataset.data[context.dataIndex][0]
                );
              },
            },
          },
        },
      },
    ],
  };

  const configV = {
    responsive: true,
    elements: {
      point: {
        hitRadius: 4,
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 200,
        },
        min: 0,
        suggestedMax: 1000,
        beginAtZero: true,
      },
    },
  };
  const configI = {
    responsive: true,
    elements: {
      point: {
        hitRadius: 4,
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 2,
        },
        min: 0,
        suggestedMax: 20,
        beginAtZero: true,
      },
    },
  };

  const config2 = {
    plugins: {
      datalabels: {
        display: function (context) {
          return (
            Math.abs(
              context.dataset.data[context.dataIndex][1] -
                context.dataset.data[context.dataIndex][0]
            ) > 0
          );
        },
        formatter: function (value, context) {
          return Math.abs(
            context.dataset.data[context.dataIndex][1] -
              context.dataset.data[context.dataIndex][0]
          );
        },
        color: "white",
        align: "center",
        anchor: "center",
        font: { size: "14" },
      },
    },
    elements: {
      point: {
        hitRadius: 4,
      },
    },
    responsive: true,
    scales: {
      y: {
        min: 0,
        suggestedMax: 1000,
        beginAtZero: true,
      },
    },
  };


  const renderStartEndDateFilter = () => {
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return (
      <div>
        <br />
        <div>
          <label>{language.stages.table.start_date}: &nbsp; </label>
          <DatePicker
            className="form-control"
            dateFormat='dd-MM-yyyy'
            locale={"es"}
            selected={startDate}
            onSelect={handleDateChange} //when day is clicked
            onChange={handleDateChange} //only when value has changed
          />
        </div>
        <br />
      </div>
    );
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <div className='col-md-6'>{renderStartEndDateFilter()}</div>
      <Row>
        <Col lg='6'>
          <h3 className='text-center'>
            {language.initiatives.stats.initiative_value_stage} {unit.value}
          </h3>
          <Bar
            options={configV}
            data={datagraphValue}
            plugins={[legendMargin]}
            
          />
        </Col>
        <Col lg='6'>
          <h3 className='text-center'>
            {language.initiatives.stats.initiative_number}
          </h3>
          <Bar
            options={configI}
            data={datagraphInitiative}
            plugins={[legendMargin]}
          />
        </Col>
      </Row>
      <br />
      <Col style={{ float: "left" }} lg='6'>
        <br />
        <h3 className='text-center'>
          {language.initiatives.stats.delta_value} {unit.value}
        </h3>
        <Bar options={config2} data={datagraph2} plugins={[legendMargin]} />
      </Col>
    </>
  );
};

export default connect()(CounterStage);
