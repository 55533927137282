const initialState_Tags = {
  request_time: null,
  error: null,
  tags: {},
};

const tags = (state = initialState_Tags, action) => {
  switch (action.type) {
    case "SET_GET_TAGS":
      return {
        ...state,
        tags: action.payload,
        request_time: action.request_time,
      };
    case "SET_CREATE_TAGS":
      try {
        return {
          ...state,
          create: action.payload,
          request_time: action.request_time,
        };
      } catch (e) {
        console.log(e);
        return state;
      }
    case "SET_UPDATE_TAGS":
      try {
        return {
          ...state,
          update: action.payload,
          request_time: action.request_time,
        };
      } catch (e) {
        console.log(e);
        return state;
      }
    case "SET_DELETE_TAGS":
      try {
        return {
          ...state,
          delete: action.payload,
          request_time: action.request_time,
        };
      } catch (e) {
        console.log(e);
        return state;
      }
    case "INITYPE_ERROR":
      return {
        ...state,
        error: action.payload.error,
        request_time: null,
        // initypes: {},
      };
    case "DELETE_INITYPE":
      let new_initypes = state.initypes;
      try {
        new_initypes = Object.keys(new_initypes).reduce(function (
          filtered,
          key
        ) {
          if (new_initypes[key].id !== action.payload.id)
            filtered[key] = new_initypes[key];
          return filtered;
        },
        {});
      } catch (e) {
        console.log(e);
      }
      return {
        ...state,
        initypes: new_initypes,
        request_time: action.request_time,
      };
    default:
      return state;
  }
};

export default tags;
