import React, { useCallback, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { language } from "../../assets/language";
import { patchInitiative, getInitiativeKpis, retrieveFilterValues, getDecisions } from "../../redux/actions";
import {  Col, Button,Card, CardBody, Row , Input, Container } from "reactstrap";
import Loading from "../../components/Loading/Loading";
import {  ListInitiativeStages } from "../stages";
import { getUserPermission } from "../../utils/getUserPermission";
import { useDispatch } from "react-redux";
import TreeGraph from "./TreeGraph";
import KPIGraph from "./KPIGraph";
import SidebarRight from "../../components/SidebarRight/SidebarRight";
import { getResponsabilityPermission } from "../../utils/getResponsabilityPermission";
import moment from "moment/moment";
import { ListInitiativeTasksSummary, CreateTask } from "../tasks";



const Progress = ({  initiative,  initiative_id, noStage=false, setProgressReady=()=>{} , progressReady = {}}) => {

    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const kpiseconds = useSelector((state) => state.kpi_seconds.kpi_seconds);
    const filters = useSelector((state)=> state.counter_stats.filter)
    const decisions = useSelector((state) => state.decisions.decisions);
    const [loading, setLoading] = useState(true);
    const [current, setCurrent] = useState("");
    const [actualState, setActualState] = useState("");
    const [lastReunionStatus, setLastReunionStatus] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);


    useEffect(() => {
      if (loading){

        if (  initiative && kpiseconds && decisions && initiative.id === initiative_id && filters) {
            setCurrent(initiative.current);
            setActualState(initiative.actual_state);
            setLastReunionStatus(initiative.last_reunion_status);
            setLoading(false);
        }
        if (filters && filters.start_date && filters.end_date) {
          const startDateFormatted = moment(filters.start_date).format("DD-MM-YYYY");
          const endDateFormatted = moment(filters.end_date).format("DD-MM-YYYY");
          setStartDate(startDateFormatted)
          setEndDate(endDateFormatted)
        }else {
          setStartDate(null)
          setEndDate(null)
        }
      }
    }, [initiative, kpiseconds, filters,decisions,initiative_id, loading]);


    useEffect(() => {
      if(loading){
        dispatch(getDecisions());
        dispatch(retrieveFilterValues())
        dispatch(getInitiativeKpis(initiative_id));
      }
    }, [loading, dispatch, initiative_id]);

 
    const canEdit = (type) => {
      if ( getUserPermission(state, type, "patch", false) || getResponsabilityPermission(state,type,"change", initiative.id, "Lider") ) {
        return true
    }else {
        return false
      }
    }




    const updateInitiativeProgress = () => {
        if (callUpdateInitiativeProgress() === 0) {
            return <></>;
        }
        return (
            <Button
              color='primary'
              className='btn float-right mt-3'
              onClick={() => callUpdateInitiativeProgress(true)}
            >
            {language["initiatives"]["form"]["update"]}
            </Button>
        );
    };
    const renderProgressDescriptions = () => {
        return (
          <div>
            
            <Row>
              <Col key={"col-1"}>
                <h4>{language["initiatives"]["titles"]["current"]}</h4>
                <Input
                  type='textarea'
                  value={current ? current : ""}
                  onChange={(e) => setCurrent(e.target.value)}
                  cols={40}
                  rows={5}
                  disabled={!canEdit("initiatives")}
                />
              </Col>
              <Col  key={"col-2"}>
                <h4>{language["initiatives"]["titles"]["last_reunion_status"]}</h4>
                <Input
                  type='textarea'
                  value={lastReunionStatus ? lastReunionStatus : ""}
                  onChange={(e) => setLastReunionStatus(e.target.value)}
                  cols={40}
                  rows={5}
                  disabled={!canEdit("initiatives")}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col className='col-md-6'  key={"col-3"}>
                <h4>{language["initiatives"]["titles"]["actual_state"]}</h4>
                <Input
                  className='form-control-sm'
                  onChange={(e) => setActualState(e.target.value)}
                  defaultValue={actualState}
                  type='select'
                  disabled={!canEdit("initiatives")}
                >
                  <option value=''>
                    {language["info_states"]["form"]["select"]}
                  </option>
                  {Object.values(decisions).map((state) => {
                    return (
                      <option key={state.id} value={state.id}>
                        {state.name}
                      </option>
                    );
                  })}
                </Input>
              </Col>
              <Col className='col-md-6 text-center'  key={"col-4"}>
                {updateInitiativeProgress()}
                </Col>
            </Row>
          </div>
        );
      };
    


    const callUpdateInitiativeProgress = (check = false) => {
        var object = {};

        if (current !== initiative.current && current !== "") {
            object.current = current;
        }
        if (
            lastReunionStatus !== initiative.last_reunion_status &&
            lastReunionStatus !== ""
        ) {
            object.last_reunion_status = lastReunionStatus;
        }
        if (actualState !== initiative.actual_state && actualState !== "") {
            object.actual_state = actualState;
        }

        if (check && Object.keys(object).length > 0) {
            dispatch(patchInitiative(initiative.id, object));
        }
        if (!check) {
            return Object.keys(object).length;
        }
    };

    const handleFilterApply = (start, end) => {
      // setLoading(true);
      const startDateFormatted = moment(start).format("DD-MM-YYYY");
      const endDateFormatted = moment(end).format("DD-MM-YYYY");
      setStartDate(startDateFormatted)
      setEndDate(endDateFormatted)

    };
    

  return loading ? <Loading /> : (
  <>
    <SidebarRight onFilterApply={handleFilterApply}/>
    <TreeGraph initiative_id={initiative_id} start={startDate} end={endDate}  />
       
    <br />
    {kpiseconds.length > 0  && kpiseconds[0].initiative_id === initiative_id  ? (
      <>
        <h4>{language["initiatives"]["titles"]["kpis"]}</h4>
        <Row md={2} xs={1}>
            {kpiseconds.map((kpisecond) => {
              return (
                <KPIGraph key={kpisecond.id} kpisecond={kpisecond} start={startDate} end={endDate} setProgressReady={setProgressReady} progressReady={progressReady} />
              );
            })}
        </Row>
      </>
    ) : (
      <></>
    )}


    {renderProgressDescriptions()}

    <br></br>
    {initiative !== null && getUserPermission(state, "tasks", "create") ? (
      <CreateTask initiative={initiative} />
    ) : (
      <></>
    )}

    <h3>{language.tasks.title}</h3>

    <ListInitiativeTasksSummary initiative={initiative} />

    <br></br>



    {(initiative.stage !== "idea" || initiative.stage !== "evaluation") && !noStage ? (
      <div>
          <h3>{language["stages"]["title"]}</h3>
          <ListInitiativeStages
          initiative={initiative}
          editForecast={false}
          editEstimated={canEdit("stages") ?  true: false}
          />
      </div>
    ) : (
      <></>
    )}
  </>
)
}

export default Progress;
