import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { getAllAreas, postCard } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Modal,
  UncontrolledTooltip,
} from "reactstrap";
const CreateCard = ({ dispatch, getCurrentUserCards }) => {
  const areas = useSelector((state) => state.areas.areas);

  let [modal, toggleModal] = useState(false);
  const unit =  localStorage.getItem("unit") !== "undefined" ? JSON.parse(localStorage.getItem("unit")) : "";


  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    toggleModal(false);
  });

  let [title, setTitle] = useState("");
  let [description, setDescription] = useState("");
  let [estimated_value, setEstimated] = useState("");
  let [font, setFont] = useState("");
  let [area_id, setAreaId] = useState("");
  let [loadedAreas, setLoadedAreas] = useState(true);

  useEffect(() => {
    dispatch(getAllAreas());
  }, [dispatch]);

  useEffect(() => {
    if (loadedAreas && Object.keys(areas) > 0) {
      setAreaId(Object.values(areas)[0].id);
      setLoadedAreas(false);
    }
  }, [areas, loadedAreas]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    dispatch(postCard(object));
    toggleModal(false);
    setTitle("");
    setDescription("");
    setEstimated("");
    setFont("");
    setLoadedAreas(true);
    setAreaId("");
    getCurrentUserCards();
  };

  return (
    <div>
      <Button
        color='success'
        className='btn-sm float-right'
        onClick={() => toggleModal(true)}
      >
        {language["cards"]["form"]["create"]}
      </Button>
      <Modal
        className='modal-dialog-centered'
        isOpen={modal}
        toggle={() => toggleModal(false)}
      >
        <div className='modal-body p-0'>
          <Card className='bg-secondary shadow border-0'>
            <CardBody className='px-lg-5 py-lg-2'>
              <h2 className='text-muted text-center mt-2 mb-3'>
                {language["cards"]["form"]["create"]}
              </h2>
              <Form onSubmit={handleSubmit} role='form'>
                <FormGroup>
                  <label htmlFor='title'>
                    {language["cards"]["table"]["title"]}
                  </label>
                  <Input
                    className='form-control-sm'
                    type='text'
                    maxLength={254}
                    name='title'
                    required
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor='description'>
                    {language["cards"]["table"]["description"]}
                  </label>
                  <Input
                    className='form-control-sm'
                    type='text'
                    name='description'
                    maxLength={254}
                    required
                    autoComplete='off'
                    multiple={true}
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor='estimated_value'>
                    {language["cards"]["table"]["estimated_value"]} {unit.value}
                  </label>
                  <Input
                    className='form-control-sm'
                    type='number'
                    name='estimated_value'
                    required
                    onChange={(e) => setEstimated(e.target.value)}
                    value={estimated_value}
                  />
                </FormGroup>
                <FormGroup>
                  <label>{language["cards"]["table"]["area"]}</label>
                  <Input
                    className='form-control-sm'
                    type='select'
                    name='area_id'
                    required
                    onChange={(e) => setAreaId(e.target.value)}
                    value={area_id}
                  >
                    {areas.map((area) => (
                      <option key={area.id} value={area.id}>
                        {area.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
             
                  <label htmlFor='fase'>
                    {language["cards"]["table"]["fase"]}
                   

                  </label>
                  <i className="ni ni-bulb-61 p-2" id="UncontrolledTooltipExample" />

                  <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample">
                      Lugar donde se creó la idea
                    </UncontrolledTooltip>
                  <Input
                    className='form-control-sm'
                    maxLength={254}
                    name='fase'
                    required
                    onChange={(e) => setFont(e.target.value)}
                    value={font}
                  />
                </FormGroup>
              
                <Button color='success' className='btn-sm' type='submit'>
                  {language["cards"]["form"]["create"]}
                </Button>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default connect()(CreateCard);
