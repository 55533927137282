import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import {
  getInitiativeApprovals,
  getAllPhases,
  getAllAreas,
  getAllUsers,
} from "../../redux/actions";
import { language } from "../../assets/language";
import SimpleTable from "../../components/tables/SimpleTable";
import { DeleteApproval } from ".";
import { getUserPermission } from "../../utils/getUserPermission";
// import { getUserPermission } from '../../utils/getUserPermission';

const ListInitiativeApprovals = ({ dispatch, initiative }) => {
  const state = useSelector(state => state);
  const approvals = useSelector(
    (state) => state.approvals.initiative_approvals
  );
  const phases = useSelector((state) => state.phases.phases);
  const users = useSelector((state) => state.users.users);
  const areas = useSelector((state) => state.areas.areas);
  const areas_dict = useMemo(() => {
    return areas.reduce((acc, area) => {
      acc[area.id] = area;
      return acc;
    }, {});
  }, [areas]);

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getAllPhases());
    dispatch(getInitiativeApprovals(initiative.id));
    dispatch(getAllAreas());
  }, [dispatch, initiative]);

  const columns = useMemo(() => {
    let col = [
      {
        Header: language.approvals.table.responsable_user,
        accessor: "user",
      },
      {
        Header: language.approvals.table.area,
        accessor: "area",
      },
      {
        Header: language.approvals.table.stage,
        accessor: "stage",
      },
      {
        Header: language.approvals.table.next_stage,
        accessor: "next_stage",
      },
      {
        Header: language.approvals.table.is_accepted,
        accessor: "is_accepted",
      },
      {
        Header: language.approvals.table.is_active,
        accessor: "is_active",
      },
      {
        Header: language.approvals.table.notes,
        accessor: "notes",
      },
      {
        Header: language.general.actions,
        accessor: "actions",
      },
    ];
    // if (getUserPermission(state, 'approvals', 'delete')) {
    //     col.push({
    //         Header: language.general.actions,
    //         accessor: 'actions',
    //     });
    // }
    return col;
  }, []);

  // render areas in list with name, description, parent_id
  const renderApprovals = () => {
    if (!approvals || approvals.length === 0) {
      return <p>{language["approvals"]["not_found"]}</p>;
    }

    let approvalsList = approvals
      .filter((approval) => approval.responsable_user != null)
      .filter((approval) => approval.initiative_id != null)
      .map((approval) => {
        return {
          id: approval.id,
          user: users[approval["responsable_user"]]
            ? users[approval["responsable_user"]].full_name
            : "",
          area:
            areas_dict &&
            approval["area_id"] &&
            areas_dict.hasOwnProperty(approval["area_id"])
              ? areas_dict[approval["area_id"]].name
              : "",
          stage: phases.hasOwnProperty(approval.stage)
            ? phases[approval.stage].name
            : approval.stage,
          next_stage: phases.hasOwnProperty(approval.next_stage)
            ? phases[approval.next_stage].name
            : approval.next_stage,
          is_accepted: approval.is_accepted
            ? language["approvals"]["completed"]["yes"]
            : language["approvals"]["completed"]["no"],
          is_active: approval.is_active
            ? language["general"]["yes"]
            : language["general"]["no"],
          notes: approval.notes ? approval.notes : "",
          actions:  getUserPermission(state, "approvals", "delete") ? <DeleteApproval approval={approval} /> : "",
        };
      });

    return <SimpleTable columns={columns} data={approvalsList} />;
  };

  return <div>{renderApprovals()}</div>;
};

export default connect()(ListInitiativeApprovals);
