import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { getAllRoles } from "../../redux/actions";
import { language } from "../../assets/language";
import SimpleTable from "../../components/tables/SimpleTable";
import { CreateRole, DeleteRole } from ".";
import { ListRolePermits } from "../permits";
import { getUserPermission } from "../../utils/getUserPermission";
import { Button, Card, CardBody } from "reactstrap";
import Loading from "../../components/Loading/Loading";
import ListRoleGroups from "../permits/ListRoleGroups";

const ListRoles = ({ dispatch }) => {
  const state = useSelector((state) => state);
  const roles = useSelector((state) => state.roles.roles);

  const [roleName, setRoleName] = useState("");
  const [hiddenList, setHiddenList ] = useState(false);

  const displayRoles = (name) => {
    
    setHiddenList(false);
    if (name === roleName){
      setRoleName("");
    } else {
      setRoleName(name);
    
    }
  }

  useEffect(() => {
    if (roleName !== "") {
      setHiddenList(true);
    }
    }, [roleName]);

  useEffect(() => {
    dispatch(getAllRoles());
  }, [dispatch]);

  const columns = useMemo(() => {
    let col = [
      {
        Header: language.roles.table.id,
        accessor: "id",
      },
      {
        Header: language.roles.table.name,
        accessor: "name",
      },
      {
        Header: language.roles.form.edit_permits,
        accessor: "edit_permits",
      },
    ];
    if (getUserPermission(state, "roles", "delete")) {
      col.push({
        Header: language.general.actions,
        accessor: "actions",
      });
    }
    return col;
  }, [state]);

  const renderRoles = () => {
    if (!roles || roles.length === 0) {
      return <p>{language["roles"]["not_found"]}</p>;
    }

    let i = 0;
    let rolesList = Object.values(roles).map((role) => {
      i++;
      return {
        id: i,
        name: language["roles"]["kinds"][role.name] || role.name,
        edit_permits: (
          <Button
            color='primary'
            className='btn-sm'
            onClick={() => displayRoles(role.name)}
          >
            {language["roles"]["form"]["edit_permits"]}
          </Button>
        ),
        actions: <DeleteRole role={role} />,
      };
    });

    return <SimpleTable columns={columns} data={rolesList} />;
  };

  const renderPermits = (name) => {
    if (roleName !== "" && hiddenList) {
      return <ListRoleGroups role={roles[name]} />;
    }
    else {
      return "";
    }

    
  };

  return (
    <Card className="p-3 m-3">
        <h1>{language.roles.title}</h1>
      <CardBody>
        <CreateRole />
        {renderRoles()}
        {hiddenList  ?  renderPermits(roleName): ""}
      </CardBody>
    </Card>
  );
};

export default connect()(ListRoles);
