import { BACKEND_URL } from "../../utils/config";
import { errorHandler } from "../../utils/error_handler";
import { getUserPermission } from "../../utils/getUserPermission";
import FileSaver from 'file-saver';




//get exports
export const getExportInitiative = (initiativeId) => ({
    type: "GET_EXPORT_INITIATIVE",
    payload: {
        initiativeId: initiativeId,
    },
    request_time: Date.now()    
    
})

export const setExportError = (error) => ({
    type: "EXPORT_ERROR",
    payload: {
        error: error
    }
});

export const setExports = (exports, initiativeId) => ({
    type: "SET_EXPORTS",
    payload: {
        exports: exports,
        initiativeId: initiativeId,
    },
    request_time: Date.now()
})


export const getInitiativeExports = (initiativeId, startDate, endDate) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "configs", "patch", true)) {
                throw new Error("No permissions");
            }

            const queryParams = new URLSearchParams({ startDate, endDate }).toString();

            fetch(`${BACKEND_URL}/initiatives/${initiativeId}/exports?${queryParams}`, {
                headers: {
                    "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    console.log("response", response);

                    return response.blob();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                const blob = new Blob([data],{
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
        
                // Get the current date
                const currentDate = new Date();
                
                // Format the date as a string
                const dateString = currentDate.toISOString().slice(0, 10);
                
                // Add the date to the file name
                const fileName = `inititative_${initiativeId}_data_${dateString}.xlsx`;
                
                // Save the file
                FileSaver.saveAs(blob, fileName);
                dispatch(setExports(data, initiativeId));
            })
            .catch((error) => {
                errorHandler("exports", error.message);
                dispatch(setExportError(error));
            })
            
        } catch (error) {
            errorHandler("exports", error.message);
            dispatch(setExportError(error));
        }
    };
    
}

export const getAllExports = (startDate, endDate) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "configs", "patch", true)) {
                throw new Error("No permissions");
            }
            
            const queryParams = new URLSearchParams({ startDate, endDate }).toString();    

            fetch(`${BACKEND_URL}/exports?${queryParams}`, {
                headers: {
                    "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    console.log("response", response);

                    return response.blob();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                const blob = new Blob([data]);
        
                // Descarga el archivo usando FileSaver.js
                const currentDate = new Date();
                
                // Format the date as a string
                const dateString = currentDate.toISOString().slice(0, 10);
                
                // Add the date to the file name
                const fileName = `exported_data_${dateString}.xlsx`;
                
                // Save the file
                FileSaver.saveAs(blob, fileName);
                dispatch(setExports(data));
            })
            .catch((error) => {
                errorHandler("exports", error.message);
                dispatch(setExportError(error));
            })
            
        } catch (error) {
            errorHandler("exports", error.message);
            dispatch(setExportError(error));
        }
    };
    

}

export const  getAllExportZip = (startDate, endDate) => {
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (!getUserPermission(state, "configs", "patch", true)) {
                throw new Error("No permissions");
            }
            
            const queryParams = new URLSearchParams({ startDate, endDate }).toString();    

            fetch(`${BACKEND_URL}/exports/csv?${queryParams}`, {
                headers: {
                    "Accept": "application/zip", 
                    "Authorization": `Bearer ${state.sessions.token}`
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    console.log("response", response);

                    return response.blob();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                const blob = new Blob([data]);
        
                // Descarga el archivo usando FileSaver.js
                const currentDate = new Date();
                
                // Format the date as a string
                const dateString = currentDate.toISOString().slice(0, 10);
                
                // Add the date to the file name
                const fileName = `exported_data_${dateString}.zip`;
                
                // Save the file
                FileSaver.saveAs(blob, fileName);
                dispatch(setExports(data));
            })
            .catch((error) => {
                errorHandler("exports", error.message);
                dispatch(setExportError(error));
            })
            
        } catch (error) {
            errorHandler("exports", error.message);
            dispatch(setExportError(error));
        }
    };
}


