import React, { useState, useRef, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { patchTree } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { Button, Input, FormGroup, Card, Modal, CardBody } from "reactstrap";
import { check_calculation } from "../../utils/check_calculation";
import Loading from "../../components/Loading/Loading";

const EditTree = ({ dispatch, tree }) => {
  let [modalTrees, setModalTrees] = useState(false);

  let [loadedTree, setLoadedTree] = useState(true);

  let [_name, setName] = useState("");
  let [abv, setAbv] = useState("");
  let [calculation, setCalculation] = useState("");
  const other_trees = useSelector((state) => state.trees.trees);

  
  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalTrees(false);
  });

  useEffect(() => {
    if (tree) {
      setName(tree.name);
      setAbv(tree.abv);
      setCalculation(tree.calculation);
      setLoadedTree(false);
    }
  }, [tree]);

  const handleSubmitTrees = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    object["id"] = tree.id;
    if (check_calculation(object, other_trees)) {
      dispatch(patchTree(object));
      setLoadedTree(true);
    }
    setModalTrees(false);
  };

  const renderModalFormTrees = () => {
    return (
      <>
        <Button
          color='primary'
          className='btn-sm mb-1'
          onClick={() => setModalTrees(true)}
          block
        >
          {language["trees"]["form"]["edit"]}
        </Button>
        <Modal
          className='modal-dialog-centered'
          isOpen={modalTrees}
          toggle={() => setModalTrees(false)}
        >
          <div className='modal-body p-0'>
            <Card className='bg-secondary shadow border-0' ref={wrapperRef}>
              <CardBody className='px-lg-5 py-lg-2'>
                <h2>{language["trees"]["form"]["edit"]}</h2>
                <form onSubmit={handleSubmitTrees}>
                  <FormGroup>
                    <label htmlFor='name'>
                      {language["trees"]["table"]["name"]}
                    </label>
                    <Input
                      className='form-control-sm'
                      maxLength={254}
                      type='text'
                      name='name'
                      multiple={true}
                      value={_name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor='abv'>
                      {language["trees"]["table"]["abv"]}
                    </label>
                    <Input
                      maxLength={254}
                      className='form-control-sm'
                      type='text'
                      name='abv'
                      multiple={true}
                      value={abv}
                      onChange={(e) => setAbv(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor='calculation'>
                      {language["trees"]["table"]["calculation"]}
                    </label>
                    <Input
                      className='form-control-sm'
                      maxLength={254}
                      type='text'
                      name='calculation'
                      multiple={true}
                      value={calculation}
                      onChange={(e) => setCalculation(e.target.value)}
                    />
                  </FormGroup>
                  <div>
                    <Button color='success' className='btn-sm' type='submit'>
                      {language["trees"]["form"]["save"]}
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </>
    );
  };

  return loadedTree ? <Loading /> : <div>{renderModalFormTrees()}</div>;
};

export default connect()(EditTree);
