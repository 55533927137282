import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { deleteTreeById } from '../../redux/actions';
import { language } from '../../assets/language';
import { useClickAway } from 'react-use';
import DeleteModal from "../../components/Modal/DeleteModal";

const DeleteTree = ({ dispatch, tree }) => {


    const handleSubmitTrees = () => {
        dispatch(deleteTreeById(tree.id));
    }



    return (
        <DeleteModal
        buttonText={language["trees"]["form"]["delete"]}
        confirmText={language["trees"]["form"]["deleteConfirm"]}
        confirm={handleSubmitTrees}
      />
    )

}

export default connect()(DeleteTree);