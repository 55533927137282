const initialState_InfoState = {
    request_time: null,
    error: null,
    info_states: {},
};

const info_states = (state = initialState_InfoState, action) => {
    switch (action.type) {
        case 'SET_INFO_STATES':
            return {
                info_states: action.payload.info_states.reduce((obj, item) => {
                    obj[item.name] = item;
                    return obj;
                }, {}),
                request_time: action.request_time,
            };
        case 'SET_INFO_STATE':
            try {
                return {
                    ...state,
                    info_states: {
                        ...state.info_states, [action.payload.name]: action.payload,
                    },
                    request_time: action.payload.request_time,
                };
            } catch (e) {
                console.log(e);
                return state;
            }
        case 'INFO_STATE_ERROR':
            return {
                ...state,
                error: action.payload.error,
                request_time: null,
                // info_states: {},
            };
        case "DELETE_INFO_STATE":
            let new_info_states = state.info_states;
            try {
                new_info_states = Object.keys(new_info_states).reduce(function (filtered, key) {
                    if (new_info_states[key].id !== action.payload.id) filtered[key] = new_info_states[key];
                    return filtered;
                }, {});
            } catch (e) {
                console.log(e);
            }
            return {
                ...state,
                info_states: new_info_states,
                request_time: action.request_time,
            };
        default:
            return state;
    }
}

export default info_states;