import { useTable, useSortBy } from "react-table";
import { Table } from "reactstrap";
import "./styles.css";

let style = {
  wordWrap: "break-word" /* Internet Explorer 5.5+ */,
  whiteSpace: "-webkit-pre-wrap" /* Newer versions of Chrome/Safari*/,
  workBreak: "break-all",
};
export default function SimpleTable({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI


  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    }, useSortBy,);
  
  return (
    <Table
      hover
      responsive
      className='align-items-center table-flush'
      style={{ width: "100%", tableLayout: "fixed"}}
      {...getTableProps()}
    >
      <thead className='thead-light'>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, ix) => (
              <th
                className={`${ix !== 0 ? "text-center" : ""}`}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                <div style={{ whiteSpace: "normal", wordWrap: "initial" }} >
                  {column.render("Header")}
                  <span className={column.isSorted
                        ? column.isSortedDesc
                          ? ' ni ni-bold-down p-1'
                          : ' ni ni-bold-up p-1'
                        : ' p-1'}>
                      
                  </span>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell, ix) => {
                return (
                  <td
                    style={style}
                    className={`${ix !== 0  ? "text-center" : ""}`}
                    {...cell.getCellProps()}
                  >
                    <div style={{ whiteSpace: "normal", wordWrap: "initial" }} >
                      {cell.render("Cell")}
                    </div>
                  </td>
                );
              })}
            </tr>
            
          
          );
        })}
      </tbody>
    </Table>
  );
}
