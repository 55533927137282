const initialState_Activity = {
    activities: [],
    initiative_activities: [],
    initiative_id: null,
    request_time: null,
    initiative_request_time: null,
    error: null,
    loading: false,
    loading_time: null
};

const activities = (state = initialState_Activity, action) => {
    switch (action.type) {
        case 'SET_ACTIVITIES':
            return {
                ...state,
                activities: action.payload.activities,
                request_time: action.request_time,
            };
        case 'SET_INITIATIVE_ACTIVITIES':
            return {
                ...state,
                initiative_activities: action.payload.activities,
                initiative_id: action.payload.initiative_id,
                initiative_request_time: action.request_time,
            };
        case "NEW_ACTIVITY":
            return {
                ...state,
                activities: [...state.activities, action.payload],
                initiative_activities: [...state.initiative_activities, action.payload],
                request_time: action.request_time,
                initiative_request_time: action.request_time,
            };
        case "UPDATE_ACTIVITY":
            let new_activities = state.activities;
            try {
                new_activities = state.activities.map(activity => {
                    if (activity.id === action.payload.id) {
                        return action.payload;
                    }
                    return activity;
                });
            } catch (e) {
            }
            let new_initiative_activities = state.initiative_activities;
            try {
                new_initiative_activities = state.initiative_activities.map(activity => {
                    if (activity.id === action.payload.id) {
                        return action.payload;
                    }
                    return activity;
                });
            } catch (e) {
            }
            return {
                ...state,
                activities: new_activities,
                initiative_activities: new_initiative_activities,
                request_time: action.request_time,
                initiative_request_time: action.request_time,
            };
        case "DELETE_ACTIVITY":
            return {
                ...state,
                activities: state.activities.filter(activity => activity.id !== action.payload.id),
                initiative_activities: state.initiative_activities.filter(activity => activity.id !== action.payload.id),
                request_time: action.request_time,
                initiative_request_time: action.request_time,
            };
        case "ACTIVITY_ERROR":
            return {
                ...state,
                // activities: [],
                // initiative_activities: [],
                request_time: null,
                error: action.payload.error,
                loading: false,
                loading_time: null
            };
        case "SET_ACTIVITIES_LOADING":
            return {
                ...state,
                loading: action.payload.loading,
                loading_time: action.request_time
            }
        default:
            return state;
    }
}

export default activities;