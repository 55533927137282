import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { deleteActivityById } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import DeleteModal from "../../components/Modal/DeleteModal";
const DeleteActivity = ({ dispatch, activity, setLoading }) => {
  let [modalActivities, setModalActivities] = useState(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setModalActivities(false);
  });


  const handleSubmitActivities = () => {
    dispatch(deleteActivityById(activity.id));
    setModalActivities(false);
    setLoading(true);
  };


  return (
    <DeleteModal
      buttonText={language["activities"]["form"]["delete"]}
      confirmText={language["activities"]["form"]["deleteConfirm"]}
      confirm={handleSubmitActivities}
    />
  );
};

export default connect()(DeleteActivity);
