const initialState_Card = {
    cards: [],
    request_time: null,
    error: null
};

const cards = (state = initialState_Card, action) => {
    switch (action.type) {
        case 'SET_CARDS':
            return {
                cards: action.payload.cards,
                request_time: action.request_time,
            };
        case "NEW_CARD":
            return {
                cards: [action.payload, ...state.cards],
                request_time: action.request_time,
            };
        case "UPDATE_CARD":
            return {
                cards: state.cards.map(card => {
                    if (card.id === action.payload.id) {
                        return action.payload;
                    }
                    return card;
                }),
                request_time: action.request_time,
            };
        case "DELETE_CARD":
            return {
                ...state,
                cards: state.cards.filter(card => card.id !== action.payload.id),
                request_time: action.request_time,
            };
        case "CARD_ERROR":
            return {
                ...state,
                error: action.payload.error,
                // cards: [],
                request_time: null,
            };
        default:
            return state;
    }
}

export default cards;