const initialState_Activity = {
    permits: [],
    role_permits: [],
    role_id: null,
    request_time: null,
    role_request_time: null,
    error: null,
    loading: false,
    role_loading: false,
    role_permits_edition: [],
    error_edition: null,
    loading_time: null,
    role_loading_time: null
};

const permits = (state = initialState_Activity, action) => {
    switch (action.type) {
        case 'SET_PERMITS':
            return {
                ...state,
                permits: action.payload.permits,
                request_time: action.request_time,
            };
        case 'SET_ROLE_PERMITS':
            return {
                ...state,
                role_permits: action.payload.role_permits,
                role_id: action.payload.role_id,
                role_request_time: action.request_time,
            };
        case "SET_ROLE_PERMIT":
            return {
                ...state,
                permits: [...state.permits, action.payload],
                role_permits: [...state.role_permits, action.payload],
                request_time: action.request_time,
                role_request_time: action.request_time,
            };
        case "DELETE_ROLE_PERMIT":
            return {
                ...state,
                role_permits: state.role_permits.filter(per => per.id !== action.payload.id),
                request_time: action.request_time,
                role_request_time: action.request_time,
            };
        case "SET_PERMITS_ERROR":
            return {
                ...state,
                // permits: [],
                // role_permits: [],
                request_time: null,
                error: action.payload.error,
                loading_time: null,
                loading: false
            };
        case "SET_PERMITS_LOADING":
            return {
                ...state,
                loading: action.payload.loading,
                loading_time: action.request_time
            }
        case "SET_ROLE_PERMITS_LOADING":
            return {
                ...state,
                role_loading: action.payload.loading,
                role_loading_time: action.request_time
            }
        case "SET_ROLE_PERMITS_EDITION":
            return {
                ...state,
                role_permits_edition: action.payload.role_permits,
                role_id: action.payload.role_id,
                role_request_time: action.request_time,
            };
        case "SET_PERMITS_EDITION_RROR":
            return {
                ...state,
                role_permits_edition: [],
                request_time: null,
                error_edition: action.payload.error,
                role_loading: false,
                role_loading_time: null
            };
        default:
            return state;
    }
}

export default permits;