import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ShowStandardTree } from '.';
import { ListTreeTshifts } from '../tshifts';
import { FormGroup, Input, Row, Col, Container, Table , Card} from "reactstrap";

const ShowInitiativeTree = ({ dispatch, initiative }) => {

    let [showTshifts, setShowTshifts] = useState(false);
    let [treeSelected, setTreeSelected] = useState(null);

    return (
        <div>
            {(showTshifts) ? <ListTreeTshifts tree={treeSelected} initiativeId={initiative.id} /> : null}
            <ShowStandardTree treeClickOpen={(tree) => {

                    setTreeSelected(tree);
                    setShowTshifts(true);
                
            }} treeClickClose={() => {
                setTreeSelected(null);
                setShowTshifts(false);
            }}
                initiative={initiative}
             />
        </div>
    );
}

export default connect()(ShowInitiativeTree);