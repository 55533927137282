import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { postActivity, reloadBlockInitiative } from "../../redux/actions";
import { language } from "../../assets/language";
import { useClickAway } from "react-use";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Modal,
} from "reactstrap";
const CreateActivity = ({ dispatch, initiative, setLoading }) => {
  const areas = useSelector((state) => state.areas.areas);
  const users = useSelector((state) => state.users.users);
  const stages = useSelector((state) => state.stages.stages);
  const phases = useSelector((state) => state.phases.phases);

  let [modalActivities, toggleModal] = useState(false);
  let [forecast_date, setForecastDate] = useState(new Date().toISOString());

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    toggleModal(false);
  });

  const handleSubmitActivities = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    dispatch(postActivity(object));
    toggleModal(false);
    setLoading(true);
    if (initiative.is_blocked) {
      dispatch(reloadBlockInitiative(initiative.id));
    }
  };

  return (
    <div>
      <Button
        color='success'
        className='float-right btn-sm'
        onClick={() => toggleModal(true)}
      >
        {language["activities"]["form"]["create"]}
      </Button>
      <Modal
        className='modal-dialog-centered'
        isOpen={modalActivities}
        toggle={() => toggleModal(false)}
      >
        <div className='modal-body p-0'>
          <Card className='bg-secondary shadow border-0'>
            <CardBody className='px-lg-5 py-lg-2'>
              <h2 className='text-muted text-center mt-2 mb-3'>
                {language["activities"]["form"]["create"]}
              </h2>
              <Form onSubmit={handleSubmitActivities} role='form'>
                <FormGroup>
                  <label htmlFor='description'>
                    {language["activities"]["table"]["title"]}
                  </label>
                  <Input
                    maxLength={254}
                    className='form-control-sm'
                    type='textarea'
                    cols={40}
                    rows={5}
                    autoComplete='off'
                    name='description'
                    required
                    multiple={true}
                  />
                </FormGroup>

                <FormGroup>
                  <label>{language["activities"]["table"]["area"]}</label>
                  <Input
                    className='form-control-sm'
                    type={"select"}
                    name='area_id'
                    required
                  >
                    {areas.map((area) => (
                      <option key={area.id} value={area.id}>
                        {area.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label>{language["activities"]["table"]["user"]}</label>
                  <Input
                    className='form-control-sm'
                    type={"select"}
                    name='responsable_user'
                    required
                    defaultValue={initiative.leader_user}
                  >
                    {Object.values(users).map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.full_name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label>{language["activities"]["table"]["stage"]}</label>
                  <Input
                    className='form-control-sm'
                    type={"select"}
                    name='stage'
                    required
                  >
                    {stages
                      .filter(
                        (st) =>
                          st.name !== "idea" &&
                          st.name !== "evaluation" &&
                          st.name !== "finalized" &&
                          st.name !== "deleted"
                      )
                      .map((stage) => (
                        <option key={stage.id} value={stage.name}>
                          {phases.hasOwnProperty(stage.name)
                            ? phases[stage.name].name
                            : stage.name}
                        </option>
                      ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label>
                    {language["activities"]["table"]["forecast_date"]}
                  </label>
                  <Input
                    className='form-control-sm'
                    type='date'
                    name='forecast_date'
                    required
                    value={forecast_date.split("T")[0]}
                    onChange={(e) => setForecastDate(e.target.value)}
                  />
                </FormGroup>

                <FormGroup>
                  <Input hidden name='initiative_id' value={initiative.id} />
                  <div className='text-center mt-4'>
                    <Button
                      onClick={() => toggleModal(false)}
                      color='danger'
                      className='btn-sm'
                    >
                      Cancelar
                    </Button>
                    <Button color='success' className='btn-sm' type='submit'>
                      {language["activities"]["form"]["create"]}
                    </Button>
                  </div>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default connect()(CreateActivity);
